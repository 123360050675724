import { useQuery } from "@tanstack/react-query";
import capitalize from "lodash/capitalize";

import {
  buildQueryFilters,
  type Filter,
  type QueryOptions,
} from "features/query-utils";

import { GuideStatus } from "../constants";
import type { Carespace, TableName } from "../types";

import { queryKeys, select } from ".";

export function useFetchMany(
  filter?: Filter<TableName>,
  options: QueryOptions = {}
) {
  const { enabled } = options;

  return useQuery({
    queryKey: filter ? queryKeys.list(filter) : queryKeys.lists,
    queryFn: async () => {
      const query = buildQueryFilters(select(), filter);

      const { data, error } = await query
        .order("name", { ascending: true })
        .order("created_at", {
          referencedTable: "paf_submission",
          ascending: true,
        });

      if (error) {
        throw error;
      }

      return data.map((network) => ({
        ...network,
        ...network.user_adlo?.[0],
        guide_status:
          network.guide_status &&
          [GuideStatus.SUBMITTED, GuideStatus.ALIGNED].includes(
            network.guide_status as GuideStatus
          )
            ? network.guide_status
            : network.paf_submission?.[0]?.status ||
              network.guide_status ||
              GuideStatus.NA,
        birthday: network.user_adlo?.[0].birthday
          ? new Date(network.user_adlo[0].birthday)
          : null,
        name:
          network.user_adlo?.[0].first_name && network.user_adlo?.[0].last_name
            ? `${capitalize(network.user_adlo[0].first_name)} ${capitalize(
                network.user_adlo[0].last_name
              )}`
            : network.name,
        network: {
          id: network.id,
          name: network.name,
        },
        care_team: network.user_role.reduce(
          (acc, { role, user }) => ({
            ...acc,
            [role]: {
              role,
              name: `${capitalize(user?.first_name || "")} ${capitalize(
                user?.last_name || ""
              )}`,
            },
          }),
          {}
        ),
      })) as Carespace[];
    },
    enabled,
  });
}
