import { createOrgAndIdentity } from "backend/functions";
import { useUpdateUserMutation } from "backend/resources/user";
import { useMutateUserOnboarding } from "backend/resources/userOnboarding";
import { supabase } from "clients/supabaseClient";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import PhoneNumberInput from "components/PhoneNumberInput/PhoneNumberInput";
import { ProfileImage } from "components/Profile/ProfileImage";
import { TextInput } from "components/TextInput";
import { CraniometrixProduct } from "hooks/product/product";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { ChangeEvent, useRef } from "react";
import { isValidPhoneNumber } from "react-phone-number-input";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import type { CareCentralOnboardingPageType } from "state/appOnboarding";
import { useAppOnboardingStore } from "state/appOnboarding";
import { resetAssessmentStore } from "state/assessment";
import { useUserStore } from "state/user";

export function CareCentralOnboarding() {
  /**
   * note @andrew: maybe we can move this to a hook comprising multiple forms, e.g. the return shape of the hook could be
   * onboardingForms
   *   return {
   *     userForm, <-- each using react-hook-form & zod
   *     profileForm,
   *     orgForm
   *   }
   */
  // root
  const navigate = useAppNavigate();
  const currentCareCentralOnboardingPage = useAppOnboardingStore(
    (state) => state.currentCareCentralOnboardingPage
  );
  const setCareCentralOnboardingPage = useAppOnboardingStore(
    (state) => state.setCareCentralOnboardingPage
  );
  const setUserOnboarding = useMutateUserOnboarding().mutateAsync;

  const isNetworkCallInProgress = useAppOnboardingStore(
    (state) => state.isNetworkCallInProgress
  );
  const setIsNetworkCallInProgress = useAppOnboardingStore(
    (state) => state.setIsNetworkCallInProgress
  );

  const resetAppOnboarding = useAppOnboardingStore((state) => state.reset);

  // user
  const authUser = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);
  const resetUserStore = useUserStore((state) => state.reset);
  const signOut = useUserStore((state) => state.signOut);
  const updateUser = useUpdateUserMutation().mutateAsync;

  // profile page
  const firstName = useAppOnboardingStore((state) => state.firstName);
  const setFirstName = useAppOnboardingStore((state) => state.setFirstName);

  const lastName = useAppOnboardingStore((state) => state.lastName);
  const setLastName = useAppOnboardingStore((state) => state.setLastName);

  const cellNumber = useAppOnboardingStore((state) => state.cellNumber);

  const profileImage = useAppOnboardingStore((state) => state.profileImage);
  const setProfileImage = useAppOnboardingStore(
    (state) => state.setProfileImage
  );
  const wouldLikeToReceiveSMSNotifications = useAppOnboardingStore(
    (state) => state.wouldLikeToReceiveSMSNotifications
  );
  const setWouldLikeToReceiveSMSNotifications = useAppOnboardingStore(
    (state) => state.setWouldLikeToReceiveSMSNotifications
  );

  async function onSaveProfile() {
    if (authUser) {
      setIsNetworkCallInProgress(true);
      try {
        await updateUser({
          ...authUser,
          first_name: firstName,
          last_name: lastName,
          profile_image: profileImage,
          cell_number: cellNumber,
          has_approved_sms_notifs: wouldLikeToReceiveSMSNotifications,
        });
        setUser({
          ...authUser,
          first_name: firstName || null,
          last_name: lastName || null,
          profile_image: profileImage || null,
          cell_number: cellNumber || null,
          has_approved_sms_notifs: wouldLikeToReceiveSMSNotifications,
        });
        setCareCentralOnboardingPage("create org");
      } catch {
        // reset to authUser
        setFirstName(authUser?.first_name);
        setLastName(authUser?.last_name);
        if (authUser?.profile_image) {
          setProfileImage(authUser.profile_image);
        }
        setWouldLikeToReceiveSMSNotifications(authUser.has_approved_sms_notifs);
        // TODO: present error to user
      }
      setIsNetworkCallInProgress(false);
    }
  }

  // org page
  const selectedOrgRole = useAppOnboardingStore(
    (state) => state.selectedOrgRole
  );
  const orgName = useAppOnboardingStore((state) => state.orgName);
  const orgAddress = useAppOnboardingStore((state) => state.orgAddress);
  const orgCity = useAppOnboardingStore((state) => state.orgCity);
  const orgState = useAppOnboardingStore((state) => state.orgState);
  const orgZip = useAppOnboardingStore((state) => state.orgZip);

  async function onSaveOrg() {
    if (authUser && selectedOrgRole && orgName) {
      setIsNetworkCallInProgress(true);
      await createOrgAndIdentity({
        authId: authUser.id,
        orgRole: selectedOrgRole,
        orgName,
        isFamilyOrg: false,
        address: orgAddress,
        city: orgCity,
        state: orgState,
        zip: orgZip,
      });
      setUserOnboarding({ userId: authUser.id });
      setIsNetworkCallInProgress(false);
      setCareCentralOnboardingPage(null);
      navigate({ path: SharedRoute.HOME });
    }
  }

  async function handleLogout(e: any) {
    e.stopPropagation();
    resetUserStore();
    resetAssessmentStore();
    await signOut();
  }

  return (
    <ResponsiveModal
      isOpen={true}
      onClose={() => {
        resetAppOnboarding();
      }}
      closeText={
        currentCareCentralOnboardingPage === "welcome"
          ? "Select a Product"
          : undefined
      }
      isNetworkCallInProgress={isNetworkCallInProgress}
      title={getPageTitle(currentCareCentralOnboardingPage)}
      footerButtons={
        currentCareCentralOnboardingPage === "welcome" ? (
          <>
            <div className="flex md:flex-grow">
              <ButtonWithIcon
                onClick={handleLogout}
                text="Logout"
                icon={IconOption.LOGOUT}
              />
            </div>
            <ButtonWithIcon
              onClick={() => {
                setCareCentralOnboardingPage("create profile");
              }}
              text={"Next"}
              icon={IconOption.ARROW}
            />
          </>
        ) : currentCareCentralOnboardingPage === "create profile" ? (
          <>
            <div className="flex md:flex-grow">
              <ButtonWithIcon
                onClick={handleLogout}
                text="Logout"
                icon={IconOption.LOGOUT}
              />
            </div>
            <ButtonWithIcon
              disabled={isNetworkCallInProgress}
              onClick={() => {
                setCareCentralOnboardingPage("welcome");
              }}
              text={"Back"}
              icon={IconOption.BACK_ARROW}
            />
            <ButtonWithIcon
              onClick={async () => {
                await onSaveProfile();
              }}
              disabled={
                !(
                  firstName &&
                  lastName &&
                  cellNumber &&
                  isValidPhoneNumber(cellNumber)
                ) || isNetworkCallInProgress
              }
              text={"Next"}
              icon={IconOption.ARROW}
            />
          </>
        ) : currentCareCentralOnboardingPage === "create org" ? (
          <>
            <div className="flex md:flex-grow">
              <ButtonWithIcon
                onClick={handleLogout}
                text="Logout"
                icon={IconOption.LOGOUT}
              />
            </div>
            <ButtonWithIcon
              onClick={() => {
                setCareCentralOnboardingPage("create profile");
              }}
              disabled={isNetworkCallInProgress}
              text={"Back"}
              icon={IconOption.BACK_ARROW}
            />
            <ButtonWithIcon
              disabled={
                !(orgName && orgAddress && orgCity && orgState && orgZip) ||
                isNetworkCallInProgress
              }
              onClick={async () => {
                await onSaveOrg();
              }}
              text={"Done"}
              icon={IconOption.CHECKMARK}
            />
          </>
        ) : null
      }
      fixedHeight="h-[80vh]"
      showLogo={CraniometrixProduct.CARE_CENTRAL}
      logoSize="small">
      {currentCareCentralOnboardingPage === "welcome" ? (
        <WelcomeMessage />
      ) : currentCareCentralOnboardingPage === "create profile" ? (
        <CreateProfilePage />
      ) : currentCareCentralOnboardingPage === "create org" ? (
        <CreateOrgPage />
      ) : null}
    </ResponsiveModal>
  );
}

function WelcomeMessage() {
  return (
    <div className="flex flex-col gap-4 flex-grow p-2">
      <p className="font-light text-sm md:text-base">
        In just a few simple steps, you’ll be ready to start working with family
        caregivers and your patients using{" "}
        <span>
          Care<span className="font-bold">Central</span>
        </span>
        .
      </p>
      <p className="font-light text-sm md:text-base">
        After you’ve set up your profile and organization, you’ll need to create
        a <span className="font-bold">Carespace </span>
        for the family, and then invite them to it so that you’re all connected.
      </p>
      <p className="font-light text-sm md:text-base">
        There are tutorials along the way to help explain how it all works, but
        if you still have any additional questions just hit the feedback button
        on any page and we’ll be right there to answer.
      </p>
      <p className="font-light text-sm md:text-base">Warmly,</p>
      <p className="flex flex-col gap-1 font-light text-sm md:text-base">
        <span>Nikhil Patel</span>
        <span>Founder & CEO, Craniometrix</span>
      </p>
    </div>
  );
}

function CreateProfilePage() {
  const authUser = useUserStore((state) => state.user);
  const setUser = useUserStore((state) => state.setUser);

  const firstName = useAppOnboardingStore((state) => state.firstName);
  const setFirstName = useAppOnboardingStore((state) => state.setFirstName);

  const lastName = useAppOnboardingStore((state) => state.lastName);
  const setLastName = useAppOnboardingStore((state) => state.setLastName);

  const cellNumber = useAppOnboardingStore((state) => state.cellNumber);
  const setCellNumber = useAppOnboardingStore((state) => state.setCellNumber);

  const profileImage = useAppOnboardingStore((state) => state.profileImage);
  const setProfileImage = useAppOnboardingStore(
    (state) => state.setProfileImage
  );

  const wouldLikeToReceiveSMSNotifications = useAppOnboardingStore(
    (state) => state.wouldLikeToReceiveSMSNotifications
  );
  const setWouldLikeToReceiveSMSNotifications = useAppOnboardingStore(
    (state) => state.setWouldLikeToReceiveSMSNotifications
  );

  const fileUploadRef = useRef<HTMLInputElement>(null);

  function onFirstNameChange(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const newFirstName = e.currentTarget.value;
    setFirstName(newFirstName);
  }

  function onLastNameChange(e: ChangeEvent<HTMLInputElement>) {
    e.preventDefault();
    const newLastName = e.currentTarget.value;
    setLastName(newLastName);
  }

  const handleProfileImageUpload = async (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const file = e.target.files && e.target.files[0];

    if (!file || !authUser) {
      // TODO(jen): handle error
      return;
    }

    e.target.value = "";

    const filename = `${authUser.id}/profile_${Date.now()}.${file.type.replace(
      /(.*)\//g,
      ""
    )}`;

    // TODO(jen): handle errors
    const { data, error } = await supabase.storage
      .from("profile-images")
      .upload(filename, file, {
        cacheControl: "3600",
        upsert: true,
      });
    if (!error) {
      setProfileImage(filename);
      setUser({
        ...authUser,
        profile_image: filename || null,
      });
    }
  };

  function openFileExplorer() {
    fileUploadRef.current?.click();
  }

  return (
    <div className="flex flex-col gap-4 flex-grow pr-3 pb-3">
      {/* profile picture row */}
      <div className="flex justify-between w-full gap-20">
        <div className="flex flex-col relative w-full items-center md:w-auto md:items-start">
          <ButtonWithIcon
            onClick={openFileExplorer}
            text={""}
            className="absolute -bottom-3 left-1/2 bg-white rounded-full"
            icon={IconOption.EDIT}
          />
          <input
            type="file"
            accept="image/*"
            className="hidden"
            ref={fileUploadRef}
            onChange={handleProfileImageUpload}
            data-hj-allow
          />
          <ProfileImage
            size={64}
            className=" w-16 h-16"
            userId={authUser?.id}
            tempProfileImageFilename={profileImage}
          />
        </div>
      </div>
      {/* caregiver info col */}
      <h2 className="text-xl font-light">My Info</h2>
      <div className=" flex-col grid-cols-2 grid  gap-4 w-full">
        {/* first name */}
        <div className="flex flex-col ">
          <p className="">First Name</p>
          <TextInput
            data-hj-allow
            value={firstName || undefined}
            onChange={onFirstNameChange}
            className="w-full"
            placeholder="Enter your first name"
          />
        </div>
        {/* last name */}
        <div className="flex flex-col ">
          <p className="">Last Name</p>
          <TextInput
            data-hj-allow
            value={lastName || undefined}
            onChange={onLastNameChange}
            className="w-full"
            placeholder="Enter your last name"
          />
        </div>
      </div>
      {/* email */}
      <div className="w-max-full overflow-wrap break-words ">
        <p className="">Email</p>
        <p className="font-light py-2 text-gray-400 ">{authUser?.email}</p>
      </div>
      <div className="flex flex-col gap-1">
        <p className="">Cell Number</p>
        <PhoneNumberInput
          cellPhone={cellNumber || undefined}
          setCellPhone={setCellNumber}
        />
        <DefaultCheckbox
          label="I agree to receive SMS based on my data"
          checked={wouldLikeToReceiveSMSNotifications}
          onChange={(isChecked: boolean) =>
            setWouldLikeToReceiveSMSNotifications(isChecked)
          }
        />
        <p className="text-xs pl-8">
          By subscribing you agree to receive marketing text messages at the
          phone number provided. Reply STOP to cancel. Msg rates may apply.
        </p>
      </div>
    </div>
  );
}

function CreateOrgPage() {
  const orgName = useAppOnboardingStore((state) => state.orgName);
  const setOrgName = useAppOnboardingStore((state) => state.setOrgName);

  const orgAddress = useAppOnboardingStore((state) => state.orgAddress);
  const setOrgAddress = useAppOnboardingStore((state) => state.setOrgAddress);

  const orgCity = useAppOnboardingStore((state) => state.orgCity);
  const setOrgCity = useAppOnboardingStore((state) => state.setOrgCity);

  const orgState = useAppOnboardingStore((state) => state.orgState);
  const setOrgState = useAppOnboardingStore((state) => state.setOrgState);

  const orgZip = useAppOnboardingStore((state) => state.orgZip);
  const setOrgZip = useAppOnboardingStore((state) => state.setOrgZip);

  return (
    <div className="flex flex-col gap-4 flex-grow pr-3">
      <label>Name</label>
      <TextInput
        data-hj-allow
        value={orgName}
        onChange={(e) => {
          setOrgName(e.currentTarget.value);
        }}
        className="w-full"
        placeholder="Enter your organization's name"
      />
      <label className="text-sm">Address</label>
      <TextInput
        data-hj-allow
        value={orgAddress}
        onChange={(e) => {
          setOrgAddress(e.currentTarget.value);
        }}
        className="w-full"
        placeholder="Enter address"
      />

      <div className="grid grid-cols-2 w-full gap-3 items-center">
        <div className="flex flex-col gap-1">
          <label className="text-sm">City</label>
          <TextInput
            data-hj-allow
            value={orgCity}
            onChange={(e) => {
              setOrgCity(e.currentTarget.value);
            }}
            className=""
            placeholder="Enter city"
          />
        </div>
        <div className="flex flex-col gap-1">
          <label className="text-sm">State </label>
          <TextInput
            data-hj-allow
            value={orgState}
            onChange={(e) => {
              setOrgState(e.currentTarget.value);
            }}
            className=""
            placeholder="Enter state"
          />
        </div>
      </div>

      <div className="flex flex-col gap-1">
        <label className="text-sm">Zip</label>
        <TextInput
          data-hj-allow
          value={orgZip}
          onChange={(e) => {
            setOrgZip(e.currentTarget.value);
          }}
          className="max-w-[12ch]"
          placeholder="Enter zip"
        />
      </div>
    </div>
  );
}

function getPageTitle(page?: CareCentralOnboardingPageType) {
  switch (page) {
    case "welcome": {
      return (
        <span className="font-light">
          Welcome to Care<span className="font-bold">Central</span>!
        </span>
      );
    }
    case "create profile": {
      return "Step 1: Create your profile";
    }
    case "create org": {
      return "Step 2: Create your organization";
    }
    default: {
      return "Welcome to Craniometrix!";
    }
  }
}
