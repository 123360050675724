import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { QUERY_KEYS } from "backend/query-keys";
import { PlanEntry, TaskWithGuideInfo } from "backend/resources/planEntry";
import { supabase } from "clients/supabaseClient";
import { Database } from "../../../../types/supabase";

export type ServiceEngagementToPlanEntry =
  Database["public"]["Tables"]["service_engagement_to_plan_entry"]["Row"];
const TABLE = "service_engagement_to_plan_entry";

export function usePlanEntriesInServiceEngagement(
  serviceEngagementId?: string | null
) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceEngagementToRequest, serviceEngagementId],
    queryFn: async () => {
      if (!serviceEngagementId) return null;
      const { data } = await supabase
        .from(TABLE)
        .select(
          "plan_entry(*, guide_task(guide_category(*), guide_sub_category(id)), user!plan_entry_user_id_fkey(first_name, last_name), network(*), service_resource_plan_entry(*,service_resource(*, service_engagement(*))))"
        )
        .eq("service_engagement_id", serviceEngagementId);
      const result = data
        ?.map((row) => row.plan_entry)
        .filter((row) => row !== null)
        .sort(
          (a, b) =>
            a?.scheduled_date_time?.localeCompare(
              b?.scheduled_date_time ?? ""
            ) ?? 0
        ) as PlanEntry[];
      return result as TaskWithGuideInfo[];
    },
  });
}

export function useServiceEngagementFromPlanEntryId(
  planEntryId: string | undefined
) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceEngagementFromPlanEntry, { planEntryId }],
    queryFn: async () => {
      if (!planEntryId) return null;
      const { data } = await supabase
        .from(TABLE)
        .select("service_engagement(*)")
        .eq("plan_entry_id", planEntryId)
        .limit(1)
        .maybeSingle();
      return data?.service_engagement;
    },
  });
}
// Retrieves tickets linked to a specific service resource.
// It returns all tickets related to a service resource via service_engagements
export function useTicketsInServiceResource(serviceResourceId?: string | null) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceResourceToRequest, serviceResourceId],
    queryFn: async () => {
      if (!serviceResourceId) return null;
      const { data } = await supabase
        .from("service_resource")
        .select(
          "service_engagement(plan_entry(*, guide_task(guide_category(*), guide_sub_category(id)), user(first_name, last_name), network(name), service_resource_plan_entry(*,service_resource(*, service_engagement(*)))))"
        )
        .eq("id", serviceResourceId);

      const planEntries = data
        ?.flatMap((row) =>
          row.service_engagement.flatMap((se) => se.plan_entry)
        )
        .filter(
          (planEntry) => planEntry !== null && planEntry.is_service_ticket
        );
      return planEntries as TaskWithGuideInfo[];
    },
  });
}

export function useUpsertPlanEntryToServiceEngagement() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (
      serviceEngagementToPlanEntry: ServiceEngagementToPlanEntry
    ) => {
      const { data, error } = await supabase
        .from(TABLE)
        .upsert(serviceEngagementToPlanEntry);
      if (error) {
        throw error;
      }

      return data;
    },
    onMutate: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagementToPlanEntry],
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagementToPlanEntry],
      });
    },
  });
}
