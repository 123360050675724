import ActivitiesIconSvg from "assets/activities-icon.svg?react";
import ArchiveIcon from "assets/archive.svg?react";
import CancelIconSvg from "assets/cancel-icon.svg?react";
import ChatIconSvg from "assets/chat-icon.svg?react";
import CheckmarkIconSvg from "assets/checkmark-icon.svg?react";
import CirclePlusIcon from "assets/circle-plus.svg?react";
import TrashIcon from "assets/delete-icon.svg?react";
import DownloadIconSvg from "assets/download.svg?react";
import EditIconSvg from "assets/edit-folder.svg?react";
import EmailIconSvg from "assets/email-icon.svg?react";
import EventsIconSvg from "assets/events-icon.svg?react";
import FinishLaterIcon from "assets/finish later.svg?react";
import ForwardIconSvg from "assets/forward-icon.svg?react";
import IntakeIconSvg from "assets/intake-icon.svg?react";
import LogoutIconSvg from "assets/logout.svg?react";
import SearchIcon from "assets/magnifying-glass.svg?react";
import MessageIconSvg from "assets/message.svg?react";
import MoreIcon from "assets/more-actions.svg?react";
import NotesIconSvg from "assets/notes-icon.svg?react";
import PADLOCK from "assets/padlock.png";
import PhoneIconSvg from "assets/phone-icon.svg?react";
import PrivacyPolicy from "assets/privacy.png";
import RemoveIcon from "assets/remove.svg?react";
import RestoreSvg from "assets/restore.svg?react";
import RightArrowButtonIcon from "assets/right-arrow-button.svg?react";
import SaveDraftIcon from "assets/save_draft_button.svg?react";
import SendInvitationIcon from "assets/send-invitation.svg?react";
import SwapIcon from "assets/swap.svg?react";
import TasksIconSvg from "assets/tasks-icon.svg?react";
import TermsOfService from "assets/tos.png";
import UploadIconSvg from "assets/upload.svg?react";
import VideoIconSvg from "assets/video_chat.svg?react";
import ViewAssessmentIcon from "assets/view-assessment.svg?react";
import WarningIcon from "assets/warning-blue.svg?react";
import WebIconSvg from "assets/website-icon.svg?react";
import { Arrow } from "icons/Arrow";

export enum IconSize {
  extra_small = 16,
  small = 20,
  medium = 24,
  large = 32,
}

export enum IconOption {
  PLUS,
  ARROW,
  CHAT_ICON,
  DOWN_ARROW,
  BACK_ARROW,
  UP_ARROW,
  EDIT,
  INTAKE,
  VIEW_ASSESSMENT,
  LATER,
  NOTES,
  TASK,
  ACTIVITY,
  EVENT,
  CANCEL,
  TRASH,
  CHECKMARK,
  SAVE_DRAFT,
  MORE,
  PHONE,
  EMAIL,
  WEB,
  PADLOCK,
  LOGOUT,
  SEARCH,
  SEND_INVITATION,
  TERMS_OF_SERVICE,
  PRIVACY_POLICY,
  ARCHIVE,
  MESSAGE,
  UPLOAD,
  SWAP,
  RESTORE,
  FORWARD,
  DOWNLOAD,
  VIDEO,
  REMOVE,
  WARNING,
}

export const ButtonIconMap = {
  [IconOption.PLUS]: <CirclePlusIcon />,
  [IconOption.RESTORE]: <RestoreSvg />,
  [IconOption.UP_ARROW]: (
    <Arrow
      className={`bg-brand-orange fill-white rounded-full p-1`}
      size={30}
    />
  ),
  [IconOption.REMOVE]: (
    <RemoveIcon className="rounded-full overflow-clip w-5 h-5" />
  ),
  [IconOption.ARROW]: <RightArrowButtonIcon />,
  [IconOption.BACK_ARROW]: <RightArrowButtonIcon className="rotate-180" />,
  [IconOption.CHAT_ICON]: <ChatIconSvg />,
  [IconOption.DOWN_ARROW]: <RightArrowButtonIcon className="rotate-90" />,
  [IconOption.EDIT]: <EditIconSvg className="w-full" />,
  [IconOption.VIEW_ASSESSMENT]: <ViewAssessmentIcon />,
  [IconOption.INTAKE]: <IntakeIconSvg />,
  [IconOption.NOTES]: <NotesIconSvg />,
  [IconOption.LATER]: <FinishLaterIcon />,
  [IconOption.TASK]: <TasksIconSvg />,
  [IconOption.ACTIVITY]: <ActivitiesIconSvg />,
  [IconOption.EVENT]: <EventsIconSvg />,
  [IconOption.CANCEL]: <CancelIconSvg />,
  [IconOption.TRASH]: <TrashIcon />,
  [IconOption.CHECKMARK]: <CheckmarkIconSvg className="w-full" />,
  [IconOption.ARCHIVE]: <ArchiveIcon />,
  [IconOption.SEARCH]: <SearchIcon />,
  [IconOption.SEND_INVITATION]: <SendInvitationIcon />,
  [IconOption.SAVE_DRAFT]: <SaveDraftIcon />,
  [IconOption.MORE]: <MoreIcon />,
  [IconOption.PHONE]: <PhoneIconSvg />,
  [IconOption.MESSAGE]: <MessageIconSvg />,
  [IconOption.EMAIL]: <EmailIconSvg />,
  [IconOption.WEB]: <WebIconSvg />,
  [IconOption.UPLOAD]: <UploadIconSvg />,
  [IconOption.DOWNLOAD]: <DownloadIconSvg />,
  [IconOption.SWAP]: <SwapIcon />,
  [IconOption.VIDEO]: <VideoIconSvg />,
  [IconOption.PADLOCK]: (
    <img
      src={PADLOCK}
      alt=""
    />
  ),
  [IconOption.LOGOUT]: <LogoutIconSvg />,
  [IconOption.TERMS_OF_SERVICE]: (
    <img
      src={TermsOfService}
      alt=""
    />
  ),
  [IconOption.PRIVACY_POLICY]: (
    <img
      src={PrivacyPolicy}
      alt=""
    />
  ),
  [IconOption.FORWARD]: <ForwardIconSvg />,
  [IconOption.WARNING]: <WarningIcon />,
};
