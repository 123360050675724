import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import Logo from "components/Logo/logo";
import { CraniometrixProduct } from "hooks/product/product";
import { createPortal } from "react-dom";

export interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  closeText?: string;
  title?: string | React.ReactNode;
  showLogo?: CraniometrixProduct | "base";
  children: React.ReactNode;
}
export default function ModalView({
  isOpen,
  onClose,
  closeText,
  title,
  showLogo,
  children,
}: ModalProps) {
  if (!isOpen) return null;

  return createPortal(
    <div className="inset-0 fixed top-0 left-0 right-0 bottom-0 items-center justify-center w-full h-full bg-white z-20 flex flex-col">
      <div className="w-full h-full shadow-lg flex flex-col relative">
        {/* Close Button */}
        {showLogo ? (
          <div className="pt-4 w-full flex justify-center">
            <Logo
              isCentered
              product={showLogo}
              onClick={() => {}}
            />
          </div>
        ) : null}

        {closeText ? (
          <div className="p-6 pb-0">
            <ButtonWithIcon
              onClick={onClose}
              size={"small"}
              text={closeText}
              icon={IconOption.BACK_ARROW}
            />
          </div>
        ) : null}

        {/* Title */}
        {title ? (
          <div className="py-4 px-5 flex justify-center">
            <h2 className="text-2xl">{title}</h2>
          </div>
        ) : null}

        {/* Children Content */}
        <div className="flex-grow overflow-y-auto">{children}</div>
      </div>
    </div>,
    document.body
  );
}
