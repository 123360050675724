import { useAssessmentIdBySlug } from "backend/resources/assessment";
import {
  AssessmentComponent,
  AssessmentType,
} from "components/AssessmentComponent/AssessmentComponent";
import BackButton from "components/BackButton/BackButton";
import { PAFSubmissionForm } from "components/CarespacePage/PAFSubmission/PAFSubmissionForm";
import { PageMainHeader } from "components/PageMainHeader";
import { useState } from "react";

interface PAFSubmissionPageProps {
  isReadOnly: boolean;
}

export const PAFSubmissionPage: React.FC<PAFSubmissionPageProps> = ({
  isReadOnly,
}) => {
  // TODO: global state to avoid prop drilling
  const [assessmentSlug, setAssessmentSlug] = useState<string | undefined>(
    undefined
  );
  const { data: assessmentId } = useAssessmentIdBySlug(assessmentSlug);
  function openAssessment(assessmentSlug: string | undefined) {
    if (assessmentSlug) {
      setAssessmentSlug(assessmentSlug);
    }
  }

  return (
    <div className="flex flex-col gap-2 pb-16">
      {isReadOnly && <BackButton />}
      <PageMainHeader
        text="Guide Alignment"
        className="mb-4"
      />
      <PAFSubmissionForm
        isReadOnly={isReadOnly}
        openAssessment={openAssessment}
      />
      {assessmentId && (
        <AssessmentComponent
          assessmentId={assessmentId}
          isOpen={!!assessmentSlug}
          onClose={() => setAssessmentSlug(undefined)}
          newAssessmentType={AssessmentType.COMPREHENSIVE}
        />
      )}
    </div>
  );
};
