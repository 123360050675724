import React from "react";
import Skeleton from "react-loading-skeleton";
import { DonutChart, DonutChartData } from "components/DonutChart/DonutChart";

interface DonutChartWithLabelsProps<T> {
  title: string;
  data: T[] | undefined | null;
  colorMapping: Record<string, string>;
  dataToSection: (
    acc: Record<string, DonutChartData>,
    item: T
  ) => Record<string, DonutChartData>;
  labels: Record<string, string>;
  radius?: number;
  textSize?: string;
  isMoney?: boolean;
  showTotalInsideDonut?: boolean;
  onLabelClick?: (label: string) => void;
}

export default function DonutChartWithLabels<T>({
  title,
  data,
  colorMapping,
  dataToSection,
  labels,
  radius,
  textSize,
  showTotalInsideDonut,
  isMoney,
  onLabelClick,
}: DonutChartWithLabelsProps<T>) {
  function createChartData(data: T[] | undefined | null) {
    return (
      data?.reduce((acc: Record<string, DonutChartData>, item: T) => {
        return dataToSection(acc, item);
      }, {}) ?? {}
    );
  }

  const chartData = createChartData(data);
  const totalTasks = Object.values(chartData).reduce(
    (total, task) => total + task.value,
    0
  );

  return (
    <div className="flex gap-20 md:min-w-[500px] md:w-[500px]">
      <div className="flex flex-col gap-3">
        <p className={`text-2xl`}>{title}</p>
        <div className="flex gap-10 items-center">
          {/* Donut Chart */}
          {!data || data.length === 0 ? (
            <div className="relative">
              <Skeleton
                circle={true}
                height={radius ? radius * 2 : 160}
                width={radius ? radius * 2 : 160}
              />
              <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[80px] h-[80px] bg-white z-[2] rounded-full" />
            </div>
          ) : (
            <div className="relative">
              <DonutChart
                data={Object.values(chartData)}
                colorMapping={colorMapping}
                radius={radius}
              />
              {showTotalInsideDonut ? (
                <p className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 text-xs font-bold">
                  {isMoney ? `$${totalTasks.toFixed(0)}` : totalTasks}
                </p>
              ) : null}
            </div>
          )}
          {/* Labels */}
          <div
            style={{ fontSize: textSize }}
            className="gap-3 whitespace-nowrap text-sm grid grid-cols-2 h-min">
            {Object.entries(labels).map(([statusKey, statusLabel], index) => {
              const value = chartData[statusKey]?.value ?? 0;
              const percentage = totalTasks
                ? parseFloat(((value / totalTasks) * 100).toFixed(0))
                : 0;
              return (
                <React.Fragment key={index}>
                  <span
                    className="font-bold flex items-center gap-1 cursor-pointer"
                    key={statusKey}
                    onClick={() => onLabelClick?.(statusKey)}>
                    <div
                      style={{ background: colorMapping[statusKey] }}
                      className="rounded-full w-2 h-2"
                      key={statusKey}
                    />
                    {statusLabel}
                  </span>
                  <span
                    className="flex items-center"
                    key={statusKey + "amount"}>
                    {isMoney ? `$${value.toFixed(0)}` : value} ({percentage}%)
                  </span>
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
