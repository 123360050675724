/**
 * @fileoverview Generic DS TextInput component.
 */
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import type { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { forwardRef } from "react";

const textInputStyles = cva(
  [
    "bg-white",
    "border-2",
    "border-neutral-200",
    "rounded",
    "focus:border-2",
    "focus:border-[#443dc4]",
  ],
  {
    variants: {
      intent: {
        primary: [],
      },
      size: {
        small: ["text-sm", "py-1", "px-2"],
        medium: ["text-sm md:text-base", "py-2", "px-4"],
      },
    },
    defaultVariants: {
      intent: "primary",
      size: "medium",
    },
  }
);

type InputProps = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> &
  VariantProps<typeof textInputStyles>;

/**
 * Generic TextInput component
 *
 * @param props HTML Input props
 * @returns input element
 */
export const TextInput = forwardRef<HTMLInputElement, InputProps>(
  (props, ref) => {
    const { className, intent, size, disabled, ...rest } = props;

    return (
      <input
        ref={ref}
        className={`${textInputStyles({ intent, size, className })} ${
          disabled ? "text-gray-500" : ""
        }`}
        {...rest}
        disabled={disabled}
        data-hj-allow></input>
    );
  }
);
