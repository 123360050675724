import {
  CaregiverStatus,
  ReassessmentReason,
} from "components/CarespacePage/PAFSubmission/pafEnums";
import type { PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import {
  getCurrentValueForSelect,
  getOptionsFromEnum,
} from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { RadioButtonsGroup } from "components/RadioGroup";
import { Select } from "components/Select/Select";
import type { FieldValues, UseFormReturn } from "react-hook-form";
import { FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import type { z } from "zod";

interface ReassessmentInputsProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
}

export const ReassessmentInputs: React.FC<
  ReassessmentInputsProps<z.infer<typeof PAFSchema>>
> = ({ form, isReadOnly }) => {
  const formValues = form.watch();
  return (
    <div className="flex flex-col gap-4">
      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Reassessment Reason"
        name="reassessment_reason"
        render={({ field }) => (
          <Select
            classNames="items-center"
            options={getOptionsFromEnum(ReassessmentReason)}
            currentOption={getCurrentValueForSelect(field)}
            {...field}
          />
        )}
      />

      {formValues.reassessment_reason ===
        ReassessmentReason.REASSESSMENT_DUE_TO_CHANGE_IN_CAREGIVER_STATUS && (
        <div className="flex flex-col gap-4">
          <FormField
            control={form.control}
            disabled={isReadOnly}
            label="Caregiver Status"
            name="caregiver_status"
            render={({ field }) => (
              <RadioButtonsGroup
                id="caregiver_status"
                options={getOptionsFromEnum(CaregiverStatus)}
                row={false}
                {...field}
              />
            )}
          />

          <FormField
            control={form.control}
            disabled={isReadOnly}
            label="If Caregiver Status is other, please specify"
            name="caregiver_status_os"
            render={({ field }) => (
              <Input
                {...field}
                value={field.value}
              />
            )}
          />
        </div>
      )}
    </div>
  );
};
