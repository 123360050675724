import type { Column } from "@tanstack/react-table";
import { useMemo, type ReactNode } from "react";
import type { DateRange } from "react-day-picker";

import { Combobox } from "shared/ui/combobox";
import { DateRangePicker } from "shared/ui/date-range-picker";
import { LabeledContent } from "shared/ui/labeled-content";

import { cn } from "shared/utils/cn";

export interface FiltersProps<TData> {
  data: TData[];
  filters: Column<TData>[];
  additionalContent?: ReactNode;
}

export function Filters<TData>({
  data,
  filters,
  additionalContent,
}: FiltersProps<TData>) {
  const filterOptions = useMemo(
    () =>
      filters.reduce((acc, filter) => {
        const { id, accessorFn } = filter;

        if (!accessorFn) {
          return acc;
        }

        return {
          ...acc,
          [id]: [
            ...new Set(data.map((row, index) => accessorFn(row, index))),
          ].filter((value) => !!value) as string[],
        };
      }, {} as Record<string, string[]>),
    [data, filters]
  );

  return (
    <div className="flex flex-col gap-4 xl:flex-row xl:gap-2">
      {additionalContent && (
        <div className="w-full xl:w-1/3">{additionalContent}</div>
      )}

      <div
        className={cn(
          "grid grid-flow-dense content-start gap-4 grow",
          additionalContent ? "grid-cols-3" : "grid-cols-4"
        )}>
        {filters.map((filter) => (
          <LabeledContent
            key={filter.id}
            label={
              filter.columnDef.meta?.columnFiltering?.filterLabel || "Filter"
            }>
            {(() => {
              switch (filter.columnDef.meta?.columnFiltering?.filterType) {
                case "date": {
                  return (
                    <DateRangePicker
                      value={filter.getFilterValue() as DateRange | undefined}
                      onChange={filter.setFilterValue}
                    />
                  );
                }
                default: {
                  return (
                    <Combobox
                      options={filterOptions[filter.id].map((option) => ({
                        value: option,
                        label: filter.columnDef.meta?.columnFiltering
                          ?.formatOptionLabel
                          ? filter.columnDef.meta?.columnFiltering.formatOptionLabel(
                              option
                            )
                          : option,
                      }))}
                      value={filter.getFilterValue() as string | undefined}
                      onChange={filter.setFilterValue}
                      searchable
                    />
                  );
                }
              }
            })()}
          </LabeledContent>
        ))}
      </div>
    </div>
  );
}
