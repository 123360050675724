import Skeleton from "react-loading-skeleton";
import { useRecommendation } from "backend/resources/userRecommendation";
import { useWindowSize } from "hooks/useWindowSize";
import { useGptStore } from "state/gpt";
import { CollapseButton } from "components/ChatGptSideBar/ChatGptSideBar";
import { MessagesThread } from "components/ChatGptSideBar/MessagesThread";

export function SideBarRecommendationsPage() {
  const { isMobile } = useWindowSize();

  const userAssessmentChatGptRecommendationId = useGptStore(
    (state) => state.userAssessmentChatGptRecommendationId
  );

  const { isLoadingRecommendation, recommendation } = useRecommendation(
    userAssessmentChatGptRecommendationId || ""
  );

  return (
    <div className="flex flex-col max-h-full flex-grow w-full">
      <div className="flex py-3 px-2 gap-3">
        {!isMobile && <CollapseButton />}

        {isLoadingRecommendation ? (
          <Skeleton className="w-24 h-4" />
        ) : (
          <h1 className="font-bold -mt-1">{recommendation?.title}</h1>
        )}
      </div>
      <MessagesThread
        threadId={userAssessmentChatGptRecommendationId || ""}
        pageType="recommendationConversationPage"
      />
    </div>
  );
}
