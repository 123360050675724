import type { UserBookmark } from "backend/resources/userBookmark";
import { useBookmarkQuery } from "backend/resources/userBookmark";
import { AddBookarksModalProps } from "components/AddBookmarksModal";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { Bookmark } from "components/LibraryPage/Bookmarks/Bookmark";
import { useState } from "react";
import Skeleton from "react-loading-skeleton";

export function AddBookmarksModalContent({
  filteredBookmarks,
  setShowPopover,
  saveSelectedBookmarkIds,
  savedBookmarkIds,
}: AddBookarksModalProps) {
  const {
    isLoadingBookmarks,
    bookmarks: allBookmarks,
    isFetchingBookmarks,
    refetchBookmarks,
  } = useBookmarkQuery();

  function onSelectBookmark(bookmark: UserBookmark) {
    if (selectedUserBookmarks.includes(bookmark.id)) {
      setSelectedBookmarkIds([
        ...selectedUserBookmarks.filter(
          (selectedUserBookmarkId) => selectedUserBookmarkId !== bookmark.id
        ),
      ]);
    } else {
      setSelectedBookmarkIds([...selectedUserBookmarks, bookmark.id]);
    }
  }

  const [selectedUserBookmarks, setSelectedBookmarkIds] =
    useState<string[]>(savedBookmarkIds);

  const bookmarks = filteredBookmarks || allBookmarks;

  const hasBookmarks = bookmarks && bookmarks.length > 0;
  return (
    <div className="grid grid-rows-[auto,1fr,auto] w-full">
      <div className="flex flex-col gap-3 w-full">
        {!hasBookmarks && !isLoadingBookmarks && !isFetchingBookmarks && (
          <p className="mt-10 -mb-10 font-light mx-10">No bookmarks yet.</p>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4 my-8 max-h-[65vh] md:overflow-y-scroll">
          {isLoadingBookmarks || isFetchingBookmarks ? (
            <Skeleton
              count={3}
              containerClassName="w-full flex gap-4 w-[700px] h-[200px]"
            />
          ) : (
            hasBookmarks &&
            bookmarks.map((bookmark: any) => {
              const isChecked = selectedUserBookmarks.includes(bookmark.id);

              return (
                <div className={`flex gap-4 items-center w-full p-1`}>
                  <DefaultCheckbox
                    checked={isChecked}
                    onChange={() => {
                      onSelectBookmark(bookmark);
                    }}
                    key={`${bookmark.id}-checkbox}`}
                  />
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      onSelectBookmark(bookmark);
                    }}>
                    <Bookmark
                      key={`${bookmark.id}-bookmark`}
                      bookmark={bookmark}
                      refetchBookmarks={refetchBookmarks}
                      hideActionbar={true}
                      isInPlanEntry={true}
                      disableClick
                    />
                  </button>
                </div>
              );
            })
          )}
        </div>
        <FooterButtons>
          <ButtonWithIcon
            className=""
            onClick={(e: any) => {
              e.stopPropagation();
              setShowPopover(false);
            }}
            text="Cancel"
            icon={IconOption.CANCEL}
          />
          <ButtonWithIcon
            className=""
            onClick={(e: any) => {
              e.stopPropagation();
              saveSelectedBookmarkIds(selectedUserBookmarks);
              setShowPopover(false);
            }}
            text="Done"
            icon={IconOption.CHECKMARK}
          />
        </FooterButtons>
      </div>
    </div>
  );
}
