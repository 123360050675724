/**
 * @fileoverview Sets up the Zustand library folders store.
 * This store holds information about library folders state.
 */
import { create } from "zustand";
import { persist } from "zustand/middleware";
import { LocalStorageKeys } from "state/localStorageKeys";

/**
 * Hook to access the logout reason store
 */

export enum LogoutReasonType {
  USER_INITIATED = "USER_INITIATED",
  WRONG_LINK = "WRONG_LINK",
}

type State = {
  logoutStatus?: string | undefined;
  logoutReason?: LogoutReasonType | undefined;
};

type Actions = {
  setLogoutStatus: (logoutStatus: string) => void;
  setLogoutReason: (logoutReason: LogoutReasonType | undefined) => void;
  reset: () => void;
};

const initialState: State = {
  logoutStatus: undefined,
  logoutReason: undefined,
};

export const useLogoutReasonStore = create<State & Actions>()(
  persist(
    (set, get) => ({
      ...initialState,

      setLogoutStatus: (logoutStatus: string) => {
        set({ logoutStatus });
      },

      setLogoutReason: (logoutReason: LogoutReasonType | undefined) => {
        set({ logoutReason });
      },

      reset: () => set({ ...initialState }),
    }),
    {
      name: LocalStorageKeys.logoutReason, // unique name
      getStorage: () => localStorage, // (optional) by default the 'localStorage' is used
    }
  )
);
