/**
 * @fileoverview Sets up the Zustand assessment store.
 * This store holds information about the action bar.
 */
import { create } from "zustand";

type State = {
  isDisabled: boolean;
};

type Actions = {
  setIsDisabled: (isDisabled: boolean) => void;
};

const initialState: State = {
  isDisabled: false,
};

/**
 * Hook to access the action bar store
 */
export const useActionBarStore = create<State & Actions>((set, get) => ({
  ...initialState,

  setIsDisabled: (isDisabled: boolean) => set({ isDisabled }),
}));
