import EmptyProfileIcon from "assets/empty-profile-icon.svg?react";
import type { UserAdlo } from "backend/resources/userAdlo";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { getInitials } from "components/AdloComponent/AdloComponent";

const adloInitialStyles = cva([], {
  variants: {
    size: {
      small: ["w-6", "h-6", "text-xs"],
      medium: ["w-20", "h-20", "text-base"],
      default: ["w-24", "h-24", "text-xl"],
    },
  },
  defaultVariants: {
    size: "default",
  },
});

export function AdloInitials({
  userAdlo,
  ...variantProps
}: { userAdlo: UserAdlo | undefined | null } & VariantProps<
  typeof adloInitialStyles
>) {
  const initials = getInitials(userAdlo);
  if (initials) {
  } else {
    return (
      <EmptyProfileIcon
        className={`${adloInitialStyles(
          variantProps
        )} rounded-full object-cover`}
      />
    );
  }
  return (
    <div
      className={`${adloInitialStyles(
        variantProps
      )} flex items-center justify-center text-center rounded-full border bg-white/10 font-semibold`}>
      {getInitials(userAdlo)}
    </div>
  );
}
