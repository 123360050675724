import { AppHeader } from "components/App/AppHeader";
import { CareCentralOnboarding } from "components/CareCentralOnboarding/CareCentralOnboarding";
import CarePilotOnboarding from "components/CarePilotOnboarding/CarePilotOnboarding";
import { Alfred } from "components/ChatGptSideBar";
import { FloatingAlfredButton } from "components/ChatGptSideBar/FloatingAlfredButton";
import { SideNav } from "components/NavBar";
import BottomNavBar from "components/NavBar/BottomNavBar";
import { UserRoleForm } from "components/UserRoleForm/UserRoleForm";
import { useUserOnboarding } from "hooks/useUserOnboarding";
import { useWindowSize } from "hooks/useWindowSize";
import "react-loading-skeleton/dist/skeleton.css";

interface AppLayoutProps {
  children?: React.ReactNode;
  hideSideNav?: boolean;
}

export const AppLayout: React.FC<AppLayoutProps> = ({
  children,
  hideSideNav = false,
}) => {
  // chatgpt sidebar state
  const { isMobile } = useWindowSize();

  // onboarding state
  const {
    isUserRoleSelectionShowing,
    isCareCentralOnboardingShowing,
    isCarePilotOnboardingShowing,
  } = useUserOnboarding();
  return (
    <div className="flex flex-col font-poppins font-light w-full h-full">
      {isUserRoleSelectionShowing ? (
        <UserRoleForm />
      ) : isCareCentralOnboardingShowing ? (
        <CareCentralOnboarding />
      ) : isCarePilotOnboardingShowing ? (
        <CarePilotOnboarding />
      ) : null}

      <AppHeader />

      <div className="flex flex-grow overflow-y-hidden">
        {hideSideNav ? null : isMobile ? <BottomNavBar /> : <SideNav />}

        <div className="min-w-0 h-full flex-grow relative overflow-y-scroll">
          {children}
        </div>

        <FloatingAlfredButton />

        <Alfred />
      </div>
    </div>
  );
};
