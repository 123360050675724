import * as Sentry from "@sentry/react";
import { useEffect, useState } from "react";
import OneSignal from "react-onesignal";
import { oneSignal } from "backend/functions";
import { useShowWelcomeMessage } from "backend/resources/userOnboarding";
import { useUserOnboarding } from "hooks/useUserOnboarding";
import { useUserStore } from "state/user";

const DOUBLE_INIT_ERROR_MESSAGE = "OneSignal is already initialized";

export default function OneSignalComponent() {
  const authUser = useUserStore((state) => state.user);
  const [isOneSignalInitialized, setIsOneSignalInitialized] = useState(false);
  const {
    isLoading: isLoadingOnboarding,
    isUserRoleSelectionShowing,
    isCareCentralOnboardingShowing,
    isCarePilotOnboardingShowing,
  } = useUserOnboarding();
  const { shouldShowWelcomeMessage } = useShowWelcomeMessage(authUser?.id);

  const isOnboarding =
    isUserRoleSelectionShowing ||
    isCareCentralOnboardingShowing ||
    (isCarePilotOnboardingShowing && shouldShowWelcomeMessage);
  useEffect(() => {
    async function setupOneSignal() {
      if (
        authUser &&
        !isOneSignalInitialized &&
        !isOnboarding &&
        !isLoadingOnboarding
      ) {
        // initialize OneSignal
        setIsOneSignalInitialized(true);
        if (OneSignal.Notifications.isPushSupported()) {
          try {
            await OneSignal.init({
              appId: import.meta.env.VITE_ONE_SIGNAL_APP_ID,
              allowLocalhostAsSecureOrigin: true,
            });
          } catch (error) {
            Sentry.captureException(error);
          }
          try {
            // configure OneSignal
            await OneSignal.login(authUser.id);
            await OneSignal.Slidedown.promptPush();
            await oneSignal(authUser.id);
          } catch (error: any) {
            if (error.message !== DOUBLE_INIT_ERROR_MESSAGE) {
              Sentry.captureException(error);
            }
          }
        }
      }
    }
    setupOneSignal();
  }, [
    authUser,
    isOneSignalInitialized,
    isOnboarding,
    isLoadingOnboarding,
    shouldShowWelcomeMessage,
  ]);
  return null;
}
