import type { OrgRoleType } from "backend/resources/userRole/types";
import { LoadingSpinner } from "components/LoadingSpinner";
import { searchOrgMembers } from "hooks/orgMember/orgMember";
import { useEffect, useState } from "react";
import type { UseFormReturn } from "react-hook-form";
import type { orgMemberSchema } from "shared/forms/CarespaceCreationWizard/AddOrgMemberNavigatorForm";
import { Combobox } from "shared/ui/combobox";
import { FormField } from "shared/ui/form";
import { capitalizeFirstLetter } from "utils";
import type { z } from "zod";

export type OrgMember = {
  organization_id: string;
  org_role: string;
  user_id: string;
  first_name: string;
  last_name: string;
  cell_number: string;
  email: string;
};

export function OrgMemberCombobox({
  form,
  orgId,
  role_type,
  disabled,
  isSuperSuperUser = false,
}: {
  form: UseFormReturn<z.infer<typeof orgMemberSchema>>;
  orgId: string | undefined;
  role_type: OrgRoleType | undefined;
  disabled?: boolean;
  isSuperSuperUser?: boolean;
}) {
  const [options, setOptions] = useState<OrgMember[]>([]);
  const [networkCallInProgress, setNetworkCallInProgress] = useState(false);

  useEffect(() => {
    const fetchMatchingOrgMembers = async () => {
      setNetworkCallInProgress(true);
      const { data, error } = await searchOrgMembers({
        orgId,
        searchTerm: "",
        role_type,
        isSuperSuperUser,
      });
      if (!error && !!data) {
        setOptions(data);
      }
      setNetworkCallInProgress(false);
    };

    fetchMatchingOrgMembers();
  }, [role_type, isSuperSuperUser, orgId]);

  if (networkCallInProgress) {
    return <LoadingSpinner className="w-6 h-6" />;
  }

  return (
    <FormField
      control={form.control}
      name={"orgMember"}
      label="Organization Member"
      render={({ field }) => (
        <Combobox
          options={options.map((orgMember) => ({
            value: orgMember.user_id,
            label: `${capitalizeFirstLetter(
              orgMember.first_name
            )} ${capitalizeFirstLetter(orgMember.last_name)}`,
          }))}
          value={field.value?.user_id}
          onChange={(newValue) =>
            form.setValue(
              "orgMember",
              options.find((option) => option.user_id === newValue)
            )
          }
        />
      )}
    />
  );
}
