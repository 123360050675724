import { useNavigateBack } from "lib/routing";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";

export default function BackButton({ className }: { className?: string }) {
  const navigateBack = useNavigateBack();
  return (
    <ButtonWithIcon
      onClick={() => navigateBack()}
      text={"Back"}
      className={className}
      icon={IconOption.BACK_ARROW}
      size="small"
    />
  );
}
