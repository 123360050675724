import { create } from "zustand";

export type AdminFilterState = {
  selectedOrgId: string | undefined;
  setSelectedOrgId: (orgId: string | undefined) => void;
};

export const useAdminFilterStore = create<AdminFilterState>((set) => ({
  selectedOrgId: undefined,
  setSelectedOrgId: (orgId: string | undefined) =>
    set(() => ({ selectedOrgId: orgId })),
}));
