import { useState } from "react";
import { useOrg } from "backend/resources/orgRole";
import { EditOrganizationForm } from "shared/forms/EditOrganizationForm";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { useAdminFilterStore } from "state/adminFilter/adminFilter";

export const DEFAULT_HELPLINE_PHONE_NUMBER = "917-982-2873";

export default function GeneralOrganizationTab() {
  const { selectedOrgId } = useAdminFilterStore();
  const { data: org } = useOrg(selectedOrgId);

  return (
    <div className="flex flex-col gap-5">
      {/* Organization Name */}
      <div>
        <div className="flex gap-2">
          <p className="text-lg">Organization Name</p>
          <EditOrganizationButton orgId={selectedOrgId} />
        </div>
        <p className="text-sm">{org?.name} </p>
      </div>
      <div>
        <p className="text-lg">Address</p>
        <p className="text-sm">{org?.address} </p>
        <p className="text-sm">
          {org?.city}, {org?.state} {org?.zip}
        </p>
      </div>

      <div>
        <p className="text-lg">Helpline Phone Number</p>
        <p className="text-sm">
          {org?.helpline_phone_number ?? DEFAULT_HELPLINE_PHONE_NUMBER}{" "}
        </p>
      </div>
    </div>
  );
}

function EditOrganizationButton({ orgId }: { orgId?: string }) {
  const [isEditOrganizationModalOpen, setIsEditOrganizationModalOpen] =
    useState(false);
  return (
    <>
      {isEditOrganizationModalOpen ? (
        <EditOrganizationForm
          organizationId={orgId}
          onClose={() => {
            setIsEditOrganizationModalOpen(false);
          }}
        />
      ) : null}
      <ButtonWithIcon
        onClick={() => setIsEditOrganizationModalOpen(true)}
        size={"small"}
        text={"Edit Organization"}
        icon={IconOption.EDIT}
      />
    </>
  );
}
