import { Assessment } from "backend/resources/assessment";
import {
  AssessmentQuestion,
  AssessmentQuestionInputType,
  useQuestionsInAssessment,
} from "backend/resources/assessmentQuestion";
import QuestionAndInput from "components/AssessmentComponent/QuestionAndInput";
import ScoreKey from "components/AssessmentComponent/ScoreKey";
import ReactMarkdown from "react-markdown";

interface AssessmentQuestionsPageProps {
  isInViewMode: boolean;
  activeAssessment: Assessment | undefined | null;
  totalScore?:
    | number
    | { distress: number; severity: number }
    | { physical_health: number; mental_health: number };
  selectedCaregiver: string | undefined;
  isPrinting?: boolean;
}

export default function AssessmentQuestionsPage({
  isInViewMode,
  activeAssessment,
  totalScore,
  selectedCaregiver,
  isPrinting = false,
}: AssessmentQuestionsPageProps) {
  const { data: assessmentQuestions, isLoading: assessmentQuestionsLoading } =
    useQuestionsInAssessment(activeAssessment?.id);
  const questionsType = assessmentQuestions?.[0]?.type;
  const getGridWrapper = (type: AssessmentQuestionInputType | undefined) => {
    switch (type) {
      case "slider":
      case "free_text":
        return "md:grid flex flex-col grid-cols-[6fr,5fr]";
      case "select":
      case "multi_select":
        return "grid grid-cols-[15fr,1fr]";
      case "npi-q":
        return "md:grid flex flex-col  grid-cols-[1fr]";
      default:
        return "grid grid-cols-[6fr,1fr]";
    }
  };

  if (
    !assessmentQuestions ||
    assessmentQuestions.length === 0 ||
    assessmentQuestionsLoading
  ) {
    return null;
  }
  return (
    <div className="pr-6">
      <p className="text-sm font-bold">Instructions</p>
      <ReactMarkdown
        className="text-sm"
        components={{
          p: ({ children }) => (
            <p style={{ marginBottom: "1rem" }}> {children} </p>
          ),
        }}>
        {activeAssessment?.instructions}
      </ReactMarkdown>
      <div
        className={`relative w-full  ${getGridWrapper(
          questionsType
        )} gap-y-2 gap-x-4 mt-4`}>
        <InputColumnHeader assessmentQuestions={assessmentQuestions} />
        {assessmentQuestions?.map((question) => (
          <QuestionAndInput
            key={question.id}
            question={question}
            isInViewMode={isInViewMode}
          />
        ))}
      </div>
      {activeAssessment?.score_key && isInViewMode && (
        <ScoreKey
          totalScore={totalScore}
          scoreKey={activeAssessment?.score_key}
        />
      )}
    </div>
  );
}

function InputColumnHeader({
  assessmentQuestions,
}: {
  assessmentQuestions: AssessmentQuestion[];
}) {
  const firstQuestion = assessmentQuestions?.[0];
  switch (firstQuestion?.type) {
    case "yes_or_no":
      return (
        <>
          {/* <div /> */}
          {/* <YesOrNoGridWrapper additionalOptionsLength={firstQuestion?.raw_data?.additional_options?.length ?? 0}>
            <p>Yes</p>
            <p>No</p>
            {firstQuestion?.raw_data.additional_options?.map((option, index) => (
              <p key={index}>{option}</p>
            ))}
          </YesOrNoGridWrapper> */}
        </>
      );
    case "radio":
      return (
        <>
          <div className="grid-cols-[1fr,3fr,3fr] gap-4 text-sm font-bold">
            <p className="text-center">Stage</p>
            <p>Stage Name</p>
            <p>Characteristic</p>
          </div>
          <p className="text-sm font-bold">Select Stage</p>
        </>
      );
    default:
      return null;
  }
}
