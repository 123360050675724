import type { HighChartDataPoint } from "components/HighChart";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef } from "react";

export interface HighPieChartProps {
  data: HighChartDataPoint[];
  title?: string;
  titleMargin?: number;
  isLoading?: boolean;
  chartMargin?: number;
  convertToDonut?: boolean;
  showDataLabels?: boolean;
  showTooltip?: boolean;
  showLegend?: boolean;
  size?: string;
  height?: string;
}

export const HighPieChart: React.FC<HighPieChartProps> = ({
  data,
  title,
  titleMargin,
  chartMargin,
  isLoading = false,
  convertToDonut = false,
  showDataLabels = false,
  showTooltip = false,
  showLegend = false,
  size = 180,
  height = "50%",
}) => {
  const chartRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    if (chartRef.current && chartRef.current.chart) {
      if (isLoading) {
        chartRef.current.chart.showLoading("Loading data...");
      } else {
        chartRef.current.chart.hideLoading();
      }
    }
  }, [isLoading]);

  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "pie",
      margin: chartMargin,
      height,
      alignTicks: false,
      // styledMode: true,
    },
    title: {
      text: title,
      margin: titleMargin,
    },
    plotOptions: {
      pie: {
        shadow: false,
        center: ["45%", "50%"],
        innerSize: convertToDonut ? "50%" : undefined,
        showInLegend: showLegend,
        size,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      enabled: showLegend,
      labelFormat: "{name}: {y}",
      x: 0,
      itemStyle: {
        fontSize: "12px",
      },
    },
    loading: {
      hideDuration: 100,
      showDuration: 100,
      labelStyle: {
        color: "#666666",
        fontSize: "16px",
        fontWeight: "bold",
      },
      style: {
        backgroundColor: "rgba(255, 255, 255, 0.8)",
      },
    },
    tooltip: {
      enabled: showTooltip,
      pointFormat: "{series.name}: <b>{point.y}</b> ({point.percentage:.1f}%)",
    },
    series: [
      {
        name: "Status",
        type: "pie",
        data:
          data.length > 0
            ? data
            : [{ name: "No data", y: 100, color: "#d1d5db" }],
        size,
        dataLabels: {
          enabled: showDataLabels,
          format: "{point.name}: {point.y} ({point.percentage:.1f}%)",
        },
        keys: ["name", "y"],
      } as Highcharts.SeriesPieOptions,
    ],
  };

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartRef}
    />
  );
};
