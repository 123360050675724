import { create } from "zustand";
import type { ShareableInterventionUpsertType } from "backend/resources/shareableIntervention/shareableIntervention";

type ShareableRecommendationState = {
  title: string | undefined;
  details: string | undefined;
  shareableInterventions: ShareableInterventionUpsertType[];
  activeShareableIntervention: ShareableInterventionUpsertType | undefined;
};

type ShareableRecommendationActions = {
  setTitle: (title: string | undefined) => void;
  setDetails: (details: string | undefined) => void;
  setShareableInterventions: (
    shareableInterventions: ShareableInterventionUpsertType[]
  ) => void;
  setActiveShareableIntervention: (
    activeShareableIntervention: ShareableInterventionUpsertType | undefined
  ) => void;
  reset: () => void;
};

export const useShareableRecommendationStore = create<
  ShareableRecommendationState & ShareableRecommendationActions
>((set) => ({
  title: undefined,
  details: undefined,
  shareableInterventions: [],
  activeShareableIntervention: undefined,

  setTitle: (title) => set({ title }),
  setDetails: (details) => set({ details }),
  setShareableInterventions: (
    shareableInterventions: ShareableInterventionUpsertType[]
  ) => {
    const uniqueInterventions = shareableInterventions.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i || !v.id
    );
    set({ shareableInterventions: uniqueInterventions });
  },
  setActiveShareableIntervention: (activeShareableIntervention) =>
    set({ activeShareableIntervention }),
  reset: () =>
    set({
      title: undefined,
      details: undefined,
      shareableInterventions: [],
      activeShareableIntervention: undefined,
    }),
}));
