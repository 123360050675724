import {
  useSaveInterventionsToRecommendation,
  useShareableInterventionFromId,
} from "backend/resources/shareableIntervention/shareableIntervention";
import { FreeFormText } from "components/AssessmentComponent/Input/inputTypes/FreeFormText";
import BackButton from "components/BackButton/BackButton";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import FooterButtons from "components/FooterButtons/FooterButtons";
import ModalView from "components/ModalView/ModalView";
import { PageContainer } from "components/PageContainer";
import { FormContainer } from "components/PageContainer/FormContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { TextInput } from "components/TextInput";
import { useWindowSize } from "hooks/useWindowSize";
import { useNavigateBack } from "lib/routing";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useShareableRecommendationStore } from "state/shareableRecommendation.ts/shareableRecommendation";

export default function CreateOrEditShareableInterventionPage() {
  const { id } = useParams();
  const { isMobile } = useWindowSize();
  const navigateBack = useNavigateBack();

  if (isMobile) {
    return (
      <ModalView
        isOpen={true}
        title={id ? "Edit Suggestion" : "Create Suggestion"}
        closeText="Back"
        onClose={() => {
          navigateBack();
        }}>
        <PageContainer>
          <CreateOrEditShareableInterventionPageComponent />
        </PageContainer>
      </ModalView>
    );
  } else {
    return (
      <PageContainer>
        <BackButton className="w-full justify-center flex md:justify-start mb-4" />
        <PageMainHeader text={id ? "Edit Suggestion" : "Create Suggestion"} />
        <CreateOrEditShareableInterventionPageComponent />
      </PageContainer>
    );
  }
}

function CreateOrEditShareableInterventionPageComponent() {
  const navigateBack = useNavigateBack();
  const { id } = useParams();
  const [title, setTitle] = useState<string | undefined>(undefined);
  const [details, setDetails] = useState<string | undefined>(undefined);
  const { setShareableInterventions, shareableInterventions } =
    useShareableRecommendationStore();
  // populate title and details with intervention details and title from id if exists
  const { data: intervention } = useShareableInterventionFromId(id);
  const saveInterventionsForRecommendation =
    useSaveInterventionsToRecommendation(
      intervention?.recommendation_id
    ).mutateAsync;
  useEffect(() => {
    if (intervention) {
      setTitle(intervention.title);
      setDetails(intervention.details);
    }
  }, [intervention]);

  function onSave() {
    // it shouldnt save as a mutation, it should add the intervention to the interventions in the rec state.
    if (title && details) {
      if (intervention) {
        saveInterventionsForRecommendation([
          {
            ...intervention,
            title,
            details,
            deprecated: true,
          },
        ]);
      } else {
        setShareableInterventions([
          ...shareableInterventions,
          { title, details, deprecated: true, id },
        ]);
      }
    }
    navigateBack();
  }

  return (
    <div className="md:pt-10 flex flex-col gap-4 font-light">
      <FormContainer className="flex flex-col gap-4">
        {/* Title */}
        <label>Title</label>
        <TextInput
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
        {/* Details */}
        <label>Details</label>
        <FreeFormText
          answer={details}
          onChange={(value) => {
            setDetails(value?.toString());
          }}
        />
      </FormContainer>
      <FooterButtons>
        <ButtonWithIcon
          onClick={() => {
            navigateBack();
          }}
          text={"Cancel"}
          size={"small"}
          icon={IconOption.CANCEL}
        />
        <ButtonWithIcon
          onClick={() => {
            onSave();
          }}
          text={"Save"}
          disabled={!details || !title}
          size={"small"}
          icon={IconOption.CHECKMARK}
        />
      </FooterButtons>
    </div>
  );
}
