import { useNetworksInOrganization } from "backend/resources/network/network";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { CarespacePageTabs } from "components/CarespacePage/Tabs/carespaceTypes";
import { useNavigateToCarespaceTab } from "routes/routesUtil";
import { useAdminFilterStore } from "state/adminFilter/adminFilter";

export default function CarespacesTab() {
  const navigateToCarespaceTab = useNavigateToCarespaceTab();
  const { selectedOrgId } = useAdminFilterStore();
  const { networks } = useNetworksInOrganization(selectedOrgId);

  return (
    <div className="gap-4 grid grid-cols-4 items-center text-xs md:text-base">
      <p className="font-medium">Name</p>
      <p className="font-medium text-center">Patient(s)</p>
      <p className="font-medium text-center">CN(s)</p>
      <p className="font-medium text-center">PCP</p>
      {networks?.map((network) => (
        <div
          key={network.id}
          className="contents">
          <button
            className="text-start"
            onClick={() =>
              navigateToCarespaceTab(CarespacePageTabs.CARE_PLAN, {
                networkId: network.id,
              })
            }>
            {network.name}
          </button>
          <button
            className="line-clamp-1"
            onClick={() =>
              navigateToCarespaceTab(CarespacePageTabs.CARE_PLAN, {
                networkId: network.id,
              })
            }>
            {network.user_adlo
              .map((adlo) => `${adlo.first_name} ${adlo.last_name}`)
              .join(", ")}
          </button>
          <button
            onClick={() =>
              navigateToCarespaceTab(CarespacePageTabs.CARE_PLAN, {
                networkId: network.id,
              })
            }>
            <p className="line-clamp-1">
              {network.user_role
                .filter(
                  (user_role) =>
                    user_role.role === NetworkRoleType.CARE_NAVIGATOR
                )
                .map(
                  (user_role) =>
                    `${user_role?.user?.first_name} ${user_role?.user?.last_name}`
                )
                .join(", ")}
            </p>
          </button>
          <button
            onClick={() =>
              navigateToCarespaceTab(CarespacePageTabs.CARE_PLAN, {
                networkId: network.id,
              })
            }>
            <p className="line-clamp-1">
              {network.primary_care_physician.name}
            </p>
          </button>
        </div>
      ))}
    </div>
  );
}
