import TakenCircleSvg from "assets/taken-circle.svg?react";
import { NotificationType, sendNotification } from "backend/functions";
import type { InvitationInsert } from "backend/resources/invitation/invitation";
import { useInsertInvitation } from "backend/resources/invitation/invitation";
import { useCreateNetworkMutation } from "backend/resources/network/network";
import { useSaveUserAdlo } from "backend/resources/userAdlo";
import { useInsertProviderToNetwork } from "backend/resources/userRole";
import type { z } from "zod";

import { useOrg } from "backend/resources/orgRole";
import { NetworkRoleType, OrgRoleType } from "backend/resources/userRole/types";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CarespacePageTabs } from "components/CarespacePage/Tabs/carespaceTypes";
import { useNavigateToCarespaceTab } from "routes/routesUtil";
import type { AddOrgMemberFormHook } from "shared/forms/CarespaceCreationWizard/AddOrgMemberNavigatorForm";
import {
  AddOrgMemberNavigatorForm,
  useAddOrgMemberForm,
} from "shared/forms/CarespaceCreationWizard/AddOrgMemberNavigatorForm";
import type { CarespaceInformationFormHook } from "shared/forms/CarespaceCreationWizard/CarespaceInformationForm";
import {
  CarespaceInformationForm,
  useCarespaceInformationForm,
} from "shared/forms/CarespaceCreationWizard/CarespaceInformationForm";
import type {
  InviteCaregiverFormHook,
  inviteCaregiverFormSchema,
} from "shared/forms/CarespaceCreationWizard/InviteCaregiverForm";
import {
  InviteCaregiverForm,
  useInviteCaregiverForm,
} from "shared/forms/CarespaceCreationWizard/InviteCaregiverForm";
import type { PCPFormHook } from "shared/forms/CarespaceCreationWizard/PCPForm";
import PCPForm, {
  usePCPForm,
  useSubmitPCPForm,
} from "shared/forms/CarespaceCreationWizard/PCPForm";
import {
  CarespaceCreationPage,
  useCarespaceCreationWizard,
} from "shared/forms/CarespaceCreationWizard/useCarespaceCreationWizard";
import type { ResponsiveModalWizardProps } from "shared/forms/types";
import { PopupOrSheet } from "shared/ui/popup-bottom-sheet";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useCarespaceFilterStore } from "state/carespaceFilter/carespaceFilter";
import { useUserStore } from "state/user";

type Organization = {
  id: string;
  is_using_cm_care_navigators: boolean;
};

export function CarespaceCreationWizard({
  onClose,
}: ResponsiveModalWizardProps) {
  const TITLE = "Create Carespace";

  const createdCarespaceId = useCarespaceCreationWizard(
    (state) => state.createdCarespaceId
  );
  const isCreatingCarespace = useCarespaceCreationWizard(
    (state) => state.isCreatingCarespace
  );

  const carespaceInformationForm = useCarespaceInformationForm();
  const addProviderForm = useAddOrgMemberForm();
  const addCareNavigatorForm = useAddOrgMemberForm();
  const inviteCaregiverForm = useInviteCaregiverForm();
  const pcpForm = usePCPForm();

  return (
    <ResponsiveModal
      isOpen={true}
      title={TITLE}
      closeText="Back"
      onClose={onClose}
      isNetworkCallInProgress={isCreatingCarespace}
      footerButtons={
        <CurrentFooterButtons
          onClose={onClose}
          carespaceInformationForm={carespaceInformationForm}
          addProviderForm={addProviderForm}
          addCareNavigatorForm={addCareNavigatorForm}
          inviteCaregiverForm={inviteCaregiverForm}
          pcpForm={pcpForm}
          isCreatingCarespace={isCreatingCarespace}
        />
      }>
      <div className="p-1">
        {createdCarespaceId ? (
          <SubmissionPopup networkId={createdCarespaceId} />
        ) : null}

        <CurrentForm
          carespaceInformationForm={carespaceInformationForm}
          addProviderForm={addProviderForm}
          addCareNavigatorForm={addCareNavigatorForm}
          inviteCaregiverForm={inviteCaregiverForm}
          pcpForm={pcpForm}
        />
      </div>
    </ResponsiveModal>
  );
}

type RequiredForms = {
  carespaceInformationForm: CarespaceInformationFormHook;
  inviteCaregiverForm: InviteCaregiverFormHook;
  addProviderForm: AddOrgMemberFormHook;
  addCareNavigatorForm: AddOrgMemberFormHook;
  pcpForm: PCPFormHook;
  isCreatingCarespace?: boolean;
};

function CurrentForm({
  carespaceInformationForm,
  inviteCaregiverForm,
  addProviderForm,
  addCareNavigatorForm,
  pcpForm,
}: RequiredForms) {
  const { org } = useCarespaceFilterStore();
  const { data: organization } = useOrg(org);

  const currentPage = useCarespaceCreationWizard((state) => state.currentPage);

  switch (currentPage) {
    case CarespaceCreationPage.CARESPACE_INFO: {
      return <CarespaceInformationForm form={carespaceInformationForm} />;
    }
    case CarespaceCreationPage.INVITE_PROVIDER: {
      return (
        <AddOrgMemberNavigatorForm
          step={2}
          form={addProviderForm}
          roleType={OrgRoleType.DOCTOR}
          isUsingCmCareNavigators={false}
        />
      );
    }
    case CarespaceCreationPage.INVITE_CARE_NAVIGATOR: {
      return (
        <AddOrgMemberNavigatorForm
          step={3}
          form={addCareNavigatorForm}
          roleType={OrgRoleType.CARE_NAVIGATOR}
          isUsingCmCareNavigators={organization?.is_using_cm_care_navigators}
        />
      );
    }
    case CarespaceCreationPage.CHANGE_PCP: {
      return (
        <PCPForm
          form={pcpForm}
          step={4}
        />
      );
    }
    case CarespaceCreationPage.INVITE_CAREGIVER: {
      return (
        <InviteCaregiverForm
          step={5}
          form={inviteCaregiverForm}
        />
      );
    }
  }
}

function CurrentFooterButtons({
  onClose,
  carespaceInformationForm,
  inviteCaregiverForm,
  addProviderForm,
  addCareNavigatorForm,
  pcpForm,
  isCreatingCarespace,
}: RequiredForms & ResponsiveModalWizardProps) {
  //
  // user context
  //
  const authUser = useUserStore((state) => state.user);
  const { org } = useCarespaceFilterStore();

  const { data: organization } = useOrg(org);

  //
  // form navigation
  //
  const currentPage = useCarespaceCreationWizard((state) => state.currentPage);
  const nextPage = useCarespaceCreationWizard((state) => state.nextPage);
  const previousPage = useCarespaceCreationWizard(
    (state) => state.previousPage
  );
  //
  // current form values
  //
  const carespaceInformationValues = carespaceInformationForm.watch();
  const addProviderValues = addProviderForm.watch();
  const addCareNavigatorValues = addCareNavigatorForm.watch();
  const inviteCaregiverValues = inviteCaregiverForm.watch();
  const pcpValues = pcpForm.watch();
  //
  // setters
  //
  const setCarespaceInformationValues = useCarespaceCreationWizard(
    (state) => state.setCarespaceInformationValues
  );
  const setAddProviderValues = useCarespaceCreationWizard(
    (state) => state.setAddProviderValues
  );
  const setCareNavigatorValues = useCarespaceCreationWizard(
    (state) => state.setAddCareNavigatorValues
  );
  const setPCP = useCarespaceCreationWizard((state) => state.setPCP);
  const setInviteCaregiverValues = useCarespaceCreationWizard(
    (state) => state.setInviteCaregiverValues
  );

  //
  // validated form values
  //
  const validatedCarespaceInformationValues = useCarespaceCreationWizard(
    (state) => state.carespaceInformationValues
  );
  const validatedAddProviderValues = useCarespaceCreationWizard(
    (state) => state.provider
  );

  const validatedAddCareNavigatorValues = useCarespaceCreationWizard(
    (state) => state.careNavigator
  );

  //
  // submission logic
  //
  const setCreatedCarespaceId = useCarespaceCreationWizard(
    (state) => state.setCreatedCarespaceId
  );
  const setIsCreatingCarespace = useCarespaceCreationWizard(
    (state) => state.setIsCreatingCarespace
  );
  // Mutations
  const createNetwork = useCreateNetworkMutation().mutateAsync;
  const insertProviderToNetwork = useInsertProviderToNetwork().mutateAsync;
  const saveUserAdlo = useSaveUserAdlo().mutateAsync;
  const insertInvitation = useInsertInvitation().mutateAsync;
  const submitPCPForm = useSubmitPCPForm().mutateAsync;

  const handleSendCaregiverInvitation = async (
    carespaceId: string,
    inviteCaregiverValues?: z.infer<typeof inviteCaregiverFormSchema>
  ) => {
    if (validatedCarespaceInformationValues && inviteCaregiverValues) {
      // grab validated props
      const {
        caregiverEmail,
        caregiverFirstName,
        caregiverLastName,
        caregiverPhoneNumber,
      } = inviteCaregiverValues;

      const newInvitation: InvitationInsert = {
        // fill in the fields of your invitation here
        network_id: carespaceId,
        invited_email: caregiverEmail,
        first_name: caregiverFirstName,
        last_name: caregiverLastName,
        role_type: NetworkRoleType.PRIMARY_CAREGIVER,
        cell_number: caregiverPhoneNumber,
      };
      const data = await insertInvitation(newInvitation);
      if (data) {
        await sendNotification(data.id, NotificationType.CARESPACE_INVITATION);
      }
    }
  };

  const handleSendProviderInvitation = async (carespaceId: string) => {
    // Add the provider (will always be defined)
    await insertProviderToNetwork({
      carespaceId,
      selectedOrgMember: validatedAddProviderValues!.orgMember!,
    });

    // Only add care navigator if Craniometrix checkbox is not checked
    // (orgMember will be undefined when checkbox is checked, as set in AddOrgMemberNavigatorForm)
    if (validatedAddCareNavigatorValues?.orgMember) {
      await insertProviderToNetwork({
        carespaceId,
        selectedOrgMember: validatedAddCareNavigatorValues.orgMember,
      });
    }
  };

  async function submitForm(
    shouldSendProviderInvitation: boolean,
    inviteCaregiverValues?: z.infer<typeof inviteCaregiverFormSchema>
  ) {
    if (
      !validatedCarespaceInformationValues ||
      !authUser ||
      !shouldSendProviderInvitation
    )
      return;
    setIsCreatingCarespace(true);
    // TODO move to backend
    // 1 - create network
    const newNetworkId = await createNetwork({
      name: validatedCarespaceInformationValues.carespaceName,
    });
    if (!newNetworkId) return;

    await Promise.all([
      saveUserAdlo({
        submission: {
          user_id: authUser.id,
          first_name: carespaceInformationValues.patientFirstName,
          last_name: carespaceInformationValues.patientLastName,
          birthday: carespaceInformationValues.patientBirthday.toISOString(),
          sex: carespaceInformationValues.patientLegalSex,
          has_caregiver: carespaceInformationValues.hasCaregiver,
          dyad: carespaceInformationValues.dyad,
        },
        networkId: newNetworkId,
      }),
      handleSendProviderInvitation(newNetworkId),
      handleSendCaregiverInvitation(newNetworkId, inviteCaregiverValues),
      submitPCPForm({ data: pcpValues, networkId: newNetworkId }),
    ]);

    setCreatedCarespaceId(newNetworkId);
    setIsCreatingCarespace(false);
  }

  const handleSubmit = async (
    event: React.FormEvent,
    sendProviderInvitation: boolean,
    inviteCaregiverValues?: z.infer<typeof inviteCaregiverFormSchema>
  ) => {
    event.preventDefault();
    await submitForm(sendProviderInvitation, inviteCaregiverValues);
  };

  return (
    <>
      {/* left */}
      <ButtonWithIcon
        onClick={() => previousPage()}
        text={"Back"}
        disabled={currentPage === CarespaceCreationPage.CARESPACE_INFO}
        icon={IconOption.BACK_ARROW}
      />

      {/* middle */}
      <ButtonWithIcon
        onClick={onClose}
        text={"Cancel"}
        icon={IconOption.CANCEL}
      />

      {(currentPage === CarespaceCreationPage.CHANGE_PCP ||
        currentPage === CarespaceCreationPage.INVITE_CAREGIVER) && (
        <ButtonWithIcon
          onClick={async (e: React.FormEvent) => {
            nextPage();
            if (currentPage === CarespaceCreationPage.INVITE_CAREGIVER) {
              await handleSubmit(e, !!validatedAddProviderValues, undefined);
            }
          }}
          text={"Skip"}
          icon={IconOption.ARROW}
          disabled={isCreatingCarespace}
        />
      )}

      {/* right */}
      <ButtonWithIcon
        onClick={async (e: React.FormEvent) => {
          switch (currentPage) {
            case CarespaceCreationPage.CARESPACE_INFO: {
              await carespaceInformationForm.handleSubmit(
                // note:
                // this callback is called when the values are valid
                (values) => {
                  setCarespaceInformationValues(values);
                  nextPage();
                }
              )();
              break;
            }
            case CarespaceCreationPage.INVITE_PROVIDER: {
              await addProviderForm.handleSubmit((values) => {
                setAddProviderValues(values);
                nextPage();
                // If using CM care navigators, skip the care navigator step
                if (organization?.is_using_cm_care_navigators) {
                  nextPage();
                }
              })();
              break;
            }
            case CarespaceCreationPage.INVITE_CARE_NAVIGATOR: {
              await addCareNavigatorForm.handleSubmit((values) => {
                setCareNavigatorValues(values);
                nextPage();
              })();
              break;
            }
            case CarespaceCreationPage.CHANGE_PCP: {
              await pcpForm.handleSubmit((values) => {
                setPCP(values);
                nextPage();
              })();
              break;
            }
            case CarespaceCreationPage.INVITE_CAREGIVER: {
              await inviteCaregiverForm.handleSubmit((values) => {
                setInviteCaregiverValues(values);
                handleSubmit(e, !!validatedAddProviderValues, values);
              })();
              break;
            }
          }
        }}
        text={
          currentPage === CarespaceCreationPage.INVITE_CAREGIVER
            ? "Finish"
            : "Next"
        }
        disabled={(() => {
          if (isCreatingCarespace) {
            return true;
          }

          switch (currentPage) {
            case CarespaceCreationPage.CARESPACE_INFO: {
              return false;
            }
            case CarespaceCreationPage.INVITE_PROVIDER: {
              return !Object.values(addProviderValues).every(Boolean);
            }
            case CarespaceCreationPage.INVITE_CARE_NAVIGATOR: {
              const isCraniometrixChecked =
                addCareNavigatorValues.isUsingCraniometrix;
              const hasMemberSelected = !!addCareNavigatorValues.orgMember;
              return !(isCraniometrixChecked || hasMemberSelected);
            }
            case CarespaceCreationPage.INVITE_CAREGIVER: {
              return !Object.values(inviteCaregiverValues).every(Boolean);
            }
            default: {
              return false;
            }
          }
        })()}
        icon={IconOption.ARROW}
      />
    </>
  );
}

function SubmissionPopup({ networkId }: { networkId?: string }) {
  const reset = useCarespaceCreationWizard((state) => state.reset);
  const navigateToCarespaceTab = useNavigateToCarespaceTab();
  return (
    <PopupOrSheet
      isOpen={true}
      icon={<TakenCircleSvg className="w-8 h-8" />}
      footerButtons={
        <ButtonWithIcon
          onClick={() => {
            reset();

            navigateToCarespaceTab(CarespacePageTabs.CARE_PLAN, { networkId });
          }}
          text="Go to Carespace"
          icon={IconOption.ARROW}
        />
      }>
      <p className="text-center w-full"> Carespace successfully created! </p>
    </PopupOrSheet>
  );
}
