import { useQuery } from "@tanstack/react-query";
import partition from "lodash/partition";

import {
  buildQueryFilters,
  type Filter,
  type QueryOptions,
} from "features/query-utils";

import type { ServiceResource, TableName } from "../types";

import { queryKeys, select } from ".";

export function useFetchMany(
  filter?: Filter<TableName>,
  options: QueryOptions = {}
) {
  const { enabled } = options;

  return useQuery({
    queryKey: filter ? queryKeys.list(filter) : queryKeys.lists,
    queryFn: async () => {
      const query = buildQueryFilters(select(), filter);

      const { data, error } = await query.order("created_at", {
        ascending: false,
      });

      if (error) {
        throw error;
      }

      return data.map((serviceResource) => {
        const [activeEngagements, inactiveEngagements] = partition(
          serviceResource.service_engagement,
          (engagement) => engagement.is_active
        );

        return {
          ...serviceResource,
          active_engagements_count: activeEngagements.length,
          inactive_engagements_count: inactiveEngagements.length,
          service_engagements: serviceResource.service_engagement.map(
            (engagement) => ({
              ...engagement,
              type: serviceResource.categories || "",
              provider: serviceResource.name || "",
              last_status_change: new Date(
                engagement.became_inactive_at || engagement.created_at
              ),
            })
          ),
        };
      }) as ServiceResource[];
    },
    enabled,
  });
}
