import { zodResolver } from "@hookform/resolvers/zod";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import * as z from "zod";

import type { Organization } from "backend/resources/orgRole";
import { useOrg } from "backend/resources/orgRole";
import { useUpdateOrg } from "backend/resources/organization/organization";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import PhoneNumberInput from "components/PhoneNumberInput/PhoneNumberInput";
import { useState } from "react";
import type { ResponsiveModalWizardProps } from "shared/forms/types";
import { PhoneNumberSchema, ZipCodeSchema } from "shared/forms/types";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { ResponsiveModal } from "shared/ui/responsive-modal";

export const editOrganizationFormSchema = z.object({
  id: z.string().uuid(),
  name: z.string().min(1),
  address: z.string().min(1),
  city: z.string().min(1),
  state: z.string().min(2),
  zip: ZipCodeSchema,
  helpline_phone_number: PhoneNumberSchema.optional().nullable(),
});

export const useEditOrganizationForm = (
  activeOrg: Organization | undefined | null
) => {
  const defaultValues = {
    id: activeOrg?.id,
    name: activeOrg?.name ?? undefined,
    address: activeOrg?.address ?? undefined,
    city: activeOrg?.city ?? undefined,
    state: activeOrg?.state ?? undefined,
    zip: activeOrg?.zip ?? undefined,
    helpline_phone_number: activeOrg?.helpline_phone_number ?? undefined,
  };

  return useForm<z.infer<typeof editOrganizationFormSchema>>({
    resolver: zodResolver(editOrganizationFormSchema),
    defaultValues,
    mode: "onSubmit",
  });
};

export type EditOrganizationFormHook = UseFormReturn<
  z.infer<typeof editOrganizationFormSchema>
>;

export const editOrganizationFormId = "edit-organization-form";

export function EditOrganizationForm({
  onClose,
  organizationId,
}: ResponsiveModalWizardProps & { organizationId?: string }) {
  const [isSending, setIsSending] = useState(false);

  const { data: activeOrg, isLoading } = useOrg(organizationId);
  const updateOrg = useUpdateOrg().mutateAsync;
  const form = useEditOrganizationForm(activeOrg);

  function close() {
    onClose();
    form.reset();
  }

  async function handleSubmit(
    validatedForm: z.infer<typeof editOrganizationFormSchema>
  ) {
    try {
      setIsSending(true);
      await updateOrg({ ...validatedForm });
    } catch (error) {
      console.error(error);
    } finally {
      setIsSending(false);
      close();
    }
  }

  if (isLoading) {
    return <LoadingSpinner className="h-6 w-6" />;
  }

  return (
    <ResponsiveModal
      isOpen={true}
      closeText="Back"
      title={"Edit Organization"}
      isNetworkCallInProgress={isSending}
      onClose={close}
      footerButtons={
        <>
          <ButtonWithIcon
            text="Cancel"
            icon={IconOption.CANCEL}
            onClick={close}
          />
          <ButtonWithIcon
            text="Done"
            icon={IconOption.CHECKMARK}
            onClick={form.handleSubmit(handleSubmit)}
          />
        </>
      }>
      <Form
        form={form}
        id={editOrganizationFormId}>
        <FormField
          control={form.control}
          name="name"
          label="Organization Name"
          render={({ field }) => <Input {...field} />}
        />
        <FormField
          control={form.control}
          name="address"
          label="Address"
          render={({ field }) => <Input {...field} />}
        />
        <div className="flex gap-3">
          <FormField
            control={form.control}
            name="city"
            label="City"
            render={({ field }) => <Input {...field} />}
          />
          <FormField
            control={form.control}
            name="state"
            label="State"
            render={({ field }) => <Input {...field} />}
          />
          <FormField
            control={form.control}
            name="zip"
            label="Zip"
            render={({ field }) => <Input {...field} />}
          />
        </div>
        <FormField
          control={form.control}
          name="helpline_phone_number"
          label="Helpline Phone Number"
          render={({ field }) => (
            <PhoneNumberInput
              cellPhone={field.value ?? undefined}
              setCellPhone={field.onChange}
            />
          )}
        />
      </Form>
    </ResponsiveModal>
  );
}
