import { useEffect } from "react";
import {
  ConversationType,
  useMutateChatGptConversations,
} from "backend/resources/chatGptConversation";
import { useChatGptMessages } from "backend/resources/chatGptMessage";
import {
  useActiveNetwork,
  useAddConversationToActiveNetwork,
} from "backend/resources/network/network";
import { MessagesThread } from "components/ChatGptSideBar/MessagesThread";

export function CarespaceDiscussion() {
  const { data: network } = useActiveNetwork();
  const { messages } = useChatGptMessages(network?.id, "carespace");

  const addConversationToActiveNetwork =
    useAddConversationToActiveNetwork().mutateAsync;
  const createConversation = useMutateChatGptConversations().mutateAsync;

  useEffect(() => {
    async function openCarespaceDiscussionThread() {
      if (network?.conversation_id) return;
      const newConversation = await createConversation({
        type: ConversationType.General,
        networkId: network?.id,
      });
      if (newConversation) {
        const newConversationId = newConversation.id;
        await addConversationToActiveNetwork(newConversationId);
      }
    }
    openCarespaceDiscussionThread();
  }, [network]);

  return (
    <div className="flex flex-col max-h-[40rem] flex-grow w-full h-full">
      <MessagesThread
        threadId={network?.id}
        pageType="carespace">
        {!messages && <InitialContent />}{" "}
      </MessagesThread>
    </div>
  );
}

function InitialContent() {
  return (
    <div className="flex flex-col pt-6 text-sm">
      <p>No Discussion yet. Write into the chat below to get started.</p>
    </div>
  );
}
