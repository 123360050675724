import {
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { isWithinInterval } from "date-fns";
import group from "lodash/groupBy";
import { useMemo, type ReactNode } from "react";
import type { DateRange } from "react-day-picker";

import type {
  ColumnDef,
  ColumnFiltersState,
  OnChangeFn,
  Row,
  RowSelectionState,
  TableOptions,
} from "@tanstack/react-table";

import { Accordion } from "shared/ui/accordion";
import { Checkbox } from "shared/ui/checkbox";
import { Text } from "shared/ui/text";

import { DisplayTable, type DisplayTableProps } from "./display-table";
import { Filters } from "./filters";

// See https://github.com/TanStack/table/issues/4382#issuecomment-2427090881 for type explanation
type Column<TData> = {
  [K in keyof Required<TData>]: ColumnDef<TData, TData[K]>;
}[keyof TData];

function getFilterFn<TData>(column: Column<TData>) {
  if (column.filterFn) {
    return column.filterFn;
  }

  if (column.meta?.columnFiltering?.filterType === "date") {
    return "inDateRange";
  }

  return "equals";
}

export interface DataTableProps<TData> {
  columns: Column<TData>[];
  data: TData[];
  onRowClick?: (row: TData) => void;
  rowSelection?: {
    selectedRows: RowSelectionState;
    setSelectedRows: OnChangeFn<RowSelectionState>;
    getRowId?: TableOptions<TData>["getRowId"];
    enableMultiRowSelection?: TableOptions<TData>["enableMultiRowSelection"];
    enableSubRowSelection?: TableOptions<TData>["enableSubRowSelection"];
  };
  columnFiltering?: {
    columnFilters: ColumnFiltersState;
    setColumnFilters: OnChangeFn<ColumnFiltersState>;
    renderContent?: (filteredRows: Row<TData>[]) => ReactNode;
  };
  grouping?: {
    groupBy: keyof TData;
  };
}

export function DataTable<TData>({
  columns: propColumns,
  data,
  onRowClick,
  rowSelection,
  columnFiltering,
  grouping,
}: DataTableProps<TData>) {
  const { selectedRows, setSelectedRows, ...rowSelectionTableOptions } =
    rowSelection || {};
  const { columnFilters, setColumnFilters, renderContent } =
    columnFiltering || {};
  const { groupBy } = grouping || {};

  const columns = useMemo(
    () =>
      [
        ...(rowSelection
          ? [
              {
                id: "row-selection",
                header: ({ table }) => (
                  <div className="flex items-center justify-center">
                    <Checkbox
                      checked={table.getIsAllPageRowsSelected()}
                      onCheckedChange={(value) =>
                        table.toggleAllPageRowsSelected(value)
                      }
                      aria-label="Select all"
                    />
                  </div>
                ),
                cell: ({ row }) => (
                  <div className="flex items-center justify-center">
                    <Checkbox
                      checked={row.getIsSelected()}
                      onCheckedChange={(value) => row.toggleSelected(value)}
                      aria-label="Select row"
                    />
                  </div>
                ),
                enableSorting: false,
                enableHiding: false,
              } as Column<TData>,
            ]
          : []),
        ...propColumns.map((column) => ({
          ...column,
          filterFn: getFilterFn(column),
        })),
      ] as Column<TData>[],
    [propColumns, rowSelection]
  );

  const table = useReactTable<TData>({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    enableRowSelection: !!rowSelection,
    enableColumnFilters: !!columnFiltering,
    onRowSelectionChange: setSelectedRows,
    onColumnFiltersChange: setColumnFilters,
    state: {
      rowSelection: selectedRows,
      columnFilters,
    },
    filterFns: {
      inDateRange: (row, columnId, filterValue: DateRange) =>
        filterValue.from && filterValue.to
          ? isWithinInterval(row.getValue(columnId), {
              start: filterValue.from,
              end: filterValue.to,
            })
          : true,
    },
    ...rowSelectionTableOptions,
  });

  const headerGroups = table.getHeaderGroups();
  const { rows } = table.getRowModel();

  const filters = headerGroups
    .flatMap((headerGroup) =>
      headerGroup.headers.map((header) => header.column)
    )
    .filter((column) => column.getCanFilter());

  const displayTableOptions: Omit<
    DisplayTableProps<TData>,
    "headerGroups" | "rows"
  > = {
    colSpan: columns.length,
    hasRowSelection: !!rowSelection,
    onRowClick,
  };

  const filteredRows = table.getFilteredRowModel().flatRows;

  if (!groupBy) {
    return (
      <div className="flex flex-col gap-6">
        <Filters
          data={data}
          filters={filters}
          additionalContent={renderContent?.(filteredRows)}
        />
        <DisplayTable
          headerGroups={headerGroups}
          rows={rows}
          {...displayTableOptions}
        />
      </div>
    );
  }

  const groupedRows = Object.entries(
    group(rows, (row) => row.original[groupBy] || "Other")
  );
  const hasVisibleRows = filteredRows.length > 0;

  return (
    <div className="flex flex-col gap-6">
      <Filters
        data={data}
        filters={filters}
        additionalContent={renderContent?.(filteredRows)}
      />
      <Accordion
        items={
          hasVisibleRows
            ? groupedRows.map(([value, rows]) => ({
                value,
                label: (
                  <Text
                    size="lg"
                    className="text-left">
                    {value}
                  </Text>
                ),
                content: (
                  <DisplayTable
                    headerGroups={headerGroups}
                    rows={rows}
                    {...displayTableOptions}
                  />
                ),
              }))
            : [
                {
                  value: "no results",
                  label: "No results",
                  content: undefined,
                },
              ]
        }
        disabled={!hasVisibleRows}
        type="multiple"
      />
    </div>
  );
}
