import { MobileMenuNavItem } from "components/NavBar";
import { useSideNavStore } from "state/navItems";
import { NAV_ITEMS_CONFIG } from "state/navItems/navItemModels";

interface MobileMenuNavItemsProps {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MobileMenuNavItems({ setIsOpen }: MobileMenuNavItemsProps) {
  const extendedItems = useSideNavStore((state) => state.extendedItems);

  return (
    <ul className=" flex flex-col justify-center">
      {extendedItems.map((item, index) => {
        return (
          <MobileMenuNavItem
            key={`mobile-menu-item-${item.name}-${index}`}
            isFirst={false}
            setIsOpen={setIsOpen}
            href={NAV_ITEMS_CONFIG[item.name].route}
            label={item.name}
          />
        );
      })}
    </ul>
  );
}
