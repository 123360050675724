import { DatePicker } from "@mui/x-date-pickers";
import {
  AssessmentSlug,
  useQueryLatestUserAssessmentBySlug,
} from "backend/resources/userAssessment";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { beneficiaryDisclaimerText } from "components/CarespacePage/PAFSubmission/constants";
import {
  DementiaStageAssessmentTool,
  PhoneType,
  ResidenceType,
} from "components/CarespacePage/PAFSubmission/pafEnums";
import type { PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import {
  getCurrentValueForSelect,
  getOptionsFromEnum,
} from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { PhoneNumberFormInput } from "components/PhoneNumberInput/PhoneNumberInput";
import { RadioButtonsGroup } from "components/RadioGroup";
import { Select } from "components/Select/Select";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import type { FC } from "react";
import { useEffect } from "react";
import type { FieldValues, UseFormReturn } from "react-hook-form";
import { Checkbox } from "shared/ui/checkbox";
import { FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { useActiveNetworkId } from "state/network/network";
import type { Json } from "types/supabase";
import type { z } from "zod";

function getTotalPromisScore(total_score: Json | undefined) {
  if (!total_score) return undefined;
  const sum = Object.values(total_score).reduce((a, b) => a + b, 0);
  return Math.min(Math.max(sum, 8), 40); // Clamp value between 8 and 40
}

interface PatientInformationInputsProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
  openAssessment: (assessment_id: string | undefined) => void;
}

export const PatientInformationInputs: FC<
  PatientInformationInputsProps<z.infer<typeof PAFSchema>>
> = ({ form, isReadOnly, openAssessment }) => {
  const networkId = useActiveNetworkId();
  const formValues = form.watch();
  const showFastTool =
    formValues.staging_tool === DementiaStageAssessmentTool.FAST;
  const { data: latestPromisAssessment } = useQueryLatestUserAssessmentBySlug(
    networkId,
    AssessmentSlug.PROMIS_GH
  );
  const { data: latestFastAssessment } = useQueryLatestUserAssessmentBySlug(
    networkId,
    AssessmentSlug.FAST
  );
  const { data: latestCdrAssessment } = useQueryLatestUserAssessmentBySlug(
    networkId,
    AssessmentSlug.CDR
  );

  useEffect(() => {
    form.setValue("cdr_id", latestCdrAssessment?.id);
    form.setValue("fast_id", latestFastAssessment?.id);
    form.setValue("fast_score", latestFastAssessment?.total_score?.toString());
    form.setValue("cdr_score", latestCdrAssessment?.total_score?.toString());
    const stagingId = showFastTool
      ? latestFastAssessment?.id
      : latestCdrAssessment?.id;
    if (stagingId) {
      form.setValue("staging_id", stagingId);
    }
    if (latestPromisAssessment?.id) {
      form.setValue("promis_id", latestPromisAssessment?.id);
    }
  }, [
    showFastTool,
    latestCdrAssessment,
    latestFastAssessment,
    latestPromisAssessment,
    form,
  ]);

  return (
    <div className="flex flex-col gap-4">
      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="First Name"
        name="first_name"
        render={({ field }) => (
          <Input
            {...field}
            className="max-w-[400px]"
          />
        )}
      />
      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Middle Initial"
        name="middle_initial"
        render={({ field }) => (
          <Input
            {...field}
            className="max-w-[400px]"
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Last Name"
        name="last_name"
        render={({ field }) => (
          <Input
            {...field}
            className="max-w-[400px]"
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Email"
        name="email"
        render={({ field }) => (
          <Input
            {...field}
            className="max-w-[400px]"
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Address"
        name="address_line"
        render={({ field }) => (
          <Input
            {...field}
            className="max-w-[500px]"
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="City"
        name="address_city"
        render={({ field }) => (
          <Input
            {...field}
            className="max-w-[500px]"
          />
        )}
      />
      <div className="grid grid-cols-2 gap-4 max-w-[615px]">
        <FormField
          control={form.control}
          disabled={isReadOnly}
          label="State"
          name="address_state"
          render={({ field }) => (
            <Input
              {...field}
              className="w-[175px]"
            />
          )}
        />

        <FormField
          control={form.control}
          disabled={isReadOnly}
          label="Zip Code"
          name="address_postalCode"
          render={({ field }) => (
            <Input
              {...field}
              className="w-[175px]"
            />
          )}
        />
      </div>
      <div className="grid gap-4 grid-cols-2 max-w-[615px]">
        <FormField
          control={form.control}
          disabled={isReadOnly}
          label="Phone"
          name="phone"
          render={({ field }) => <PhoneNumberFormInput {...field} />}
        />

        <FormField
          control={form.control}
          disabled={isReadOnly}
          label="Type"
          name="phone_type"
          render={({ field }) => (
            <Select
              classNames="max-w-[185px] w-[185px]"
              options={getOptionsFromEnum(PhoneType)}
              currentOption={getCurrentValueForSelect(field)}
              placeHolder="Select Phone Type"
              {...field}
            />
          )}
        />
      </div>

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Date of Birth"
        name="date_of_birth"
        render={({ field }) => (
          <DatePicker
            {...field}
            value={field.value ? dayjs(field.value) : null}
            onChange={(date: Dayjs | null) =>
              field.onChange(date ? date.format("YYYY-MM-DD") : null)
            }
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Residence Type"
        name="residence_type"
        render={({ field }) => (
          <RadioButtonsGroup
            options={getOptionsFromEnum(ResidenceType)}
            row={false}
            id="residence_type"
            {...field}
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Check to confirm patient is not a long-term nursing home resident"
        name="not_nursing_home"
        render={({ field }) => <Checkbox {...field} />}
        orientation="horizontal"
        inverted
      />
      <p className="mt-4 mb-4">{beneficiaryDisclaimerText}</p>

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Patient Medicare Beneficiary Identifier"
        name="mbi"
        render={({ field }) => (
          <Input
            {...field}
            className="max-w-[200px]"
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Patient Medicaid ID number (if applicable)"
        name="medicaid_id"
        render={({ field }) => (
          <Input
            {...field}
            className="max-w-[200px]"
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Patient dementia stage: (CDR, FAST):"
        name="staging_tool"
        render={({ field }) => (
          <Select
            classNames="max-w-[200px] w-[200px]"
            options={getOptionsFromEnum(DementiaStageAssessmentTool)}
            currentOption={getCurrentValueForSelect(field)}
            placeHolder="Select Dementia Stage Assessment Tool"
            {...field}
          />
        )}
      />

      {showFastTool ? (
        <div className="flex space-x-4 items-center">
          <FormField
            control={form.control}
            disabled={true}
            label="FAST Score"
            name="fast_score"
            render={({ field }) => (
              <Input
                {...field}
                className="max-w-[200px] w-[200px]"
                disabled
              />
            )}
          />

          <ButtonWithIcon
            onClick={() => openAssessment(AssessmentSlug.FAST)}
            text={`Take FAST Assessment`}
            icon={IconOption.PLUS}
          />

          <div className="hidden">
            <FormField
              control={form.control}
              disabled={true}
              label="Hidden FAST Id"
              name="fast_id"
              render={({ field }) => (
                <Input
                  {...field}
                  type="hidden"
                  disabled
                />
              )}
            />
          </div>
        </div>
      ) : (
        <div className={"flex space-x-4 items-center"}>
          <FormField
            control={form.control}
            disabled={true}
            label="CDR Score"
            name="cdr_score"
            render={({ field }) => (
              <Input
                {...field}
                className="max-w-[200px] w-[200px]"
                disabled
              />
            )}
          />

          <ButtonWithIcon
            onClick={() => openAssessment(AssessmentSlug.CDR)}
            text={`Take CDR Assessment`}
            icon={IconOption.PLUS}
          />

          <div className="hidden">
            <FormField
              control={form.control}
              disabled={true}
              label="Hidden CDR Id"
              name="cdr_id"
              render={({ field }) => (
                <Input
                  {...field}
                  type="hidden"
                  disabled
                />
              )}
            />
          </div>
        </div>
      )}

      <div className="flex space-x-4 items-center">
        <FormField
          control={form.control}
          disabled={true}
          label={`Promis 10 Score`}
          name="promis_id"
          render={({ field }) => (
            <Input
              {...field}
              value={getTotalPromisScore(latestPromisAssessment?.total_score)}
              className="max-w-[200px] w-[200px]"
              disabled
            />
          )}
        />
        <ButtonWithIcon
          onClick={() => openAssessment(AssessmentSlug.PROMIS_GH)}
          text={"Take Promis 10 Assessment"}
          icon={IconOption.PLUS}
        />
      </div>
    </div>
  );
};
