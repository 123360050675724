export const TABLE_NAME = "network";

export enum Sex {
  MALE = "Male",
  FEMALE = "Female",
  UNKNOWN = "Unknown",
}

export enum Dyad {
  LOW = "Low",
  MODERATE = "Moderate",
  HIGH = "High",
  NA = "N/a",
}

export enum GuideStatus {
  DRAFT = "DRAFT",
  READY = "READY",
  ALIGNED = "ALIGNED",
  SUBMITTED = "SUBMITTED",
  NA = "N/A",
}
