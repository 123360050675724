import { DatePicker } from "@mui/x-date-pickers";
import {
  AssessmentSlug,
  useQueryLatestUserAssessmentBySlug,
} from "backend/resources/userAssessment";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { caregiverYearsInRoleText } from "components/CarespacePage/PAFSubmission/constants";
import {
  HasPrimaryCareGiver,
  PhoneType,
  Race,
  Relationship,
  Sex,
  YesNo,
} from "components/CarespacePage/PAFSubmission/pafEnums";
import type { PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import {
  getCurrentValueForSelect,
  getOptionsFromEnum,
} from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { PhoneNumberFormInput } from "components/PhoneNumberInput/PhoneNumberInput";
import { Select } from "components/Select/Select";
import type { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { useEffect } from "react";
import type { FieldValues, UseFormReturn } from "react-hook-form";
import { FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { useActiveNetworkId } from "state/network/network";
import type { z } from "zod";

interface PrimaryCareGiverInputsProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
  openAssessment: (assessment_id: string | undefined) => void;
}

export const PrimaryCareGiverInputs: React.FC<
  PrimaryCareGiverInputsProps<z.infer<typeof PAFSchema>>
> = ({ form, isReadOnly, openAssessment }) => {
  const formValues = form.watch();
  const networkId = useActiveNetworkId();
  const has_pcg = [
    HasPrimaryCareGiver.YES_MULTIPLE,
    HasPrimaryCareGiver.YES_ONE,
  ].includes(formValues.has_pcg);
  const { data: latestZbiAssessment } = useQueryLatestUserAssessmentBySlug(
    networkId,
    AssessmentSlug.ZARIT
  );

  useEffect(() => {
    if (has_pcg && latestZbiAssessment?.id) {
      form.setValue("zbi_id", latestZbiAssessment?.id);
    }
  }, [form, has_pcg, latestZbiAssessment]);

  return (
    <div className="flex flex-col gap-4">
      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="First Name"
        name="pcg_first_name"
        render={({ field }) => (
          <Input
            {...field}
            className="max-w-[400px]"
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Last Name"
        name="pcg_last_name"
        render={({ field }) => (
          <Input
            {...field}
            className="max-w-[400px]"
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Email"
        name="pcg_email"
        render={({ field }) => (
          <Input
            {...field}
            className="max-w-[400px]"
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Address"
        name="pcg_address_line"
        render={({ field }) => (
          <Input
            {...field}
            className="max-w-[500px]"
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="City"
        name="pcg_address_city"
        render={({ field }) => (
          <Input
            {...field}
            className="max-w-[500px]"
          />
        )}
      />
      <div className="grid gap-4 grid-cols-2 max-w-[615px]">
        <FormField
          control={form.control}
          disabled={isReadOnly}
          label="State"
          name="pcg_address_state"
          render={({ field }) => (
            <Input
              {...field}
              className="w-[175px]"
            />
          )}
        />

        <FormField
          control={form.control}
          disabled={isReadOnly}
          label="Zip Code"
          name="pcg_address_postalCode"
          render={({ field }) => (
            <Input
              {...field}
              className="w-[175px]"
            />
          )}
        />
      </div>
      <div className="grid gap-4 grid-cols-2 max-w-[615px]">
        <FormField
          control={form.control}
          disabled={isReadOnly}
          label="Phone"
          name="pcg_phone"
          render={({ field }) => <PhoneNumberFormInput {...field} />}
        />

        <FormField
          control={form.control}
          disabled={isReadOnly}
          label="Phone Type"
          name="pcg_phone_type"
          render={({ field }) => (
            <Select
              classNames="max-w-[185px] w-[185px]"
              options={getOptionsFromEnum(PhoneType)}
              currentOption={getCurrentValueForSelect(field)}
              placeHolder="Select Phone Type"
              {...field}
            />
          )}
        />
      </div>

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Date of Birth"
        name="pcg_date_of_birth"
        render={({ field }) => (
          <DatePicker
            {...field}
            value={field.value ? dayjs(field.value) : null}
            onChange={(date: Dayjs | null) =>
              field.onChange(date ? date.format("YYYY-MM-DD") : null)
            }
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Gender"
        name="pcg_sex"
        render={({ field }) => (
          <Select
            classNames="max-w-[400px] w-[400px]"
            options={getOptionsFromEnum(Sex)}
            currentOption={getCurrentValueForSelect(field)}
            placeHolder="Select Gender"
            {...field}
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Race/Ethnicity"
        name="pcg_race"
        render={({ field }) => (
          <Select
            classNames="max-w-[400px] w-[400px]"
            options={getOptionsFromEnum(Race)}
            currentOption={getCurrentValueForSelect(field)}
            placeHolder="Select Race/Ethnicity"
            {...field}
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Relationship to patient"
        name="pcg_relationship"
        render={({ field }) => (
          <Select
            classNames="max-w-[400px] w-[400px]"
            options={getOptionsFromEnum(Relationship)}
            currentOption={getCurrentValueForSelect(field)}
            placeHolder="Select Relationship to Patient"
            {...field}
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Does primary caregiver live with patient?"
        name="pcg_residence"
        render={({ field }) => (
          <Select
            classNames="max-w-[200px] w-[200px]"
            options={getOptionsFromEnum(YesNo)}
            currentOption={getCurrentValueForSelect(field)}
            placeHolder="Select Yes or No"
            {...field}
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Is primary caregiver a Medicare beneficiary? "
        name="pcg_medicare_status"
        render={({ field }) => (
          <Select
            classNames="max-w-[200px] w-[200px]"
            options={getOptionsFromEnum(YesNo)}
            currentOption={getCurrentValueForSelect(field)}
            placeHolder="Select Yes or No"
            {...field}
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly || formValues.pcg_medicare_status === YesNo.NO}
        label="PCG Medicare Beneficiary Identifier"
        name="pcg_mbi"
        render={({ field }) => (
          <Input
            className="max-w-[200px]"
            {...field}
          />
        )}
      />

      <div className="flex space-x-4">
        <FormField
          control={form.control}
          disabled={isReadOnly ?? latestZbiAssessment}
          label="Primary caregiver Zarit Burden Interview score: "
          name="zbi_id"
          render={({ field }) => (
            <Input
              className="max-w-[200px]"
              {...field}
              value={field.value as string}
              disabled
            />
          )}
        />
        <ButtonWithIcon
          onClick={() => openAssessment(AssessmentSlug.ZARIT)}
          text={"Take Zarit Assessment"}
          icon={IconOption.PLUS}
        />
      </div>
      <div className="mt-4 mb-4">{caregiverYearsInRoleText}</div>

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Years in Role (if unknown leave blank)"
        name="pcg_role_years"
        render={({ field }) => (
          <Input
            className="max-w-[200px]"
            type={"number"}
            {...field}
          />
        )}
      />

      <FormField
        control={form.control}
        disabled={isReadOnly}
        label="Months in Role (if unknown leave blank)"
        name="pcg_role_months"
        render={({ field }) => (
          <Input
            className="max-w-[200px]"
            type={"number"}
            {...field}
          />
        )}
      />
    </div>
  );
};
