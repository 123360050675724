import { DonutChartData } from "components/DonutChart/DonutChart";
import DonutChartWithLabels from "components/DonutChart/DonutChartWithLabels";
import { BillingPageData } from "components/BillingPage/types";
import BillingPageBanner from "components/BillingPage/components/shared/BillingPageBanner";

export default function TotalsSection({
  data,
}: {
  data: BillingPageData | undefined | null;
}) {
  return (
    <div className="w-full">
      <BillingPageBanner
        text="TOTALS"
        color="rgba(120, 208,143,.5)"
      />
      <div className="p-3 flex justify-between w-full">
        <TotalRevenueDonut data={data} />
        <TotalPatientsDonut data={data} />
      </div>
    </div>
  );
}

function TotalRevenueDonut({
  data,
}: {
  data: BillingPageData | undefined | null;
}) {
  enum revenueLabels {
    Standard = "Standard",
    Respite = "Respite",
  }
  const totalRevenueData = [
    { label: revenueLabels.Standard, amount: data?.dcmp.total.amount ?? 0 },
    { label: revenueLabels.Respite, amount: data?.respite?.total.amount ?? 0 },
  ];

  function dataToSection(
    acc: Record<string, DonutChartData>,
    item: { label: string; amount: number }
  ) {
    acc[item.label] = acc[item.label] ?? { section: item.label, value: 0 };
    acc[item.label].value = item.amount;
    return acc;
  }

  const totalRevenueColors = {
    [revenueLabels.Standard]: "#97DBA9",
    [revenueLabels.Respite]: "#99D0F8",
  };

  return (
    <DonutChartWithLabels
      title="Revenue"
      data={totalRevenueData}
      colorMapping={totalRevenueColors}
      dataToSection={dataToSection}
      labels={revenueLabels}
      radius={70}
      textSize="12px"
      showTotalInsideDonut={true}
      isMoney={true}
    />
  );
}

function TotalPatientsDonut({
  data,
}: {
  data: BillingPageData | undefined | null;
}) {
  enum revenueLabels {
    New = "New",
    Established = "Established",
  }
  const totalRevenueData = [
    { label: revenueLabels.New, amount: data?.new_patients_count ?? 0 },
    {
      label: revenueLabels.Established,
      amount: data?.established_patients_count ?? 0,
    },
  ];

  function dataToSection(
    acc: Record<string, DonutChartData>,
    item: { label: string; amount: number }
  ) {
    acc[item.label] = acc[item.label] ?? { section: item.label, value: 0 };
    acc[item.label].value = item.amount;
    return acc;
  }

  const totalRevenueColors = {
    [revenueLabels.New]: "#97DBA9",
    [revenueLabels.Established]: "#99D0F8",
  };

  return (
    <DonutChartWithLabels
      title="Patients"
      data={totalRevenueData}
      colorMapping={totalRevenueColors}
      dataToSection={dataToSection}
      labels={revenueLabels}
      radius={70}
      textSize="12px"
      showTotalInsideDonut={true}
    />
  );
}
