import { Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { DonutChartData } from "components/DonutChart/DonutChart";
import DonutChartWithLabels from "components/DonutChart/DonutChartWithLabels";
import {
  BillingPageData,
  DCMPData,
  DCMPDataRow,
  DCMPLabel,
  DCMPLabelColor,
} from "components/BillingPage/types";
import { toDollar } from "components/BillingPage/utils";
import BillingPageBanner from "components/BillingPage/components/shared/BillingPageBanner";

export default function StandardCareSection({
  data,
}: {
  data: BillingPageData | undefined | null;
}) {
  const standardCareColor = "rgba(255, 175, 56, .3)";

  return (
    <div className="flex flex-col gap-4">
      <BillingPageBanner
        text="Standard Care"
        color={standardCareColor}
      />
      <div className="flex justify-between">
        <StandardCareSectionDonut
          data={data?.dcmp.newPatients}
          title="New Patients (< 6 months)"
        />
        <StandardCareSectionDonut
          data={data?.dcmp.establishedPatients}
          title="Established Patients (6+ months)"
        />
      </div>

      <div className="px-3 py-1 flex flex-col gap-4">
        <DCMPBillingTable
          title="New Patients (< 6 months)"
          data={data?.dcmp.newPatients}
        />
        <DCMPBillingTable
          title="Established Patients (6+ months)"
          data={data?.dcmp.establishedPatients}
        />
      </div>

      <BillingPageBanner
        text="STANDARD CARE TOTAL"
        color={standardCareColor}
        patientCount={data?.dcmp.total.patientCount ?? 0}
        amount={data?.dcmp.total.amount ?? 0}
        numColumns={5}
      />
    </div>
  );
}

function DCMPBillingTable({
  title,
  data,
}: {
  title: string;
  data: DCMPData | undefined;
}) {
  return (
    <div className="flex flex-col gap-4 text-sm">
      <p className="text-lg"> {title}</p>

      {/* Header Row */}
      <div className="grid grid-cols-5 items-end text-center font-bold ">
        <p className=" text-left">Dyad Tier</p>
        <p className="">DCMP Code</p>
        <p className="">Base Rate</p>
        <p className="">Patient Count</p>
        <p className="text-right">Base Amount</p>
        {/* <p className="w-[70px]">Adjust w/ GAF</p>
      <p className="w-[70px]">Total Adjusted w/ PBA</p> */}
      </div>

      {/* With Caregiver section */}
      <p className="font-bold text-sm">With Caregiver</p>

      {/* Data Rows */}
      <div className="grid grid-cols-5 text-center">
        {data?.withCaregiver ? (
          data.withCaregiver.map((item, index) => (
            <Fragment key={index}>
              <p className=" text-left">{item.dyadTier}</p>
              <p className="">{item.dcmpCode}</p>
              <p className="">${item.baseRate}</p>
              <p className="">{item.patientCount}</p>
              <p className="text-right">{toDollar(item.baseAmount)}</p>
            </Fragment>
          ))
        ) : (
          <Fragment>
            <p className=" text-left">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
          </Fragment>
        )}
      </div>

      {/* Without Caregiver section */}
      <p className="font-bold text-sm">No Caregiver</p>
      <div className="grid grid-cols-5 text-center">
        {data?.withoutCaregiver ? (
          data.withoutCaregiver.map((item, index) => (
            <Fragment key={index}>
              <p className=" text-left">{item.dyadTier}</p>
              <p className="">{item.dcmpCode}</p>
              <p className="">${item.baseRate}</p>
              <p className="">{item.patientCount}</p>
              <p className="text-right">{toDollar(item.baseAmount)}</p>
            </Fragment>
          ))
        ) : (
          <Fragment>
            <p className=" text-left">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
          </Fragment>
        )}
      </div>

      <div className="border-b border-[1px] border-black w-full" />
      <div className="grid grid-cols-5 text-center font-bold -mt-3">
        <p className=" text-left">Subtotal</p>
        <p />
        <p />
        <p className="">{data?.subtotalCount ?? <Skeleton />}</p>
        <p className="text-right">
          {data ? toDollar(data.subtotalAmount) : <Skeleton />}
        </p>
      </div>
    </div>
  );
}

function StandardCareSectionDonut({
  data,
  title,
}: {
  data: DCMPData | undefined;
  title: string;
}) {
  function dataToSection(
    acc: Record<string, DonutChartData>,
    item: DCMPDataRow
  ) {
    acc[item.dyadTier] = acc[item.dyadTier] ?? {
      section: item.dyadTier,
      value: 0,
    };
    acc[item.dyadTier].value += item.baseAmount;
    return acc;
  }
  const dcmpData = data
    ? [
        ...data.withCaregiver,
        ...data.withoutCaregiver.map((item) => ({
          ...item,
          dyadTier: `${item.dyadTier}-NC`,
        })),
      ]
    : [];

  return (
    <DonutChartWithLabels
      title={title}
      data={dcmpData}
      colorMapping={DCMPLabelColor}
      dataToSection={dataToSection}
      labels={DCMPLabel}
      radius={70}
      textSize="12px"
      showTotalInsideDonut={true}
      isMoney={true}
    />
  );
}
