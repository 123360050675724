import ReactMarkdown from "react-markdown";

import { cva } from "class-variance-authority";

export type TotalScore =
  | number
  | { distress: number; severity: number }
  | { physical_health: number; mental_health: number }
  | undefined;

const containerVariants = cva("w-full flex text-sm gap-5 mb-5", {
  variants: {
    justify: {
      end: "justify-end",
      center: "justify-center",
    },
  },
  defaultVariants: {
    justify: "end",
  },
});

export default function ScoreKey({
  totalScore,
  scoreKey,
  justify = "end",
}: {
  totalScore: TotalScore;
  scoreKey: string;
  justify?: "end" | "center";
}) {
  return (
    <>
      <div className={containerVariants({ justify })}>
        <p className="font-bold">
          Total Score:{" "}
          {totalScore === undefined || totalScore === null
            ? "N/A"
            : typeof totalScore === "number"
            ? totalScore
            : typeof totalScore === "object" &&
              "severity" in totalScore &&
              "distress" in totalScore
            ? `Severity: ${totalScore.severity}, Distress: ${totalScore.distress}`
            : `Physical Health: ${totalScore?.physical_health}, Mental Health: ${totalScore?.mental_health}`}
        </p>
      </div>

      <div className="rounded bg-[#6DB9FF]/[.36] w-full h-full p-3 flex flex-col gap-4">
        <p className="text-sm font-bold">Score Key</p>
        <ReactMarkdown
          className="text-sm"
          components={{
            p: ({ children }) => (
              <p style={{ marginBottom: "1rem" }}>{children}</p>
            ),
            ul: ({ children }) => (
              <ul style={{ listStyleType: "disc", marginLeft: "1.5rem" }}>
                {children}
              </ul>
            ),
            ol: ({ children }) => (
              <ol style={{ listStyleType: "decimal", marginLeft: "1.5rem" }}>
                {children}
              </ol>
            ),
            li: ({ children }) => (
              <li style={{ marginBottom: "0.5rem" }}>{children}</li>
            ),
          }}>
          {scoreKey}
        </ReactMarkdown>
      </div>
    </>
  );
}
