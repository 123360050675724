import * as Sentry from "@sentry/react";
import { useQuery } from "@tanstack/react-query";
import { QUERY_KEYS } from "backend/query-keys";
import type { Network } from "backend/resources/network/network";
import type { Organization, OrganizationRole } from "backend/resources/orgRole";
import { useOrgs } from "backend/resources/orgRole";
import type { User } from "backend/resources/user";
import type { UserRole } from "backend/resources/userRole";
import { OrgRoleType } from "backend/resources/userRole/types";
import { supabase } from "clients/supabaseClient";
import { OrgMember } from "shared/forms/CarespaceCreationWizard/OrgMemberAutocomplete";
import { useActiveOrganizationId } from "state/organization/organization";

type UserOrgCarespace = User & {
  organization_role: (OrganizationRole & { organization: Organization })[];
} & {
  user_role: (UserRole & { network: Network })[];
};

export function useOrgAndCarespaceIdentitiesForUser(userId?: string) {
  // carespace and org identities
  const { isLoading, error, data, isFetching, refetch } = useQuery({
    queryKey: [QUERY_KEYS.orgAndCarespaceIdentities, { userId }],
    queryFn: () => fetchCarespaceAndOrgIdentitiesByUserId(userId),
    refetchOnWindowFocus: false,
  });

  return {
    // query obj
    isLoading,
    error,
    data,
    isFetching,
    refetch,
    // derived
    carespaceNames: data?.user_role?.map((role) => role?.network?.name),
    orgNames: data?.organization_role?.map((role) => role?.organization?.name),
  };
}

async function fetchCarespaceAndOrgIdentitiesByUserId(userId?: string) {
  if (!userId) {
    return null;
  }

  const { data, error } = await supabase
    .from("user")
    .select(
      "*, organization_role(*, organization(*)), user_role(*, network(*))"
    )
    .eq("id", userId)
    .returns<UserOrgCarespace[]>();

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }

  return data?.[0];
}

export const useSearchOrgMembers = (
  query: string,
  role_type: OrgRoleType | undefined
) => {
  const orgId = useActiveOrganizationId();
  const { isSuperSuperUser } = useOrgs();

  return useQuery({
    queryKey: [QUERY_KEYS.orgMembers, { query, role_type, isSuperSuperUser }],
    queryFn: async () => {
      const { data, error } = await searchOrgMembers({
        orgId,
        searchTerm: query,
        role_type,
        ...(isSuperSuperUser !== undefined && { isSuperSuperUser }),
      });
      return data;
    },
    refetchOnWindowFocus: false,
  });
};

export async function searchOrgMembers({
  orgId,
  searchTerm,
  role_type,
  isSuperSuperUser = false,
}: {
  orgId: string | null | undefined;
  searchTerm: string | null;
  role_type: OrgRoleType | undefined;
  isSuperSuperUser?: boolean;
}) {
  // Early return if required params are missing
  if (!orgId || !role_type) {
    return { data: null, error: null };
  }

  // Handle super super user case
  if (isSuperSuperUser) {
    return await searchSuperOrgMembers(searchTerm, role_type);
  }

  // Handle regular org member case
  return await searchRegularOrgMembers(orgId, searchTerm, role_type);
}

async function searchSuperOrgMembers(
  searchTerm: string | null,
  role_type: OrgRoleType
) {
  const query = supabase
    .from("organization")
    .select(
      `
      id,
      organization_role!inner(
        role,
        is_superuser,
        user!inner(
          id,
          first_name,
          last_name,
          cell_number,
          email
        )
      )
    `
    )
    .eq("is_super_org", true)
    .eq("organization_role.role", OrgRoleType.CARE_NAVIGATOR);

  if (searchTerm) {
    query.or(
      `organization_role.user.first_name.ilike.*${searchTerm}*,` +
        `organization_role.user.last_name.ilike.*${searchTerm}*`
    );
  }

  const { data: superOrgData, error } = await query;

  if (error) {
    return { data: null, error };
  }

  const data = superOrgData?.flatMap((org) =>
    (org.organization_role ?? [])
      .filter((role) => role.user != null)
      .map((role) => ({
        organization_id: org.id,
        org_role: role_type,
        user_id: role.user?.id ?? "",
        first_name: role.user?.first_name ?? "",
        last_name: role.user?.last_name ?? "",
        cell_number: role.user?.cell_number ?? "",
        email: role.user?.email ?? "",
      }))
  ) as OrgMember[];

  return { data, error };
}

async function searchRegularOrgMembers(
  orgId: string,
  searchTerm: string | null,
  role_type: OrgRoleType
) {
  const query = supabase
    .from("user")
    .select(
      "*,organization_role!inner(role, organization_id, organization!inner(*))"
    )
    .eq("organization_role.organization_id", orgId)
    .eq("organization_role.role", role_type);

  if (searchTerm) {
    query.or(
      `first_name.ilike.*${searchTerm}*,last_name.ilike.*${searchTerm}*`
    );
  }

  const { data: matchingMembersData, error } = await query;

  if (error) {
    return { data: null, error };
  }

  const data = matchingMembersData?.map((member) => ({
    organization_id: member.organization_role[0].organization_id,
    org_role: member.organization_role[0].role,
    user_id: member.id,
    first_name: member.first_name,
    last_name: member.last_name,
    cell_number: member.cell_number,
    email: member.email,
  })) as OrgMember[];

  return { data, error };
}
