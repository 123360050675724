import { track } from "analytics/track";
import { useUserAdlos } from "backend/resources/userAdlo";
import { useEffect, useState } from "react";

import {
  useActiveNetworkName,
  useUpdateNetworkNameMutation,
} from "backend/resources/network/network";
import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import type { LegalSexOptionsKeys } from "components/CarePilotOnboarding/components/BasicInformationModal";
import { legalSexOptions } from "components/CarePilotOnboarding/components/BasicInformationModal";
import { EditCarespaceView } from "components/CarespacePage/EditCarespaceView";
import { LoadingSpinner } from "components/LoadingSpinner";
import { FormContainer } from "components/PageContainer/FormContainer";
import { Select } from "components/Select";
import { differenceInYears } from "date-fns";
import { useWindowSize } from "hooks/useWindowSize";
import { useUserStore } from "state/user";

export function CareSpace() {
  const [isEditing, setIsEditing] = useState(false);
  const { isMobile } = useWindowSize();
  if (isEditing) {
    return <EditCarespaceView onClose={() => setIsEditing(false)} />;
  } else {
    return <CareSpaceComponent setIsEditing={setIsEditing} />;
  }
}

function CareSpaceComponent({ setIsEditing }: { setIsEditing: any }) {
  const authUser = useUserStore((state) => state.user);
  const { isMobile } = useWindowSize();
  const [isSavingChanges, setIsSavingChanges] = useState(false);
  // user

  // track event
  useEffect(() => {
    track({ type: "Page View", properties: { name: "profile" } });
  }, []);

  const { data } = useUserAdlos();
  const userAdloData = data?.[0];
  // network
  const updateNetworkName = useUpdateNetworkNameMutation().mutateAsync;
  const { networkName } = useActiveNetworkName();

  // adlo

  const [adloFirstName, setAdloFirstName] = useState<string>(
    userAdloData?.first_name || ""
  );

  const [adloLastName, setAdloLastName] = useState<string>(
    userAdloData?.last_name || ""
  );
  const [adloBirthday, setAdloBirthday] = useState<Date | null>(
    userAdloData?.birthday ? new Date(userAdloData.birthday) : null
  );
  const [legalSex, setLegalSex] = useState<string | null>("male");

  const [adloNickname, setAdloNickname] = useState<string | null | undefined>(
    userAdloData?.first_name
  );

  // keep input in sync with react query
  useEffect(() => {
    setAdloFirstName(userAdloData?.first_name || "");
    setAdloLastName(userAdloData?.last_name || "");
    setAdloBirthday(
      userAdloData?.birthday ? new Date(userAdloData.birthday) : null
    );
    setLegalSex(userAdloData?.sex || null);
  }, [userAdloData]);
  // TODO(jen): show error here
  if (!authUser) {
    return <div>Please log back in.</div>;
  }
  if (!userAdloData) {
    return <LoadingSpinner className="w-6 h-6" />;
  }

  return (
    <>
      <div className="flex gap-5 items-center mt-5">
        <h2 className="text-base md:text-lg font-light">Carespace Name</h2>
        {!isMobile ? (
          <ButtonWithIcon
            onClick={() => setIsEditing(true)}
            text={"Edit"}
            icon={IconOption.EDIT}
          />
        ) : null}
      </div>
      <FormContainer className="flex flex-col pt-2 gap-5">
        <div className="flex flex-col">
          <label className="mt-2 line-clamp-1  text-gray-400">
            {networkName || "Empty"}
          </label>
        </div>
        <h2 className="text-base md:text-lg font-light">
          Person I'm caring for
        </h2>
        <div className="grid grid-cols-2 xl:grid-cols-4 gap-4 w-full text-sm md:text-base">
          {/* first name */}
          <div className="flex flex-col">
            <p className="">First Name</p>
            <label className="mt-2 line-clamp-1 text-gray-400">
              {adloFirstName || "Empty"}
            </label>
          </div>
          {/* last name */}
          <div className="flex flex-col">
            <p className="">Last Name</p>
            <label className="mt-2 line-clamp-1  text-gray-400">
              {adloLastName || "Empty"}
            </label>
          </div>
          {/* Birthday */}
          <div className="flex flex-col">
            <p className="">DOB</p>
            <label className="mt-2 line-clamp-1 text-gray-400">
              {adloBirthday?.toLocaleDateString()} (
              {adloBirthday
                ? differenceInYears(adloBirthday, new Date())
                : "Empty"}
              )
            </label>
          </div>
          {/* adlo sex */}
          <div className="flex flex-col">
            <p>Sex</p>
            {/* a drop down component for selection of sex */}
            <Select
              data-hj-allow
              options={Object.values(legalSexOptions)}
              disabled={true}
              onChange={() => {}}
              currentOption={{
                value: legalSex || "male",
                label:
                  legalSexOptions[legalSex as LegalSexOptionsKeys]?.label ??
                  "Male",
              }}
              borderClass="border-2 border-neutral-200"
              classNames="p-0.5 pr-2 h-full bg-white"
            />
          </div>
          {isSavingChanges && (
            <div className="flex flex-col items-center mt-5">
              <p className="mb-2">Saving...</p>
              <div
                className="spinner-border text-gray-200"
                role="status">
                <span className="sr-only">Saving...</span>
              </div>
            </div>
          )}
        </div>
      </FormContainer>
      <BottomActionBar
        centerSlot={
          <ButtonWithIcon
            onClick={() => setIsEditing(true)}
            text={""}
            icon={IconOption.EDIT}
          />
        }
      />
    </>
  );
}
