import { useNetworkPCP, useNetwork } from "backend/resources/network/network";
import { NetworkRoleType, OrgRoleType } from "backend/resources/userRole/types";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import CarespaceMembers from "components/CarespaceMembers/CarespaceMembers";
import { AddCaregiverView } from "components/CarespacePage/AddCaregiver";
import { AddProviderView } from "components/CarespacePage/AddProviderView";
import ChangePCPView from "components/CarespacePage/ChangePCPView";
import { AssignCareTeamModal } from "components/HomePage/CareCentralHome/Carespaces/AssignCareTeamModal";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { FC, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useParams } from "react-router-dom";
import { useOrgs } from "backend/resources/orgRole";
import { useUserRolesInNetwork } from "backend/resources/userRole";

interface CarespaceCareTeamTabProps {}

export const CarespaceCareTeamTab: FC<CarespaceCareTeamTabProps> = () => {
  const navigate = useAppNavigate();
  const { carespaceId } = useParams();
  const { data: pcp } = useNetworkPCP();
  const [searchParams] = useSearchParams();
  const should_display_add_caregiver =
    searchParams.get("should_display_add_caregiver") === "true";
  const [shouldDisplayAddCaregiver, setShouldDisplayAddCaregiver] = useState(
    should_display_add_caregiver
  );
  const [shouldDisplayAddProvider, setShouldDisplayAddProvider] =
    useState(false);
  const [shouldDisplayAddNavigator, setShouldDisplayAddNavigator] =
    useState(false);
  const [shouldDisplayAddPCP, setShouldDisplayAddPCP] = useState(false);
  const { isSuperSuperUser } = useOrgs();
  const { data: network } = useNetwork(carespaceId);
  const isUsingCMCareNavigators =
    network?.organization?.is_using_cm_care_navigators;

  return (
    <div className="flex flex-col gap-2">
      {shouldDisplayAddCaregiver ? (
        <AddCaregiverView onClose={() => setShouldDisplayAddCaregiver(false)} />
      ) : null}
      {shouldDisplayAddProvider ? (
        <AddProviderView
          roleType={OrgRoleType.DOCTOR}
          onClose={() => setShouldDisplayAddProvider(false)}
        />
      ) : null}
      {shouldDisplayAddNavigator ? (
        <AssignCareTeamModal
          isOpen={shouldDisplayAddNavigator}
          onClose={() => setShouldDisplayAddNavigator(false)}
          selectedCarespaces={carespaceId ? [carespaceId] : []}
        />
      ) : null}
      {shouldDisplayAddPCP ? (
        <ChangePCPView onClose={() => setShouldDisplayAddPCP(false)} />
      ) : null}
      <div className="flex items-center gap-5 max-w-[50%]">
        <div className="text-lg">Primary Caregiver(s)</div>
        <ButtonWithIcon
          size={"small"}
          onClick={() => setShouldDisplayAddCaregiver(true)}
          icon={IconOption.PLUS}
          text=""
        />
      </div>
      <CarespaceMembers
        rolesToFilterBy={[NetworkRoleType.PRIMARY_CAREGIVER]}
        disableEditingCarespaceMember
        hideSuperSuperUsers
      />
      <div className="flex items-center gap-3 mt-5">
        <div className="text-lg">Provider(s)</div>
        <ButtonWithIcon
          size={"small"}
          onClick={() => setShouldDisplayAddProvider(true)}
          icon={IconOption.PLUS}
          text=""
        />
      </div>
      <CarespaceMembers
        rolesToFilterBy={[OrgRoleType.DOCTOR, OrgRoleType.ADMIN]}
        hideSuperSuperUsers
        disableEditingCarespaceMember
      />
      <div className="flex items-center gap-3 mt-5">
        <div className="text-lg">Care Navigator(s)</div>
        {isSuperSuperUser && isUsingCMCareNavigators && (
          <ButtonWithIcon
            size={"small"}
            onClick={() => setShouldDisplayAddNavigator(true)}
            icon={IconOption.PLUS}
            text=""
          />
        )}
      </div>
      <CarespaceMembers
        rolesToFilterBy={[OrgRoleType.CARE_NAVIGATOR]}
        disableEditingCarespaceMember
      />
      <div className="flex items-center gap-3 mt-5">
        <div className="text-lg">PCP</div>
        <ButtonWithIcon
          size={"small"}
          onClick={() => setShouldDisplayAddPCP(true)}
          icon={pcp ? IconOption.EDIT : IconOption.PLUS}
          text=""
        />
      </div>
      {pcp && (
        <button
          className="text-start"
          onClick={() =>
            navigate({
              path: SharedRoute.SERVICE_PROVIDER_PAGE,
              params: {
                id: pcp.id,
              },
            })
          }>
          {pcp.name}
        </button>
      )}
    </div>
  );
};
