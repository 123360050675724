import PhoneNumberInput from "components/PhoneNumberInput/PhoneNumberInput";
import { yellowPageCategoryOptions } from "components/ResourcesPage/components/categories";
import { Select } from "components/Select";
import type { UseFormReturn } from "react-hook-form";
import { YellowPagesListingSchema } from "shared/forms/types";
import { z } from "zod";

import { Input } from "@mui/material";
import { Form, FormField } from "shared/ui/form";

export const ExtendedYellowPagesListingSchema = YellowPagesListingSchema.extend(
  {
    name: z.string(),
    phone: z.string(),
    email: z.string(),
    categories: z.string(),
  }
);

export function AddNewProviderManuallyForm({
  form,
}: {
  form: UseFormReturn<z.infer<typeof ExtendedYellowPagesListingSchema>>;
}) {
  return (
    <Form
      form={form}
      id="add-new-provider-manually">
      <FormField
        control={form.control}
        name="name"
        label="Name"
        render={({ field }) => (
          <Input
            className="w-full"
            {...field}
          />
        )}
      />
      <FormField
        control={form.control}
        name="categories"
        label="Category"
        render={({ field }) => (
          <Select
            placeHolder="Please select..."
            borderClass="border-2 border-neutral-200"
            classNames={`p-0.5 pr-2 h-[2.5rem] w-full bg-white`}
            currentOption={yellowPageCategoryOptions.find(
              (option) => option.value === field.value
            )}
            options={yellowPageCategoryOptions}
            {...field}
          />
        )}
      />
      <FormField
        control={form.control}
        name="phone"
        label="Phone"
        render={({ field }) => (
          <PhoneNumberInput
            cellPhone={field.value ?? undefined}
            setCellPhone={field.onChange}
          />
        )}
      />
      <FormField
        control={form.control}
        name="email"
        label="Email"
        render={({ field }) => (
          <Input
            className="w-full"
            {...field}
          />
        )}
      />

      <FormField
        control={form.control}
        name="website"
        label="Website"
        render={({ field }) => (
          <Input
            className="w-full"
            {...field}
          />
        )}
      />
    </Form>
  );
}
