import { FieldValues, UseFormReturn } from "react-hook-form";
import { isValidPhoneNumber } from "react-phone-number-input";
import { z } from "zod";

/**
 * Utility type useful when creating a multi-page form (a.k.a. a "wizard" or "funnel form")
 */
export type MultiPageFormProps<T extends FieldValues> = {
  form: UseFormReturn<T>;
};

/**
 * Base type for multi-page forms that are displayed in responsive modal
 */
export type ResponsiveModalWizardProps = {
  onClose: () => void;
};

export const PhoneNumberSchema = z
  .string()
  .refine(isValidPhoneNumber, {
    message: "Please enter a valid phone number.",
  })
  .refine((value) => value.startsWith("+1"), {
    message: "Must be a US phone number.",
  });
export const ZipCodeSchema = z
  .string()
  .refine((zipCode) => zipCode.length === 5, {
    message: "Zip code must be exactly 5 characters long",
  });

export const YellowPagesListingSchema = z.object({
  name: z.string(),
  phone: z.string().optional().nullable(),
  website: z.string().optional().nullable(),
  email: z.string().optional().nullable(),
  details: z.string().optional().nullable(),
  categories: z.string().optional().nullable(),
  hours: z.string().optional().nullable(),
  address: z.string().optional().nullable(),
  city: z.string().optional().nullable(),
  state: z.string().optional().nullable(),
  zip: z.string().optional().nullable(),
  image_url: z.string().optional().nullable(),
  // latitude: z.number().optional().nullable(),
  // longitude: z.number().optional().nullable(),
  listing_id: z.string(),
});

export const ISODateSchema = z
  .string()
  .refine((value) => !isNaN(Date.parse(value)), {
    message: "Must be a valid ISO date string",
  });
