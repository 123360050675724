import { Popover } from "@mui/material";
import CalendarIconSvg from "assets/calendar-icon.svg?react";
import CirclePlusIconSvg from "assets/circle-plus.svg?react";
import ListIconSvg from "assets/list-icon.svg?react";
import SortIconSvg from "assets/sort-icon.svg?react";
import { useAlfredPageSideBar } from "backend/resources/chatGptConversation";
import type { PlanEntry } from "backend/resources/planEntry";
import {
  getPlanEntryOrderLabel,
  getPlanEntrySortLabel,
  usePlanEntries,
  useUpdatePlanEntry,
} from "backend/resources/planEntry";
import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import "components/MyPlanPage/calendar.css";
import { CalendarView } from "components/MyPlanPage/CalendarView";
import { EntryViewDetail } from "components/MyPlanPage/EntryViewDetail";
import { EntryViewEdit } from "components/MyPlanPage/EntryViewEdit";
import { EntryViewNew } from "components/MyPlanPage/EntryViewNew";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { TaskTable } from "components/Tables/TaskTable/TaskTable";
import { TaskTableFields } from "components/Tables/TaskTable/TaskTableTypes";
import { useWindowSize } from "hooks/useWindowSize";
import { Checkmark } from "icons/Checkmark";
import { CareCentralRoute, CarePilotRoute, useAppNavigate } from "lib/routing";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import {
  PlanEntryOrderKeys,
  PlanEntrySortKeys,
  useMyPlanStore,
} from "state/myPlan/myPlan";

type Props = {
  should_display_new_task_form?: boolean;
  should_display_edit_task_form?: boolean;
};

export function MyPlanPage({
  should_display_new_task_form,
  should_display_edit_task_form,
}: Props) {
  const { id } = useParams();
  const { isMobile } = useWindowSize();
  const navigate = useAppNavigate();

  // Queries
  useAlfredPageSideBar("todosPage");
  const { sortedAndFilteredPlanEntries: myPlanEntries } = usePlanEntries({
    includeAll: true,
  });

  return (
    <>
      <PageContainer>
        {should_display_new_task_form ? (
          <EntryViewNew />
        ) : should_display_edit_task_form ? (
          <EntryViewEdit />
        ) : id ? (
          <EntryViewDetail />
        ) : (
          <div className="relative grid grid-rows-[auto,auto,1fr] w-full h-full gap-4 pb-40">
            {/* header is centered on mobile */}
            <div className={`${isMobile && "mx-auto"}`}>
              <PageMainHeader text="Tasks" />
            </div>
            <PlanEntriesComponent planEntries={myPlanEntries ?? []} />
          </div>
        )}
      </PageContainer>
      <BottomActionBar
        centerSlot={
          <ButtonWithIcon
            onClick={() => {
              navigate({
                path: CareCentralRoute.NEW_TASK,
              });
            }}
            icon={IconOption.PLUS}
            text=""
          />
        }
      />
    </>
  );
}

export function PlanEntriesComponent({
  planEntries,
  parentPlanEntryId,
  serviceEngagementId,
  hideCreateNewButton,
}: {
  planEntries: PlanEntry[];
  parentPlanEntryId?: string;
  serviceEngagementId?: string;
  hideCreateNewButton?: boolean;
}) {
  return (
    <>
      <MyPlanActionBar
        parentPlanEntryId={parentPlanEntryId}
        serviceEngagementId={serviceEngagementId}
        hideCreateNewButton={hideCreateNewButton}
      />
      <MyPlanActiveView planEntries={planEntries} />
    </>
  );
}

// skeleton

function MyPlanActiveView({ planEntries }: { planEntries: PlanEntry[] }) {
  const activeMyPlanLayout = useMyPlanStore(
    (state) => state.activeMyPlanLayout
  );
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;
  const navigate = useAppNavigate();
  switch (activeMyPlanLayout) {
    case "list": {
      return (
        <TaskTable
          handleClick={(cell, row) => {
            navigate({
              path: CarePilotRoute.PLAN_ID,
              params: {
                id: row.original[TaskTableFields.Id],
              },
            });
          }}
          data={planEntries}
          updateTask={updatePlanEntry}
          hiddenColumns={[
            TaskTableFields.Category,
            TaskTableFields.ServiceStatus,
            TaskTableFields.CreatedAt,
          ]}
        />
      );
    }
    case "calendar": {
      return <CalendarView planEntries={planEntries} />;
    }
    default: {
      return <></>;
    }
  }
}

//
// shared components
//

function MyPlanActionBar({
  parentPlanEntryId,
  serviceEngagementId,
  hideCreateNewButton,
}: {
  parentPlanEntryId?: string;
  serviceEngagementId?: string;
  hideCreateNewButton?: boolean;
}) {
  const navigate = useAppNavigate();

  const { isMobile } = useWindowSize();

  const activeMyPlanLayout = useMyPlanStore(
    (state) => state.activeMyPlanLayout
  );

  const setMyPlanLayout = useMyPlanStore((state) => state.setMyPlanLayout);

  if (isMobile) {
    // mobile component
    return (
      <div className="flex items-center justify-between">
        {/* left */}
        <div className="flex items-center -ml-2">
          <PlanEntryFilterButtonPopover />
        </div>
        {/* right */}
        <div className="flex items-center -mr-2">
          <PlanEntrySortButtonPopover />
          <div className="text-xl px-2">|</div>
          {/* list button */}
          <button
            type="button"
            className={`flex items-center gap-2 px-2 py-1 text-sm ${
              activeMyPlanLayout === "list"
                ? "saturate-100"
                : "saturate-0 focus:saturate-50 hover:saturate-50"
            }`}
            onClick={(e) => {
              e.preventDefault();
              setMyPlanLayout("list");
            }}
            aria-label="Switch to list">
            <ListIconSvg className="w-6 h-6" />
          </button>
          {/* calendar button */}
          <button
            type="button"
            className={`flex items-center gap-2 px-2 py-1 text-sm ${
              activeMyPlanLayout === "calendar"
                ? "saturate-100"
                : "saturate-0 focus:saturate-50 hover:saturate-50"
            }`}
            onClick={(e) => {
              e.preventDefault();
              setMyPlanLayout("calendar");
            }}
            aria-label="Switch to calendar">
            <CalendarIconSvg className="w-6 h-6" />
          </button>
        </div>
      </div>
    );
  } else {
    // desktop component
    return (
      <div className="flex items-center justify-between">
        {/* left side  */}
        {!hideCreateNewButton && (
          <button
            type="button"
            className="flex items-center gap-2"
            onClick={() =>
              navigate({
                path: CareCentralRoute.NEW_TASK,
                queryParams: {
                  parent_plan_entry_id: parentPlanEntryId ?? null,
                  service_engagement_id: serviceEngagementId ?? null,
                },
              })
            }>
            <CirclePlusIconSvg className="flex-shrink-0 w-6 h-6" />
            <p className="text-sm">Create new</p>
          </button>
        )}
        {/* right side */}
        <div
          className="flex items-center flex-grow gap-3"
          style={{ justifyContent: hideCreateNewButton ? "start" : "end" }}>
          {/* filter and sort */}
          <div className="flex items-center gap-2">
            <PlanEntryFilterButtonPopover />
            <PlanEntrySortButtonPopover />
          </div>
          <div className="text-2xl">|</div>
          {/* tabs group */}
          <div className="flex">
            {/* list button */}
            <button
              type="button"
              className={`flex items-center gap-2 px-2 py-1 text-sm ${
                activeMyPlanLayout === "list"
                  ? "saturate-100"
                  : "saturate-0 focus:saturate-50 hover:saturate-50"
              }`}
              onClick={() => setMyPlanLayout("list")}
              aria-label="Switch to list">
              <ListIconSvg className="w-6 h-6" />
              List
            </button>
            {/* calendar button */}
            <button
              type="button"
              className={`flex items-center gap-2 px-2 py-1 text-sm ${
                activeMyPlanLayout === "calendar"
                  ? "saturate-100"
                  : "saturate-0 focus:saturate-50 hover:saturate-50"
              }`}
              onClick={() => setMyPlanLayout("calendar")}
              aria-label="Switch to calendar">
              <CalendarIconSvg className="w-6 h-6" />
              Calendar
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export function PlanEntrySortButtonPopover() {
  const { isMobile } = useWindowSize();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const myPlanSortsState = useMyPlanStore((state) => state.myPlanSortsState);
  const setMyPlanSortsState = useMyPlanStore(
    (state) => state.setMyPlanSortsState
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget); // Pass event.currentTarget
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "planEntry-sort-popover" : undefined;
  return (
    <div>
      <button
        type="button"
        aria-describedby={id}
        className={`flex items-center gap-2 p-2 text-sm rounded-sm ${
          open && "bg-zinc-50"
        }`}
        onClick={handleClick}>
        <SortIconSvg className="w-5 h-5 md:w-6 md:h-6" />
        {!isMobile && "Sort"}
      </button>
      <Popover
        elevation={2}
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}>
        <div className="flex flex-col w-56 text-sm">
          <span className="bg-zinc-100 p-2">Sort by</span>
          {PlanEntrySortKeys.map((sortKey) => (
            <button
              type="button"
              key={sortKey}
              onClick={() => {
                setMyPlanSortsState({
                  ...myPlanSortsState,
                  activeSortKey: sortKey,
                });
              }}
              className="border-b border-neutral-200 p-2 hover:bg-zinc-50">
              <div className="grid grid-cols-[1fr,6fr]">
                <div className="flex items-center justify-center">
                  {myPlanSortsState.activeSortKey === sortKey && (
                    <Checkmark size="14px" />
                  )}
                </div>
                <div className="flex">{getPlanEntrySortLabel(sortKey)}</div>
              </div>
            </button>
          ))}
          <span className="bg-zinc-100 p-2">Order</span>
          {PlanEntryOrderKeys.map((orderKey) => (
            <button
              type="button"
              key={orderKey}
              onClick={() => {
                setMyPlanSortsState({
                  ...myPlanSortsState,
                  activeSortOrder: orderKey,
                });
              }}
              className="border-b border-neutral-200 p-2 hover:bg-zinc-50">
              <div className="grid grid-cols-[1fr,6fr]">
                <div className="flex items-center justify-center">
                  {myPlanSortsState.activeSortOrder === orderKey && (
                    <Checkmark size="14px" />
                  )}
                </div>
                <div className="flex">{getPlanEntryOrderLabel(orderKey)}</div>
              </div>
            </button>
          ))}
        </div>
      </Popover>
    </div>
  );
}

export function PlanEntryFilterButtonPopover() {
  const myPlanFiltersState = useMyPlanStore(
    (state) => state.myPlanFiltersState
  );
  const setMyPlanFiltersState = useMyPlanStore(
    (state) => state.setMyPlanFiltersState
  );

  return (
    <div className="flex items-center">
      <DefaultCheckbox
        checked={!myPlanFiltersState.status_filters.done}
        onChange={() => {
          setMyPlanFiltersState({
            ...myPlanFiltersState,
            status_filters: {
              ...myPlanFiltersState.status_filters,
              done: !myPlanFiltersState.status_filters.done,
            },
          });
        }}
      />
      <label className="ml-2 text-sm text-gray-900">Hide Done</label>
    </div>
  );
}
