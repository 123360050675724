import { Fragment } from "react";
import Skeleton from "react-loading-skeleton";
import { DonutChartData } from "components/DonutChart/DonutChart";
import DonutChartWithLabels from "components/DonutChart/DonutChartWithLabels";
import {
  BillingPageData,
  RespiteLabelColors,
  RespiteLabels,
  RespiteServicesData,
  RespiteServicesDataRow,
} from "components/BillingPage/types";
import { toDollar } from "components/BillingPage/utils";
import BillingPageBanner from "components/BillingPage/components/shared/BillingPageBanner";

export function RespiteCareSection({
  data,
}: {
  data: BillingPageData | undefined | null;
}) {
  const respiteCareColor = "rgba(125, 195, 246, .43)";
  return (
    <div className="flex flex-col gap-4">
      <BillingPageBanner
        text="Respite Care"
        color={respiteCareColor}
      />
      <div className="flex justify-between">
        <RespiteSectionDonut
          data={data?.respite?.newPatients}
          title="New Patients (< 6 months)"
        />
        <RespiteSectionDonut
          data={data?.respite?.establishedPatients}
          title="Established Patients (6+ months)"
        />
      </div>

      <div className="px-3 py-1 flex flex-col gap-4">
        <RespiteServicesBillingTable
          title="New Patients (< 6 months)"
          data={data?.respite?.newPatients}
        />
        <RespiteServicesBillingTable
          title="Established Patients (6+ months)"
          data={data?.respite?.establishedPatients}
        />
      </div>

      <BillingPageBanner
        text="RESPITE TOTAL"
        color={respiteCareColor}
        patientCount={undefined}
        amount={data?.respite?.total.amount ?? 0}
        numColumns={5}
      />
    </div>
  );
}

function RespiteServicesBillingTable({
  title,
  data,
}: {
  title: string;
  data: RespiteServicesData | undefined;
}) {
  return (
    <div className="flex flex-col gap-4 text-sm">
      <p className="text-lg">{title}</p>

      {/* Header Row */}
      <div className="grid grid-cols-5 items-end text-center font-bold">
        <p className="w-[200px] text-left">Location</p>
        <p className="">Respite Code</p>
        <p className="">Units</p>
        <p className="">Rate</p>
        <p className=" text-right">Amount</p>
      </div>

      {/* With Caregiver section */}
      <p className="font-bold text-sm">With Caregiver</p>

      {/* Data Rows */}
      <div className="grid grid-cols-5 text-center">
        {data?.withCaregiver?.length ? (
          data.withCaregiver.map((item, index) => (
            <Fragment key={index}>
              <p className="w-[200px] text-left">{item.location}</p>
              <p className="">{item.respiteCode}</p>
              <p className="">{item.units.toFixed(1)}</p>
              <p className="">${item.rate}</p>
              <p className=" text-right">{toDollar(item.amount)}</p>
            </Fragment>
          ))
        ) : (
          <Fragment>
            <p className="w-[200px] text-left">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
          </Fragment>
        )}
      </div>

      {/* Without Caregiver section */}
      <p className="font-bold text-sm">No Caregiver</p>
      <div className="grid grid-cols-5 text-center">
        {data?.withoutCaregiver?.length ? (
          data.withoutCaregiver.map((item, index) => (
            <Fragment key={index}>
              <p className="w-[200px] text-left">{item.location}</p>
              <p className="">{item.respiteCode}</p>
              <p className="">{item.units.toFixed(1)}</p>
              <p className="">${item.rate}</p>
              <p className="text-right">{toDollar(item.amount)}</p>
            </Fragment>
          ))
        ) : (
          <Fragment>
            <p className="w-[200px] text-left">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
            <p className="">
              <Skeleton />
            </p>
          </Fragment>
        )}
      </div>

      <div className="border-b border-[1px] border-black w-full" />
      <div className="grid grid-cols-5 text-center font-bold -mt-3">
        <p className="text-left">Subtotal</p>
        <p />
        <p />
        <p />
        {data ? (
          <p className="text-right">{toDollar(data.subtotalAmount ?? 0)}</p>
        ) : (
          <p className=" text-right">
            <Skeleton />
          </p>
        )}
      </div>
    </div>
  );
}

export default function RespiteSectionDonut({
  data,
  title,
}: {
  data: RespiteServicesData | undefined;
  title: string;
}) {
  function dataToSection(
    acc: Record<string, DonutChartData>,
    item: RespiteServicesDataRow
  ) {
    acc[item.location] = acc[item.location] ?? {
      section: item.location,
      value: 0,
    };
    acc[item.location].value += item.amount;
    return acc;
  }

  const respiteData = data
    ? [
        ...data.withCaregiver,
        ...data.withoutCaregiver.map((item) => ({
          ...item,
          location: `${item.location}-NC`,
        })),
      ]
    : [];

  return (
    <DonutChartWithLabels
      title={title}
      data={respiteData}
      colorMapping={RespiteLabelColors}
      dataToSection={dataToSection}
      labels={RespiteLabels}
      radius={70}
      textSize="12px"
      showTotalInsideDonut={true}
      isMoney={true}
    />
  );
}
