// add caregiver

import { useParams } from "react-router-dom";
import { z } from "zod";
import { NotificationType, sendNotification } from "backend/functions";
import {
  InvitationInsert,
  useInsertInvitation,
} from "backend/resources/invitation/invitation";
import { NetworkRoleType } from "backend/resources/userRole/types";
import {
  InviteCaregiverForm,
  inviteCaregiverFormSchema,
  useInviteCaregiverForm,
} from "shared/forms/CarespaceCreationWizard/InviteCaregiverForm";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";

export function AddCaregiverView({ onClose }: { onClose: () => void }) {
  const PAGE_TITLE = "Add Caregiver";
  const form = useInviteCaregiverForm();
  const formValues = form.watch();
  const { carespaceId } = useParams();

  const insertInvitation = useInsertInvitation().mutateAsync;

  const handleSendInvitation = async (
    validatedForm: z.infer<typeof inviteCaregiverFormSchema>
  ) => {
    // guard: if the carespaceId is empty, we cannot invite anyone
    if (!carespaceId) return;

    const {
      caregiverFirstName,
      caregiverLastName,
      caregiverEmail,
      caregiverPhoneNumber,
    } = validatedForm;

    const newInvitation: InvitationInsert = {
      // fill in the fields of your invitation here
      network_id: carespaceId,
      invited_email: caregiverEmail,
      first_name: caregiverFirstName,
      last_name: caregiverLastName,
      role_type: NetworkRoleType.PRIMARY_CAREGIVER, // TODO: allow selection of other roles? maybe not
      cell_number: caregiverPhoneNumber,
    };

    const data = await insertInvitation(newInvitation);

    if (data) {
      await sendNotification(data.id, NotificationType.CARESPACE_INVITATION);
    }
  };

  return (
    <ResponsiveModal
      isOpen={true}
      title={PAGE_TITLE}
      closeText="Back"
      onClose={() => {
        onClose();
        form.reset();
      }}
      footerButtons={
        <>
          <ButtonWithIcon
            onClick={() => {
              onClose();
              form.reset();
            }}
            text="Cancel"
            icon={IconOption.CANCEL}
          />
          <ButtonWithIcon
            text="Send invitation"
            icon={IconOption.SEND_INVITATION}
            onClick={() => {
              form.handleSubmit((values) => {
                handleSendInvitation(values);
                onClose();
              })();
            }}
            disabled={!Object.values(formValues).every((value) => value)}
          />
        </>
      }>
      <InviteCaregiverForm form={form} />
    </ResponsiveModal>
  );
}
