import { queryClient } from "app";
import { QUERY_KEYS } from "backend/query-keys";
import { useGuideTasks } from "backend/resources/guide/guideTask";
import { useNetworksInOrganization } from "backend/resources/network/network";
import type { OrgAndIdentityReturnType } from "backend/resources/orgRole";
import { useActiveOrgRole, useOrgs } from "backend/resources/orgRole";
import { TaskTableRowType } from "components/Tables/TaskTable/TaskTableTypes";
import type { FilterConfig } from "components/TaskNavigatorPage/TaskFilters";
import { TaskFilterTypes } from "components/TaskNavigatorPage/TaskFilters";
import { useOrganizationStore } from "state/organization/organization";
import {
  TaskDueDateRange,
  useTaskFilterStore,
} from "state/taskFilter/taskFilter";

import { Combobox } from "shared/ui/combobox";
import { LabeledContent } from "shared/ui/labeled-content";

interface TaskFiltersProps {
  filterConfig: FilterConfig;
  shouldStackInputs?: boolean;
}

export function TaskFilters({
  filterConfig,
  shouldStackInputs = false,
}: TaskFiltersProps) {
  const {
    carespace: network,
    category,
    who,
    setCarespace: setNetwork,
    setCategory,
    setWho,
    dueDate,
    setDueDate,
    taskType,
    setTaskType,
    guideTask,
    setGuideTask,
    organization,
    setOrganization,
  } = useTaskFilterStore();

  const { networks } = useNetworksInOrganization();
  const { data: guideTasks } = useGuideTasks();
  const { ownOrgIdentities, ownOrganizations } = useOrgs();
  const { data: activeOrgAndIdentity } = useActiveOrgRole();
  const { setActiveOrganizationId } = useOrganizationStore();

  const activeOrgRoles = ownOrgIdentities?.filter(
    (organizationRole: OrgAndIdentityReturnType) =>
      organizationRole.organization_id === activeOrgAndIdentity?.organization_id
  );

  const handleOrganizationChange = (orgId: string) => {
    const newOrgId = orgId === "All" ? undefined : orgId;
    setOrganization(newOrgId);
    setActiveOrganizationId(newOrgId);
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.activeOrg] });
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.orgs] });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.orgAndCarespaceIdentities],
    });
    queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.orgAndIdentities] });
  };

  const orgOptions = [
    { value: "All", label: "All" },
    ...(ownOrganizations
      ?.filter((org) => org.name?.trim())
      ?.map((org) => ({
        label: org.name || org.id,
        value: org.id,
      })) ?? []),
  ];

  const whoOptions = [
    { value: "All", label: "All" },
    ...(activeOrgRoles?.map((orgRole: OrgAndIdentityReturnType) => ({
      label: `${orgRole.user.first_name} ${orgRole.user.last_name}`,
      value: orgRole.user.id,
    })) ?? []),
  ];

  return (
    <div className="flex flex-col gap-y-4 w-full h-full justify-center">
      {filterConfig[TaskFilterTypes.ORGANIZATION] && (
        <div className={`${shouldStackInputs ? "w-full" : "w-[700px]"}`}>
          <LabeledContent label={TaskFilterTypes.ORGANIZATION}>
            <Combobox
              options={orgOptions}
              value={organization || "All"}
              onChange={(orgId) => handleOrganizationChange(orgId || "All")}
              emptyMessage="No organizations found"
              searchable
            />
          </LabeledContent>
        </div>
      )}

      <div
        className={`grid ${shouldStackInputs ? "grid-cols-1" : "grid-cols-2"} ${
          shouldStackInputs ? "w-full" : "w-[700px]"
        } gap-x-5 gap-y-4 h-min`}>
        {filterConfig[TaskFilterTypes.CARESPACE] && (
          <LabeledContent label={TaskFilterTypes.CARESPACE}>
            <Combobox
              options={[
                { value: "All", label: "All" },
                ...(networks?.map((network) => ({
                  label: network.name || "Default",
                  value: network.id,
                })) ?? []),
              ]}
              value={network || "All"}
              onChange={(networkId) => setNetwork(networkId)}
              emptyMessage="No carespaces found"
              searchable
            />
          </LabeledContent>
        )}
        {filterConfig[TaskFilterTypes.WHO] && (
          <LabeledContent label={TaskFilterTypes.WHO}>
            <Combobox
              options={whoOptions}
              value={who || "All"}
              onChange={(who) => setWho(who)}
              emptyMessage="No one found"
              searchable
            />
          </LabeledContent>
        )}
        {filterConfig[TaskFilterTypes.TASK_TYPE] && (
          <LabeledContent label={TaskFilterTypes.TASK_TYPE}>
            <Combobox
              options={[
                { value: "All", label: "All" },
                ...Object.values(TaskTableRowType).map((taskType) => ({
                  label: taskType,
                  value: taskType,
                })),
              ]}
              value={taskType || "All"}
              onChange={(newTaskType) =>
                setTaskType(
                  newTaskType === "All"
                    ? undefined
                    : (newTaskType as TaskTableRowType)
                )
              }
            />
          </LabeledContent>
        )}
        {filterConfig[TaskFilterTypes.DUE_DATE] && (
          <LabeledContent label={TaskFilterTypes.DUE_DATE}>
            <Combobox
              options={[
                ...Object.values(TaskDueDateRange).map((date) => ({
                  label: date,
                  value: date,
                })),
              ]}
              value={dueDate || "All"}
              onChange={(date) => setDueDate(date as TaskDueDateRange)}
            />
          </LabeledContent>
        )}
      </div>
    </div>
  );
}
