import { useMemo } from "react";
import { useOrgs } from "backend/resources/orgRole";
import { Select } from "components/Select";
import { useAdminFilterStore } from "state/adminFilter/adminFilter";
import { TaskFilter } from "components/TaskNavigatorPage/TaskFilters";

export function AdminOrgFilter() {
  const { selectedOrgId, setSelectedOrgId } = useAdminFilterStore();
  const { ownOrgIdentities, hasAdminAccess } = useOrgs();

  // Get organizations where user has admin access
  const adminOrgs = useMemo(() => {
    // Create a map to deduplicate by name
    const orgMap = new Map<string, { value: string; label: string }>();

    ownOrgIdentities
      ?.filter(
        (org) =>
          (org.is_superuser || org.organization?.is_super_org) &&
          org.organization?.name?.trim()
      )
      .forEach((org) => {
        const name = org.organization?.name || "";
        // Only add if we haven't seen this name before
        if (!orgMap.has(name)) {
          orgMap.set(name, {
            value: org.organization_id,
            label: name,
          });
        }
      });

    // Convert map to array and sort
    return (
      Array.from(orgMap.values()).sort((a, b) =>
        a.label.localeCompare(b.label)
      ) || []
    );
  }, [ownOrgIdentities]);

  // Set first org as default if none selected
  useMemo(() => {
    if (!selectedOrgId && adminOrgs.length > 0) {
      setSelectedOrgId(adminOrgs[0].value);
    }
  }, [adminOrgs, selectedOrgId]);

  if (!hasAdminAccess || adminOrgs.length === 0) {
    return null;
  }

  return (
    <div className="w-[300px]">
      <TaskFilter label="Organization">
        <Select
          currentOption={
            adminOrgs.find((org) => org.value === selectedOrgId) || adminOrgs[0]
          }
          options={adminOrgs}
          onChange={setSelectedOrgId}
          classNames="w-full"
          isSearchable
          placeHolder="Select organization..."
        />
      </TaskFilter>
    </div>
  );
}
