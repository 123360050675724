import { cloneElement, type ReactElement, useId } from "react";

import { Label } from "shared/ui/label";

import { cn } from "shared/utils/cn";

export interface LabeledContentProps {
  label: string;
  children: ReactElement;
  className?: string;
  orientation?: "vertical" | "horizontal";
}

export const LabeledContent = ({
  label,
  children,
  className,
  orientation = "vertical",
}: LabeledContentProps) => {
  const id = useId();

  return (
    <div
      className={cn(
        "flex gap-2",
        orientation === "vertical" ? "flex-col" : "flex-row items-center",
        className
      )}>
      <Label htmlFor={id}>{label}</Label>
      {cloneElement(children, { id })}
    </div>
  );
};
