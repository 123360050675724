import { EditTeamMemberPage } from "components/AdminPage/EditTeamMemberPage";
import { InvitationPage } from "components/AdminPage/InvitationPage";
import { CookieUse } from "components/CookieUse/cookieUse";
import DeletedUserPage from "components/DeletedUserPage/DeletedUserPage";
import { HomePage } from "components/HomePage";
import PatientSearch from "components/HomePage/SuperSuperUserHome/PatientSearch";
import InvalidInvitationPage from "components/InvalidInvitationPage/InvalidInvitationPage";
import { LogIn } from "components/LogIn";
import MFA from "components/MFA/MFA";
import { PrivacyPolicyPage } from "components/PrivacyPolicyPage";
import { ResetPassword } from "components/ResetPassword";
import ServicePage from "components/ServicePage/ServicePage";
import ServiceProviderPage from "components/ServiceProviderPage.tsx/ServiceProviderPage";
import CreateOrEditServiceRequestPage from "components/ServiceRequest/CreateOrEditServiceRequestPage";
import ViewServiceRequestPage from "components/ServiceRequest/ViewServiceRequestPage";
import { SignUp } from "components/SignUp";
import { TermsOfServicePage } from "components/TermsOfServicePage";
import { SharedRoute, SuperSuperUserRoute } from "lib/routing";
import "react-loading-skeleton/dist/skeleton.css";
import { RoutLayoutObject, RouteLayout } from "routes";
import { HomeRedirect } from "routes/HomeRedirect";

export const sharedRoutes: RoutLayoutObject[] = [
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.SERVICE_PROVIDER_PAGE,
    element: <ServiceProviderPage />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.SERVICE_PAGE,
    element: <ServicePage />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.SERVICE_REQUEST_NEW,
    element: <CreateOrEditServiceRequestPage />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.SERVICE_REQUEST_VIEW,
    element: <ViewServiceRequestPage />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.SERVICE_REQUEST_EDIT,
    element: <CreateOrEditServiceRequestPage />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.VIEW_INVITATION,
    element: <InvitationPage />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.TEAM_EDIT_TEAM_MEMBER,
    element: <EditTeamMemberPage />,
  },
  {
    hideSideNav: true,
    routeLayout: RouteLayout.APP_LAYOUT,
    path: SharedRoute.ROOT,
    element: <LogIn />,
  },
  {
    hideSideNav: true,
    routeLayout: RouteLayout.APP_LAYOUT,
    path: SharedRoute.LOGIN,
    element: <LogIn />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SharedRoute.HOME,
    element: <HomePage />,
  },
  {
    path: SharedRoute.TERMS_OF_SERVICE,
    element: <TermsOfServicePage />,
  },
  {
    path: SharedRoute.COOKIE_USE,
    element: <CookieUse />,
  },
  {
    path: SharedRoute.PRIVACY_POLICY,
    element: <PrivacyPolicyPage />,
  },
  {
    path: SharedRoute.RESET_PASSWORD,
    element: <ResetPassword />,
  },
  {
    hideSideNav: true,
    routeLayout: RouteLayout.APP_LAYOUT,
    path: SharedRoute.DELETED,
    element: <DeletedUserPage />,
  },
  {
    hideSideNav: true,
    routeLayout: RouteLayout.APP_LAYOUT,
    path: SharedRoute.SIGN_UP,
    element: <SignUp />,
  },
  {
    hideSideNav: true,
    routeLayout: RouteLayout.APP_LAYOUT,
    path: SharedRoute.INVITATION_INVALID,
    element: <InvalidInvitationPage />,
  },
  {
    hideSideNav: true,
    routeLayout: RouteLayout.APP_LAYOUT,
    path: SharedRoute.ROOT,
    element: <LogIn />,
  },
  {
    hideSideNav: true,
    routeLayout: RouteLayout.PROTECTED_ROUTE,
    path: SharedRoute.WILDCARD,
    element: <HomeRedirect />,
  },
  {
    routeLayout: RouteLayout.PROTECTED_ROUTE_WITH_APP_LAYOUT,
    path: SuperSuperUserRoute.PATIENT_SEARCH,
    element: <PatientSearch />,
  },
  {
    path: SharedRoute.MFA,
    element: <MFA />,
  },
];
