import {
  createColumnHelper,
  type ColumnFiltersState,
} from "@tanstack/react-table";
import { format } from "date-fns";
import { useState } from "react";

import { SharedRoute, useAppNavigate } from "lib/routing";

import { LoadingSpinner } from "components/LoadingSpinner";

import { Badge } from "shared/ui/badge";
import { DataTable } from "shared/ui/table";
import { Text } from "shared/ui/text";

import { useFetchMany } from "features/service-resources/queries/hooks";
import { type ServiceEngagement } from "features/service-resources/types";

const columnHelper = createColumnHelper<ServiceEngagement>();

const columns = [
  columnHelper.accessor("network.name", {
    header: "Carespace",
    meta: {
      columnFiltering: {
        filterLabel: "Carespace",
      },
    },
  }),
  columnHelper.accessor("name", {
    header: "Service",
    enableColumnFilter: false,
  }),
  columnHelper.accessor("type", {
    header: "Service Type",
    meta: {
      columnFiltering: {
        filterLabel: "Service Type",
      },
    },
  }),
  columnHelper.accessor("provider", {
    header: "Provider",
    meta: {
      columnFiltering: {
        filterLabel: "Provider",
      },
    },
  }),
  columnHelper.accessor("is_active", {
    header: "Status",
    cell: (ctx) => {
      const value = ctx.getValue();

      return (
        <Badge color={value ? "green" : "red"}>
          {value ? "Active" : "Inactive"}
        </Badge>
      );
    },
    meta: {
      columnFiltering: {
        filterLabel: "Status",
        formatOptionLabel: (value) => (value ? "Active" : "Inactive"),
      },
    },
  }),
  columnHelper.accessor("last_status_change", {
    header: "Active/Inactive Date",
    cell: (ctx) => {
      const value = ctx.getValue();

      return value ? format(value, "MM/dd/yyyy") : "N/A";
    },
    enableColumnFilter: false,
  }),
];

export default function Services() {
  const navigate = useAppNavigate();
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const { data: serviceResources = [], isLoading, isError } = useFetchMany();

  if (isLoading) {
    <LoadingSpinner className="h-6 w-6" />;
  }

  if (isError) {
    return <Text>Sorry, something went wrong. Please try again.</Text>;
  }

  return (
    <DataTable
      columns={columns}
      data={serviceResources.flatMap(
        (resource) => resource.service_engagements
      )}
      onRowClick={(serviceEngagement) =>
        navigate({
          path: SharedRoute.SERVICE_PAGE,
          params: {
            id: serviceEngagement.id,
          },
        })
      }
      columnFiltering={{
        columnFilters,
        setColumnFilters,
      }}
    />
  );
}
