import { useAlfredPageSideBar } from "backend/resources/chatGptConversation";
import { useOrgs } from "backend/resources/orgRole";
import CareCentralHome from "components/HomePage/CareCentralHome/CareCentralHome";
import { CaregiverHome } from "components/HomePage/CaregiverHome";
import { LoadingSpinner } from "components/LoadingSpinner";

export function HomePage() {
  useAlfredPageSideBar("homePage");
  const { isLoading: isUserAccessLoading, hasCareCentralAccess } = useOrgs();

  if (isUserAccessLoading) {
    return (
      <div className="w-full h-full flex justify-center items-center">
        <LoadingSpinner className="w-20 h-20" />
      </div>
    );
  } else if (hasCareCentralAccess) {
    return <CareCentralHome />;
  } else {
    return <CaregiverHome />;
  }
}
