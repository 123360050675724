export function HeaderNamePill({
  text,
  color = "#3496CE",
}: {
  text: string | undefined | null;
  color?: string;
}) {
  return (
    <p
      className="truncate rounded-full text-sm text-white px-2 h-min"
      style={{ backgroundColor: color }}>
      {text ? `${text}` : ""}
    </p>
  );
}
