import type { VariantProps } from "class-variance-authority";
import { iconWrapperStyles, navItemStyles } from "components/NavBar";
import type { ReactNode } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSideNavStore } from "state/navItems";

export function NavItem({
  name,
  icon,
  pathname,
  isExternalLink,
  disabled,
  ...variantProps
}: {
  name: string;
  disabled?: boolean;
  icon: ReactNode;
  pathname: string;
  isExternalLink?: boolean;
} & VariantProps<typeof navItemStyles>) {
  const { pathname: currentPathname } = useLocation();
  const navigate = useNavigate();
  const isActive = currentPathname === pathname;
  const isMinimized = useSideNavStore((state) => state.isMinimized);
  const navItemClassName = navItemStyles(variantProps);
  const iconWrapperClassName = iconWrapperStyles(variantProps);
  return (
    <button
      className={`pointer-events-auto flex items-center gap-2 ${navItemClassName} ${
        disabled ? "text-gray-500 saturate-0" : ""
      }`}
      title={name}
      disabled={disabled}
      onClick={() => {
        if (isExternalLink) {
          window.open(pathname, "_blank");
        } else if (currentPathname !== pathname) {
          navigate(pathname);
        } else {
          window.location.reload();
        }
      }}>
      <div className={`${iconWrapperClassName} flex flex-col items-center`}>
        {icon}
      </div>
      {!isMinimized && (
        <p className={`text-left ${isActive && "text-brand-orange"}`}>{name}</p>
      )}
    </button>
  );
}
