import { TextInput } from "components/TextInput";
import useMFA from "hooks/useMFA/useMFA";
import { useCallback, useEffect, useState } from "react";
import { Button } from "../Button/Button";

const MFATOTP = () => {
  const [qrCode, setQRCode] = useState<string | undefined>(undefined); // QR code state
  const [enrolled, setEnrolled] = useState<boolean>(false); // Track if enrollment has been completed
  const [_, setEnrollData] = useState<any>(null); // Track enrollment data
  const {
    shouldVerifyMFA,
    currentFactorId,
    factorsList,
    factorsRetrieved,
    error,
    enrollTOTPFactor,
    executeChallenge,
    verifyChallenge,
    setCurrentFactorId,
    getFactorByType,
    setError,
  } = useMFA();
  const [verificationCode, setVerificationCode] = useState<string>("");

  // Function to enroll MFA and prevent duplicate enrollments
  const setEnrollMFA = useCallback(async () => {
    if (enrolled || qrCode) return; // Prevent re-enrollment if QR code is already set
    try {
      const enrollData = await enrollTOTPFactor();
      setCurrentFactorId(enrollData?.factorId); // Save the factor ID
      setEnrolled(true); // Mark as enrolled
      setEnrollData(enrollData); // Save the enrollment data
      return enrollData;
    } catch (error) {
      console.error("Error during MFA enrollment:", error);
    }
  }, [enrollTOTPFactor, enrolled, qrCode]);

  // Handle verification
  const handleVerifyFactor = useCallback(async () => {
    setError(null); // Clear any previous errors
    if (!verificationCode || !currentFactorId) return;

    try {
      const challengeId = await executeChallenge(currentFactorId);
      if (challengeId) {
        await verifyChallenge(currentFactorId, challengeId, verificationCode);
      }
    } catch (error) {
      console.error("Verification error:", error);
    }
  }, [verificationCode]);

  // Automatically enroll MFA when the component mounts
  useEffect(() => {
    if (!factorsRetrieved) return;
    const displayEnrollMFA = async () => {
      const enrollData = await setEnrollMFA();
      if (enrollData) {
        setQRCode(enrollData.qrCode);
        setCurrentFactorId(enrollData.factorId);
      }
    };
    const TOTPFactor = getFactorByType("totp");
    const isTOTPFactorVerified = TOTPFactor?.status === "verified";

    if (!TOTPFactor || !isTOTPFactorVerified) {
      displayEnrollMFA();
    } else {
      setCurrentFactorId(TOTPFactor.id);
    }
  }, [factorsList, factorsRetrieved]);

  return (
    <main className="min-h-[400px] w-full space-y-6 p-6">
      <header className="w-full">
        <h1 className="font-medium text-2xl text-gray-800 text-center">
          TOTP Authentication
        </h1>
      </header>

      {/* Display QR code only when necessary */}
      {qrCode && (
        <section className="flex justify-center">
          <img
            src={qrCode}
            alt="QR Code"
            className="w-60 h-60"
          />
        </section>
      )}

      {shouldVerifyMFA && currentFactorId && (
        <section className="flex justify-center">
          <p className="text-center text-gray-800">
            Enter the 6-digit code from your authenticator app
          </p>
        </section>
      )}

      <section className="flex gap-3 items-center justify-center">
        <TextInput
          data-hj-allow
          type="text"
          onChange={(e) => {
            setVerificationCode(e.target.value);
            setError(null);
          }}
          className="text-xs"
          placeholder="6-digit code"
          value={verificationCode}
        />
        <Button
          disabled={verificationCode.trim().length !== 6}
          onClick={handleVerifyFactor}>
          Verify
        </Button>
      </section>

      <section className="">
        {error && (
          <p className="text-red-500 mt-2 text-sm text-center">{error}</p>
        )}
      </section>
    </main>
  );
};

export default MFATOTP;
