import { PAFStatus } from "components/CarespacePage/PAFSubmission/pafEnums";

export enum GuideAlignmentStatus {
  ALIGNED = "ALIGNED",
  SUBMITTED = "SUBMITTED",
  NA = "N/A",
}

export const PAFStatusLabelMap = {
  [PAFStatus.DRAFT]: "Draft",
  [PAFStatus.READY]: "Ready",
  [GuideAlignmentStatus.ALIGNED]: "Aligned",
  [GuideAlignmentStatus.SUBMITTED]: "Submitted",
  [GuideAlignmentStatus.NA]: "N/a",
};

export enum PAFButtonLabel {
  NEW = "New",
  EDIT = "Edit",
  VIEW = "View",
}

export const GuideAlignmentPillColorMap = {
  [PAFStatusLabelMap[GuideAlignmentStatus.NA]]: "#CFCFCF",
  [PAFStatusLabelMap[PAFStatus.DRAFT]]: "#F6B26B",
  [PAFStatusLabelMap[PAFStatus.READY]]: "#7DC3F6",
  [PAFStatusLabelMap[GuideAlignmentStatus.SUBMITTED]]: "#8690EE",
  [PAFStatusLabelMap[GuideAlignmentStatus.ALIGNED]]: "#25C322",
};
