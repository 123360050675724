import type {
  PlanEntry,
  PlanEntryCalendarObject,
} from "backend/resources/planEntry";
import { TaskStatus } from "backend/resources/planEntry";
import type { PlaceType } from "components/PlacesAutocomplete";
import { TaskTableRowType } from "components/Tables/TaskTable/TaskTableTypes";
import type { FilterConfig } from "components/TaskNavigatorPage/TaskFilters";
import { TaskFilterTypes } from "components/TaskNavigatorPage/TaskFilters";
import { getFirstAndLastDayInDueDateRange } from "components/TaskNavigatorPage/taskTableUtils";
import type { PlanEntryOrderKey, PlanEntrySortKey } from "state/myPlan";
import type { TaskFilterState } from "state/taskFilter/taskFilter";
import { z } from "zod";

export const PlaceTypeSchema = z.object({
  description: z.string(),
  structured_formatting: z.object({
    main_text: z.string(),
    secondary_text: z.string(),
    main_text_matched_substrings: z
      .array(
        z.object({
          offset: z.number(),
          length: z.number(),
        })
      )
      .optional(),
  }),
});

export function filterPlanEntries(
  queryBuilder: any,
  filterConfig: FilterConfig,
  guideFilterState: TaskFilterState,
  user_id: string,
  networkIds: string[]
) {
  queryBuilder
    .is("parent_plan_entry_id", null)
    .in("network_id", networkIds)
    .is("parent_plan_entry_id", null);
  if (
    filterConfig[TaskFilterTypes.CARESPACE] &&
    guideFilterState.carespace &&
    guideFilterState.carespace !== "All"
  ) {
    queryBuilder.eq("network_id", guideFilterState.carespace);
  }
  if (
    filterConfig[TaskFilterTypes.TASK_TYPE] &&
    guideFilterState.taskType &&
    guideFilterState.taskType !== "All"
  ) {
    switch (guideFilterState.taskType as TaskTableRowType) {
      case TaskTableRowType.SERVICE_REQUEST: {
        queryBuilder.eq("is_service_ticket", true);
        break;
      }
      case TaskTableRowType.ONBOARDING: {
        queryBuilder
          .not("guide_task_id", "is", null)
          .eq("is_service_ticket", false);
        break;
      }
      default: {
        queryBuilder.is("guide_task_id", null).eq("is_service_ticket", false);
        break;
      }
    }
  }
  if (
    filterConfig[TaskFilterTypes.CATEGORY] &&
    guideFilterState.category &&
    guideFilterState.category !== "All"
  ) {
    queryBuilder.eq(
      "guide_task.guide_category.title",
      guideFilterState.category
    );
  }
  if (
    filterConfig[TaskFilterTypes.GUIDE_TASK] &&
    guideFilterState.guideTask &&
    guideFilterState.guideTask !== "All"
  ) {
    queryBuilder.eq("guide_task_id", guideFilterState.guideTask);
  }
  if (
    filterConfig[TaskFilterTypes.HIDE_DONE] &&
    guideFilterState.hideDone &&
    (!guideFilterState.status || guideFilterState.status === "All")
  ) {
    queryBuilder.neq("status", TaskStatus.Done);
  }
  if (
    filterConfig[TaskFilterTypes.WHO] &&
    guideFilterState.who &&
    guideFilterState.who !== "All"
  ) {
    queryBuilder.eq("user_id", guideFilterState.who);
  }
  if (
    filterConfig[TaskFilterTypes.STATUS] &&
    guideFilterState.status &&
    guideFilterState.status !== "All"
  ) {
    if (guideFilterState.status === "Overdue") {
      queryBuilder.lte("scheduled_date_time", new Date().toISOString());
      queryBuilder.neq("status", TaskStatus.Done);
      queryBuilder.neq("status", TaskStatus.NotApplicable);
    } else {
      queryBuilder.eq("status", guideFilterState.status);
    }
  }
  if (
    filterConfig[TaskFilterTypes.DUE_DATE] &&
    guideFilterState.dueDate &&
    guideFilterState.dueDate !== "All"
  ) {
    const [firstDay, lastDay] = getFirstAndLastDayInDueDateRange(
      guideFilterState.dueDate
    );
    queryBuilder
      .lte("scheduled_date_time", lastDay.toISOString())
      .gte("scheduled_date_time", firstDay.toISOString());
  }

  return queryBuilder;
}

export function filterPlanEntriesByCarespaces(
  queryBuilder: any,
  filterConfig: FilterConfig,
  guideFilterState: TaskFilterState,
  user_id: string,
  networkIds: string[]
) {
  queryBuilder
    .is("plan_entry.parent_plan_entry_id", null)
    .in("plan_entry.network_id", networkIds)
    .is("plan_entry.parent_plan_entry_id", null);

  if (
    filterConfig[TaskFilterTypes.CARESPACE] &&
    guideFilterState.carespace &&
    guideFilterState.carespace !== "All"
  ) {
    queryBuilder.eq("id", guideFilterState.carespace);
  }
  if (
    filterConfig[TaskFilterTypes.CATEGORY] &&
    guideFilterState.category &&
    guideFilterState.category !== "All"
  ) {
    queryBuilder.eq(
      "plan_entry.guide_task.guide_category.title",
      guideFilterState.category
    );
  }
  if (
    filterConfig[TaskFilterTypes.GUIDE_TASK] &&
    guideFilterState.guideTask &&
    guideFilterState.guideTask !== "All"
  ) {
    queryBuilder.eq("plan_entry.guide_task_id", guideFilterState.guideTask);
  }
  if (
    filterConfig[TaskFilterTypes.HIDE_DONE] &&
    guideFilterState.hideDone &&
    (!guideFilterState.status || guideFilterState.status === "All")
  ) {
    queryBuilder.neq("plan_entry.status", TaskStatus.Done);
  }
  if (
    filterConfig[TaskFilterTypes.WHO] &&
    guideFilterState.who &&
    guideFilterState.who !== "All"
  ) {
    queryBuilder.eq("plan_entry.user_id", guideFilterState.who);
  }
  if (
    filterConfig[TaskFilterTypes.STATUS] &&
    guideFilterState.status &&
    guideFilterState.status !== "All"
  ) {
    if (guideFilterState.status === "Overdue") {
      queryBuilder.lte(
        "plan_entry.scheduled_date_time",
        new Date().toISOString()
      );
      queryBuilder.neq("plan_entry.status", TaskStatus.Done);
      queryBuilder.neq("plan_entry.status", TaskStatus.NotApplicable);
    } else {
      queryBuilder.eq("plan_entry.status", guideFilterState.status);
    }
  }
  if (
    filterConfig[TaskFilterTypes.DUE_DATE] &&
    guideFilterState.dueDate &&
    guideFilterState.dueDate !== "All"
  ) {
    const [firstDay, lastDay] = getFirstAndLastDayInDueDateRange(
      guideFilterState.dueDate
    );
    queryBuilder
      .lte("plan_entry.scheduled_date_time", lastDay.toISOString())
      .gte("plan_entry.scheduled_date_time", firstDay.toISOString());
  }

  if (filterConfig[TaskFilterTypes.TASK_TYPE] && guideFilterState.taskType) {
    switch (guideFilterState.taskType) {
      case TaskTableRowType.SERVICE_REQUEST: {
        queryBuilder.eq("plan_entry.is_service_ticket", true);
        break;
      }
      case TaskTableRowType.CARE_PLAN: {
        queryBuilder.not("plan_entry.need_item_id", "is", null);
        break;
      }
      case TaskTableRowType.GENERAL: {
        queryBuilder.is("plan_entry.type", null);
        break;
      }
      default: {
        queryBuilder.eq("plan_entry.type", guideFilterState.taskType);
      }
    }
  }

  return queryBuilder;
}

export function formatPlaceTypeForGoogleCalendar(place: PlaceType) {
  if (!place || !place.structured_formatting) {
    return "";
  }

  const mainText = place.structured_formatting.main_text || "";
  const secondaryText = place.structured_formatting.secondary_text || "";

  return `${mainText}, ${secondaryText}`;
}

export function getPlanEntryOrderLabel(sortKey: PlanEntryOrderKey) {
  switch (sortKey) {
    case "ascending": {
      return "Ascending";
    }
    case "descending": {
      return "Descending";
    }
    default: {
      return "";
    }
  }
}

export function getPlanEntrySortLabel(sortKey: PlanEntrySortKey) {
  switch (sortKey) {
    case "name": {
      return "Name";
    }
    case "scheduled_date_time": {
      return "When";
    }
    case "who": {
      return "Who";
    }
    default: {
      return "";
    }
  }
}

// GCal
export function makeCalendarObjectsForPlanEntries(
  entries: PlanEntry[]
): PlanEntryCalendarObject[] {
  return entries
    .filter((entry) => entry.scheduled_date_time != null)
    .map((entry) => {
      const calendarObj: PlanEntryCalendarObject = {
        id: entry.id,
        title: entry.name,
        start: entry.scheduled_date_time || "",
      };
      return calendarObj;
    });
}
