import { zodResolver } from "@hookform/resolvers/zod";
import { PhoneNumberFormInput } from "components/PhoneNumberInput/PhoneNumberInput";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import type { MultiPageFormProps } from "shared/forms/types";
import { PhoneNumberSchema } from "shared/forms/types";
import { Form, FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { Title } from "shared/ui/title";
import * as z from "zod";

export const inviteCaregiverFormSchema = z.object({
  caregiverFirstName: z.string(),
  caregiverLastName: z.string(),
  caregiverEmail: z.string().email("Please enter a valid email address."),
  caregiverPhoneNumber: PhoneNumberSchema,
});

export const useInviteCaregiverForm = () =>
  useForm<z.infer<typeof inviteCaregiverFormSchema>>({
    resolver: zodResolver(inviteCaregiverFormSchema),
    defaultValues: {},
    mode: "onSubmit",
  });

export type InviteCaregiverFormHook = UseFormReturn<
  z.infer<typeof inviteCaregiverFormSchema>
>;

export const inviteCaregiverFormId = "invite-caregiver-form";

export function InviteCaregiverForm(
  props: MultiPageFormProps<z.infer<typeof inviteCaregiverFormSchema>> & {
    step?: number;
  }
) {
  const { form, step } = props;

  return (
    <Form
      form={form}
      id={inviteCaregiverFormId}>
      <Title order={3}>
        {step && `Step ${step}:`} Invite a primary caregiver
      </Title>
      <div className="grid grid-cols-[2fr,3fr] gap-2 p-1">
        <FormField
          control={form.control}
          name="caregiverFirstName"
          label="First"
          render={({ field }) => <Input {...field} />}
        />
        <FormField
          control={form.control}
          name="caregiverLastName"
          label="Last"
          render={({ field }) => <Input {...field} />}
        />
      </div>
      <div className="grid grid-cols-[3fr,2fr] gap-2">
        <FormField
          control={form.control}
          name="caregiverEmail"
          label="Email"
          render={({ field }) => <Input {...field} />}
        />
      </div>
      <div className="grid grid-cols-[3fr,2fr] gap-2">
        <FormField
          control={form.control}
          name="caregiverPhoneNumber"
          label="Cell"
          render={({ field }) => <PhoneNumberFormInput {...field} />}
        />
      </div>
    </Form>
  );
}
