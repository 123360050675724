import type { PostgrestFilterBuilder } from "@supabase/postgrest-js";

import type { Database, Tables } from "types/supabase";

type PublicSchema = Database[Extract<keyof Database, "public">];
type TableNames = keyof (PublicSchema["Tables"] & PublicSchema["Views"]);

export interface Filter<TableName extends TableNames> {
  equals?: Partial<Tables<TableName>>;
  in?: Partial<{
    [Key in keyof Tables<TableName>]: Tables<TableName>[Key][];
  }>;
}

export interface QueryOptions {
  enabled?: boolean;
}

export function buildQueryFilters<
  TableName extends TableNames,
  Row extends Record<string, unknown>,
  Result,
  RelationName = unknown,
  Relationships = unknown
>(
  query: PostgrestFilterBuilder<
    PublicSchema,
    Row,
    Result,
    RelationName,
    Relationships
  >,
  filter?: Filter<TableName>
) {
  let filterQuery = query;

  if (filter?.equals) {
    filterQuery = filterQuery.match(filter.equals);
  }

  if (filter?.in) {
    Object.entries(filter.in).forEach(([column, values]) => {
      filterQuery = filterQuery.in(column, values as Array<unknown>);
    });
  }

  return filterQuery;
}
