import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { oneSignal } from "backend/functions";
import { QUERY_KEYS } from "backend/query-keys";
import { supabase } from "clients/supabaseClient";
import OneSignal from "react-onesignal";
import { useUserStore } from "state/user";
import type { Tables, TablesInsert, TablesUpdate } from "types/supabase";

const TABLE = "user";

export type User = Tables<"user">;
export type UserInsert = TablesInsert<"user">;
export type UserUpdateRow = TablesUpdate<"user">;

export type UserWithOrganizations = User & {
  organizations: string[];
};

export async function fetchUserById(id: string) {
  return supabase
    .from(TABLE)
    .select(`*, organization_role(organization_id)`)
    .eq("id", id)
    .limit(1)
    .single();
}

export const useUserQuery = (userId?: string | null) => {
  const {
    data: user,
    error: userError,
    isLoading: isUserLoading,
    refetch: refetchUser,
  } = useQuery({
    queryKey: [QUERY_KEYS.user, { userId }],
    queryFn: async () => {
      if (!userId) return;
      const { data } = await fetchUserById(userId);
      return data;
    },
    refetchOnWindowFocus: false,
  });
  return {
    user,
    userError,
    isUserLoading,
    refetchUser,
  };
};

export function useUpdateUserMutation() {
  const setUser = useUserStore((state) => state.setUser);
  return useMutation({
    mutationFn: updateUser,
    onSuccess: async (data) => {
      if (data?.cell_number) {
        if (OneSignal.Notifications.isPushSupported()) {
          OneSignal.User.addSms(data?.cell_number);
        } else {
          await oneSignal(data?.id);
        }
      }

      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.user, { userId: data?.id }],
      });
      if (data) {
        setUser({
          ...data,
          organizations: data?.organization_role.map(
            (role) => role.organization_id
          ),
        });
      }
    },
    onError: (error) => {
      Sentry.captureException(error);
      throw error;
    },
  });
}

export async function updateUser({
  organizations,
  ...user
}: UserWithOrganizations) {
  if (!user.id) throw new Error("User id is required");

  const { data, error } = await supabase
    .from(TABLE)
    .update(user)
    .eq("id", user.id)
    .select(
      `
    *,
    organization_role(
      organization_id
    )
  `
    )
    .order("created_at", { ascending: false })
    .limit(1)
    .single();

  if (error) {
    throw error;
  }

  return data;
}
