import {
  useDeleteRespiteServiceActivity,
  useInsertRespiteServiceActivity,
  useRespiteServiceActivityForNetwork,
} from "backend/resources/services/respiteServiceActivity";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import React, { useState } from "react";

import type { ServiceEngagementWithServiceResource } from "backend/resources/services/serviceEngagement";
import type { RespiteServiceActivityDateType } from "shared/forms/AddRespiteServiceActivityForm";
import AddRespiteServiceActivityForm, {
  useAddRespiteServiceActivityForm,
} from "shared/forms/AddRespiteServiceActivityForm";
import { ResponsiveModal } from "shared/ui/responsive-modal";

export default function RespiteServicesActivitySection({
  serviceEngagement,
}: {
  serviceEngagement: ServiceEngagementWithServiceResource;
}) {
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  // Query
  const {
    data: respiteServiceActivity,
    totalCost,
    totalHours,
    annualCap,
    remaining,
  } = useRespiteServiceActivityForNetwork({
    networkId: serviceEngagement.network_id,
  });

  // Mutations
  const deleteRespiteServiceActivity =
    useDeleteRespiteServiceActivity().mutateAsync;

  return (
    <div className="flex flex-col gap-5">
      {/* Header */}
      <div className="flex gap-5">
        <p className="text-lg ">Dates of Service</p>
        <AddRespiteServiceActivityDate serviceEngagement={serviceEngagement} />
      </div>

      {/* Table */}
      <div className="grid grid-cols-[1fr_2fr_2fr_.5fr_1fr_.5fr] truncate text-sm">
        {/* Table Headers */}
        <p className="font-bold">Date</p>
        <p className="font-bold">Location</p>
        <p className="font-bold">Provider</p>
        <p className="font-bold text-center">Hours</p>
        <p className="font-bold text-center">Cost</p>
        <p />
        {/* Table rows */}

        {respiteServiceActivity?.map((activity) => (
          <React.Fragment key={activity.id}>
            <p className="py-1">
              {new Date(activity.date_of_service).toLocaleDateString("en-US", {
                timeZone: "UTC",
              })}
            </p>{" "}
            <p className="py-1">{activity.location}</p>
            <div className="truncate py-1">
              <p className="truncate">{activity.provider_name}</p>
            </div>
            <p className="py-1 text-center">{activity.hours_of_service}</p>
            <p className="py-1 text-center">${activity.cost.toFixed(2)}</p>
            <div className="py-1">
              <>
                <ButtonWithIcon
                  size="small"
                  icon={IconOption.TRASH}
                  onClick={() => setIsDeleteConfirmOpen(true)}
                  text=""
                />

                <ResponsiveModal
                  isOpen={isDeleteConfirmOpen}
                  title="Delete Date of Service"
                  footerButtons={
                    <>
                      <ButtonWithIcon
                        icon={IconOption.CANCEL}
                        onClick={() => setIsDeleteConfirmOpen(false)}
                        text="Cancel"
                      />
                      <ButtonWithIcon
                        icon={IconOption.TRASH}
                        onClick={async () => {
                          await deleteRespiteServiceActivity(activity.id);
                          setIsDeleteConfirmOpen(false);
                        }}
                        text="Delete"
                      />
                    </>
                  }
                  onClose={() => setIsDeleteConfirmOpen(false)}>
                  <p>Are you sure you want to delete this service line?</p>
                </ResponsiveModal>
              </>
            </div>
          </React.Fragment>
        ))}

        <p className="font-bold text-left py-1">TOTAL</p>
        <p className="py-1" />
        <p className="py-1" />
        <p className="py-1 text-center">{totalHours}</p>
        <p className="py-1 text-center">${totalCost.toFixed(2)}</p>
        <p className="py-1" />
      </div>

      <div className="bg-neutral-300 rounded-md grid grid-cols-[3fr,1fr] p-5 w-[300px] text-sm">
        <p className="font-bold mb-1">YTD TOTALS</p> <p />
        <p> Annual Cap:</p> <p className="text-right">${annualCap}</p>
        <p>Spent this Year:</p>{" "}
        <p className="text-right">{`$${totalCost.toFixed(2)}`}</p>
        <p>Amount Remaining:</p>
        <p className={`text-right ${remaining < 0 ? "text-red-500" : ""}`}>
          {/* Conditionally format the amount with a minus sign for negative values
            to ensure the minus sign appears before the dollar sign, e.g., -$322.83
            instead of $-322.83, which looks awkward. */}
          {`${remaining < 0 ? "-" : ""}$${Math.abs(remaining).toFixed(2)}`}
        </p>
      </div>
    </div>
  );
}

function AddRespiteServiceActivityDate({
  serviceEngagement,
}: {
  serviceEngagement: ServiceEngagementWithServiceResource;
}) {
  const [isAddingDatePopupOpen, setIsAddingDatePopupOpen] = useState(false);
  const form = useAddRespiteServiceActivityForm();
  const insertRespiteServiceActivity =
    useInsertRespiteServiceActivity().mutateAsync;

  if (!isAddingDatePopupOpen) {
    return (
      <ButtonWithIcon
        size="small"
        icon={IconOption.PLUS}
        onClick={() => {
          setIsAddingDatePopupOpen(true);
        }}
        text=""
      />
    );
  }
  async function handleSave(values: RespiteServiceActivityDateType) {
    if (!serviceEngagement.service_resource) return;
    await insertRespiteServiceActivity({
      ...values,
      network_id: serviceEngagement.network_id,
      service_id: serviceEngagement.id,
      provider_name: serviceEngagement.service_resource?.name,
      service_provider_id: serviceEngagement.service_resource_id,
    });
    setIsAddingDatePopupOpen(false);
  }

  return (
    <ResponsiveModal
      isOpen={isAddingDatePopupOpen}
      onClose={() => {
        setIsAddingDatePopupOpen(false);
      }}
      title="Add Service"
      footerButtons={
        <>
          <ButtonWithIcon
            icon={IconOption.CANCEL}
            onClick={async () => {
              setIsAddingDatePopupOpen(false);
            }}
            text="Cancel"
          />
          <ButtonWithIcon
            icon={IconOption.CHECKMARK}
            onClick={form.handleSubmit((values) => {
              handleSave(values);
            })}
            text="Save"
          />
        </>
      }>
      <AddRespiteServiceActivityForm form={form} />
    </ResponsiveModal>
  );
}
