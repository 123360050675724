import { supabase } from "clients/supabaseClient";

import type { Filter } from "features/query-utils";

import { TABLE_NAME } from "../constants";
import type { TableName } from "../types";

const all = ["tasks"];
const lists = [...all, "list"];

export const queryKeys = {
  all,
  lists,
  list: (filter: Filter<TableName>) => [...lists, filter],
};

export const select = () =>
  supabase.from(TABLE_NAME).select(
    `
    id,
    created_at,
    name,
    description,
    status,
    scheduled_date_time,
    type,
    is_service_ticket,
    user!plan_entry_user_id_fkey(
      id,
      first_name,
      last_name,
      profile_image
    ),
    network(
      name
    ),
    service_request_to_engagement(
      service_engagement(
        is_active
      )
    )
  `
  );
