import { SplideSlide } from "@splidejs/react-splide";
import { useParams } from "react-router-dom";
import Meter1 from "assets/meter-1.png";
import Meter2 from "assets/meter-2.png";
import Meter3 from "assets/meter-3.png";
import Meter4 from "assets/meter-4.png";
import { useAlfredPageSideBar } from "backend/resources/chatGptConversation";
import { useUserQuery } from "backend/resources/user";
import { useLatestCompletedCaregiverAssesment } from "backend/resources/userAssessment";
import { CraniometrixProduct, useProductAccess } from "hooks/product/product";
import { useNavigateBack } from "lib/routing";
import { useUserStore } from "state/user";
import BackButton from "components/BackButton/BackButton";
import Carousel from "components/Carousel/Carousel";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { YouTubeVideo } from "components/YouTubeVideo";

const SHORT_ZARIT_ASSESMENT_ID = "9a52a105-2885-442f-9e80-0050ea7dd504";
const METERS = [Meter1, Meter2, Meter3, Meter4];

export default function MyCarePage() {
  const navigateBack = useNavigateBack();

  // Store
  const authUser = useUserStore((state) => state.user);
  let { id } = useParams();
  id = id || authUser?.id;

  // queries
  useAlfredPageSideBar("myCarePage");
  const { data: productAccess } = useProductAccess();
  const { user: caregiver } = useUserQuery(id);
  const {
    data: lastCompletedCaregiverAssessment,
    isLoading: isLoadingLatestCompletedCaregiverAssessment,
  } = useLatestCompletedCaregiverAssesment(id);

  // consts
  const isOwnPage = id === authUser?.id;
  const latestScore = lastCompletedCaregiverAssessment?.total_score as number;
  const hasTakenAnAssessment = !!lastCompletedCaregiverAssessment;

  // state
  const [burdenText, meterIndex] = getBurdenLevel();

  // functions
  function getBurdenLevel(): [string, number] {
    if (latestScore === undefined) {
      return [
        "No assessments have been taken yet. Please click on New Assessment to complete an assessment.",
        0,
      ];
    } else if (latestScore <= 10) {
      return ["This indicates no to mild burden", 1];
    } else if (latestScore <= 20) {
      return ["This indicates mild to moderate burden", 2];
    } else {
      return ["This indicates high burden", 3];
    }
  }

  const isLatestAssessmentShortZarit =
    lastCompletedCaregiverAssessment?.assessment_id ===
    SHORT_ZARIT_ASSESMENT_ID;
  const highestScorePossible = isLatestAssessmentShortZarit ? 48 : 88;

  // +-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+-+
  return (
    <PageContainer>
      {productAccess === CraniometrixProduct.CARE_CENTRAL ? (
        <BackButton className="mb-2" />
      ) : null}
      <PageMainHeader
        text={isOwnPage ? "My Care" : `${caregiver?.first_name}'s Care`}
        className="mb-10"
      />

      {!isLoadingLatestCompletedCaregiverAssessment ? (
        <>
          {/* <div className="w-full flex justify-left py-4">
            <img
              className="w-32"
              src={METERS[meterIndex]}
              alt=""
            />
          </div> */}
          <p className="font-bold py-4 text-lg">ZARIT Stress Score</p>
          {hasTakenAnAssessment ? (
            <p className="text-sm">
              As of{" "}
              {isOwnPage
                ? "your"
                : `${caregiver?.first_name} ${caregiver?.last_name}'s`}{" "}
              last assessment taken on{" "}
              {new Date(
                lastCompletedCaregiverAssessment?.created_at
              ).toLocaleDateString()}
              , {isOwnPage ? "your" : "their"} state of stress was rated a{" "}
              <label className="font-bold">{latestScore} </label>
              out of {highestScorePossible}, where 0 is the lowest and{" "}
              {highestScorePossible} is the highest.
              {/* {burdenText} */}
            </p>
          ) : (
            <p className="text-sm">
              No self assessments have been taken yet. Your doctor will assign
              one to you at the appropriate time. If you have any questions,
              please ask in the Discussions section.
            </p>
          )}
        </>
      ) : (
        <div className="w-full flex justify-center items-center">
          <LoadingSpinner className="w-10" />
        </div>
      )}
      {hasTakenAnAssessment ? (
        <>
          <p className="text-lg my-6">Recommended for You</p>
          <Carousel
            showArrowsInMobile
            items={[
              "https://www.youtube.com/embed/4iLvxd_7Pns",
              "https://www.youtube.com/embed/EyeSbGBAmpI",
              "https://www.youtube.com/embed/yXwQS3ory6E",
              "https://www.youtube.com/embed/H3RQ9-hOuIE",
              "https://www.youtube.com/embed/Ayl_wgwvaLk",
            ].map((link) => (
              <SplideSlide>
                <YouTubeVideo
                  url={link}
                  slug={link}
                />
              </SplideSlide>
            ))}
          />
        </>
      ) : null}
    </PageContainer>
  );
}
