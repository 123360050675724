import { useUpdateNetworkCareNavigator } from "backend/resources/network/network";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { FancyLoadingState } from "components/FancyLoadingState";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import {
  AssignCareNavigatorForm,
  useAssignCareTeamForm,
} from "./AssignCareTeamForm";

interface AssignCareTeamModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedCarespaces: string[];
}

export function AssignCareTeamModal({
  isOpen,
  onClose,
  selectedCarespaces,
}: AssignCareTeamModalProps) {
  const assignCareTeamForm = useAssignCareTeamForm();
  const updateCareNavigator = useUpdateNetworkCareNavigator();

  const handleSubmit = async () => {
    const values = assignCareTeamForm.getValues();
    if (!values.orgMember) return;

    await updateCareNavigator.mutateAsync({
      networkIds: selectedCarespaces,
      careNavigatorId: values.orgMember.user_id,
    });

    onClose();
    assignCareTeamForm.reset();
  };

  return (
    <ResponsiveModal
      isOpen={isOpen}
      title="Assign Care Team"
      closeText="Close"
      onClose={onClose}
      footerButtons={
        <>
          <ButtonWithIcon
            onClick={onClose}
            text="Cancel"
            icon={IconOption.CANCEL}
          />
          <ButtonWithIcon
            onClick={() => {
              assignCareTeamForm.handleSubmit(handleSubmit)();
            }}
            text="Apply Changes"
            icon={IconOption.ARROW}
            disabled={updateCareNavigator.isPending}
          />
        </>
      }>
      {updateCareNavigator.isPending ? (
        <div className="flex justify-center items-center min-h-[200px]">
          <FancyLoadingState />
        </div>
      ) : (
        <div className="flex flex-col gap-4">
          <p>Select care team members to apply to the selected carespaces:</p>
          <AssignCareNavigatorForm form={assignCareTeamForm} />
        </div>
      )}
    </ResponsiveModal>
  );
}
