import ErrorIcon from "assets/error.svg?react";
import { useParams } from "react-router-dom";

export default function InvalidInvitationPage() {
  const { error } = useParams();
  return (
    <div className="bg-white w-full h-full flex flex-col sm:items-center justify-center overflow-y-scroll text-lg">
      <ErrorIcon className="w-20 h-20  bg-red-500 rounded-full mb-10" />
      <h1 className="text-2xl font-bold mb-10">
        Oops! The Invitation Is Not Valid.
      </h1>
      <p className="mb-2">Reason: {error}.</p>
      <p>If you need additional support, contact support@craniometrix.com</p>
    </div>
  );
}
