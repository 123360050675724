import { useGenerateCarePlan } from "backend/resources/carePlan/carePlan";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CarespacePageTabs } from "components/CarespacePage/Tabs/carespaceTypes";
import { useNavigateToCarespaceTab } from "routes/routesUtil";
import { PopupOrSheet } from "shared/ui/popup-bottom-sheet";

interface Props {
  onClose: () => void;
  isOpen: boolean;
}
export default function GenerateCarePlanSubmissionPopup({
  isOpen,
  onClose,
}: Props) {
  const navigateToCarespaceTab = useNavigateToCarespaceTab();

  const generateCarePlan = useGenerateCarePlan().mutateAsync;

  return (
    <PopupOrSheet
      isOpen={isOpen}
      onClose={onClose}
      footerButtons={
        <>
          <ButtonWithIcon
            className="text-xs md:text-base"
            onClick={onClose}
            size={"small"}
            text={"Cancel"}
            icon={IconOption.CANCEL}
          />

          <ButtonWithIcon
            className="text-xs md:text-base"
            onClick={async () => {
              await generateCarePlan();
              navigateToCarespaceTab(CarespacePageTabs.CARE_PLAN);
              onClose();
            }}
            size={"small"}
            text={"Continue"}
            icon={IconOption.ARROW}
          />
        </>
      }>
      <p>Are you sure you want to generate a coaching plan?</p>
    </PopupOrSheet>
  );
}
