import { zodResolver } from "@hookform/resolvers/zod";
import { NotificationType, sendNotification } from "backend/functions";
import type {
  OrganizationInvitation,
  OrganizationInvitationInsert,
} from "backend/resources/organizationInvitations/organizationInvitation";
import { useInsertOrganizationInvitation } from "backend/resources/organizationInvitations/organizationInvitation";
import {
  ORG_ROLE_OPTIONS,
  OrgRoleType,
} from "backend/resources/userRole/types";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import { PhoneNumberFormInput } from "components/PhoneNumberInput/PhoneNumberInput";
import { useState } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import "react-phone-number-input/style.css";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
} from "shared/ui/form";
import { Input } from "shared/ui/input";
import { RadioGroup, RadioGroupItem } from "shared/ui/radio-group";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { Title } from "shared/ui/title";
import { useActiveOrganizationId } from "state/organization/organization";
import * as z from "zod";
import type { MultiPageFormProps, ResponsiveModalWizardProps } from "./types";
import { PhoneNumberSchema } from "./types";

export const addToOrganizationFormSchema = z.object({
  first_name: z.string(),
  last_name: z.string(),
  email: z.string().email("Please enter a valid email."),
  cell_number: PhoneNumberSchema,
  org_role: z.string(),
  is_superuser: z.boolean(),
});

export const useAddToOrganizationForm = () =>
  useForm<z.infer<typeof addToOrganizationFormSchema>>({
    resolver: zodResolver(addToOrganizationFormSchema),
    defaultValues: {
      is_superuser: false,
    },
    mode: "onSubmit",
  });

export type AddToOrganizationFormHook = UseFormReturn<
  z.infer<typeof addToOrganizationFormSchema>
>;

export const addToOrganizationFormId = "add-to-organization-form";

export function AddToOrganizationFormComponent(
  props: MultiPageFormProps<z.infer<typeof addToOrganizationFormSchema>>
) {
  const { form } = props;
  const orgRole = form.watch("org_role");
  return (
    <Form
      form={form}
      id={addToOrganizationFormId}>
      <Title order={3}>Organization member details</Title>
      <div className="grid grid-cols-[2fr,3fr] gap-2">
        <FormField
          control={form.control}
          name="first_name"
          label="First Name"
          render={({ field }) => <Input {...field} />}
        />
        <FormField
          control={form.control}
          name="last_name"
          label="Last Name"
          render={({ field }) => <Input {...field} />}
        />
      </div>

      <div className="grid grid-cols-[3fr,2fr]">
        <FormField
          control={form.control}
          name="email"
          label="Email"
          render={({ field }) => <Input {...field} />}
        />
      </div>

      <div className="grid grid-cols-[3fr,2fr]">
        <FormField
          control={form.control}
          name="cell_number"
          label="Cell"
          render={({ field }) => <PhoneNumberFormInput {...field} />}
        />
      </div>

      <div className="grid grid-cols-[3fr,2fr]">
        <FormField
          control={form.control}
          name="org_role"
          label="Role"
          render={({ field }) => (
            <RadioGroup
              onValueChange={field.onChange}
              defaultValue={field.value}
              className="flex flex-col space-y-1">
              {ORG_ROLE_OPTIONS.map((option) => (
                <FormItem
                  key={option.value}
                  className="flex items-center space-x-3 space-y-0">
                  <FormControl>
                    <RadioGroupItem value={option.value} />
                  </FormControl>
                  <FormLabel>{option.label}</FormLabel>
                </FormItem>
              ))}
            </RadioGroup>
          )}
        />
      </div>

      <div className="grid grid-cols-[3fr,2fr]">
        <FormField
          control={form.control}
          name="is_superuser"
          label="Administrative Privileges"
          render={({ field }) => (
            <DefaultCheckbox
              checked={field.value || orgRole === OrgRoleType.ADMIN}
              onChange={field.onChange}
            />
          )}
          orientation="horizontal"
          inverted
        />
      </div>
    </Form>
  );
}

export function AddToOrganizationForm({ onClose }: ResponsiveModalWizardProps) {
  const TITLE = "Add To Organization";
  const form = useAddToOrganizationForm();
  const formValues = form.watch();
  const [isSending, setIsSending] = useState(false);

  const organizationId = useActiveOrganizationId();

  const insertInvitation = useInsertOrganizationInvitation().mutateAsync;

  const handleSendInvitation = async (
    validatedForm: z.infer<typeof addToOrganizationFormSchema>
  ) => {
    setIsSending(true);

    if (!organizationId) return;

    const newInvitation: OrganizationInvitationInsert | OrganizationInvitation =
      {
        organization_id: organizationId,
        invited_email: validatedForm.email,
        first_name: validatedForm.first_name,
        last_name: validatedForm.last_name,
        role_type: validatedForm.org_role,
        cell_number: validatedForm.cell_number,
        is_superuser:
          validatedForm.is_superuser ||
          validatedForm.org_role === OrgRoleType.ADMIN, // TODO add a checkbox
      };

    const data: OrganizationInvitation | undefined = await insertInvitation(
      newInvitation
    );

    if (data) {
      // Create URL object. We use the location origin so that it works for all enviromnets
      await sendNotification(data.id, NotificationType.ORGANIZATION_INVITATION);
    }

    setIsSending(false);
  };

  return (
    <ResponsiveModal
      isOpen={true}
      closeText="Back"
      title={TITLE}
      isNetworkCallInProgress={isSending}
      onClose={() => {
        onClose();
        form.reset();
      }}
      footerButtons={
        <>
          <ButtonWithIcon
            text="Cancel"
            icon={IconOption.CANCEL}
            onClick={() => {
              onClose();
              form.reset();
            }}
          />
          <ButtonWithIcon
            text="Done"
            icon={IconOption.CHECKMARK}
            onClick={async () => {
              await form.handleSubmit((values) => {
                handleSendInvitation(values);
                onClose();
                form.reset();
              })();
            }}
            disabled={
              Object.values(formValues).some(
                (value) => value === "" || value === undefined
              ) || isSending
            }
          />
        </>
      }>
      <AddToOrganizationFormComponent form={form} />
    </ResponsiveModal>
  );
}
