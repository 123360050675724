import type {
  PAFStatus,
  Sex,
} from "components/CarespacePage/PAFSubmission/pafEnums";
import type { GuideAlignmentStatus } from "components/CarespacePage/Tabs/CarespaceMainTab/constants";
import type { DYAD } from "components/HomePage/CareCentralHome/Carespaces/CarespaceTable/constants";
import { useUserStore } from "state/user";
import { create } from "zustand";

export type CarespaceFilterState = {
  carespace: string | undefined;
  dob: string | undefined;
  sex: Sex | undefined;
  dyad: DYAD | undefined;
  org: string | undefined;
  status: PAFStatus | GuideAlignmentStatus | undefined;
  doctor: string | undefined;
  pcg: string | undefined;
  cn: string | undefined;
  pcp: string | undefined;
  setCarespace: (carespace: string | undefined) => void;
  setDob: (dob: string | undefined) => void;
  setSex: (sex: Sex | undefined) => void;
  setDyad: (dyad: DYAD | undefined) => void;
  setOrg: (org: string | undefined) => void;
  setStatus: (status: PAFStatus | GuideAlignmentStatus | undefined) => void;
  setDoctor: (doctor: string | undefined) => void;
  setPcg: (pcg: string | undefined) => void;
  setCn: (cn: string | undefined) => void;
  setPcp: (pcp: string | undefined) => void;
};

export const useLocalCarespaceFilterStore = create<CarespaceFilterState>(
  (set) => ({
    carespace: undefined,
    dob: undefined,
    sex: undefined,
    dyad: undefined,
    org: undefined,
    status: undefined,
    doctor: undefined,
    pcg: undefined,
    cn: undefined,
    pcp: undefined,
    setCarespace: (carespace: string | undefined) => set({ carespace }),
    setDob: (dob: string | undefined) => set({ dob }),
    setSex: (sex: Sex | undefined) => set({ sex }),
    setDyad: (dyad: DYAD | undefined) => set({ dyad }),
    setOrg: (org: string | undefined) => set({ org }),
    setStatus: (status: PAFStatus | GuideAlignmentStatus | undefined) =>
      set({ status }),
    setDoctor: (doctor: string | undefined) => set({ doctor }),
    setPcg: (pcg: string | undefined) => set({ pcg }),
    setCn: (cn: string | undefined) => set({ cn }),
    setPcp: (pcp: string | undefined) => set({ pcp }),
  })
);

export const useCarespaceFilterStore = () => {
  const authUser = useUserStore((state) => state.user);
  const localFilter = useLocalCarespaceFilterStore();
  return {
    ...localFilter,
    org:
      localFilter.org ||
      (authUser?.organizations.length === 1
        ? authUser.organizations[0]
        : undefined),
  };
};
