import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";
import * as React from "react";
import type { DateRange } from "react-day-picker";

import { Button } from "shared/ui/button";
import { Calendar } from "shared/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "shared/ui/popover";
import { Text } from "shared/ui/text";

import { cn } from "shared/utils/cn";

import useControlledProps, {
  type ControlledProps,
} from "shared/hooks/use-controlled-props";

export interface DateRangePickerProps extends ControlledProps<DateRange> {
  className?: string;
  id?: string;
  disabled?: boolean;
  placeholder?: string;
}

const DateRangePicker = React.forwardRef<
  React.ElementRef<typeof Button>,
  DateRangePickerProps
>(
  (
    { className, id, disabled, placeholder = "Select date range", ...props },
    ref
  ) => {
    const [open, setOpen] = React.useState(false);
    const { value, onChange } = useControlledProps(props);

    return (
      <Popover
        open={open}
        onOpenChange={(newOpen) => !disabled && setOpen(newOpen)}>
        <PopoverTrigger asChild>
          <Button
            ref={ref}
            id={id}
            variant="combobox"
            role="combobox"
            disabled={disabled}
            aria-expanded={open}
            className={cn(className, "justify-start")}>
            <CalendarIcon className="mr-2 h-4 w-4 text-brand-orange" />
            <Text
              size="sm"
              className="truncate">
              {value
                ? `${value.from ? format(value.from, "MM/dd/yyyy") : ""} - ${
                    value.to ? format(value.to, "MM/dd/yyyy") : ""
                  }`
                : placeholder}
            </Text>
          </Button>
        </PopoverTrigger>

        <PopoverContent
          className="p-2"
          sideOffset={6}
          align="start">
          <Calendar
            mode="range"
            defaultMonth={value?.from}
            selected={value}
            onSelect={onChange}
            initialFocus
          />
        </PopoverContent>
      </Popover>
    );
  }
);
DateRangePicker.displayName = "DateRangePicker";

export { DateRangePicker };
