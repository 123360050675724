import type { ChatGptMessage } from "backend/resources/chatGptMessage";
import { useServiceResource } from "backend/resources/services/serviceResource";
import { useUserQuery } from "backend/resources/user";
import { AssistantMessage } from "components/ChatGptSideBar/Message/AssistantMessage";
import { SystemMessage } from "components/ChatGptSideBar/Message/SystemMessage";
import { ProfileImage } from "components/Profile/ProfileImage";
import ReactMarkdown from "react-markdown";
import { useUserStore } from "state/user";

// TODO(@andrew): restructure project directories for StaticActionBar and other shared elements

interface Props {
  message: ChatGptMessage;
}

export function Message({ message }: Props) {
  const authUser = useUserStore((state) => state.user);
  const { user: messageAuthorUser } = useUserQuery(message.user_id);
  const { data: serviceResource } = useServiceResource(
    message.service_resource_id
  );
  switch (message.role) {
    case "system": {
      switch (message.type) {
        case "ai": {
          return <SystemMessage message={message} />;
        }
        default: {
          return null;
        }
      }
    }
    case "assistant": {
      return <AssistantMessage message={message} />;
    }
    case "service_resource": {
      return (
        <div className="flex flex-row-reverse w-full justify-end max-w-max min-w-0 gap-3 items-end">
          <div className="flex flex-col justify-start min-w-0 w-full">
            <p className="ml-2 text-xs">{serviceResource?.name}</p>
            <p className="bg-gray-50 text-black px-4 py-2 rounded-md break-words whitespace-pre-line">
              <ReactMarkdown
                components={{
                  a: ({ node, children, ...props }) => (
                    <a
                      {...props}
                      style={{ textDecoration: "underline" }}
                      target="_blank">
                      {children}
                    </a>
                  ),
                }}>
                {message.content}
              </ReactMarkdown>
            </p>
          </div>
          <ProfileImage
            size={30}
            imageUrl={serviceResource?.image_url}
          />
        </div>
      );
    }
    case "user": {
      return (
        <div
          className={`min-w-0 gap-3 items-end ${
            message.user_id && message.user_id != authUser?.id
              ? "flex flex-row-reverse w-full justify-end max-w-max"
              : "flex self-end w-full max-w-max"
          }`}>
          <div className="flex flex-col justify-start min-w-0 w-full">
            {message.user_id && message.user_id !== authUser?.id ? (
              <p className="ml-2 text-xs">{messageAuthorUser?.first_name}</p>
            ) : null}
            <p
              className={`${
                message.user_id && message.user_id === authUser?.id
                  ? "bg-indigo-400 text-white"
                  : "bg-gray-50 text-black"
              } px-4 py-2 rounded-md break-words whitespace-pre-line`}>
              <ReactMarkdown
                components={{
                  // This custom component will render links with an underline
                  a: ({ node, children, ...props }) => (
                    <a
                      {...props}
                      style={{ textDecoration: "underline" }}
                      target="_blank">
                      {children}
                    </a>
                  ),
                }}>
                {message.content}
              </ReactMarkdown>
            </p>
          </div>

          <ProfileImage
            size={30}
            userId={message.user_id || authUser?.id}
          />
        </div>
      );
    }
    default: {
      return null;
    }
  }
}
