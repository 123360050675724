import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGoalTemplateQueryById } from "backend/resources/goalTemplate/goalTemplate";
import { useRecommendationsForTemplate } from "backend/resources/goalTemplate/goalTemplateToRecommendation";
import type { ShareableRecommendationType } from "backend/resources/shareableRecommendation/shareableRecommendation";
import { useWindowSize } from "hooks/useWindowSize";
import { CareCentralRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import BackButton from "components/BackButton/BackButton";
import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { ShareableRecommendation } from "components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendation/ShareableRecommendation";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { GoalRecSuggestionsBreadcrumbs } from "components/GoalPage/GoalRecSuggestionsBreadcrumbs";
import { TimeFrame } from "components/GoalPage/utils";

export default function GoalTemplatePage() {
  const { id: goalTemplateId } = useParams();
  const { isMobile } = useWindowSize();
  const [state, setState] = useState<{
    title: string;
    details: string;
    recommendations: ShareableRecommendationType[];
    timeframe: string;
  }>({
    title: "",
    details: "",
    timeframe: "",
    recommendations: [],
  });

  const { goalTemplate } = useGoalTemplateQueryById(goalTemplateId);

  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();

  const { data: recommendationsData } =
    useRecommendationsForTemplate(goalTemplateId);

  useEffect(() => {
    if (goalTemplateId && recommendationsData && goalTemplate) {
      setState({
        title: goalTemplate.title,
        details: goalTemplate.description,
        recommendations: recommendationsData,
        timeframe: TimeFrame.getLabelFromValue(goalTemplate.timeline),
      });
    }
  }, [recommendationsData, goalTemplate]);

  return (
    <PageContainer>
      <div className="flex gap-4 flex-col">
        <div className="flex justify-between">
          <BackButton />
        </div>
        <GoalRecSuggestionsBreadcrumbs />

        <div className="flex gap-5 items-center -mt-3">
          <PageMainHeader text={state.title} />
          {!isMobile && (
            <ButtonWithIcon
              onClick={() => {
                if (goalTemplateId) {
                  navigate({
                    path: CareCentralRoute.GOAL_TEMPLATE_EDIT_ID,
                    params: {
                      id: goalTemplateId,
                    },
                  });
                }
              }}
              text={"Edit Goal"}
              size={"small"}
              icon={IconOption.EDIT}
            />
          )}
        </div>

        <h1>{state.details.replace('"', "").replace('"', "")}</h1>
        <div className="w-full text-sm items-center">
          <label className="flex gap-1">
            Time frame: <p className="">{state.timeframe}</p>
          </label>
        </div>

        <div className="flex w-full">
          Recommendations ({state.recommendations.length})
        </div>

        <div className="flex flex-wrap gap-4 justify-center md:justify-start pb-40">
          {/* if there's a recommendationId, we show */}
          {state.recommendations
            ?.sort((a, b) => {
              return a.title.localeCompare(b.title);
            })
            ?.map((recommendation) => (
              <ShareableRecommendation recommendation={recommendation} />
            ))}
        </div>
      </div>
      <BottomActionBar
        centerSlot={
          <ButtonWithIcon
            onClick={() => {
              if (goalTemplateId) {
                navigate({
                  path: CareCentralRoute.GOAL_TEMPLATE_EDIT_ID,
                  params: {
                    id: goalTemplateId,
                  },
                });
              }
            }}
            text={""}
            className="bg-white rounded-full"
            icon={IconOption.EDIT}
          />
        }
      />
    </PageContainer>
  );
}
