import {
  InitialPatient,
  PTReferralSource,
} from "components/CarespacePage/PAFSubmission/pafEnums";
import type { PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { getOptionsFromEnum } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { RadioButtonsGroup } from "components/RadioGroup";
import type { FieldValues, UseFormReturn } from "react-hook-form";
import { FormField } from "shared/ui/form";
import type { z } from "zod";

interface InitialAssessmentInputsProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
}

export const InitialAssessmentInputs: React.FC<
  InitialAssessmentInputsProps<z.infer<typeof PAFSchema>>
> = ({ form, isReadOnly }) => (
  <div className="flex flex-col gap-4">
    <FormField
      control={form.control}
      disabled={isReadOnly}
      label="Initial Patient"
      name="initial_patient"
      render={({ field }) => (
        <RadioButtonsGroup
          options={getOptionsFromEnum(InitialPatient)}
          id="initial_patient"
          {...field}
        />
      )}
    />

    <FormField
      control={form.control}
      disabled={isReadOnly}
      label="PT Referral Source"
      name="pt_referral_source"
      render={({ field }) => (
        <RadioButtonsGroup
          row={false}
          options={getOptionsFromEnum(PTReferralSource)}
          id="pt_referral_source"
          {...field}
        />
      )}
    />
  </div>
);
