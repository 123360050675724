import {
  ChangeEvent,
  cloneElement,
  forwardRef,
  ReactElement,
  useId,
} from "react";
export interface CheckboxProps {
  /**
   * The label displayed next to the checkbox.
   */
  label?: string;

  /**
   * Controls the checked state of the checkbox.
   * If not provided, the checkbox will manage its own state internally.
   * @default false
   */
  checked: boolean;

  /**
   * Callback function triggered when the checked state changes.
   * Receives the new checked state as a boolean.
   */
  onChange?: (checked: boolean) => void;

  /**
   * The SVG icon to display when the checkbox is checked.
   * Should be a React element, typically an imported SVG component.
   */
  icon?: ReactElement;

  /**
   * The SVG icon to display when the checkbox is unchecked.
   * Should be a React element, typically an imported SVG component.
   */
  uncheckedIcon?: ReactElement;

  /**
   * Disables the checkbox, preventing user interaction.
   * When disabled, the checkbox will appear faded and will not respond to clicks.
   * @default false
   */
  disabled?: boolean;

  /**
   * The width of the checkbox icons.
   * @default 20
   */
  iconWidth?: number;

  /**
   * The height of the checkbox icons.
   * @default 20
   */
  iconHeight?: number;
}

/**
 * A reusable Checkbox component that supports custom SVG icons, Tailwind CSS styling,
 * and enhanced accessibility features. The checkbox can display different icons based
 * on its checked state and can be controlled externally or manage its own state.
 *
 * @component
 *
 * @param {CustomIconCheckboxProps} props - The props for the Checkbox component.
 * @returns {JSX.Element} The rendered Checkbox component.
 *
 * @example
 * ```tsx
 * import { useState } from "react";
 * import Checkbox from "./Checkbox/Checkbox";
 * import CheckedIcon from "../../assets/checkbox_icon.svg?react";
 * import UncheckedIcon from "../../assets/unchecked_icon.svg?react";
 *
 * const ExampleComponent: React.FC = () => {
 *   const [isChecked, setIsChecked] = useState<boolean>(false);
 *
 *   const handleCheckboxChange = (checked: boolean) => {
 *     setIsChecked(checked);
 *   };
 *
 *   return (
 *     <Checkbox
 *       label="Accept Terms"
 *       checked={isChecked}
 *       onChange={handleCheckboxChange}
 *       icon={<CheckedIcon />}
 *       uncheckedIcon={<UncheckedIcon />}
 *       iconWidth={24}
 *       iconHeight={24}
 *     />
 *   );
 * };
 *
 * export default ExampleComponent;
 * ```
 */

export const Checkbox = forwardRef<HTMLDivElement, CheckboxProps>(
  ({
    label,
    checked = false,
    onChange,
    icon,
    uncheckedIcon,
    disabled = false,
    iconWidth = 20,
    iconHeight = 20,
  }) => {
    // Unique ID for accessibility linkage between input and label
    const id = useId();

    /**
     * Handles the change event when the checkbox is toggled.
     * Updates the internal state and calls the onChange callback if provided.
     *
     * @param {ChangeEvent<HTMLInputElement>} e - The change event from the checkbox input.
     */
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      if (disabled) return; // Prevent changes if disabled

      const newChecked = e.target.checked;

      if (onChange) {
        onChange(newChecked);
      }
    };

    /**
     * Clones the provided icon element and injects the width and height props.
     *
     * @param {ReactElement} element - The React element to clone.
     * @returns {ReactElement} The cloned React element with injected size props.
     */
    const renderIcon = (element: ReactElement | undefined) => {
      if (!element) return null;
      return cloneElement(element, {
        width: iconWidth,
        height: iconHeight,
      });
    };

    return (
      <div>
        {/* Hidden native checkbox input for accessibility */}
        <input
          type="checkbox"
          id={id}
          className="hidden"
          checked={checked}
          onChange={handleChange}
          disabled={disabled}
          aria-checked={checked}
          aria-disabled={disabled}
        />

        {/* Label that wraps the custom checkbox and the text label */}
        <label
          htmlFor={id}
          className={`flex items-center ${
            disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"
          }`}>
          {/* Custom checkbox appearance without inherent styles */}
          <span className="flex items-center justify-center">
            {checked ? renderIcon(icon) : renderIcon(uncheckedIcon)}
          </span>

          {label && <span className="ml-2 text-gray-700">{label}</span>}
        </label>
      </div>
    );
  }
);
