// ... other imports

import { useState } from "react";
import { useLatestMessages } from "backend/resources/chatGptMessage";
import { useOrgs } from "backend/resources/orgRole";
import { useAllNetworks } from "backend/resources/network/network";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import ActionButtons from "shared/ui/action-buttons";
import { useDiscussionFilterStore } from "state/discussionsFilter/discussionsFilter";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import DiscussionTable from "components/Tables/DiscussionTable";
import CreateConversationModal from "components/DiscussionComponent/components/CreateConversationModal";
import {
  DiscussionFields,
  DiscussionFilterTypes,
  DiscussionFilters,
} from "components/DiscussionComponent/components/DiscussionsFilter";

export default function DiscussionsComponent() {
  const navigate = useAppNavigate();

  // Stores
  const { org: orgFilter, carespace: carespaceFilter } =
    useDiscussionFilterStore();
  const { isMobile } = useWindowSize();
  // States
  const [showStartCarespaceDiscussion, setShowStartCarespaceDiscussion] =
    useState(false);

  // Queries
  const { data: latestMessages, isLoading: isLoadingLatestMessages } =
    useLatestMessages();
  const { hasCareCentralAccess } = useOrgs();
  const { networks, isLoading: isLoadingNetworks } = useAllNetworks();

  // Consts


  const unreadMessages = latestMessages?.filter(
    (message) => !message?.is_read
  );
  const readMessages = latestMessages?.filter(
    (message) => message?.is_read
  );

  // Determine which columns to hide based on the screen size and access rights
  const baseHiddenColumns = isMobile ? [] : [DiscussionFields.SentBy];

  const hiddenColumns = hasCareCentralAccess
    ? baseHiddenColumns
    : [...baseHiddenColumns, DiscussionFields.Carespace];

  // *---------------**---------------**---------------**---------------**---------------**---------------*

  if (isLoadingLatestMessages || isLoadingNetworks) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner className="w-32" />
      </div>
    );
  }
  return (
    <>
      <CreateConversationModal
        isOpen={showStartCarespaceDiscussion}
        onClose={() => setShowStartCarespaceDiscussion(false)}
      />
      <div className="max-w-6xl pb-20">
        <div className="flex flex-col gap-2 items-center mt-5">
          <DiscussionFilters
            filterConfig={{
              [DiscussionFilterTypes.ORG]: hasCareCentralAccess,
              [DiscussionFilterTypes.CARESPACE]: hasCareCentralAccess,
              [DiscussionFilterTypes.TYPE]: true,
            }}
            carespaces={networks}
          />
          <ActionButtons>
            {hasCareCentralAccess ? (
              <ButtonWithIcon
                onClick={() => setShowStartCarespaceDiscussion(true)}
                text={"Create New Discussion"}
                size="small"
                className="self-start "
                icon={IconOption.PLUS}
              />
            ) : (
              <ButtonWithIcon
                onClick={() =>
                  navigate({
                    path: CarePilotRoute.GENERAL_DISCUSSION,
                    queryParams: { network_id: carespaceFilter ?? null },
                  })
                }
                text={"Go to General Discussion"}
                size="small"
                className="self-start"
                icon={IconOption.ARROW}
              />
            )}
          </ActionButtons>
        </div>
        <div className="flex flex-col gap-10">
          <div className="flex flex-col gap-2">
            <h2 className="text-xl mt-4">Unread Messages</h2>
            <DiscussionTable
              data={unreadMessages ?? []}
              latestMessageWidth="300px"
              hiddenColumns={hiddenColumns}
            />
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="text-xl mt-4">Read Messages</h2>
            <DiscussionTable
              data={readMessages ?? []}
              latestMessageWidth="300px"
              hiddenColumns={hiddenColumns}
            />
          </div>
        </div>
      </div>
    </>
  );
}
