import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useOrgs } from "backend/resources/orgRole";
import { useShareableInterventionFromId } from "backend/resources/shareableIntervention/shareableIntervention";
import { useWindowSize } from "hooks/useWindowSize";
import { CareCentralRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import BackButton from "components/BackButton/BackButton";
import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { GoalRecSuggestionsBreadcrumbs } from "components/GoalPage/GoalRecSuggestionsBreadcrumbs";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { Resource } from "components/GuidancePage/Recommendations/Recommendation/Interventions/Intervention/Resource";

export default function ShareableInterventionPage() {
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();
  const { id: interventionId } = useParams();
  const { isMobile } = useWindowSize();
  const [state, setState] = useState<{
    title: string;
    details: string;
  }>({
    title: "",
    details: "",
  });

  const { data: intervention } = useShareableInterventionFromId(interventionId);
  const { hasCareCentralAccess } = useOrgs();

  useEffect(() => {
    if (interventionId && intervention) {
      setState({
        title: intervention.title,
        details: intervention.details,
      });
    }
  }, [intervention]);

  return (
    <PageContainer>
      <div className="flex gap-4 flex-col relative pb-40">
        <BackButton />
        <GoalRecSuggestionsBreadcrumbs />
        <div className="flex items-center gap-10 md:sticky -mt-3 -mb-2 md:py-6 bg-white z-[1]">
          <PageMainHeader text={state.title} />
          {!isMobile && hasCareCentralAccess && (
            <ButtonWithIcon
              onClick={() => {
                if (interventionId) {
                  navigate({
                    path: CareCentralRoute.SHAREABLE_INTERVENTION_ADD_ID,
                    params: {
                      id: interventionId,
                    },
                  });
                }
              }}
              text={""}
              size={"small"}
              icon={IconOption.EDIT}
            />
          )}
        </div>
        <div className="flex flex-wrap gap-4 justify-center ">
          {state.details.split("\n").map((paragraph, index) => (
            <p
              key={index}
              className="font-poppins text-base font-extralight  pt-2">
              {paragraph}
            </p>
          ))}
        </div>

        <Resource interventionId={interventionId || ""} />
      </div>
      <BottomActionBar
        centerSlot={
          <ButtonWithIcon
            className="bg-white rounded-full"
            onClick={() => {
              if (interventionId) {
                navigate({
                  path: CareCentralRoute.SHAREABLE_INTERVENTION_ADD_ID,
                  params: {
                    id: interventionId,
                  },
                });
              }
            }}
            text={""}
            icon={IconOption.EDIT}
          />
        }
      />
    </PageContainer>
  );
}
