import { Button } from "components/Button";
import type { PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { Arrow } from "icons/Arrow";
import type { FC, ReactNode } from "react";
import { useState } from "react";
import type { FieldValues } from "react-hook-form";
import type { z } from "zod";

interface PAFInformationSectionProps<T extends FieldValues> {
  children: ReactNode;
  buttonLabel: string;
}

export const PAFInformationSection: FC<
  PAFInformationSectionProps<z.infer<typeof PAFSchema>>
> = ({ children, buttonLabel }) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  return (
    <>
      {/* Assessments Information */}
      <Button
        intent={"secondary"}
        className="text-left flex items-center w-full"
        type="button"
        onClick={() => setIsExpanded(!isExpanded)}>
        <span
          className="transform transition-transform duration-200 mr-6"
          style={{
            transform: isExpanded ? "rotate(-180deg)" : "rotate(0deg)",
          }}>
          <Arrow fill={"#f9aa34"} />
        </span>
        <span>{buttonLabel}</span>
      </Button>
      <div
        className="flex flex-col gap-4"
        style={{ display: isExpanded ? "flex" : "none" }}>
        {children}
      </div>
    </>
  );
};
