import {
  NetworkWithPlanEntries,
  TaskStatus,
  TaskWithGuideInfo,
} from "backend/resources/planEntry";
import { TableDataRowType } from "../InfiniteScrollingTable";
import { TaskTableRowType } from "../TaskTable/TaskTableTypes";

export function getTaskTypeForTasksByCarespaceTable(task: TaskWithGuideInfo) {
  if (!!task.is_service_ticket) {
    return TaskTableRowType.SERVICE_REQUEST;
  } else if (task.type?.includes("-")) {
    return TaskTableRowType.CARE_PLAN;
  } else {
    return task.type;
  }
}

export enum TasksByCarespaceTableFields {
  Id = "Id",
  CARESPACE_NAME = "Carespace",
  Type = "Type",
  Status = "Status",
  Due_Date = "Next Due Date",
  TIME = "Time",
  GUIDE_Percentage_Complete = "GUIDE %",
  RawData = "Raw Data",
}

export type TasksByCarespaceTableType = {
  [TasksByCarespaceTableFields.Id]: string;
  [TasksByCarespaceTableFields.CARESPACE_NAME]: string;
  [TasksByCarespaceTableFields.Type]: string;
  [TasksByCarespaceTableFields.Status]: TaskStatus;
  [TasksByCarespaceTableFields.Due_Date]: string;
  [TasksByCarespaceTableFields.TIME]: string;
  [TasksByCarespaceTableFields.GUIDE_Percentage_Complete]: number;
  [TasksByCarespaceTableFields.RawData]:
    | NetworkWithPlanEntries
    | TaskWithGuideInfo;
  ["subRows"]?: TasksByCarespaceTableSubRowType[];
};

export enum TasksByCarespaceTableSubRowFields {
  Id = "Id",
  TASK_NAME = "Task Name",
  Type = "Type",
  Status = "Status",
  Due_Date = "Date",
  TIME = "Time",
  Who = "Who",
  RawData = "Raw Data",
}
export interface TasksByCarespaceTableSubRowType
  extends TableDataRowType<TasksByCarespaceTableSubRowType> {
  [TasksByCarespaceTableSubRowFields.Id]: string;
  [TasksByCarespaceTableSubRowFields.TASK_NAME]: string;
  [TasksByCarespaceTableSubRowFields.Type]: string;
  [TasksByCarespaceTableSubRowFields.Status]: TaskStatus;
  [TasksByCarespaceTableSubRowFields.Due_Date]: string;
  [TasksByCarespaceTableSubRowFields.TIME]: string;
  [TasksByCarespaceTableSubRowFields.Who]: string;
  [TasksByCarespaceTableSubRowFields.RawData]:
    | NetworkWithPlanEntries
    | TaskWithGuideInfo;
}
