import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { QUERY_KEYS } from "backend/query-keys";
import {
  ConversationType,
  useMutateChatGptConversations,
} from "backend/resources/chatGptConversation";
import {
  Network,
  useNetworksInOrganization,
} from "backend/resources/network/network";
import { ServiceResource } from "backend/resources/services/serviceResource";
import { supabase } from "clients/supabaseClient";
import { useActiveOrganizationId } from "state/organization/organization";
import { Database } from "../../../../types/supabase";

const TABLE = "service_engagement";
export type ServiceEngagement =
  Database["public"]["Tables"]["service_engagement"]["Row"];
export type ServiceEngagementInsert =
  Database["public"]["Tables"]["service_engagement"]["Insert"];
export type ServiceEngagementUpdate =
  Database["public"]["Tables"]["service_engagement"]["Update"];

export type ServiceEngagementWithServiceResource = ServiceEngagement & {
  service_resource: ServiceResource | null;
};
export type ServiceEngagementWithServiceResourceAndNetwork =
  ServiceEngagementWithServiceResource & {
    network: Network | null;
  };

export function useServiceEngagementByServiceResourceIdAndNetworkId({
  service_resource_id,
  network_id,
}: {
  service_resource_id: string | undefined | null;
  network_id: string | undefined | null;
}) {
  return useQuery({
    queryKey: [
      QUERY_KEYS.serviceEngagements,
      { service_resource_id, network_id },
    ],
    queryFn: () =>
      fetchServiceEngagementByServiceResourceIdAndNetworkId(
        service_resource_id,
        network_id
      ),
  });
}

export async function fetchServiceEngagementByServiceResourceIdAndNetworkId(
  service_resource_id: string | undefined | null,
  network_id: string | undefined | null
) {
  if (!service_resource_id || !network_id) return null;
  const { data, error } = await supabase
    .from(TABLE)
    .select("*")
    .eq("service_resource_id", service_resource_id)
    .eq("network_id", network_id)
    .limit(1)
    .maybeSingle();
  return data;
}

export function useServiceEngagementByServiceRequestId({
  service_request_id,
}: {
  service_request_id: string | undefined | null;
}) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceEngagements, { service_request_id }],
    queryFn: async () => {
      if (!service_request_id) return null;
      const { data, error } = await supabase
        .from("service_request_to_engagement")
        .select("*, service_engagement(*)")
        .eq("plan_entry_id", service_request_id)
        .limit(1)
        .maybeSingle();

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      return data?.service_engagement;
    },
  });
}

export function useServiceEngagementById(
  serviceEngagementId: string | undefined | null
) {
  return useQuery({
    queryKey: [QUERY_KEYS.serviceEngagements, { serviceEngagementId }],
    queryFn: async () => {
      if (!serviceEngagementId) return null;
      const { data, error } = await supabase
        .from(TABLE)
        .select("*, service_resource(*)")
        .eq("id", serviceEngagementId)
        .limit(1)
        .maybeSingle();
      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      return data as ServiceEngagementWithServiceResource;
    },
  });
}

export function useServiceEngagements() {
  const activeOrgId = useActiveOrganizationId();
  const { networks } = useNetworksInOrganization();
  const networkIds = networks?.map((network) => network.id) ?? [];
  return useQuery({
    queryKey: [QUERY_KEYS.serviceEngagements, { activeOrgId, networkIds }],
    queryFn: async () => {
      const { data, error } = await supabase
        .from(TABLE)
        .select("*, service_resource(*),  network(*)")
        .in("network_id", networkIds);

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      const filteredData = data.filter(
        (entry) => entry.network?.organization_id === activeOrgId
      );
      return filteredData as ServiceEngagementWithServiceResourceAndNetwork[];
    },
  });
}

export function useCreateServiceEngagement() {
  return useMutation({
    mutationFn: async ({
      serviceEngagement,
      serviceRequestId,
    }: {
      serviceEngagement: ServiceEngagementInsert;
      serviceRequestId: string;
    }) => {
      const { data, error } = await supabase
        .from(TABLE)
        .insert(serviceEngagement)
        .select()
        .limit(1)
        .maybeSingle();

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      await supabase
        .from("service_request_to_engagement")
        .insert({
          plan_entry_id: serviceRequestId,
          service_engagement_id: data!.id,
        })
        .select()
        .limit(1)
        .maybeSingle();

      return data;
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagements],
      });
    },
  });
}

export function useUpdateServiceEngagement() {
  return useMutation({
    mutationFn: async (serviceEngagement: ServiceEngagementUpdate) => {
      if (serviceEngagement.id) {
        const { data, error } = await supabase
          .from(TABLE)
          .update(serviceEngagement)
          .eq("id", serviceEngagement.id);
        if (error) {
          Sentry.captureException(error);
          throw new Error(error.message);
        }
        return data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.chatGptConversation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagements],
      });
    },
  });
}

export function useDeleteServiceEngagement() {
  return useMutation({
    mutationFn: async (serviceEngagementId: string) => {
      const { data, error } = await supabase
        .from(TABLE)
        .delete()
        .eq("id", serviceEngagementId);
      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      return data;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagements],
      });
    },
  });
}

export function useAddConversationToServiceEngagement() {
  const updateServiceEngagement = useUpdateServiceEngagement().mutateAsync;
  const chatGptConversationMutation = useMutateChatGptConversations();
  return useMutation({
    mutationFn: async ({
      serviceEngagementId,
      isExternal,
      service_resource_id,
      networkId,
    }: {
      serviceEngagementId: string | undefined;
      isExternal: boolean;
      service_resource_id: string | undefined;
      networkId: string | undefined;
    }) => {
      if (!serviceEngagementId) return;
      const newConversation = await chatGptConversationMutation.mutateAsync({
        type: ConversationType.Service,
        external_participant_id: isExternal ? service_resource_id : undefined,
        networkId,
      });
      const conversationIdField = isExternal
        ? "external_conversation_id"
        : "conversation_id";
      return await updateServiceEngagement({
        id: serviceEngagementId,
        [conversationIdField]: newConversation?.id,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.serviceEngagements],
      });
    },
  });
}
