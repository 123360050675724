import { Factor } from "@supabase/supabase-js";
import { LoadingSpinner } from "components/LoadingSpinner";
import MFAPhone from "components/MFA/MFAPhone";
import MFATOTP from "components/MFA/MFATOTP";
import useMFA from "hooks/useMFA/useMFA";
import { useEffect, useState } from "react";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { resetAssessmentStore } from "state/assessment";
import { useUserStore } from "state/user";

enum MFAOptions {
  Phone = "Phone",
  TOTP = "TOTP",
}

const AuthenticationSettingsPage = () => {
  const { factorsList, unenrollFactor, error } = useMFA();
  const [MFAOption, setMFAOption] = useState<MFAOptions | undefined>();
  const [TOPTFactor, setTOPTFactor] = useState<Factor | undefined>();
  const [phoneFactor, setPhoneFactor] = useState<Factor | undefined>();
  const [hasTOTPFactor, setHasTOTPFactor] = useState<boolean>(false);
  const [hasPhoneFactor, setHasPhoneFactor] = useState<boolean>(false);
  const [showData, setShowData] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const resetUserStore = useUserStore((state) => state.reset);
  const signOut = useUserStore((state) => state.signOut);
  const [currentFactorToDelete, setCurrentFactorToDelete] = useState<
    Factor | undefined
  >(undefined);

  async function handleLogout() {
    resetUserStore();
    resetAssessmentStore();
    await signOut();
  }

  useEffect(() => {
    const TOTPFactor = factorsList?.find(
      (factor) => factor.factor_type === "totp" && factor.status === "verified"
    );
    setTOPTFactor(TOTPFactor);
    setHasTOTPFactor(!!TOTPFactor);
    const phoneFactor = factorsList?.find(
      (factor) => factor.factor_type === "phone" && factor.status === "verified"
    );
    setPhoneFactor(phoneFactor);
    setHasPhoneFactor(!!phoneFactor);
    setTimeout(() => setShowData(true), 600);
  }, [factorsList]);

  const isAllowedToDeactivateOneFactor = hasPhoneFactor && hasTOTPFactor;

  const handleUnenrollFactor = async (factor: Factor) => {
    const unenrollSuccessfully = await unenrollFactor(factor.id);
    if (unenrollSuccessfully) {
      factor.factor_type === "phone"
        ? setHasPhoneFactor(false)
        : setHasTOTPFactor(false);
    }
    handleLogout();
  };

  return (
    <section>
      <h2 className="text-lg font-medium">
        Manage your authentication settings.
      </h2>
      <p className="my-3 text-sm max-w-xl">
        Multi-factor authentication (MFA) adds an extra layer of security to
        your account. Enable MFA to protect your account from unauthorized
        access.
      </p>
      <p className="text-sm max-w-xl">
        You should at least have one authentication factor enabled to secure
      </p>
      {showData ? (
        <>
          <section className="space-y-6 mt-8">
            <section className="flex flex-col gap-2 justify-center">
              <div className="flex gap-2 items-center">
                <p className="font-medium text-lg">Authenticator App</p>
                <span
                  className={`text-gray-800 text-xs rounded-lg p-2 ${
                    !hasTOTPFactor ? "bg-gray-200" : "bg-green-300"
                  }`}></span>
              </div>

              <div>
                {!hasTOTPFactor && (
                  <button
                    className="rounded-md bg-green-300 font-medium text-sm text-white px-2 py-1"
                    onClick={() => setMFAOption(MFAOptions.TOTP)}>
                    Activate
                  </button>
                )}
                {isAllowedToDeactivateOneFactor && (
                  <button
                    className="rounded-md bg-gray-200 font-medium text-sm text-gray-700 px-2 py-1"
                    onClick={() => {
                      setCurrentFactorToDelete(TOPTFactor);
                      setIsOpen(true);
                    }}>
                    Deactivate
                  </button>
                )}
              </div>
            </section>

            <section className="flex flex-col gap-2 justify-center">
              <div className="flex gap-2 items-center">
                <p className="font-medium text-lg">Text Message</p>
                <span
                  className={`text-gray-800 text-xs rounded-lg p-2 ${
                    !hasPhoneFactor ? "bg-gray-200" : "bg-green-300"
                  }`}></span>
              </div>

              <div>
                {!hasPhoneFactor && (
                  <button
                    className="rounded-md bg-green-300 font-medium text-sm text-white px-2 py-1"
                    onClick={() => setMFAOption(MFAOptions.Phone)}>
                    Activate
                  </button>
                )}
                {isAllowedToDeactivateOneFactor && (
                  <button
                    className="rounded-md bg-gray-200 font-medium text-sm text-gray-700 px-2 py-1"
                    onClick={() => {
                      setCurrentFactorToDelete(phoneFactor);
                      setIsOpen(true);
                    }}>
                    Deactivate
                  </button>
                )}
              </div>
            </section>
          </section>

          <section className="h-full w-full space-y-6 p-6 flex flex-col items-center mt-4">
            {MFAOption && (
              <section className="w-96 md:w-[480px] flex justify-center flex-col items-center border rounded-2xl shadow-sm py-6 bg-white">
                <section className="w-full bg-white">
                  {MFAOption === MFAOptions.TOTP && <MFATOTP />}
                  {MFAOption === MFAOptions.Phone && <MFAPhone />}
                </section>
              </section>
            )}
          </section>
        </>
      ) : (
        <section className="w-full h-40 flex items-center justify-center">
          <LoadingSpinner className="h-10 w-10" />
        </section>
      )}

      <ResponsiveModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={
          "Are you sure you want to deactivate this authentication factor?"
        }>
        <section className="w-full flex flex-col h-full text-center gap-6 md:gap-10 justify-center items-center pb-6 px-10 md:pb-0 md:px-0">
          <p className="font-light">
            Disabling this factor will immediately log you out of your account
            for security purposes. You will need to log in again to regain
            access.
          </p>
          <div className="flex justify-around w-full">
            <button
              className="rounded-md bg-gray-200 font-medium text-sm text-gray-700 px-2 py-1"
              onClick={() => setIsOpen(false)}>
              Cancel
            </button>
            <button
              className="rounded-md bg-red-500 font-medium text-sm text-white px-2 py-1"
              onClick={async () => {
                if (!currentFactorToDelete) return;
                setIsOpen(false);
                await handleUnenrollFactor(currentFactorToDelete);
              }}>
              Confirm
            </button>
          </div>
        </section>
      </ResponsiveModal>
    </section>
  );
};

export default AuthenticationSettingsPage;
