import {
  CarePlanGenerationStatuses,
  useCarePlanGenerationStatus,
} from "backend/resources/carePlanGenerationStatus";
import { useOrgs } from "backend/resources/orgRole";
import { usePastAssessments } from "backend/resources/userAssessment";
import {
  AssessmentComponent,
  AssessmentType,
} from "components/AssessmentComponent/AssessmentComponent";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import GenerateCarePlanSubmissionPopup from "components/CareBinder/components/GenerateCarePlanSubmissionPopup";
import { CarespacePageTabs } from "components/CarespacePage/Tabs/carespaceTypes";
import { Select } from "components/Select";
import {
  AssessmentStatus,
  AssessmentStatusLabels,
  AssessmentsTable,
} from "components/Tables/AssessmentsTable";
import { useState } from "react";
import { useNavigateToCarespaceTab } from "routes/routesUtil";
import { useAssessmentStore } from "state/assessment";

export function AssessmentsTab() {
  const navigateToCarespaceTab = useNavigateToCarespaceTab();

  const [
    isCarePlanConfirmationPopupShowing,
    setIsCarePlanConfirmationPopupShowing,
  ] = useState(false);
  const [newAssessmentType, setNewAssessmentType] = useState<
    AssessmentType | undefined
  >(undefined);

  const [isOpen, setIsOpen] = useState(false);

  const [
    assessmentStatusForComprehensiveAssessments,
    setAssessmentStatusForComprehensiveAssessments,
  ] = useState<AssessmentStatus>(AssessmentStatus.All);

  const [assessmentStatusForScreenings, setAssessmentStatusForScreenings] =
    useState<AssessmentStatus>(AssessmentStatus.All);

  const setActiveUserAssessmentId = useAssessmentStore(
    (state) => state.setActiveUserAssessmentId
  );

  const { data: goalStatus } = useCarePlanGenerationStatus();
  const { data: pastAssessments } = usePastAssessments();
  const { hasCareCentralAccess } = useOrgs();

  const pastComprehensiveAssessments = pastAssessments?.filter(
    (user_assessment) => !user_assessment.assessment?.is_for_screening
  );
  const pastScreeningAssessments = pastAssessments?.filter(
    (user_assessment) => user_assessment.assessment?.is_for_screening
  );
  const assessmentStatusOptions = Object.values(AssessmentStatusLabels).map(
    (status) => ({ value: status, label: status })
  );

  function onCloseNewAssessment() {
    setNewAssessmentType(undefined);
    setActiveUserAssessmentId(pastAssessments?.[0]?.id);
    setIsOpen(false);
    navigateToCarespaceTab(CarespacePageTabs.ASSESSMENTS);
  }

  return (
    <>
      <AssessmentComponent
        isOpen={!!newAssessmentType || isOpen}
        onClose={onCloseNewAssessment}
        newAssessmentType={newAssessmentType}
      />
      <GenerateCarePlanSubmissionPopup
        isOpen={isCarePlanConfirmationPopupShowing}
        onClose={() => {
          setIsCarePlanConfirmationPopupShowing(false);
        }}
      />
      <div className="flex gap-5">
        <p className="text-xl">Assessments</p>
        {hasCareCentralAccess && (
          <>
            <ButtonWithIcon
              onClick={() => {
                setNewAssessmentType(AssessmentType.COMPREHENSIVE);
              }}
              className="whitespace-nowrap"
              text="Take New Assessment"
              icon={IconOption.PLUS}
              disabled={
                goalStatus?.status === CarePlanGenerationStatuses.PENDING
              }
              size={"small"}
            />
            <ButtonWithIcon
              onClick={async () => {
                setIsCarePlanConfirmationPopupShowing(true);
              }}
              className="whitespace-nowrap"
              text="Generate New Coaching Plan"
              disabled={
                goalStatus?.status === CarePlanGenerationStatuses.PENDING
              }
              icon={IconOption.ARROW}
              size={"small"}
            />
          </>
        )}
      </div>

      {goalStatus?.status === CarePlanGenerationStatuses.PENDING && (
        <p className="text-sm text-zinc-400">
          (NOTE: New assessments will be available when the suggested coaching
          plan is ready)
        </p>
      )}

      <div className="flex gap-5 items-center">
        <p>Status</p>
        <Select
          options={assessmentStatusOptions}
          onChange={setAssessmentStatusForComprehensiveAssessments}
          currentOption={assessmentStatusOptions?.find(
            (option) =>
              option.value === assessmentStatusForComprehensiveAssessments
          )}
        />
      </div>

      <AssessmentsTable
        data={pastComprehensiveAssessments ?? []}
        statusFilter={assessmentStatusForComprehensiveAssessments}
      />

      <div className="flex gap-5 items-center mt-5">
        <p className="text-xl">Screenings</p>
        {hasCareCentralAccess && (
          <ButtonWithIcon
            onClick={() => {
              setNewAssessmentType(AssessmentType.SCREENINGS);
            }}
            className="whitespace-nowrap"
            text="Take New Screening"
            icon={IconOption.PLUS}
            disabled={goalStatus?.status === CarePlanGenerationStatuses.PENDING}
            size={"small"}
          />
        )}
      </div>

      <div className="flex gap-5 items-center">
        <p>Status</p>
        <Select
          options={assessmentStatusOptions}
          onChange={setAssessmentStatusForScreenings}
          currentOption={assessmentStatusOptions?.find(
            (option) => option.value === assessmentStatusForScreenings
          )}
        />
      </div>

      <AssessmentsTable
        data={pastScreeningAssessments ?? []}
        statusFilter={assessmentStatusForScreenings}
      />
    </>
  );
}
