import CheckedIcon from "assets/checkbox_icon.svg?react";
import UncheckedIcon from "assets/checkbox_icon_unchecked.svg?react";
import { Checkbox, CheckboxProps } from "components/Checkbox";

export interface DefaultCheckboxProps
  extends Omit<CheckboxProps, "icon" | "uncheckedIcon"> {}

import { forwardRef } from "react";

export const DefaultCheckbox = forwardRef<HTMLDivElement, DefaultCheckboxProps>(
  (props, ref) => {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}>
        <Checkbox
          ref={ref}
          icon={<CheckedIcon />}
          uncheckedIcon={<UncheckedIcon />}
          {...props}
        />
      </div>
    );
  }
);
