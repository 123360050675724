export const hasPCGLabel =
  "Does patient have a caregiver, defined as a relative, or an unpaid nonrelative, who assists the patient with activities of daily living and/or instrumental activities of daily living?";

export const clinicianSectionText =
  "In my clinical judgment, the assessed patient meets the National Institute on Aging Alzheimer's Association diagnostic guidelines for dementia and/or the DSM-5 diagnostic guidelines for major neurocognitive disorder, or | have received a written report (electronic or hard-copy) of a documented dementia diagnosis from another Medicare qualified health professional.";

export const beneficiaryDisclaimerText =
  "(Participant confirms the beneficiary resided within your Zip code service area)";

export const caregiverYearsInRoleText =
  "How long has the primary caregiver been in their caregiver role?";
