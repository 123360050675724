import { useAlfredPageSideBar } from "backend/resources/chatGptConversation";
import { useNetwork } from "backend/resources/network/network";
import BackButton from "components/BackButton/BackButton";
import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { EditCarespaceView } from "components/CarespacePage/EditCarespaceView";
import { CarespaceTabContent } from "components/CarespacePage/Tabs/CarespaceTabContent";
import { CarespacePageTabs } from "components/CarespacePage/Tabs/carespaceTypes";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import Tabs from "components/Tabs/Tabs";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNetworkStore } from "state/network/network";

export function CarespacePage() {
  const { carespaceId } = useParams();

  const [activeTab, setActiveTab] = useState<CarespacePageTabs>(
    CarespacePageTabs.CARE_PLAN
  );
  const [isEditCarespaceOpen, setIsEditCarespaceOpen] = useState(false);

  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );

  useAlfredPageSideBar("homePage");
  const { data, isLoading } = useNetwork(carespaceId);

  useEffect(() => {
    setActiveNetworkId(carespaceId);
  }, [carespaceId, setActiveNetworkId]);

  if (isLoading) {
    return (
      <PageContainer>
        <BackButton className="mb-2" />
        <div className="flex justify-center pt-2">
          <LoadingSpinner className="w-6 h-6" />
        </div>
      </PageContainer>
    );
  }

  if (!data) {
    return (
      <PageContainer>
        <BackButton className="mb-2" />
        <div className="flex justify-center pt-2">
          <p>No data</p>
        </div>
      </PageContainer>
    );
  }

  return (
    <>
      {isEditCarespaceOpen ? (
        <EditCarespaceView onClose={() => setIsEditCarespaceOpen(false)} />
      ) : null}
      <PageContainer>
        <div className="flex flex-col">
          <BackButton className="mb-2" />
          <PageMainHeader text={data?.name} />
          <div className="flex gap-2 items-center mt-3">
            <Tabs
              tabs={Object.values(CarespacePageTabs)}
              currentTab={activeTab}
              setCurrentTab={setActiveTab}
            />
            {!isEditCarespaceOpen &&
            activeTab === CarespacePageTabs.CARE_PLAN ? (
              <ButtonWithIcon
                size={"small"}
                onClick={() => setIsEditCarespaceOpen(true)}
                icon={IconOption.EDIT}
                text="Edit"
              />
            ) : null}
          </div>
          <CarespaceTabContent
            tabType={activeTab}
            network={data ?? null}
          />
        </div>
      </PageContainer>
      <BottomActionBar
        centerSlot={
          <ButtonWithIcon
            onClick={() => setIsEditCarespaceOpen(true)}
            text={""}
            icon={IconOption.EDIT}
          />
        }
      />
    </>
  );
}
