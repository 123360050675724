import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { fetchUserRole } from "backend/resources/userRole";
import { Auth } from "components/Auth";
import LaunchDarklyProvider from "components/LaunchDarklyProvider/LaunchDarklyProvider";
import OneSignalComponent from "components/OneSignalComponent/OneSignalComponent";
import ProductFruitsWrapper from "components/ProductFruitsWrapper/ProductFruitsWrapper";
import "react-loading-skeleton/dist/skeleton.css";
import { Routes } from "react-router-dom";
import type { RoutLayoutObject } from "routes";
import { renderRoute } from "routes";
import { careCentralRoutes } from "routes/care-central-routes";
import { carePilotRoutes } from "routes/carePilotRoutes";
import { sharedRoutes } from "routes/sharedRoutes";
import { resetAssessmentStore } from "state/assessment";
import { getActiveNetworkIdDirectly } from "state/network/network";
import {
  getUserIdDirectly,
  resetUserStoreDirectly,
  signOutDirectly,
} from "state/user";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
    mutations: {
      onError: async (error) => {
        if (
          error.message.includes("new row violates row-level security policy")
        ) {
          const userRole = await fetchUserRole(
            getUserIdDirectly(),
            getActiveNetworkIdDirectly()
          );
          if (userRole?.is_deactivated) {
            await signOutDirectly();
            resetAssessmentStore();
            resetUserStoreDirectly();
            window.location.href = `${window.origin}/deleted`;
          }
        }
      },
    },
  },
});

/**
 * Renders the entry point for the app.
 */
export function App() {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <QueryClientProvider client={queryClient}>
        <Auth>
          <LaunchDarklyProvider>
            <OneSignalComponent />
            <ProductFruitsWrapper />
            <Routes>
              {[...sharedRoutes, ...carePilotRoutes, ...careCentralRoutes].map(
                (route: RoutLayoutObject) => renderRoute(route)
              )}
            </Routes>
          </LaunchDarklyProvider>
        </Auth>
      </QueryClientProvider>
    </LocalizationProvider>
  );
}
