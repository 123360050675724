import { useGuideTask } from "backend/resources/guide/guideTask";
import type { TaskWithGuideInfo } from "backend/resources/planEntry";
import {
  usePlanEntries,
  usePlanEntryWithGuideTask,
  useServiceRequests,
  useUpdatePlanEntry,
} from "backend/resources/planEntry";
import { useServiceEngagementInCarespaceGuideTask } from "backend/resources/services/serviceEngagementToGuideTask";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { MinimizableButton } from "components/MinimizableButton/MinimizableButton";
import ServicesTable, { ServiceFields } from "components/Tables/ServicesTable";
import { TaskTable } from "components/Tables/TaskTable/TaskTable";
import { TaskTableFields } from "components/Tables/TaskTable/TaskTableTypes";
import { GuideCategories } from "components/TaskNavigatorPage/taskTableUtils";
import {
  CareCentralRoute,
  CarePilotRoute,
  SharedRoute,
  useAppNavigate,
} from "lib/routing";

export default function TaskManagement({ id }: { id: string | undefined }) {
  const { data: entryData, isLoading: isLoadingEntryData } =
    usePlanEntryWithGuideTask(id);
  const navigate = useAppNavigate();

  const { data: guideTaskData, isLoading: isLoadingGuideTaskData } =
    useGuideTask(entryData?.guide_task_id);
  const { data: serviceEngagementData } =
    useServiceEngagementInCarespaceGuideTask({
      guide_task_id: guideTaskData?.id,
      network_id: entryData?.network_id,
    });
  const { data: serviceRequests } = useServiceRequests();
  const { planEntries } = usePlanEntries({ parentPlanEntryId: entryData?.id });
  const assessmentAssignments = planEntries?.filter(
    (planEntry) => planEntry.assessment_id
  );
  const todos = planEntries
    ?.filter((planEntry) => !planEntry.assessment_id)
    .sort(
      (a, b) =>
        a.scheduled_date_time?.localeCompare(b.scheduled_date_time ?? "") ?? 0
    );

  const taskRequirements = guideTaskData?.guide_pre_requisite ?? [];
  const categoryRequirements =
    guideTaskData?.guide_category?.guide_pre_requisite ?? [];
  const guideRequirements = entryData?.mah_guide_task?.text ?? [];

  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;

  if (isLoadingGuideTaskData || isLoadingEntryData)
    return (
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <div className="h-4 w-4 animate-spin rounded-full border-t-2 border-b-2 border-gray-900"></div>
          <p className="text-sm text-zinc-400">Loading Guide Task Data...</p>
        </div>
      </div>
    );

  return (
    <div className="flex flex-col gap-6">
      <MinimizableButton
        title="Subtask(s)"
        titleButton={
          <ButtonWithIcon
            onClick={() =>
              navigate({
                path: CareCentralRoute.NEW_TASK,
                queryParams: {
                  parent_plan_entry_id: entryData?.id ?? null,
                },
              })
            }
            className="text-sm"
            text="Add Subtask"
            size="extra_small"
            icon={IconOption.PLUS}
          />
        }>
        {(todos ?? []).length > 0 ? (
          <TaskTable
            handleClick={(_, row) =>
              navigate({
                path: CarePilotRoute.PLAN_ID,
                params: {
                  id: row.original[TaskTableFields.Id],
                },
              })
            }
            hiddenColumns={[
              TaskTableFields.Carespace,
              TaskTableFields.Category,
              TaskTableFields.ServiceStatus,
              TaskTableFields.CreatedAt,
            ]}
            data={todos as unknown as TaskWithGuideInfo[]}
            updateTask={updatePlanEntry}
          />
        ) : (
          <p className="text-sm text-zinc-400">No Task(s) added yet</p>
        )}
      </MinimizableButton>

      <MinimizableButton
        title="Requirements"
        children={
          <>
            <div
              dangerouslySetInnerHTML={{ __html: guideRequirements.toString() }}
            />

            {guideTaskData && (
              <ul className="list-disc ml-4 flex flex-col gap-2">
                {taskRequirements.map((preReq) => (
                  <li
                    className="text-sm"
                    key={preReq.id}>
                    {preReq.text}
                  </li>
                ))}
                {categoryRequirements.length > 0 &&
                  taskRequirements.length > 0 && (
                    <>
                      <hr className="y-2 border-b w-full" />
                      <p className="text-sm text-center">
                        {" "}
                        Category Requirements
                      </p>
                    </>
                  )}
                {categoryRequirements.map((preReq) => (
                  <li
                    className="text-sm"
                    key={preReq.id}>
                    {preReq.text}
                  </li>
                ))}

                {categoryRequirements.length === 0 &&
                  taskRequirements.length === 0 &&
                  !guideTaskData && <li className="text-sm">N/A</li>}
                {guideTaskData?.GUIDE_identifier && (
                  <li className="text-sm">{`${
                    guideTaskData?.GUIDE_identifier
                      ? ` * GUIDE Ref# ${guideTaskData?.GUIDE_identifier}: `
                      : ""
                  } ${guideTaskData?.requirement ?? "N/A"}`}</li>
                )}
              </ul>
            )}
          </>
        }
      />

      {guideTaskData?.guide_category?.title ===
        GuideCategories.ComprehensiveAssessment && (
        <MinimizableButton
          title="Assessment(s)"
          titleButton={
            <ButtonWithIcon
              className="text-sm"
              onClick={() =>
                navigate({
                  path: CareCentralRoute.NEW_TASK,
                  queryParams: {
                    is_assessment_assignment: "true",
                    parent_plan_entry_id: entryData?.id ?? null,
                  },
                })
              }
              text="Add Assessment"
              size="extra_small"
              icon={IconOption.PLUS}
            />
          }>
          {(assessmentAssignments ?? []).length > 0 ? (
            <TaskTable
              handleClick={(_, row) =>
                navigate({
                  path: CarePilotRoute.PLAN_ID,
                  params: {
                    id: row.original[TaskTableFields.Id],
                  },
                })
              }
              hiddenColumns={[
                TaskTableFields.Carespace,
                TaskTableFields.Category,
                TaskTableFields.ServiceStatus,
                TaskTableFields.CreatedAt,
              ]}
              data={assessmentAssignments as unknown as TaskWithGuideInfo[]}
              updateTask={updatePlanEntry}
            />
          ) : (
            <p className="text-sm text-zinc-400">No Assessment(s) added yet</p>
          )}
        </MinimizableButton>
      )}

      <MinimizableButton
        title="Service Request(s)"
        titleButton={
          <ButtonWithIcon
            onClick={() =>
              navigate({
                path: SharedRoute.SERVICE_REQUEST_NEW,
                queryParams: {
                  network_id: entryData?.network_id ?? "",
                  guide_task_id: entryData?.guide_task_id ?? "",
                },
              })
            }
            className="text-sm"
            text="Add SR"
            size="extra_small"
            icon={IconOption.PLUS}
          />
        }>
        {(serviceRequests ?? []).length > 0 ? (
          <TaskTable
            filterConfig={{
              customAdditionalFilter: (row) =>
                row.network_id === entryData?.network_id &&
                row.guide_task_id === entryData.guide_task_id &&
                row.is_service_ticket,
            }}
            hideCheckbox
            handleClick={(_, row) =>
              navigate({
                path: SharedRoute.SERVICE_REQUEST_VIEW,
                params: {
                  id: row.original[TaskTableFields.Id],
                },
              })
            }
            hiddenColumns={[
              TaskTableFields.Carespace,
              TaskTableFields.CategoryFullTitle,
              TaskTableFields.Category,
              TaskTableFields.ServiceStatus,
            ]}
            data={serviceRequests}
            updateTask={updatePlanEntry}
          />
        ) : (
          <p className="text-sm text-zinc-400">
            No Service Request(s) added yet
          </p>
        )}
      </MinimizableButton>

      <MinimizableButton title="Active Service(s)">
        {" "}
        {(serviceEngagementData ?? []).length > 0 ? (
          <ServicesTable
            data={serviceEngagementData ?? []}
            filterConfig={{}}
            hiddenColumns={[
              ServiceFields.Carespace,
              ServiceFields.Status,
              ServiceFields.ServiceType,
            ]}
          />
        ) : (
          <p className="text-sm text-zinc-400">
            No Active Service(s) added yet
          </p>
        )}
      </MinimizableButton>
    </div>
  );
}
