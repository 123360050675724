import { Cell, flexRender, Row } from "@tanstack/react-table";
import { TaskStatusColor, TaskStatusLabel } from "backend/resources/planEntry";
import Who, { UserType } from "components/MyPlanPage/components/Who";
import { TaskTableFields } from "../TaskTable/TaskTableTypes";
import {
  TasksByCarespaceTableFields,
  TasksByCarespaceTableSubRowFields,
  TasksByCarespaceTableSubRowType,
  TasksByCarespaceTableType,
} from "./TaskTableTypes";

export default function TasksByCarespaceTableCellContent({
  cell,
  row,
}: {
  cell: Cell<TasksByCarespaceTableType, unknown>;
  row: Row<TasksByCarespaceTableType>;
}): JSX.Element {
  switch (cell.column.id) {
    case TasksByCarespaceTableFields.Type:
      const needItem = cell.row.original[TasksByCarespaceTableFields.Type];
      return <div className="w-[300px] max-w-[300px]">{needItem}</div>;
    case TaskTableFields.Status:
      return (
        <div className="flex gap-2 items-center justify-center w-[150px] max-w-[150px]">
          <p
            style={{
              background: TaskStatusColor[cell.row.original.Status],
            }}
            className={`rounded-full text-xs py-1 text-white text-center whitespace-nowrap px-4 w-[100px]  w-min-[100px] w-max-[100px]`}>
            {TaskStatusLabel[cell.row.original.Status]}
          </p>
          {/* {(rawData.plan_entry_attendee?.length || rawData.google_meeting_code) && <MeetingSVG className="w-4 h-4" />} */}
        </div>
      );
    case TasksByCarespaceTableFields.CARESPACE_NAME:
      const taskName =
        cell.row.original[TasksByCarespaceTableFields.CARESPACE_NAME];
      return (
        <p
          className={`rounded-full text-left w-[160px] min-w-[160px] max-w-[160px] md:w-[300px] md:w-min-[300px] md:max-w-[300px]`}>
          <div className="flex items-center">
            <span className="truncate mr-1">{taskName}</span>
          </div>
        </p>
      );

    case TasksByCarespaceTableFields.GUIDE_Percentage_Complete:
      return (
        <div className="text-center w-[100px]">
          {cell.row.original[cell.column.id]}%
        </div>
      );
    default:
      return (
        <div className="text-center w-[100px]">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </div>
      );
  }
}

export function TaskTableCellContentSubrow({
  cell,
  row,
}: {
  cell: Cell<TasksByCarespaceTableSubRowType, unknown>;
  row: Row<TasksByCarespaceTableSubRowType>;
}): JSX.Element {
  switch (cell.column.id) {
    case TasksByCarespaceTableSubRowFields.Type:
      const needItem =
        cell.row.original[TasksByCarespaceTableSubRowFields.Type];
      return <div className="w-[300px] max-w-[300px]">{needItem}</div>;
    case TasksByCarespaceTableSubRowFields.Status:
      return (
        <div className="flex gap-2 items-center justify-center w-[150px] max-w-[150px]">
          <p
            style={{
              background: TaskStatusColor[cell.row.original.Status],
            }}
            className={`rounded-full text-xs py-1 text-white text-center whitespace-nowrap px-4 w-[100px]  w-min-[100px] w-max-[100px]`}>
            {TaskStatusLabel[cell.row.original.Status]}
          </p>
          {/* {(rawData.plan_entry_attendee?.length || rawData.google_meeting_code) && <MeetingSVG className="w-4 h-4" />} */}
        </div>
      );
    case TasksByCarespaceTableSubRowFields.TASK_NAME:
      const taskName =
        cell.row.original[TasksByCarespaceTableSubRowFields.TASK_NAME];
      return (
        <p
          className={`rounded-full text-left w-[160px] min-w-[160px] max-w-[160px] md:w-[300px] md:w-min-[300px] md:max-w-[300px]`}>
          <div className="flex items-center">
            <span className="truncate mr-1">{taskName}</span>
          </div>
        </p>
      );

    case TasksByCarespaceTableSubRowFields.Who:
      return cell.row.original.Who ? (
        <div className="flex w-[100px]">
          <Who
            userType={UserType.ORG_USERS}
            entry={null}
            selectedPlanEntryOwnerId={cell.row.original.Who}
            setPlanEntryOwner={() => {}}
          />
        </div>
      ) : (
        <div></div>
      );

    case TasksByCarespaceTableSubRowFields.Due_Date:
      return (
        <div className="text-center w-[100px]">
          {cell.row.original[cell.column.id]}
        </div>
      );
    default:
      return (
        <div className="text-center w-[100px]">
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </div>
      );
  }
}
