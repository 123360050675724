import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";

export function CloseButton() {
  return (
    <ButtonWithIcon
      icon={IconOption.REMOVE}
      onClick={() => {}}
      text=""
      size="small"
    />
  );
}
