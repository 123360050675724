import {
  NotificationChannel,
  NotificationPreference,
  NotificationSection,
  useNotificationPreferences,
  useUpsertNotificationPreferenceMutation,
} from "backend/resources/notificationPreferences/notificationPreferences";
import { useActiveUserRole } from "backend/resources/userRole";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { DefaultCheckbox } from "components/Checkbox";
import SMSNotificationsApprovalModal from "components/SMSNotificationsApprovalModal/SMSNotificationsApprovalModal";
import React, { useState } from "react";
import OneSignal from "react-onesignal";
import { useUserStore } from "state/user";
import PushSettingPopup from "./Popups/PushSettingPopup";

export default function SettingsPage() {
  const authUser = useUserStore((state) => state.user);
  const [showSmsPopup, setShowSmsPopup] = useState(false);
  const [showPushPopup, setShowPushPopup] = useState(false);

  // Queries/Mutations
  const { notificationPreferences } = useNotificationPreferences();
  const { userRole } = useActiveUserRole();
  const upsertUserPreference =
    useUpsertNotificationPreferenceMutation().mutateAsync;

  // Helpers
  async function handleCheckboxChange(
    section: NotificationSection,
    type: NotificationChannel
  ) {
    if (!authUser) return;
    const currentPreference = notificationPreferences?.find(
      (pref) => pref.section_name === section
    );
    const currentPreferenceValue = currentPreference?.[type];
    if (
      type === NotificationChannel.SMS &&
      (!authUser.has_approved_sms_notifs || !authUser.cell_number)
    ) {
      setShowSmsPopup(true);
    } else if (
      type === NotificationChannel.PUSH &&
      !OneSignal.User.PushSubscription.optedIn &&
      !currentPreferenceValue
    ) {
      setShowPushPopup(true);
      OneSignal.User.PushSubscription.optIn();
    } else {
      // Update the preference
      await upsertUserPreference({
        section_name: section,
        [type]: !currentPreferenceValue,
        user_id: authUser?.id,
      });
    }
  }

  function getSectionDisplayName(section: NotificationSection): string {
    switch (section) {
      case "My Plan":
        return "Tasks";
      default:
        return section;
    }
  }

  return (
    <div>
      {showSmsPopup && (
        <SMSNotificationsApprovalModal onClose={() => setShowSmsPopup(false)} />
      )}
      {showPushPopup && (
        <PushSettingPopup onClose={() => setShowPushPopup(false)} />
      )}
      <div className="flex flex-col gap-5 sticky  -top-[88px] z-[2]">
        <p className="text-xl mt-4">Notifications</p>
        <div className="grid grid-cols-[5fr,1fr,1fr,1fr]  text-base  text-center items-center mb-3 w-full gap-4">
          <p></p> <p>SMS</p> <p>Email</p> <p>Push</p>
          {getNotificationSections(userRole?.role as NetworkRoleType).map(
            (section) => {
              const currentPreference = notificationPreferences?.find(
                (pref: NotificationPreference) => pref.section_name === section
              );
              return (
                <React.Fragment key={section}>
                  <p className="text-left">{getSectionDisplayName(section)}</p>
                  {Object.values(NotificationChannel).map((type) => (
                    <div
                      className="w-full flex justify-center"
                      key={type}>
                      <DefaultCheckbox
                        checked={currentPreference?.[type] ?? false}
                        onChange={() => handleCheckboxChange(section, type)}
                      />
                    </div>
                  ))}
                </React.Fragment>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}

function getNotificationSections(role: NetworkRoleType): NotificationSection[] {
  if (role === NetworkRoleType.FAMILY_MEMBER) {
    return [NotificationSection.GENERAL, NotificationSection.FAMILY_HUB];
  }
  return Object.values(NotificationSection);
}
