import { clinicianSectionText } from "components/CarespacePage/PAFSubmission/constants";
import { ClinicianAttestation } from "components/CarespacePage/PAFSubmission/pafEnums";
import type { PAFSchema } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { getOptionsFromEnum } from "components/CarespacePage/PAFSubmission/pafFormUtil";
import { RadioButtonsGroup } from "components/RadioGroup";
import type { FC } from "react";
import type { FieldValues, UseFormReturn } from "react-hook-form";
import { FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import type { z } from "zod";

interface ClinicianInputsProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
}

export const ClinicianInputs: FC<
  ClinicianInputsProps<z.infer<typeof PAFSchema>>
> = ({ form, isReadOnly }) => (
  <div className="flex flex-col gap-4">
    <div>{clinicianSectionText}</div>

    <FormField
      control={form.control}
      disabled={isReadOnly}
      label="Clinician Attestation"
      name="clinician_attestation"
      render={({ field }) => (
        <RadioButtonsGroup
          row={false}
          id="clinician_attestation"
          options={getOptionsFromEnum(ClinicianAttestation)}
          {...field}
        />
      )}
    />

    <FormField
      control={form.control}
      disabled={isReadOnly}
      label="First Name"
      name="clinician_first_name"
      render={({ field }) => (
        <Input
          className="max-w-[300px]"
          {...field}
        />
      )}
    />

    <FormField
      control={form.control}
      disabled={isReadOnly}
      label="Middle Name"
      name="clinician_middle_name"
      render={({ field }) => (
        <Input
          className="max-w-[300px]"
          {...field}
        />
      )}
    />

    <FormField
      control={form.control}
      disabled={isReadOnly}
      label="Last Name"
      name="clinician_last_name"
      render={({ field }) => (
        <Input
          className="max-w-[300px]"
          {...field}
        />
      )}
    />

    <FormField
      control={form.control}
      disabled={isReadOnly}
      label="National Provider Identification Number"
      name="npi"
      render={({ field }) => (
        <Input
          className="max-w-[300px]"
          {...field}
        />
      )}
    />
  </div>
);
