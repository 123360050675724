import { useLaunchDarklyInfoQuery } from "backend/functions";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { ReactNode } from "react";
import { useUserStore } from "state/user";

import { create } from "zustand";
import { persist } from "zustand/middleware";

type State = {
  launchDarklyIds: Record<string, string>;
  setLaunchDarklyId: (userId: string, encryptedUserId: string) => void;
};
// TODO: remove this as a persistent store, its a HIPAA violation
// Instead we  should store the pseudonymized user id in the database in another table
// See https://app.shortcut.com/craniometrix/story/3092/frontend-backend-stop-persisting-launch-darkly-provider
export const useLaunchDarklyIdStore = create<State>()(
  persist(
    (set) => ({
      launchDarklyIds: {},
      setLaunchDarklyId: (userId, encryptedUserId) =>
        set((state) => ({
          launchDarklyIds: {
            ...state.launchDarklyIds,
            [userId]: encryptedUserId,
          },
        })),
    }),
    {
      name: "launchDarklyIds",
      getStorage: () => localStorage,
    }
  )
);

export default function LaunchDarklyProvider({
  children,
}: {
  children: ReactNode;
}) {
  const authUser = useUserStore((state) => state.user);
  const { data } = useLaunchDarklyInfoQuery();

  const attributes = data?.attributes;
  const LDProviderWithUser = withLDProvider({
    clientSideID: import.meta.env.VITE_LAUNCH_DARKLY_KEY,
    reactOptions: {
      useCamelCaseFlagKeys: false,
      sendEventsOnFlagRead: true,
    },
    user: {
      key: data?.encrypted_user_id,
      custom: attributes,
    },
  })(() => <>{children}</>);

  // If there is no authUser, don't initialize LD at all
  if (!authUser) {
    return <>{children}</>;
  }

  return <LDProviderWithUser />;
}
