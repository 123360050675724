import { useState } from "react";
import { useAlfredPageSideBar } from "backend/resources/chatGptConversation";
import CardLayoutSelector, {
  CardLayoutTypes,
} from "components/CardLayoutSelector/CardLayoutSelector";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { Bookmarks } from "components/LibraryPage/Bookmarks";
import { LibraryFolders } from "components/LibraryPage/LibraryFolders";

export function LibraryPage() {
  useAlfredPageSideBar("myLibraryPage");

  const [activeLayout, setActiveLayout] = useState<CardLayoutTypes>(
    CardLayoutTypes.DEFAULT
  );

  return (
    <>
      <div className="mt-4 md:mt-0 flex w-full justify-center md:justify-start md:pt-8 md:pl-10 whitespace-nowrap relative">
        <PageMainHeader
          text="Favorites"
          className="md:pb-4"
        />
        <div className="absolute right-4 top-2 w-full flex flex-grow justify-end ">
          <CardLayoutSelector
            activeLayout={activeLayout}
            setActiveLayout={setActiveLayout}
          />
        </div>
      </div>
      <div className="sticky -top-10 pb-4 z-[1] bg-white border-b  md:pl-10 flex w-full  ">
        <LibraryFolders />
      </div>
      <PageContainer>
        <div className="pb-[300px] ">
          <Bookmarks activeLayout={activeLayout} />
        </div>
      </PageContainer>
    </>
  );
}
