import { zodResolver } from "@hookform/resolvers/zod";
import type { YellowPagesListing } from "backend/functions";
import { useGuideTasks } from "backend/resources/guide/guideTask";
import { useNetworksInOrganization } from "backend/resources/network/network";
import {
  TaskStatus,
  TaskStatusLabel,
  usePlanEntryData,
  useUpdatePlanEntry,
} from "backend/resources/planEntry";
import {
  useServiceResources,
  useServiceResourcesFromPlanEntry,
  useUpsertServiceResourceAndPlanEntry,
} from "backend/resources/services/serviceResource";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import Who, { UserType } from "components/MyPlanPage/components/Who";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { LocalServicesSearch } from "components/ResourcesPage/components/LocalServicesSearch";
import { Select } from "components/Select";
import { DocumentSchema } from "components/ServicePage/components/DocumentsSection";
import { TextArea } from "components/TextArea";
import { SharedRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams, useLocation } from "react-router-dom";
import { YellowPagesListingSchema } from "shared/forms/types";
import { Form, FormField, FormLabel, FormMessage } from "shared/ui/form";
import { Input } from "shared/ui/input";
import { useActiveOrganizationId } from "state/organization/organization";
import { useUserStore } from "state/user";
import * as z from "zod";

import { outlinedInputClasses } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useUpsertPlanEntryToServiceEngagement } from "backend/resources/services/serviceEngagementToPlanEntry";
import BackButton from "components/BackButton/BackButton";
import { DefaultCheckbox } from "components/Checkbox";
import { LocalResourceCard } from "components/ResourcesPage/components/LocalResourceCard";
import { GUIDE_IDENTIFIER_FOR_RESPITE_SERVICE } from "components/ServicePage/ServicePage";
import dayjs from "dayjs";
import { ResponsiveModal } from "shared/ui/responsive-modal";

const CREATE_SERVICE_REQUEST_FORM_ID = "create-service-request-form";

export const ServiceRequestSchema = z.object({
  name: z.string(),
  description: z.string().optional().nullable(),
  network_id: z.string(),
  scheduled_date_time: z.string().refine((value) => !isNaN(Date.parse(value)), {
    message: "Invalid date string",
  }),
  status: z.string(),
  user_id: z.string(),
  guide_task_id: z.string().optional().nullable(),
  service: YellowPagesListingSchema,
  documents: z.array(DocumentSchema).optional().nullable(),
});

type FormData = z.infer<typeof ServiceRequestSchema>;

type FormDataOptional = {
  [K in keyof FormData]?: FormData[K] | null;
};

const useCreateServiceRequestForm = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  return useForm({
    resolver: zodResolver(ServiceRequestSchema),
    mode: "onSubmit",
    defaultValues: {
      name: queryParams.get("name") ?? undefined,
      description: queryParams.get("description") ?? undefined,
      network_id: queryParams.get("network_id") ?? undefined,
      scheduled_date_time:
        queryParams.get("scheduled_date_time") ??
        new Date(Date.now()).toISOString(),
      status: queryParams.get("status") ?? TaskStatus.NotStarted,
      user_id: queryParams.get("user_id") ?? undefined,
      guide_task_id: queryParams.get("guide_task_id") ?? undefined,
      service: queryParams.get("service") ?? undefined,
    } as FormDataOptional,
  });
};

export default function CreateOrEditServiceRequestPage() {
  const navigateBack = useNavigateBack();
  const navigate = useAppNavigate();
  const inputRef = useRef<HTMLInputElement>(null);
  const authUser = useUserStore((state) => state.user);
  const { id: service_request_id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceEngagementId = queryParams.get("service_engagement_id");

  const { networks } = useNetworksInOrganization();
  const { data: guideTasks } = useGuideTasks();
  const { data: planEntry } = usePlanEntryData(service_request_id);
  const { data: serviceResource } =
    useServiceResourcesFromPlanEntry(service_request_id);
  const activeOrgId = useActiveOrganizationId();

  const form = useCreateServiceRequestForm();
  const formValues = form.watch();
  const [originalResource, setOriginalResource] = useState<
    YellowPagesListing | null | undefined
  >(null);

  useEffect(() => {
    form.reset({
      ...planEntry,
      service: serviceResource ?? undefined,
    });
    setOriginalResource(serviceResource);
  }, [form, planEntry, serviceResource]);

  const createNewServiceRequest = useUpdatePlanEntry().mutateAsync;
  const upsertServiceResource =
    useUpsertServiceResourceAndPlanEntry().mutateAsync;
  const upsertPlanEntryToServiceEngagement =
    useUpsertPlanEntryToServiceEngagement().mutateAsync;

  const carespaceOptions =
    networks?.map((network) => ({
      value: network.id,
      label: network.name ?? "",
    })) ?? [];

  const statusOptions = Object.values(TaskStatus).map((status) => ({
    value: status,
    label: TaskStatusLabel[status],
  }));

  const guideTaskOptions =
    guideTasks?.map((guideTask) => ({
      value: guideTask.id,
      label: guideTask.title,
    })) ?? [];

  const respiteGuideTask = guideTasks?.find(
    (task) => task.GUIDE_identifier === GUIDE_IDENTIFIER_FOR_RESPITE_SERVICE
  );
  const isRespiteChecked = formValues.guide_task_id === respiteGuideTask?.id;

  const onSubmit = async (data: FormData) => {
    const { service, ...serviceRequestData } = data;
    const newPlanEntry = await createNewServiceRequest({
      id: service_request_id,
      ...serviceRequestData,
      scheduled_date_time: data.scheduled_date_time,
      is_service_ticket: true,
    });

    if (!newPlanEntry || !newPlanEntry?.id) {
      return;
    }

    if (service) {
      await upsertServiceResource({
        serviceResourceUpdate: {
          ...service,
          organization_id: activeOrgId,
        },
        plan_entry_id: newPlanEntry.id,
      });
    }

    if (serviceEngagementId) {
      await upsertPlanEntryToServiceEngagement({
        service_engagement_id: serviceEngagementId,
        plan_entry_id: newPlanEntry.id,
      });
    }

    if (service_request_id) {
      navigateBack();
    } else {
      navigate(
        {
          path: SharedRoute.SERVICE_REQUEST_VIEW,
          params: { id: newPlanEntry.id },
        },
        { replace: true }
      );
    }
  };

  return (
    <PageContainer>
      <div className="relative">
        <div className="max-w-4xl flex-grow overflow-auto">
          <BackButton className="mb-6 " />
          <PageMainHeader
            text={
              service_request_id
                ? "Edit Service Request"
                : "Create New Service Request"
            }
          />
          <Form
            form={form}
            id={CREATE_SERVICE_REQUEST_FORM_ID}>
            <FormField
              control={form.control}
              name="name"
              label="Title*"
              render={({ field }) => (
                <Input
                  {...field}
                  value={field.value ?? undefined}
                  disabled={queryParams.has(field.name)}
                />
              )}
            />

            <FormField
              control={form.control}
              name="description"
              label="Details"
              render={({ field }) => (
                <TextArea
                  {...field}
                  value={field.value ?? undefined}
                  className="w-full"
                  disabled={queryParams.has(field.name)}
                />
              )}
            />

            <div className="flex flex-wrap gap-y-4 gap-x-10 xl:w-[1000px]">
              <FormField
                control={form.control}
                name="network_id"
                label="Carespace*"
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={queryParams.has(field.name)}
                    placeHolder="Please Select"
                    onChange={(value) => {
                      if (value) form.setValue("network_id", value);
                    }}
                    options={carespaceOptions}
                    currentOption={carespaceOptions.find(
                      (option) => option.value === field.value
                    )}
                  />
                )}
              />

              <FormField
                control={form.control}
                name="status"
                label="Status"
                render={({ field }) => (
                  <Select
                    {...field}
                    disabled={queryParams.has(field.name)}
                    onChange={(value) => {
                      if (value) form.setValue("status", value);
                    }}
                    options={statusOptions}
                    currentOption={statusOptions.find(
                      (option) => option.value === field.value
                    )}
                  />
                )}
              />

              <FormField
                control={form.control}
                name="user_id"
                label="Assigned To*"
                render={({ field }) => (
                  <Who
                    userType={UserType.ORG_USERS}
                    isEditing={!queryParams.has(field.name)}
                    networkId={formValues.network_id}
                    entry={undefined}
                    selectedPlanEntryOwnerId={field.value ?? undefined}
                    setPlanEntryOwner={(planEntryOwner: string) =>
                      form.setValue("user_id", planEntryOwner)
                    }
                  />
                )}
              />

              <FormField
                control={form.control}
                name="scheduled_date_time"
                label="Date*"
                render={({ field }) => (
                  <DatePicker
                    onChange={(date) => {
                      if (date) field.onChange(date.format());
                    }}
                    value={dayjs(field.value ?? dayjs())}
                    slotProps={{
                      field: {
                        onKeyDown: (e) => {
                          if (e.key === "Enter") {
                            e.preventDefault();
                          }
                        },
                      },
                      textField: {
                        sx: {
                          fieldset: {
                            borderRadius: 2,
                            borderColor: "#E4E4E7",
                          },
                          [`.${outlinedInputClasses.root}`]: {
                            height: 40,
                            width: 165,
                            fontSize: 14,
                            fontWeight: 400,
                            fontFamily: "Poppins",
                          },
                        },
                      },
                    }}
                  />
                )}
              />

              <FormField
                control={form.control}
                name="guide_task_id"
                label="GUIDE Task"
                render={({ field }) => (
                  <Select
                    key={field.value}
                    classNames=" w-[440px]"
                    {...field}
                    disabled={queryParams.has(field.name)}
                    placeHolder="Please Select"
                    onChange={(value) => {
                      if (value) form.setValue("guide_task_id", value);
                    }}
                    options={guideTaskOptions}
                    currentOption={guideTaskOptions.find(
                      (option) => option.value === field.value
                    )}
                  />
                )}
              />

              <FormField
                control={form.control}
                name="guide_task_id"
                label="Respite Request"
                render={() => (
                  <DefaultCheckbox
                    checked={isRespiteChecked}
                    onChange={(isChecked) => {
                      if (isChecked) {
                        form.setValue("guide_task_id", respiteGuideTask?.id);
                      } else {
                        form.setValue("guide_task_id", null);
                      }
                    }}
                  />
                )}
              />
            </div>

            <FormField
              control={form.control}
              name="service"
              label="Add Resource"
              render={({ field }) => (
                <>
                  <div className="flex gap-5">
                    <FormLabel className="text-base">Add Resource</FormLabel>
                    <FormMessage />
                    <OpenLocalResourceModalButton
                      setSelectedResource={(service) => {
                        if (service) {
                          form.setValue("service", service);
                          setOriginalResource(service);
                        }
                      }}
                    />
                  </div>

                  <div className="flex flex-col gap-5">
                    <LocalResourceCard
                      listing={originalResource}
                      selectedLocalResources={field.value ? [field.value] : []}
                      onSelectLocalResource={(service) => {
                        if (service) form.setValue("service", service);
                      }}
                    />
                    <LocalServicesSearch
                      selectedLocalResources={field.value ? [field.value] : []}
                      onSelectLocalResource={(service) => {
                        if (service) {
                          form.setValue("service", service);
                        }
                      }}
                      backgroundColor="bg-gray-100/80"
                    />
                  </div>
                </>
              )}
            />
          </Form>
        </div>
        <div className="bg-white h-20 z-1 sticky -bottom-10 left-0 flex gap-5 justify-end max-w-4xl">
          <ButtonWithIcon
            onClick={navigateBack}
            text={"Cancel"}
            icon={IconOption.CANCEL}
            size="small"
            type="button"
          />
          <ButtonWithIcon
            onClick={form.handleSubmit((data) => {
              const result = ServiceRequestSchema.safeParse(data);
              if (result.success) {
                onSubmit(result.data);
              }
            })}
            text={"Submit"}
            icon={IconOption.CHECKMARK}
            size="small"
          />
        </div>
      </div>
    </PageContainer>
  );
}

interface LocalResourceModalProps {
  setSelectedResource: (resource: YellowPagesListing | null) => void;
}

function OpenLocalResourceModalButton({
  setSelectedResource,
}: LocalResourceModalProps) {
  const { data: localResources, isLoading } = useServiceResources();
  const [localSelectedResource, setLocalSelectedResource] =
    useState<YellowPagesListing | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ButtonWithIcon
        onClick={() => setIsOpen(true)}
        text={"Add from Inventory"}
        icon={IconOption.PLUS}
        size="small"
      />
      <ResponsiveModal
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title="Select a Local Resource">
        <div className="overflow-y-auto max-h-96 flex flex-col gap-5">
          {isLoading ? (
            <div>Loading...</div>
          ) : (
            localResources?.map((resource) => (
              <div
                key={resource.id}
                className="flex items-center gap-2">
                <LocalResourceCard
                  listing={resource}
                  selectedLocalResources={
                    localSelectedResource ? [localSelectedResource] : []
                  }
                  onSelectLocalResource={(resource) =>
                    setLocalSelectedResource(resource)
                  }
                />
              </div>
            ))
          )}
        </div>
        <div className="flex justify-end mt-4 gap-2">
          <ButtonWithIcon
            onClick={() => setIsOpen(false)}
            text={"Cancel"}
            size={"small"}
            icon={IconOption.CANCEL}
          />
          <ButtonWithIcon
            onClick={() => {
              setIsOpen(false);
              setSelectedResource(localSelectedResource);
            }}
            text={"Save"}
            size={"small"}
            icon={IconOption.CHECKMARK}
          />
        </div>
      </ResponsiveModal>
    </>
  );
}
