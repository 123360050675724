import { supabase } from "clients/supabaseClient";

import type { Filter } from "features/query-utils";

import { TABLE_NAME } from "../constants";
import type { TableName } from "../types";

const all = ["carespaces"];
const lists = [...all, "list"];

export const queryKeys = {
  all,
  lists,
  list: (filter: Filter<TableName>) => [...lists, filter],
};

export const select = () =>
  supabase.from(TABLE_NAME).select(
    `
    *,
    primary_care_physician:service_resource(
      name
    ),
    paf_submission(
      status
    ),
    organization(
      id,
      name,
      is_using_cm_care_navigators
    ),
    user_adlo(
      *
    ),
    user_role(
      user(
        *
      ),
      role
    )
  `
  );
