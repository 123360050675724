import type { VariantProps } from "class-variance-authority";
import { navItemStyles } from "components/NavBar";
import { useWindowSize } from "hooks/useWindowSize";
import { Arrow } from "icons/Arrow";
import type { ReactNode } from "react";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSideNavStore } from "state/navItems";
import { NavItemName, SubNavItem } from "state/navItems/navItemModels";

export function CollapsibleNavItem({
  name,
  icon,
  subNavItems,
  isExpanded,
  ...variantProps
}: {
  name: NavItemName;
  icon: ReactNode;
  subNavItems: SubNavItem[];
  isExpanded: boolean;
} & VariantProps<typeof navItemStyles>) {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const collapseItem = useSideNavStore((state) => state.collapseItem);
  const expandItem = useSideNavStore((state) => state.expandItem);
  const isMinimized = useSideNavStore((state) => state.isMinimized);
  const setIsMinimized = useSideNavStore((state) => state.setIsMinimized);
  const { width } = useWindowSize();
  const shouldMinimize = width && width <= 1100;
  const navItemClassName = navItemStyles(variantProps);

  useEffect(() => {
    collapseItem(name);
  }, [shouldMinimize]);

  return (
    <div className={`${navItemClassName} ml-1`}>
      <button
        className="flex items-center gap-2"
        title={name}
        onClick={() => {
          if (isMinimized) {
            setIsMinimized(false);
          }
          if (isExpanded) {
            collapseItem(name);
          } else {
            expandItem(name);
          }
        }}>
        {icon}

        {!isMinimized && <p className="text-left">{name}</p>}
        {!isMinimized && (
          <Arrow
            className={`${
              !isExpanded ? "rotate-180" : "rotate-0"
            } transition-all fill-brand-orange`}
            size={10}
          />
        )}
      </button>
      {isExpanded && (
        <div className="ml-8 mt-4 flex flex-col gap-3">
          {subNavItems.map((subNavItem) => {
            const isSelected = subNavItem.pathname === pathname;
            return (
              <button
                key={subNavItem.name}
                className={`text-left flex gap-2 items-center text-sm ${
                  isSelected ? "font-medium text-brand-orange" : ""
                }`}
                onClick={() => navigate(subNavItem.pathname)}>
                {subNavItem.icon}
                {subNavItem.name}
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
}

export function CollapseSidebarButton() {
  const isMinimized = useSideNavStore((state) => state.isMinimized);
  const setIsMinimized = useSideNavStore((state) => state.setIsMinimized);
  const resetItems = useSideNavStore((state) => state.resetItems);

  return (
    <button
      className={`hover:bg-gray-100 z-[2] flex items-center justify-center w-6 h-6 rounded-full border border-neutral-200 bg-white`}
      onClick={() => {
        resetItems();
        setIsMinimized(!isMinimized);
      }}>
      <Arrow
        className={`w-2 h-2 ${
          isMinimized ? "rotate-90" : "-rotate-90"
        } transition-all fill-brand-orange`}
      />
    </button>
  );
}
