import { useAlfredPageSideBar } from "backend/resources/chatGptConversation";
import { useActiveNetwork } from "backend/resources/network/network";
import { useNavigateBack } from "lib/routing";
import BackButton from "components/BackButton/BackButton";
import { DiscussionType, UserDiscussion } from "components/ChatGptSideBar";
import { PageContainer } from "components/PageContainer";
import { PageMainHeaderWithNetworkName } from "components/PageMainHeader/PageMainHeaderWithNetwork";

export default function GeneralDiscussionPage() {
  const navigateBack = useNavigateBack();

  // Queries
  useAlfredPageSideBar("discussionsPage");
  const { data: activeNetwork } = useActiveNetwork();

  return (
    <PageContainer>
      <BackButton className="mb-2" />
      <PageMainHeaderWithNetworkName text={"General Discussion"} />
      <div className="h-full pb-24 w-full pt-2">
        <UserDiscussion
          discussionType={DiscussionType.Carespace}
          hideBorder
          threadId={activeNetwork?.id}
        />
      </div>
    </PageContainer>
  );
}
