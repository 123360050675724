import * as Sentry from "@sentry/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { QUERY_KEYS } from "backend/query-keys";
import { supabase } from "clients/supabaseClient";
import { GuideAlignmentStatus } from "components/CarespacePage/Tabs/CarespaceMainTab/constants";
import { Database } from "types/supabase";
import { useUpdateNetworkGuideStatus } from "../network/network";

export type PAFSubmission =
  Database["public"]["Tables"]["paf_submission"]["Row"];

export type PAFSubmissionUpdate =
  Database["public"]["Tables"]["paf_submission"]["Update"];

// Mutation function to insert or update PAF submission
export const useUpsertPAFSubmission = () => {
  const updateNetworkGuideStatus = useUpdateNetworkGuideStatus().mutateAsync;

  return useMutation({
    mutationFn: async (pafSubmission: PAFSubmissionUpdate) => {
      if (!pafSubmission.network_id) return null;
      const { data: result, error } = await supabase
        .from("paf_submission")
        .upsert(
          { ...pafSubmission, network_id: pafSubmission.network_id },
          { onConflict: "id" }
        );

      if (error) {
        Sentry.captureException(error);
        throw new Error(error.message);
      }
      await updateNetworkGuideStatus({
        guideStatus: GuideAlignmentStatus.NA,
        networkId: pafSubmission.network_id,
      });
      return result;
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.pafSubmission],
      });
    },
  });
};

export const usePAFSubmissionQuery = (networkId: string | undefined) => {
  return useQuery({
    queryKey: [QUERY_KEYS.pafSubmission, { networkId }],
    queryFn: async () => queryPAFSubmission(networkId),
    enabled: !!networkId, // Only run the query if networkId is truthy
  });
};

const queryPAFSubmission = async (networkId: string | undefined) => {
  if (!networkId) return null;
  const { data: result, error } = await supabase
    .from("paf_submission")
    .select("*")
    .eq("network_id", networkId)
    .order("created_at", { ascending: false })
    .limit(1)
    .maybeSingle();

  if (error) {
    Sentry.captureException(error);
    throw new Error(error.message);
  }
  return result;
};
