import { LoadingSpinner } from "components/LoadingSpinner";
import Logo from "components/Logo/logo";
import { CraniometrixProduct } from "hooks/product/product";
import { ReactNode } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "shared/ui/dialog";
import { ScrollArea } from "shared/ui/scroll-area";

type Props = {
  isOpen: boolean;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
  isNetworkCallInProgress?: boolean;
  closeText?: string;
  title?: ReactNode;
  icon?: ReactNode;
  description?: ReactNode;
  showLogo?: CraniometrixProduct | "base";
  isLogoCentered?: boolean;
  logoSize?: "small" | "large";
  footerButtons?: ReactNode;
  children?: ReactNode;
  fixedHeight?: string;
  customWidth?: string;
  customMaxWidth?: string;
};

export function Popup({
  isOpen,
  onClose,
  isNetworkCallInProgress,
  closeText,
  title,
  icon,
  description,
  showLogo,
  isLogoCentered,
  logoSize = "small",
  footerButtons,
  children,
  fixedHeight,
  customWidth,
  customMaxWidth,
}: Props) {
  return (
    <Dialog
      open={isOpen}
      onOpenChange={onClose}>
      <DialogContent
        className={`${fixedHeight || ""} ${customWidth || ""} ${
          customMaxWidth || ""
        }`}
        noClose={!closeText}
        onInteractOutside={(e) => {
          if (!closeText) {
            e.preventDefault();
          }
        }}
        onEscapeKeyDown={(e) => {
          if (!closeText) {
            e.preventDefault();
          }
        }}>
        {title || icon ? (
          <DialogHeader>
            {icon ? <div className="flex justify-center ">{icon}</div> : null}
            {title ? (
              <DialogTitle>
                {showLogo ? (
                  <div className="pb-4 w-full">
                    <Logo
                      isDisabled
                      product={showLogo}
                      isCentered={isLogoCentered}
                      size={logoSize}
                    />
                  </div>
                ) : null}
                <p className="text-center ">{title}</p>
              </DialogTitle>
            ) : null}
            {description ? (
              <DialogDescription>{description}</DialogDescription>
            ) : null}
          </DialogHeader>
        ) : null}
        <ScrollArea>
          <div className="w-full h-full max-h-[calc(90vh-10rem)] overflow-y-auto p-1">
            {children}
          </div>
        </ScrollArea>
        <DialogFooter>
          {footerButtons}
          {isNetworkCallInProgress ? (
            <div className="h-full flex flex-col justify-center">
              <LoadingSpinner className="w-4 h-4" />
            </div>
          ) : null}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
