/**
 * @fileoverview Sets up the Zustand library folders store.
 * This store holds information about library folders state.
 */
import { create } from "zustand";
import type { LibraryFolder } from "backend/resources/libraryFolder";

type State = {
  activeFolder?: LibraryFolder;
  activeBookmarkId?: string;
};

type Actions = {
  setActiveFolder: (activeFolder?: LibraryFolder) => void;
  setActiveBookmarkId: (activeBookmarkId?: string) => void;
};

const initialState: State = {
  activeFolder: undefined,
  activeBookmarkId: undefined,
};

/**
 * Hook to access the library folders store
 */
export const useLibraryFoldersStore = create<State & Actions>((set) => ({
  ...initialState,

  setActiveFolder: (activeFolder?: LibraryFolder) => set({ activeFolder }),
  setActiveBookmarkId: (activeBookmarkId?: string) => set({ activeBookmarkId }),
}));
