import type { OrganizationRole } from "backend/resources/orgRole";
import {
  useUpdateOrgRole,
  useUserFromOrgRoleId,
} from "backend/resources/orgRole";
import {
  useUpdateUserRole,
  useUserFromUserRoleId,
} from "backend/resources/userRole";
import {
  NETWORK_ROLE_OPTIONS,
  NetworkRoleType,
  ORG_ROLE_OPTIONS,
  OrgRoleType,
} from "backend/resources/userRole/types";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { DefaultCheckbox } from "components/Checkbox";
import FooterButtons from "components/FooterButtons/FooterButtons";
import ModalInMobileView from "components/ModalView/ModalInMobileView";
import { RadioButtonsGroup } from "components/RadioGroup";
import { TextInput } from "components/TextInput";
import { useNavigateBack } from "lib/routing";
import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

export function EditTeamMemberPage() {
  const navigateBack = useNavigateBack();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const isForOrganization = queryParams.get("is_for_organization") === "true";
  const { id } = useParams();
  const updateRole = isForOrganization
    ? useUpdateOrgRole().mutateAsync
    : useUpdateUserRole().mutateAsync;
  const { data: role } = isForOrganization
    ? useUserFromOrgRoleId(id)
    : useUserFromUserRoleId(id);
  const [firstName, setFirstName] = useState<string | undefined>(undefined);
  const [lastName, setLastName] = useState<string | undefined>(undefined);
  const [email, setEmail] = useState<string | undefined>(undefined);
  const [cellPhone, setCellPhone] = useState<string | undefined>(undefined);
  const [roleType, setRoleType] = useState<
    NetworkRoleType | OrgRoleType | undefined
  >(undefined);
  const [hasAdminPriveleges, setHasAdminPriveleges] = useState(false);

  useEffect(() => {
    if (
      role &&
      role.user &&
      role.user.first_name &&
      role.user.last_name &&
      role.user.email &&
      role.user.cell_number
    ) {
      setFirstName(role.user.first_name);
      setLastName(role.user.last_name);
      setEmail(role.user.email);
      setCellPhone(role.user.cell_number);
      setRoleType(role.role as any);
      if (isForOrganization) {
        setHasAdminPriveleges((role as OrganizationRole).is_superuser);
      }
    }
  }, [role]);

  const handleSave = async () => {
    if (roleType && role) {
      await updateRole({
        roleId: role.id,
        newRole: roleType as any,
        isSuperUser: hasAdminPriveleges || roleType === OrgRoleType.ADMIN,
      });
    }
  };

  return (
    <ModalInMobileView
      title="Edit User"
      onClose={() => {}}>
      <div className="flex flex-col gap-5 w-full text-sm md:text-base">
        <h2 className=" font-light mt-8 mb-2 text-base">
          Personal Information
        </h2>
        <div className="flex gap-10 xl:grid-cols-2 xl:grid">
          <div className="flex flex-col gap-2 ">
            <p className="font-medium">First Name*</p>
            <TextInput
              data-hj-allow
              value={firstName}
              disabled={true}
              onChange={(e) => setFirstName(e.currentTarget.value)}
              className="w-full"
              placeholder="Enter first name"
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-medium">Last Name*</p>
            <TextInput
              data-hj-allow
              value={lastName}
              disabled={true}
              onChange={(e) => setLastName(e.currentTarget.value)}
              className="w-full"
              placeholder="Enter last name"
            />
          </div>
        </div>
        <div className="flex gap-10 xl:grid-cols-2 xl:grid">
          <div className="flex flex-col gap-2">
            <p className="font-medium">Email*</p>
            <TextInput
              data-hj-allow
              value={email}
              disabled={true}
              onChange={(e) => setEmail(e.currentTarget.value)}
              className="w-full"
              placeholder="Enter email"
            />
          </div>
          <div className="flex flex-col gap-2">
            <p className="font-medium">Cell Phone*</p>
            <TextInput
              data-hj-allow
              value={cellPhone}
              onChange={(e) => setCellPhone(e.currentTarget.value)}
              className="w-full"
              disabled={true}
              placeholder="Enter cell phone number"
            />
          </div>
        </div>
      </div>
      <div className="flex flex-col gap-2 w-[15rem] text-sm md:text-base mt-4">
        <label className="font-semibold mt-4 md:mt-0">Role*</label>
        <RadioButtonsGroup
          row={false}
          id="invitation-radiogroup-type"
          options={
            isForOrganization
              ? ORG_ROLE_OPTIONS
              : NETWORK_ROLE_OPTIONS.filter(
                  (role) =>
                    !Object.values(OrgRoleType).includes(
                      role.value as unknown as OrgRoleType
                    )
                )
          }
          value={roleType || ""}
          onChange={(type: string) => setRoleType(type as NetworkRoleType)}
        />
      </div>
      {isForOrganization ? (
        <div className="flex flex-col gap-2">
          <label className=" mt-4 md:mt-6">Additional Access</label>
          <DefaultCheckbox
            label="Administrative Privileges"
            checked={hasAdminPriveleges || roleType === OrgRoleType.ADMIN}
            disabled={roleType === OrgRoleType.ADMIN}
            onChange={() => setHasAdminPriveleges(!hasAdminPriveleges)}
          />
        </div>
      ) : null}

      <FooterButtons>
        <ButtonWithIcon
          onClick={() => navigateBack()}
          text={"Cancel"}
          icon={IconOption.CANCEL}
        />
        <ButtonWithIcon
          onClick={async () => {
            await handleSave();
            navigateBack();
          }}
          text={"Save"}
          icon={IconOption.CHECKMARK}
        />
      </FooterButtons>
    </ModalInMobileView>
  );
}
