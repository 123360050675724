import {
  TaskStatus,
  TaskStatusColor,
  TaskStatusLabel,
  TaskWithGuideInfo,
} from "backend/resources/planEntry";
import { DonutChartData } from "components/DonutChart/DonutChart";
import DonutChartWithLabels from "components/DonutChart/DonutChartWithLabels";
import { FilterConfig } from "components/TaskNavigatorPage/TaskFilters";
import {
  isTaskOverdue,
  useFilteredData,
} from "components/TaskNavigatorPage/taskTableUtils";
import { useTaskFilterStore } from "state/taskFilter/taskFilter";

interface TaskDonutChartsProps {
  title: string;
  data: TaskWithGuideInfo[] | undefined;
  filterConfig: FilterConfig;
}

/**
 * @deprecated This component will be deprecated in the future. Please use HighPieChart instead.
 * HighChart Documentation:https://www.highcharts.com/demo/highcharts/donut-race
 */
export function TaskDonutCharts({
  title,
  data,
  filterConfig,
}: TaskDonutChartsProps) {
  const filteredData = useFilteredData({ data, config: filterConfig })?.filter(
    (item) => item.status !== TaskStatus.NotApplicable
  );
  const { setStatus } = useTaskFilterStore();
  function dataToSection(
    acc: Record<string, DonutChartData>,
    item: TaskWithGuideInfo
  ) {
    const status = item.status as TaskStatus;
    if (status !== TaskStatus.NotApplicable) {
      const isOverdue = item.scheduled_date_time
        ? isTaskOverdue(item.scheduled_date_time, status)
        : false;
      if (!acc[status]) {
        acc[status] = {
          section: TaskStatusLabel[status as TaskStatus],
          value: 0,
        };
      }
      acc[status].value++;
      if (isOverdue) {
        if (!acc["Overdue"]) {
          acc["Overdue"] = { section: "Overdue", value: 0 };
        }
        acc["Overdue"].value++;
      }
    }
    return acc;
  }
  const totalTasks = filteredData?.length || 0;
  const completedTasks =
    filteredData?.filter((item) => item.status === TaskStatus.Done).length || 0;
  const percentageComplete =
    totalTasks && !isNaN(completedTasks) && !isNaN(totalTasks)
      ? parseFloat(((completedTasks / totalTasks) * 100).toFixed(0))
      : 0;

  const availableTaskStatusLabels = Object.fromEntries(
    Object.entries({ ...TaskStatusLabel, Overdue: "Overdue" }).filter(
      ([statusKey]) => statusKey !== TaskStatus.NotApplicable
    )
  ) as Record<string, string>;

  return (
    <DonutChartWithLabels
      title={title + ` (${percentageComplete}% Complete)`}
      data={filteredData}
      colorMapping={TaskStatusColor}
      dataToSection={dataToSection}
      labels={availableTaskStatusLabels}
      radius={80}
      onLabelClick={(status) => setStatus(status as TaskStatus)}
    />
  );
}
