import CriticalWarningSVG from "assets/red_warning.svg?react";
import WarningSVG from "assets/warning.svg?react";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import ReactMarkdown from "react-markdown";
import { PopupOrSheet } from "shared/ui/popup-bottom-sheet";

const CRITICAL_RISK_MESSAGE = "imminent risk";
const WARNING_MESSAGE = "Attention Needed";
const CRITICAL_WARNING_MESSAGE = "Attention Required";

interface Props {
  onClose: () => void;
  message: string | null | undefined;
}
export default function AssessmentImmediateAttentionPage({
  onClose,
  message,
}: Props) {
  const components = {
    strong: (props: any) => (
      <strong
        style={{ color: "red" }}
        className="font-bold">
        {props.children}
      </strong>
    ),
  };

  const isCritical = message?.includes(CRITICAL_RISK_MESSAGE);

  return (
    <PopupOrSheet
      isOpen={true}
      onClose={onClose}
      icon={
        isCritical ? (
          <CriticalWarningSVG className={`w-10 h-10`} />
        ) : (
          <WarningSVG className={`w-10 h-10`} />
        )
      }
      footerButtons={
        <ButtonWithIcon
          className="text-xs md:text-base"
          onClick={onClose}
          size={"small"}
          text={"OK"}
          icon={IconOption.CHECKMARK}
        />
      }
      title={isCritical ? CRITICAL_WARNING_MESSAGE : WARNING_MESSAGE}>
      <ReactMarkdown components={components}>{message}</ReactMarkdown>
    </PopupOrSheet>
  );
}
