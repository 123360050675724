import startCase from "lodash/startCase";

import { TaskStatus } from "./constants";
import { type Task } from "./types";

export function formatStatus(task: Task) {
  const status =
    task.status === TaskStatus.NotApplicable ? "N/A" : startCase(task.status);
  return `${status}${task.is_overdue ? " (Overdue)" : ""}`;
}

export function formatWho(task: Task, currentUser?: string) {
  if (task.user?.id === currentUser) {
    return "Me";
  }

  return `${task.user?.first_name} ${task.user?.last_name}`;
}
