import AssessmentComplete from "assets/assessment_complete.svg?react";
import type { Assessment } from "backend/resources/assessment";
import { useUserAssessmentById } from "backend/resources/userAssessment";
import ScoreKey from "components/AssessmentComponent/ScoreKey";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { PopupOrSheet } from "shared/ui/popup-bottom-sheet";
import { useAssessmentStore } from "state/assessment";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  activeAssessment: Assessment | undefined | null;
}
export default function AssessmentSubmissionPage({
  isOpen,
  onClose,
  activeAssessment,
}: Props) {
  const activeUserAssessmentId = useAssessmentStore(
    (state) => state.activeUserAssessmentId
  );
  const { data: activeUserAssessment } = useUserAssessmentById(
    activeUserAssessmentId
  );
  const totalScore = activeUserAssessment?.total_score as
    | number
    | { distress: number; severity: number }
    | { physical_health: number; mental_health: number };
  return (
    <PopupOrSheet
      isOpen={isOpen}
      onClose={onClose}
      icon={
        <div className="flex justify-center items-center w-full">
          <AssessmentComplete className="w-14 h-14" />
        </div>
      }
      footerButtons={
        <ButtonWithIcon
          className="text-xs md:text-base"
          onClick={onClose}
          size={"small"}
          text={"OK"}
          icon={IconOption.CHECKMARK}
        />
      }>
      <div className="flex flex-col gap-2">
        <p className="text-center">Assessment submitted successfully</p>
        {activeAssessment?.score_key && (
          <ScoreKey
            totalScore={totalScore}
            scoreKey={activeAssessment?.score_key}
            justify="center"
          />
        )}
      </div>
    </PopupOrSheet>
  );
}
