import { DatePicker, TimePicker } from "@mui/x-date-pickers";
import CancelIconSvg from "assets/cancel-icon.svg?react";
import CheckmarkIconSvg from "assets/checkmark-icon.svg?react";
import CirclePlusIconSvg from "assets/circle-plus.svg?react";
import EditIconSvg from "assets/edit-folder.svg?react";
import { useNetworkName } from "backend/resources/network/network";
import { useActiveOrg, useOrgs } from "backend/resources/orgRole";
import type { PlanEntryUpdate, TaskStatus } from "backend/resources/planEntry";
import {
  PlaceTypeSchema,
  TaskStatusColor,
  TaskStatusLabel,
  usePlanEntryData,
  useUpdatePlanEntry,
} from "backend/resources/planEntry";
import { usePlanEntryAtendeeByPlanEntry } from "backend/resources/planEntry/planEntryAttendee";
import { useServiceEngagementFromPlanEntryId } from "backend/resources/services/serviceEngagementToPlanEntry";
import { useBookmarkQuery } from "backend/resources/userBookmark";
import { AddBookmarksModal } from "components/AddBookmarksModal";
import BackButton from "components/BackButton/BackButton";
import Carousel from "components/Carousel/Carousel";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { Bookmark } from "components/LibraryPage/Bookmarks/Bookmark";
import ModalView from "components/ModalView/ModalView";
import AddAttendeesButtonPopup from "components/MyPlanPage/components/AddAttendeesButtonPopup";
import EntryParentLinkComponent from "components/MyPlanPage/components/EntryParentLinkComponent";
import VideoCallButton from "components/MyPlanPage/components/VideoCallButton";
import Who, { UserType } from "components/MyPlanPage/components/Who";
import { PageMainHeader } from "components/PageMainHeader";
import { HeaderNamePill } from "components/Pill";
import type { PlaceType } from "components/PlacesAutocomplete";
import { GoogleAutocomplete } from "components/PlacesAutocomplete";
import { Select } from "components/Select";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import dayjs from "dayjs";
import { RECURRING_INTERVAL_OPTIONS } from "features/tasks/constants";
import { useWindowSize } from "hooks/useWindowSize";
import { CarePilotRoute, useAppNavigate, useNavigateBack } from "lib/routing";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNetworkStore } from "state/network/network";
import { useUserStore } from "state/user";

export function EntryViewEdit() {
  const { isMobile } = useWindowSize();
  const navigateBack = useNavigateBack();
  if (isMobile) {
    return (
      <ModalView
        isOpen={true}
        title="Edit Task"
        closeText="Back"
        onClose={() => {
          navigateBack();
        }}>
        <EntryViewEditComponents />
      </ModalView>
    );
  } else {
    return <EntryViewEditComponents />;
  }
}

export function EntryViewEditComponents() {
  // setup
  const { isMobile } = useWindowSize();
  const { id: entryId } = useParams();
  const authUser = useUserStore((state) => state.user);
  const navigate = useAppNavigate();
  const navigateBack = useNavigateBack();

  // load entry data
  const { data: initialEntryData } = usePlanEntryData(entryId);
  const { data: attendeesData } = usePlanEntryAtendeeByPlanEntry(entryId);

  const parsedInitialLocation = () => {
    try {
      return PlaceTypeSchema.parse(initialEntryData?.location);
    } catch {
      return null;
    }
  };

  const { data: optionalServiceEngagement } =
    useServiceEngagementFromPlanEntryId(initialEntryData?.id);
  // form setup
  const [error, setError] = useState<string>();
  const [isAddBookmarksPopoverOpen, setIsAddBookmarksPopoverOpen] =
    useState<boolean>(false);

  // form values
  const [name, setName] = useState<string>(initialEntryData?.name || "");
  const [planEntryOwner, setPlanEntryOwner] = useState<string | undefined>(
    initialEntryData?.user_id
  );
  const [description, setDescription] = useState<string>(
    initialEntryData?.description || ""
  );
  const [status, setStatus] = useState<string>(initialEntryData?.status || "");
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(
    dayjs(initialEntryData?.scheduled_date_time) || dayjs()
  );
  const [selectedTime, setSelectedTime] = useState<dayjs.Dayjs | null>(
    dayjs(initialEntryData?.scheduled_date_time) || dayjs()
  );
  const [selectedLocation, setSelectedLocation] = useState<PlaceType | null>(
    parsedInitialLocation()
  );
  const [selectedBookmarkIds, setSelectedBookmarkIds] = useState<string[]>(
    initialEntryData?.user_bookmark_ids || []
  );
  const [notes, setNotes] = useState<string | null>(
    initialEntryData?.notes || null
  );

  const [isSetupDone, setIsSetupDone] = useState(false);

  const [googleMeetingCode, setGoogleMeetingCode] = useState<string | null>(
    initialEntryData?.google_meeting_code as string
  );

  const [attendees, setAttendees] = useState<string[]>(attendeesData ?? []);

  const [recurringInterval, setRecurringInterval] = useState<string | null>(
    initialEntryData?.recurring_interval as string
  );

  // derived form details
  const { bookmarks, refetchBookmarks } = useBookmarkQuery();

  const filteredBookmarks = bookmarks?.filter((bookmark) =>
    selectedBookmarkIds.includes(bookmark.id)
  );
  const { networkName } = useNetworkName(
    initialEntryData?.network_id ?? undefined
  );
  const { data: activeOrg } = useActiveOrg();
  const { hasCareCentralAccess } = useOrgs();

  // form submission
  const mutatePlanEntry = useUpdatePlanEntry();
  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );
  const combineDateTime = () => {
    if (selectedDate && selectedTime) {
      const year = selectedDate.year();
      const month = selectedDate.month();
      const day = selectedDate.date();
      const hours = selectedTime.hour();
      const minutes = selectedTime.minute();

      return dayjs()
        .year(year)
        .month(month)
        .date(day)
        .hour(hours)
        .minute(minutes);
    } else {
      return null;
    }
  };

  const validateForm = () => {
    if (!name.trim()) {
      setError("Name is required");
      return false;
    }

    if (!status.trim()) {
      setError("Status is required");
      return false;
    }

    if (!selectedDate) {
      setError("Date is required");
      return false;
    }

    if (!selectedTime) {
      setError("Time is required");
      return false;
    }

    // If all the above conditions pass, clear any previous error.
    setError("");
    return true;
  };

  async function submitForm() {
    // Validate the form, and if validation fails, return early.
    if (!validateForm()) {
      return;
    }

    const date = combineDateTime();
    if (!date) {
      setError("Please try entering date and time again.");
      return;
    }

    const location = selectedLocation
      ? JSON.parse(JSON.stringify(selectedLocation))
      : null;

    const dateString = date.toISOString();
    // If validation passes, attempt to submit the form.
    if (planEntryOwner) {
      const entryData: PlanEntryUpdate = {
        id: entryId,
        name,
        user_adlo_id: null, // todo with select
        description,
        notes,
        status,
        scheduled_date_time: dateString,
        location,
        user_bookmark_ids: selectedBookmarkIds,
        user_id: planEntryOwner,
        recurring_interval: recurringInterval,
        guide_task_id: null,
        google_meeting_code: googleMeetingCode,
        plan_entry_attendee: attendees.map((attendee) => ({
          user_id: attendee,
        })),
      };

      try {
        // Attempt to submit the entry
        await mutatePlanEntry.mutateAsync(entryData);
        // If successful, add attendees and navigate to the plan page
        if (entryId) {
          navigate(
            {
              path: CarePilotRoute.PLAN_ID,
              params: {
                id: entryId,
              },
            },
            { replace: true }
          );
        }
      } catch {
        // Handle submission error here. You can set an error message if needed.
        setError("Failed to submit the entry. Please try again.");
      }
    }
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    submitForm();
  };

  useEffect(() => {
    if (!!initialEntryData && !isSetupDone) {
      // TODO instead of individual fields, should use the schema
      // TODO make network_id non nullable
      if (initialEntryData.network_id) {
        setActiveNetworkId(initialEntryData.network_id);
      }
      setName(initialEntryData.name);
      setDescription(initialEntryData.description ?? "");
      setSelectedDate(dayjs(initialEntryData.scheduled_date_time));
      setSelectedTime(dayjs(initialEntryData.scheduled_date_time));
      setStatus(initialEntryData.status);
      setIsSetupDone(true);
      setNotes(initialEntryData.notes ?? "");
      setSelectedBookmarkIds(initialEntryData.user_bookmark_ids ?? []);
      setRecurringInterval(
        initialEntryData.recurring_interval?.toString() ?? null
      );
      setAttendees(attendeesData ?? []);
      setGoogleMeetingCode(initialEntryData.google_meeting_code ?? null);
      setSelectedLocation(parsedInitialLocation());
      setPlanEntryOwner(initialEntryData.user_id);
    }
  }, [initialEntryData, isSetupDone]);

  return (
    <div className="flex flex-col h-full gap-4">
      {/* top bar */}
      <div className="flex justify-between">{!isMobile && <BackButton />}</div>
      {/* form */}
      <form className="flex flex-col gap-4 max-w-[65ch] px-8 pb-28">
        <div className="flex gap-1">
          <HeaderNamePill
            text={activeOrg?.name}
            color="#5D63BA"
          />
          <HeaderNamePill
            text={networkName}
            color="#176590"
          />
        </div>
        {/* header */}
        <PageMainHeader text="Edit Task" />
        {/* name group */}
        <label htmlFor="new_plan_entry_name">Name</label>
        <TextInput
          data-hj-allow
          id="new_plan_entry_name"
          placeholder="ex: Watch video recommendation"
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
        {/* description group */}
        <label htmlFor="new_plan_entry_name">Description</label>
        <TextArea
          data-hj-allow
          id="new_plan_entry_description"
          placeholder="ex: 1-1 time to watch video recommendation"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <EntryParentLinkComponent
          parent_plan_entry_id={initialEntryData?.parent_plan_entry_id ?? null}
          service_engagement_id={optionalServiceEngagement?.id ?? null}
        />

        {/* date, time, status row */}
        <div className="grid grid-cols-2 gap-4">
          {/* date */}
          <div className="flex flex-col gap-2">
            <label>Date</label>
            <DatePicker
              className="w-full"
              value={selectedDate}
              onChange={(newValue: dayjs.Dayjs | null) =>
                setSelectedDate(newValue)
              }
              slotProps={{
                field: {
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  },
                },
              }}
            />
          </div>
          {/* time */}
          <div className="flex flex-col gap-2">
            <label>Time</label>
            <TimePicker
              ampm={true}
              className="w-full"
              value={selectedTime}
              onChange={(newValue: dayjs.Dayjs | null) =>
                setSelectedTime(newValue)
              }
              slotProps={{
                field: {
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  },
                },
              }}
            />
          </div>
          {/* Recurring */}
          <div className="flex flex-col gap-2 border-zinc-300">
            <label>Recurring Interval</label>
            <Select
              onChange={(value) => {
                if (value) setRecurringInterval(value);
              }}
              options={RECURRING_INTERVAL_OPTIONS}
              currentOption={
                recurringInterval
                  ? RECURRING_INTERVAL_OPTIONS.find(
                      (option) => option.value === recurringInterval
                    )
                  : { value: "None", label: "N/A" }
              }
            />
          </div>
          {/* status */}
          <div className="flex flex-col gap-2">
            <label>Status</label>
            <Select
              classNames="text-sm w-full h-full"
              borderClass="border-zinc-300"
              currentOption={{
                value: status,
                label: TaskStatusLabel[status as TaskStatus],
                background: TaskStatusColor[status as TaskStatus],
              }}
              options={Object.entries(TaskStatusLabel).map(([key, label]) => ({
                value: key,
                label,
                background: TaskStatusColor[key as TaskStatus],
              }))}
              onChange={setStatus}
            />
          </div>
          {/* location */}
          <div className="flex flex-col gap-2">
            <label>Location</label>
            <GoogleAutocomplete
              initialValue={selectedLocation}
              onValueChange={(value, _) => {
                setSelectedLocation(value);
              }}
            />
          </div>
          {/* user tags */}
          <div className="flex flex-col gap-2">
            <label htmlFor="new_plan_entry_who">Assigned To*</label>
            <div className="flex h-full items-center gap-2">
              <Who
                entry={initialEntryData}
                isEditing
                userType={UserType.NETWORK_USERS}
                setPlanEntryOwner={setPlanEntryOwner}
                selectedPlanEntryOwnerId={planEntryOwner}
              />
            </div>
          </div>
        </div>
        {/* Add Attendee(s) */}
        <AddAttendeesButtonPopup
          editOptions={{ setAttendees }}
          initialAttendeeIds={attendees}
        />
        {/* Google Meeting Button */}
        <VideoCallButton
          googleMeetingCode={googleMeetingCode}
          setGoogleMeetingCode={setGoogleMeetingCode}
        />
        {/* bookmarks */}
        {!hasCareCentralAccess && (
          <div className="flex flex-col gap-4 pt-3">
            <div className="flex w-full gap-3 items-center">
              <label>Related bookmarks</label>
              <button
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  setIsAddBookmarksPopoverOpen(true);
                }}
                className="flex items-center justify-center">
                {filteredBookmarks && filteredBookmarks.length > 0 ? (
                  <EditIconSvg className="w-5 h-5" />
                ) : (
                  <CirclePlusIconSvg className="w-5 h-5" />
                )}
              </button>
            </div>
            {/* if there are bookmarks, show */}
            {filteredBookmarks && filteredBookmarks.length > 0 ? (
              <Carousel
                items={filteredBookmarks?.map((bookmark) => (
                  <Bookmark
                    key={bookmark.id}
                    bookmark={bookmark}
                    refetchBookmarks={refetchBookmarks}
                    hideActionbar={true}
                    isInPlanEntry={true}
                  />
                ))}
              />
            ) : (
              <div className="w-full h-[100px] border border-dashed border-zinc-200 text-zinc-500 italic p-3">
                No bookmarks attached
              </div>
            )}
            {isAddBookmarksPopoverOpen && (
              <div>
                <AddBookmarksModal
                  closeText="Back"
                  savedBookmarkIds={selectedBookmarkIds}
                  saveSelectedBookmarkIds={setSelectedBookmarkIds}
                  setShowPopover={setIsAddBookmarksPopoverOpen}
                />
              </div>
            )}
          </div>
        )}
        {/* notes */}
        <label htmlFor="new_plan_entry_who">Notes</label>
        <TextArea
          data-hj-allow
          className="min-h-[200px]"
          onChange={(event) => setNotes(event.target.value)}
          value={notes || ""}
          placeholder="Type your note..."
        />
        {/* buttons row */}
        <FooterButtons>
          <button
            type="button"
            className="flex items-center p-2 gap-2"
            onClick={navigateBack}>
            <CancelIconSvg className="w-5 h-5" />
            <span>Cancel</span>
          </button>
          <button
            type="submit"
            className="flex items-center p-2 gap-2"
            onClick={handleSubmit}>
            <CheckmarkIconSvg className="w-5 h-5" />
            <span>Save</span>
          </button>
        </FooterButtons>
        {/* error message row — @TODO: per element error messaging using something like react-hook-form */}
        {error && (
          <div className="flex w-full justify-end gap-2 text-red-700 px-8">
            Error submitting form: {error}
          </div>
        )}
      </form>
    </div>
  );
}
