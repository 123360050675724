import { useQuery } from "@tanstack/react-query";

import {
  buildQueryFilters,
  type Filter,
  type QueryOptions,
} from "features/query-utils";

import { ServiceStatus, TaskStatus } from "../constants";
import type { TableName, Task } from "../types";

import { queryKeys, select } from ".";

export function useFetchMany(
  filter?: Filter<TableName>,
  options: QueryOptions = {}
) {
  const { enabled } = options;

  return useQuery({
    queryKey: filter ? queryKeys.list(filter) : queryKeys.lists,
    queryFn: async () => {
      const query = buildQueryFilters(select(), filter);

      const { data, error } = await query;

      if (error) {
        throw error;
      }

      return data.map((task) => ({
        ...task,
        scheduled_date_time: task.scheduled_date_time
          ? new Date(task.scheduled_date_time)
          : null,
        is_overdue:
          task.status === TaskStatus.Done || !task.scheduled_date_time
            ? false
            : new Date(task.scheduled_date_time) < new Date(),
        service_status: task.service_request_to_engagement?.service_engagement
          ? task.service_request_to_engagement?.service_engagement.is_active
            ? ServiceStatus.Active
            : ServiceStatus.Inactive
          : ServiceStatus.NotApplicable,
      })) as Task[];
    },
    enabled,
  });
}
