import { useState } from "react";

import { isValidPhoneNumber } from "react-phone-number-input";
import type { InvitationInsert } from "backend/resources/invitation/invitation";
import {
  useInsertInvitation as useInsertInvitationMutation,
  useInvitationQuery,
} from "backend/resources/invitation/invitation";
import { useActiveUserRole } from "backend/resources/userRole";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { useActiveNetworkId } from "state/network/network";

import { NotificationType, sendNotification } from "backend/functions";
import { useUserAdlos } from "backend/resources/userAdlo";
import { useUserStore } from "state/user";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import CarespaceMembers from "components/CarespaceMembers/CarespaceMembers";
import { FormContainer } from "components/PageContainer/FormContainer";
import PhoneNumberInput from "components/PhoneNumberInput/PhoneNumberInput";
import { RadioButtonsGroup } from "components/RadioGroup";
import { TextInput } from "components/TextInput";
/**
 *
 */

export function Team() {
  const insertInvitationMutation = useInsertInvitationMutation();
  const networkId = useActiveNetworkId();
  const { data: invitations, isLoading: isLoadingInvitations } =
    useInvitationQuery();
  const { userRole } = useActiveUserRole();
  const { data: userAdlos } = useUserAdlos();

  const [firstName, setFirstName] = useState<string | undefined>();
  const [lastName, setLastName] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [cellPhone, setCellPhone] = useState<string | undefined>();
  const [roleType, setRoleType] = useState<NetworkRoleType | undefined>();
  const authUser = useUserStore((state) => state.user);

  const handleSendInvitation = async () => {
    if (
      email &&
      firstName &&
      lastName &&
      roleType &&
      networkId &&
      cellPhone &&
      isValidPhoneNumber(cellPhone)
    ) {
      const newInvitation: InvitationInsert = {
        // fill in the fields of your invitation here
        network_id: networkId,
        invited_email: email,
        first_name: firstName,
        last_name: lastName,
        role_type: roleType,
        cell_number: cellPhone,
      };
      const data = await insertInvitationMutation.mutateAsync(newInvitation);
      if (data) {
        // Create URL object. We use the location origin so that it works for all enviromnets
        await sendNotification(data.id, NotificationType.CARESPACE_INVITATION);
        setFirstName("");
        setLastName("");
        setEmail("");
        setCellPhone("");
        setRoleType(undefined);
      }
    }
  };

  // TODO: refactor membership queries
  const userHasCarespaceInvitation = invitations?.some(
    (invitation) => invitation.user_role_id === userRole?.id
  );

  return (
    <>
      <div className="flex flex-col gap-5 w-full text-sm md:text-base">
        <h2 className=" font-light mt-4 mb-2 text-base">
          Invite Others to Your Carespace
        </h2>
        <FormContainer className="flex flex-col gap-5">
          <div className="grid grid-cols-2 gap-5">
            <div className="flex flex-col gap-2 ">
              <p className="font-medium">First Name*</p>
              <TextInput
                data-hj-allow
                value={firstName}
                onChange={(e) => setFirstName(e.currentTarget.value)}
                className="w-full"
                placeholder="Enter first name"
              />
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-medium">Last Name*</p>
              <TextInput
                data-hj-allow
                value={lastName}
                onChange={(e) => setLastName(e.currentTarget.value)}
                className="w-full"
                placeholder="Enter last name"
              />
            </div>
          </div>
          <div className="grid grid-rows-2 md:grid-rows-1 md:grid-cols-2 gap-5">
            <div className="flex flex-col gap-2">
              <p className="font-medium">Email*</p>
              <TextInput
                data-hj-allow
                value={email}
                onChange={(e) => setEmail(e.currentTarget.value)}
                className="w-full"
                placeholder="Enter email"
              />
            </div>
            <div className="flex flex-col gap-2">
              <p className="font-medium">Cell Phone*</p>
              <PhoneNumberInput
                cellPhone={cellPhone || undefined}
                setCellPhone={setCellPhone}
                className="h-full"
              />
            </div>
          </div>
          <div className="flex flex-col gap-2 text-sm md:text-base">
            <label className="font-semibold mt-4 md:mt-6">Role*</label>
            <RadioButtonsGroup
              id="invitation-radiogroup-type"
              options={[
                {
                  value: NetworkRoleType.PRIMARY_CAREGIVER,
                  label: "Primary Caregiver (can send invitations)",
                },
                {
                  value: NetworkRoleType.CAREGIVER,
                  label: "Caregiver (can give updates)",
                },
                {
                  value: NetworkRoleType.FAMILY_MEMBER,
                  label: "Family member (will see updates)",
                },
              ]}
              value={roleType || ""}
              onChange={(type: string) => setRoleType(type as NetworkRoleType)}
              row={false}
            />
          </div>
        </FormContainer>
      </div>

      <ButtonWithIcon
        onClick={handleSendInvitation}
        text="Send invitation"
        className="justify-end w-full my-4"
        icon={IconOption.ARROW}
        size={"small"}
        disabled={
          !(
            email &&
            firstName &&
            lastName &&
            roleType &&
            cellPhone &&
            isValidPhoneNumber(cellPhone)
          )
        }
      />
      <CarespaceMembers />
    </>
  );
}
