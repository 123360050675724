import CirclePlusIconSvg from "assets/circle-plus.svg?react";
import EditIconSvg from "assets/edit-folder.svg?react";

import { DatePicker, TimePicker } from "@mui/x-date-pickers";

import { useAssessment } from "backend/resources/assessment";
import { useCreateGuideEvent } from "backend/resources/guide/guideCall";
import {
  useNetworkName,
  useNetworksInOrganization,
} from "backend/resources/network/network";
import { useActiveOrg, useOrgs } from "backend/resources/orgRole";
import type { PlanEntryUpdate } from "backend/resources/planEntry";
import {
  TaskStatus,
  TaskStatusColor,
  TaskStatusLabel,
  usePlanEntryData,
  useUpdatePlanEntry,
} from "backend/resources/planEntry";
import { useServiceEngagementById } from "backend/resources/services/serviceEngagement";
import { useUpsertPlanEntryToServiceEngagement } from "backend/resources/services/serviceEngagementToPlanEntry";
import { useBookmarkQuery } from "backend/resources/userBookmark";
import { AddBookmarksModal } from "components/AddBookmarksModal/AddBookmarksModal";
import { AssessmentSelectorPage } from "components/AssessmentComponent/subpages/AssessmentSelectorPage";
import BackButton from "components/BackButton/BackButton";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import Carousel from "components/Carousel/Carousel";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { Bookmark } from "components/LibraryPage/Bookmarks/Bookmark";
import AddAttendeesButtonPopup from "components/MyPlanPage/components/AddAttendeesButtonPopup";
import EntryParentLinkComponent from "components/MyPlanPage/components/EntryParentLinkComponent";
import VideoCallButton from "components/MyPlanPage/components/VideoCallButton";
import Who, { UserType } from "components/MyPlanPage/components/Who";
import { useUsersInActiveNetworkAndOrganization } from "components/MyPlanPage/utils";
import { HeaderNamePill } from "components/Pill";
import type { PlaceType } from "components/PlacesAutocomplete";
import { GoogleAutocomplete } from "components/PlacesAutocomplete";
import { Select } from "components/Select";
import { TextArea } from "components/TextArea";
import { TextInput } from "components/TextInput";
import dayjs from "dayjs";
import {
  RECURRING_INTERVAL_OPTIONS,
  STATUS_OPTIONS,
} from "features/tasks/constants";
import { useNavigateBack } from "lib/routing";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useMyPlanStore } from "state/myPlan";
import { useNetworkStore } from "state/network/network";
import { useUserStore } from "state/user";

import { Checkbox } from "shared/ui/checkbox";
import { LabeledContent } from "shared/ui/labeled-content";
import { Title } from "shared/ui/title";

export default function NewTask() {
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const parentPlanEntryId = queryParams.get("parent_plan_entry_id");
  const serviceEngagementId = queryParams.get("service_engagement_id");
  const isAssessmentAssignment =
    queryParams.get("is_assessment_assignment") === "true";
  const isSchedulingCall = queryParams.get("is_scheduling_call") === "true";
  const navigateBack = useNavigateBack();
  const newEntryBookmarkId = useMyPlanStore(
    (state) => state.newEntryBookmarkId
  );
  const setNewEntryBookmarkId = useMyPlanStore(
    (state) => state.setNewEntryBookmarkId
  );
  const { hasCareCentralAccess } = useOrgs();
  const authUser = useUserStore((state) => state.user);

  const [error, setError] = useState<string>();
  const [isAddBookmarksPopoverOpen, setIsAddBookmarksPopoverOpen] =
    useState<boolean>(false);

  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [isPrivate, setIsPrivate] = useState(!hasCareCentralAccess);
  const [status, setStatus] = useState<string>("not_started");
  const [selectedDate, setSelectedDate] = useState<dayjs.Dayjs | null>(
    getNearestRoundTime()
  );
  const [selectedAssessmentId, setSelectedAssessmentId] = useState<
    string | undefined
  >(undefined);
  const [isAssessmentAssignmentModalOpen, setIsAssessmentAssignmentModalOpen] =
    useState<boolean>(isAssessmentAssignment);
  const [selectedTime, setSelectedTime] = useState<dayjs.Dayjs | null>(
    getNearestRoundTime()
  );
  const [recurringInterval, setRecurringInterval] = useState<string | null>(
    null
  );
  const [selectedLocation, setSelectedLocation] = useState<PlaceType | null>(
    null
  );
  const [selectedBookmarkIds, setSelectedBookmarkIds] = useState<string[]>([]);
  const [notes, setNotes] = useState<string | null>(null);
  const [planEntryOwner, setPlanEntryOwner] = useState<string | undefined>();
  const [googleMeetingCode, setGoogleMeetingCode] = useState<string | null>(
    null
  );
  const [attendees, setAttendees] = useState<string[]>([]);

  useEffect(() => {
    if (newEntryBookmarkId) {
      setSelectedBookmarkIds([...selectedBookmarkIds, newEntryBookmarkId]);
      setNewEntryBookmarkId(null);
    }
  }, [newEntryBookmarkId, selectedBookmarkIds, setNewEntryBookmarkId]);

  useEffect(() => {
    if (hasCareCentralAccess) {
      setIsPrivate(false);
    }
  }, [hasCareCentralAccess]);

  useEffect(() => {
    if (isPrivate && authUser?.id) {
      setPlanEntryOwner(authUser.id);
    }
  }, [authUser?.id, isPrivate]);

  const { data: parentPlanEntry } = usePlanEntryData(parentPlanEntryId);
  const { data: serviceEngagement } =
    useServiceEngagementById(serviceEngagementId);
  const activeNetworkId = useNetworkStore((state) => state.activeNetworkId);
  const { networkName } = useNetworkName(
    parentPlanEntry?.network_id ??
      serviceEngagement?.network_id ??
      activeNetworkId
  );
  const { data: activeOrg } = useActiveOrg();
  const { usersInNetwork } = useUsersInActiveNetworkAndOrganization(
    activeNetworkId ?? undefined
  );
  const { data: assessment } = useAssessment(selectedAssessmentId);
  const { networks } = useNetworksInOrganization();

  const { bookmarks, refetchBookmarks } = useBookmarkQuery();

  const filteredBookmarks = bookmarks?.filter((bookmark) =>
    selectedBookmarkIds.includes(bookmark.id)
  );

  const createGuideEvent = useCreateGuideEvent().mutateAsync;

  const mutatePlanEntry = useUpdatePlanEntry();
  const upsertPlanEntryToServiceEngagement =
    useUpsertPlanEntryToServiceEngagement().mutateAsync;
  const setActiveNetworkId = useNetworkStore(
    (state) => state.setActiveNetworkId
  );

  const combineDateTime = () => {
    if (selectedDate && selectedTime) {
      const year = selectedDate.year();
      const month = selectedDate.month();
      const day = selectedDate.date();
      const hours = selectedTime.hour();
      const minutes = selectedTime.minute();

      return dayjs()
        .year(year)
        .month(month)
        .date(day)
        .hour(hours)
        .minute(minutes);
    } else {
      return null;
    }
  };

  const validateForm = () => {
    if (!name.trim()) {
      setError("Name is required");
      return false;
    }

    if (!status.trim()) {
      setError("Status is required");
      return false;
    }

    if (!selectedDate) {
      setError("Date is required");
      return false;
    }

    if (!selectedTime) {
      setError("Time is required");
      return false;
    }

    if (!planEntryOwner) {
      setError("No user selected");
      return false;
    }

    setError("");
    return true;
  };

  async function submitForm() {
    if (!validateForm()) {
      return;
    }

    const date = combineDateTime();
    if (!date) {
      setError("Please try entering date and time again.");
      return;
    }

    const location = selectedLocation
      ? JSON.parse(JSON.stringify(selectedLocation))
      : null;

    const dateString = date.toISOString();

    if (planEntryOwner) {
      const entryData: PlanEntryUpdate = {
        name,
        user_adlo_id: null,
        network_id: activeNetworkId,
        description,
        notes,
        status,
        scheduled_date_time: dateString,
        location,
        user_bookmark_ids: selectedBookmarkIds,
        user_id: planEntryOwner,
        recurring_interval: recurringInterval,
        guide_task_id: null,
        parent_plan_entry_id: parentPlanEntryId,
        google_meeting_code: googleMeetingCode,
        plan_entry_attendee: attendees.map((attendee) => ({
          user_id: attendee,
        })),
        assessment_id: selectedAssessmentId,
        is_private: isPrivate,
      };

      try {
        const newEntry = await mutatePlanEntry.mutateAsync(entryData);
        if (serviceEngagementId && newEntry.id) {
          await upsertPlanEntryToServiceEngagement({
            service_engagement_id: serviceEngagementId,
            plan_entry_id: newEntry.id,
          });
        }

        if (isSchedulingCall) {
          await createGuideEvent({
            perform_event_plan_entry_id: newEntry.id,
            title: name,
            status,
            type: "Call",
            user_id: planEntryOwner,
            network_id: activeNetworkId || "",
          });
        }

        navigateBack();
      } catch {
        setError("Failed to submit the entry. Please try again.");
      }
    }
  }

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    submitForm();
  };

  function getNearestRoundTime() {
    const currentTime = dayjs();

    const minutesToAdd = (30 - (currentTime.minute() % 30)) % 30;

    const roundedUpTime = currentTime.add(minutesToAdd, "minute");

    return roundedUpTime;
  }

  function createAssessmentAssignment() {
    setName(`Take ${assessment?.name}`);
    setPlanEntryOwner(usersInNetwork?.[0]?.user?.id);
  }

  useEffect(() => {
    if (parentPlanEntry || serviceEngagement) {
      setActiveNetworkId(
        parentPlanEntry?.network_id || serviceEngagement?.network_id
      );
      if (isSchedulingCall) {
        setName(`Call/Visit -- ${networkName}`);
      }
    }
  }, [
    parentPlanEntry?.network_id,
    serviceEngagement?.network_id,
    networkName,
    parentPlanEntry,
    serviceEngagement,
    setActiveNetworkId,
    isSchedulingCall,
  ]);

  return (
    <div className="flex flex-col gap-4 p-8 h-full overflow-scroll">
      <ResponsiveModal
        isOpen={isAssessmentAssignmentModalOpen}
        title="Assessment Assignment"
        closeText="Back"
        onClose={navigateBack}
        footerButtons={
          <ButtonWithIcon
            disabled={!selectedAssessmentId}
            onClick={() => {
              setIsAssessmentAssignmentModalOpen(false);
              createAssessmentAssignment();
            }}
            text="Assign"
            icon={IconOption.ARROW}
          />
        }>
        <AssessmentSelectorPage
          selectedAssessmentId={selectedAssessmentId}
          setSelectedAssessmentId={setSelectedAssessmentId}
          newAssessmentType={undefined}
        />
      </ResponsiveModal>

      <BackButton />

      <form className="flex flex-col gap-4 max-w-[65ch]">
        {(parentPlanEntryId || serviceEngagementId) && (
          <div className="flex gap-1">
            <HeaderNamePill
              text={activeOrg?.name}
              color="#5D63BA"
            />
            <HeaderNamePill
              text={networkName}
              color="#176590"
            />
          </div>
        )}

        <Title order={1}>
          {isSchedulingCall ? "Schedule Call / Visit" : "New Task"}
        </Title>

        <LabeledContent
          label="Private Task (only visible to you)"
          orientation="horizontal">
          <Checkbox
            checked={isPrivate}
            onCheckedChange={setIsPrivate}
          />
        </LabeledContent>

        <label htmlFor="new_plan_entry_name">Name</label>
        <TextInput
          data-hj-allow
          id="new_plan_entry_name"
          placeholder="ex: Watch video recommendation"
          type="text"
          value={name}
          onChange={(event) => setName(event.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />

        <label htmlFor="new_plan_entry_name">Description</label>
        <TextArea
          data-hj-allow
          id="new_plan_entry_description"
          placeholder="ex: 1-1 time to watch video recommendation"
          value={description}
          onChange={(event) => setDescription(event.target.value)}
        />
        <EntryParentLinkComponent
          parent_plan_entry_id={parentPlanEntryId}
          service_engagement_id={serviceEngagementId}
        />

        <div className="grid grid-cols-2 lg:grid-cols-2 gap-4 items-center">
          <div className="flex flex-col gap-2">
            <label>Date</label>
            <DatePicker
              className="w-full"
              value={selectedDate}
              onChange={(newValue: dayjs.Dayjs | null) =>
                setSelectedDate(newValue)
              }
              slotProps={{
                field: {
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  },
                },
              }}
            />
          </div>

          <div className="flex flex-col gap-2">
            <label>Time</label>
            <TimePicker
              ampm={true}
              className="w-full"
              value={selectedTime}
              onChange={(newValue: dayjs.Dayjs | null) =>
                setSelectedTime(newValue)
              }
              slotProps={{
                field: {
                  onKeyDown: (e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  },
                },
              }}
            />
          </div>

          <div className="flex flex-col gap-2">
            <label>Recurring Interval</label>
            <Select
              classNames="text-sm w-full h-full"
              borderClass="border-zinc-300"
              onChange={(value) => {
                if (value) setRecurringInterval(value);
              }}
              options={RECURRING_INTERVAL_OPTIONS}
              currentOption={
                recurringInterval
                  ? RECURRING_INTERVAL_OPTIONS.find(
                      (option) => option.value === recurringInterval
                    )
                  : { value: "None", label: "N/A" }
              }
            />
          </div>

          {!isSchedulingCall ? (
            <div className="flex flex-col gap-2">
              <label>Status</label>
              <Select
                classNames="text-sm w-full h-full"
                borderClass="border-zinc-300"
                currentOption={
                  status
                    ? STATUS_OPTIONS.find((option) => option.value === status)
                    : {
                        value: TaskStatus.NotStarted,
                        label: TaskStatusLabel.not_started,
                      }
                }
                options={Object.entries(TaskStatusLabel).map(
                  ([key, label]) => ({
                    value: key,
                    label,
                    background: TaskStatusColor[key as TaskStatus],
                  })
                )}
                onChange={setStatus}
              />
            </div>
          ) : null}

          {hasCareCentralAccess && !parentPlanEntry && !serviceEngagement && (
            <div className="flex flex-col h-full gap-3">
              <label>Carespace</label>
              <Select
                classNames="text-sm w-full"
                borderClass="border-zinc-300"
                currentOption={
                  activeNetworkId
                    ? {
                        value: activeNetworkId,
                        label:
                          networks?.find(
                            (network) => network.id === activeNetworkId
                          )?.name ?? "",
                      }
                    : {
                        value: networks?.[0]?.id ?? "",
                        label: networks?.[0]?.name ?? "",
                      }
                }
                options={networks?.map((network) => ({
                  value: network.id,
                  label: network.name ?? "",
                }))}
                onChange={setActiveNetworkId}
              />
            </div>
          )}

          <div className="flex flex-col">
            <label>Location</label>
            <GoogleAutocomplete
              initialValue={selectedLocation}
              onValueChange={(value, _) => {
                setSelectedLocation(value);
              }}
            />
          </div>

          <div className="flex flex-col gap-3">
            <label
              className="-mt-1"
              htmlFor="new_plan_entry_who">
              Assigned To*
            </label>
            <Who
              className="w-full"
              entry={undefined}
              selectedPlanEntryOwnerId={planEntryOwner}
              isEditing
              userType={UserType.NETWORK_USERS}
              setPlanEntryOwner={setPlanEntryOwner}
              disabled={isPrivate}
            />
          </div>
        </div>

        <AddAttendeesButtonPopup
          editOptions={{ setAttendees }}
          initialAttendeeIds={attendees}
        />

        <VideoCallButton
          googleMeetingCode={googleMeetingCode}
          setGoogleMeetingCode={setGoogleMeetingCode}
        />

        {!hasCareCentralAccess && (
          <div className="flex flex-col gap-4 pt-3">
            <div className="flex w-full gap-3 items-center">
              <label>Related bookmarks</label>
              <button
                type="button"
                onClick={(event) => {
                  event.preventDefault();
                  setIsAddBookmarksPopoverOpen(true);
                }}
                className="flex items-center justify-center">
                {filteredBookmarks && filteredBookmarks.length > 0 ? (
                  <EditIconSvg className="w-5 h-5" />
                ) : (
                  <CirclePlusIconSvg className="w-5 h-5" />
                )}
              </button>
            </div>
            {filteredBookmarks && filteredBookmarks.length > 0 ? (
              <Carousel
                items={filteredBookmarks?.map((bookmark) => (
                  <Bookmark
                    key={bookmark.id}
                    bookmark={bookmark}
                    refetchBookmarks={refetchBookmarks}
                    hideActionbar={true}
                    isInPlanEntry={true}
                  />
                ))}
              />
            ) : (
              <div className="w-full h-[100px] border border-dashed border-zinc-200 text-zinc-500 italic p-3">
                No bookmarks attached
              </div>
            )}
            {isAddBookmarksPopoverOpen && (
              <div>
                <AddBookmarksModal
                  closeText="Back"
                  savedBookmarkIds={selectedBookmarkIds}
                  saveSelectedBookmarkIds={setSelectedBookmarkIds}
                  setShowPopover={setIsAddBookmarksPopoverOpen}
                />
              </div>
            )}
          </div>
        )}
        <label
          className="pt-3"
          htmlFor="new_plan_entry_who">
          Notes
        </label>
        <TextArea
          data-hj-allow
          className="min-h-[200px]"
          onChange={(event) => setNotes(event.target.value)}
          value={notes || ""}
          placeholder="Type your note..."
        />
        <FooterButtons>
          <ButtonWithIcon
            onClick={navigateBack}
            icon={IconOption.CANCEL}
            text="Cancel"
            type="button"
          />
          <ButtonWithIcon
            type="submit"
            onClick={handleSubmit}
            icon={IconOption.CHECKMARK}
            text="Save"
          />
        </FooterButtons>

        {error && (
          <div className="flex w-full justify-end gap-2 text-red-700 px-8">
            Error submitting form: {error}
          </div>
        )}
      </form>
    </div>
  );
}
