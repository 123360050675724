import { useUserAdloById } from "backend/resources/userAdlo";
import {
  useAdloNoteQuery,
  useUpsertAdloNote,
} from "backend/resources/userNote";
import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { LoadingSpinner } from "components/LoadingSpinner";
import { TextArea } from "components/TextArea";
import { useWindowSize } from "hooks/useWindowSize";
import { FC, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ResponsiveModal } from "shared/ui/responsive-modal";

interface AdloNotesModalProps {
  adloId?: string;
  textAreaHeight?: string;
  isOpen: boolean;
  onClose: () => void;
}

export const AdloNotesModal: FC<AdloNotesModalProps> = ({
  adloId,
  textAreaHeight,
  isOpen,
  onClose,
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const { isMobile } = useWindowSize();
  if (!adloId) {
    adloId = useParams().adloId;
  }
  const { data: userAdlo, isLoading: isLoadingUserAdlo } =
    useUserAdloById(adloId);
  const { adloNote, isLoadingAdloNote } = useAdloNoteQuery(adloId);
  const adloNoteUpdatedAt = adloNote?.adlo_note_updated_at;
  const saveAdloNote = useUpsertAdloNote().mutateAsync;
  const [currentNote, setCurrentNote] = useState(adloNote?.note);
  const [isModified, setIsModified] = useState(false);
  const textAreaClassNames = textAreaHeight
    ? `flex-grow w-full mt-6 h-[${textAreaHeight}]`
    : "flex-grow w-full mt-6";

  function handleNoteChange(e: React.ChangeEvent<HTMLTextAreaElement>) {
    setCurrentNote(e.target.value);
    setIsModified(e.target.value !== adloNote?.note);
  }

  function saveNote() {
    if (isModified) {
      saveAdloNote({
        user_adlo_id: adloId,
        note: currentNote,
        adlo_note_updated_at: new Date().toISOString(),
      });
      setIsModified(false);
    }
  }

  // Update currentNote and isModified when note changes (e.g. refetch or on load)
  useEffect(() => {
    setCurrentNote(adloNote?.note);
    setIsModified(false);
  }, [adloNote]);

  return (
    <ResponsiveModal
      onClose={onClose}
      isOpen={isOpen}>
      <div className="flex flex-col h-full pb-36 md:px-6 md:pb-0">
        {/* title and select component */}
        <div className="flex justify-center">
          {isLoadingUserAdlo ? (
            <LoadingSpinner className="w-5 h-5" />
          ) : (
            <div className="flex flex-col items-center gap-2">
              <h1 className="text-2xl font-light">
                {isEditing ? "Editing " : ""} Notes for {userAdlo?.first_name}
              </h1>
              <span className="text-sm">
                Last updated:{" "}
                {adloNoteUpdatedAt
                  ? new Date(adloNoteUpdatedAt).toLocaleDateString("en-US", {
                      year: "numeric",
                      month: "2-digit",
                      day: "2-digit",
                    })
                  : "Not updated yet"}
              </span>
            </div>
          )}
        </div>
        {/* content */}
        {isLoadingAdloNote ? (
          <LoadingSpinner className="w-20 h-20" />
        ) : (
          <TextArea
            className={textAreaClassNames}
            data-hj-allow
            value={currentNote || ""}
            onChange={handleNoteChange}
            disabled={isMobile && !isEditing}
          />
        )}
        {!isMobile ? (
          <FooterButtons>
            <ButtonWithIcon
              disabled={!isModified}
              text="Save"
              onClick={(e: any) => {
                e.stopPropagation();
                saveNote();
              }}
              icon={IconOption.CHECKMARK}
            />
          </FooterButtons>
        ) : isEditing ? (
          <FooterButtons>
            <ButtonWithIcon
              onClick={() => {
                setIsEditing(false);
              }}
              icon={IconOption.CANCEL}
              text="Cancel"
            />
            <ButtonWithIcon
              disabled={!isModified}
              text="Save"
              onClick={(e: any) => {
                e.stopPropagation();
                saveNote();
              }}
              icon={IconOption.CHECKMARK}
            />
          </FooterButtons>
        ) : (
          <BottomActionBar
            centerSlot={
              <ButtonWithIcon
                onClick={() => setIsEditing(true)}
                text={""}
                icon={IconOption.EDIT}
              />
            }
          />
        )}
      </div>
    </ResponsiveModal>
  );
};
