import type { VariantProps } from "class-variance-authority";
import type { UserBookmarkWithGuidanceResource } from "backend/resources/userBookmark";
import type {
  AmazonMetadata,
  NewsMetadata,
  UserAssessmentChatGptInterventionResource,
} from "backend/resources/userGuidanceResource";
import {
  GuidanceResourceType,
  toGuidanceResourceType,
} from "backend/resources/userGuidanceResource";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import type { cardStyles } from "components/Card/Card";
import NewsComponent from "components/HomePage/CaregiverHome/News/NewsComponent";
import { YouTubeButton } from "components/YouTubeButton";
import AmazonButton from "components/LibraryPage/AmazonButton/AmazonButton";
import { getResourceMetadataForBookmark } from "components/LibraryPage/Bookmarks/Bookmark/GuidanceResourceBookmark/utils";

interface Props {
  bookmark: UserBookmarkWithGuidanceResource & {
    user_guidance_resource: UserAssessmentChatGptInterventionResource;
  };
}

export function GuidanceResourceBookmark({
  bookmark,
  ...variantProps
}: Props & VariantProps<typeof cardStyles>) {
  const navigate = useAppNavigate();

  // GPT Store

  const resourceType = toGuidanceResourceType(
    bookmark.user_guidance_resource?.guidance_resource?.type
  );

  const resourceMetadata = getResourceMetadataForBookmark(
    bookmark,
    resourceType
  );

  return (
    <div className="w-full h-full  overflow-clip ">
      <button
        className="w-full h-full"
        onClick={() => {
          navigate({
            path: CarePilotRoute.BOOKMARK,
            params: {
              id: bookmark.id,
            },
          });
        }}>
        {(resourceType === GuidanceResourceType.YOUTUBE && (
          <div className="mt-2 w-full h-[100px] md:h-full rounded-md px-2 justify-center flex items-center flex-grow">
            <YouTubeButton
              url={resourceMetadata as string}
              onClick={() => {}}
            />
          </div>
        )) ||
          null}
        {(resourceType === GuidanceResourceType.AMAZON && (
          <div className="w-full overflow-hidden rounded-md">
            <AmazonButton
              className="w-full"
              name={
                bookmark.user_guidance_resource?.guidance_resource?.name || ""
              }
              metadata={resourceMetadata as AmazonMetadata}
              onClick={() => {}}
            />
          </div>
        )) ||
          null}
        {(resourceType === GuidanceResourceType.NEWS && (
          <div className="w-full h-full overflow-hidden rounded-md text-left flex justify-center items-center">
            <NewsComponent
              metadata={resourceMetadata as NewsMetadata}
              {...variantProps}
            />
          </div>
        )) ||
          null}
      </button>
    </div>
  );
}
