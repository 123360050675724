import { PhoneNumberFormInput } from "components/PhoneNumberInput/PhoneNumberInput";
import type { FieldValues, UseFormReturn } from "react-hook-form";
import { FormField } from "shared/ui/form";
import { Input } from "shared/ui/input";
import type { z } from "zod";
import type { PAFSchema } from "./pafFormUtil";

interface PrimaryCarePhysicianInputsProps<T extends FieldValues> {
  form: UseFormReturn<T>;
  isReadOnly: boolean;
}

export const PrimaryCarePhysicianInputs: React.FC<
  PrimaryCarePhysicianInputsProps<z.infer<typeof PAFSchema>>
> = ({ form, isReadOnly }) => (
  <div className="max-w-[615px] flex flex-col gap-4">
    <FormField
      control={form.control}
      disabled={isReadOnly}
      label="First Name"
      name="pcp_first_name"
      render={({ field }) => (
        <Input
          className="max-w-[400px]"
          {...field}
        />
      )}
    />

    <FormField
      control={form.control}
      disabled={isReadOnly}
      label="Last Name"
      name="pcp_last_name"
      render={({ field }) => (
        <Input
          className="max-w-[400px]"
          {...field}
        />
      )}
    />

    <FormField
      control={form.control}
      disabled={isReadOnly}
      label="Phone"
      name="pcp_phone"
      render={({ field }) => <PhoneNumberFormInput {...field} />}
    />
  </div>
);
