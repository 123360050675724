import { useState } from "react";

import TrashIcon from "assets/trash-new.svg?react";

import { useDeletePlanEntry } from "backend/resources/planEntry";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import ResponsivePopup from "components/ResponsivePopup/ResponsivePopup";
import { useWindowSize } from "hooks/useWindowSize";
import { useNavigateBack } from "lib/routing";

export function TaskDeleteModalButton({ entryId }: { entryId: string }) {
  const deletePlanEntry = useDeletePlanEntry().mutateAsync;
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useWindowSize();
  const navigateBack = useNavigateBack();
  const handleClick = async () => {
    if (isMobile) {
      await deletePlanEntry({ planEntryId: entryId });
      handleClose();
      navigateBack();
    } else {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handleDelete = () => {
    deletePlanEntry({ planEntryId: entryId });
    handleClose();
    navigateBack();
  };
  return (
    <div className="ml-auto">
      <button
        // aria-describedby={id}
        className={`flex items-center gap-2 text-sm rounded-sm ${
          isOpen && "bg-zinc-50"
        }`}
        onClick={handleClick}>
        <TrashIcon className="w-6 h-6" />
        Delete entry
      </button>
      <ResponsivePopup
        isOpen={isOpen}
        title="Are you sure you want to delete this entry?"
        onClose={handleClose}
        footerButtons={
          <div className="flex gap-3">
            <ButtonWithIcon
              onClick={handleClose}
              className="p-2"
              text="Cancel"
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              onClick={handleDelete}
              className="p-2"
              text="Yes, delete entry"
              icon={IconOption.CHECKMARK}
            />
          </div>
        }>
        <span>Are you sure you want to delete this entry?</span>
      </ResponsivePopup>
    </div>
  );
}
