/**
 * @fileoverview Sets up the Zustand user store.
 * This store holds information about the user and auth.
 */
import type {
  AuthError,
  AuthResponse,
  SignUpWithPasswordCredentials,
} from "@supabase/supabase-js";
import type { GoogleApiScope } from "backend/functions";
import type { UserWithOrganizations } from "backend/resources/user";
import { supabase } from "clients/supabaseClient";
import OneSignal from "react-onesignal";
import { create } from "zustand";
import isEqual from "lodash/isEqual";

type AuthData = {
  email: string;
  password: string;
};

type State = {
  user?: UserWithOrganizations;
  currentProvider?: string;
  googleProviderToken?: {
    token: string;
    updatedAt: string;
    status: "valid" | "expired";
    scopes: GoogleApiScope[];
  };
};

type Actions = {
  setUser: (user?: UserWithOrganizations) => void;
  setCurrentProvider: (currentProvider: string) => void;
  setGoogleProviderToken: (
    token: string,
    updatedAt: string,
    status: "valid" | "expired",
    scopes: GoogleApiScope[]
  ) => void;
  signIn: (authData: AuthData) => Promise<AuthResponse>;
  signUp: (
    signUpCredentials: SignUpWithPasswordCredentials
  ) => Promise<AuthResponse>;
  signOut: () => Promise<{
    error: AuthError | null;
  }>;
  reset: () => void;
};

const initialState: State = {
  user: undefined,
  currentProvider: undefined,
  googleProviderToken: undefined,
};

/**
 * Hook to access the user store
 */
export const useUserStore = create<State & Actions>()((set) => ({
  /**
   * state
   */
  ...initialState,

  /**
   * actions
   */
  setUser: (user?: UserWithOrganizations) =>
    set((state) => (isEqual(state.user, user) ? {} : { user })),

  setCurrentProvider: (currentProvider: string) => set({ currentProvider }),

  setGoogleProviderToken: (
    token: string,
    updatedAt: string,
    status: "valid" | "expired",
    scopes: GoogleApiScope[]
  ) => set({ googleProviderToken: { token, updatedAt, status, scopes } }),

  signIn: (authData: AuthData) => supabase.auth.signInWithPassword(authData),

  signOut: async () => {
    if (OneSignal.Notifications.isPushSupported()) {
      await OneSignal.logout();
    }
    return supabase.auth.signOut();
  },

  signUp: (signUpCredentials: SignUpWithPasswordCredentials) =>
    supabase.auth.signUp(signUpCredentials),

  reset: () => set(initialState),
}));

// You can now use this function outside of a React component
export const signOutDirectly = async () => {
  const { signOut } = useUserStore.getState();
  await signOut();
};

// You can now use this function outside of a React component
export const resetUserStoreDirectly = () => {
  const { reset } = useUserStore.getState();
  reset();
};

// You can now use this function outside of a React component
export const getUserIdDirectly = () => {
  const { user } = useUserStore.getState();
  return user?.id;
};
