import { zodResolver } from "@hookform/resolvers/zod";
import { useCreatePrivateConversationMutation } from "backend/functions";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import Who, { UserType } from "components/MyPlanPage/components/Who";
import { RadioButtonsGroup } from "components/RadioGroup";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import { useState } from "react";
import type { UseFormReturn } from "react-hook-form";
import { useForm } from "react-hook-form";
import { Form, FormField } from "shared/ui/form";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useDiscussionFilterStore } from "state/discussionsFilter/discussionsFilter";
import { z } from "zod";

enum ConversationCreationModalSubPage {
  TYPE_SELECTION = "Select Type",
  MEMBER_SELECTION = "Select Member",
}

enum NewConversationType {
  General = "General",
  Private = "Private",
}

const ConversationSchema = z
  .object({
    type: z.nativeEnum(NewConversationType),
    provider: z.string().uuid().optional(),
    orgMember: z.string().uuid().optional(),
  })
  .refine((data) => data.provider || data.orgMember, {
    message: "Either provider or member must be provided",
    path: ["provider", "orgMember"],
  });

const useConversationForm = (): UseFormReturn<
  z.infer<typeof ConversationSchema>
> =>
  useForm({
    resolver: zodResolver(ConversationSchema),
    mode: "onSubmit",
  });

export default function CreateConversationModal({
  isOpen,
  onClose: onCloseModal,
}: {
  isOpen: boolean;
  onClose: () => void;
}) {
  const navigate = useAppNavigate();
  const form = useConversationForm();
  const formValues = form.watch();

  const [page, setPage] = useState<ConversationCreationModalSubPage>(
    ConversationCreationModalSubPage.TYPE_SELECTION
  );

  const { carespace: carespaceFilter } = useDiscussionFilterStore();

  const createPrivateConversation =
    useCreatePrivateConversationMutation().mutateAsync;

  const isCarespaceSelected = !!carespaceFilter && carespaceFilter !== "All";

  function isNextButtonDisabled() {
    const pageFormValueMap = {
      [ConversationCreationModalSubPage.TYPE_SELECTION]: formValues.type,
      [ConversationCreationModalSubPage.MEMBER_SELECTION]: formValues.orgMember,
    };
    return !pageFormValueMap[page];
  }

  async function handleNextButtonClick() {
    if (page === ConversationCreationModalSubPage.MEMBER_SELECTION) {
      if (!formValues.orgMember || !carespaceFilter) return;
      const conversationId = await createPrivateConversation({
        user_id: formValues.orgMember,
        network_id: carespaceFilter,
      });
      if (conversationId) {
        navigate({
          path: CarePilotRoute.CONVERSATION,
          params: { conversation_id: conversationId },
        });
      }
      return;
    }

    switch (formValues.type) {
      case NewConversationType.General: {
        navigate({
          path: CarePilotRoute.GENERAL_DISCUSSION,
          queryParams: { network_id: carespaceFilter ?? null },
        });
        break;
      }
      case NewConversationType.Private: {
        setPage(ConversationCreationModalSubPage.MEMBER_SELECTION);
        break;
      }
      default: {
        break;
      }
    }
  }

  function onClose() {
    onCloseModal();
    setPage(ConversationCreationModalSubPage.TYPE_SELECTION);
  }

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title="Create New Discussion"
      footerButtons={
        isCarespaceSelected ? (
          <>
            <ButtonWithIcon
              text="Cancel"
              onClick={onClose}
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              disabled={isNextButtonDisabled()}
              text="Next"
              onClick={handleNextButtonClick}
              icon={IconOption.ARROW}
            />
          </>
        ) : (
          <ButtonWithIcon
            text="Close"
            onClick={onClose}
            icon={IconOption.CHECKMARK}
          />
        )
      }>
      <div>
        <Form
          form={form}
          id="create-conversation-form">
          {isCarespaceSelected ? (
            <>
              {page === ConversationCreationModalSubPage.TYPE_SELECTION && (
                <TypeSelection form={form} />
              )}
              {page === ConversationCreationModalSubPage.MEMBER_SELECTION && (
                <MemberSelection form={form} />
              )}
            </>
          ) : (
            <p className="w-full text-center">
              Please select a carespace first
            </p>
          )}
        </Form>
      </div>
    </ResponsiveModal>
  );

  interface FormProps {
    form: UseFormReturn<z.infer<typeof ConversationSchema>>;
  }

  function TypeSelection({ form }: FormProps) {
    return (
      <FormField
        control={form.control}
        name="type"
        label="Type"
        render={({ field }) => (
          <RadioButtonsGroup
            id={"type"}
            options={Object.values(NewConversationType).map((value) => ({
              value,
              label: value,
            }))}
            {...field}
            row={false}></RadioButtonsGroup>
        )}
      />
    );
  }

  function MemberSelection({ form }: FormProps) {
    return (
      <FormField
        control={form.control}
        name="orgMember"
        label="Select Member"
        render={({ field }) => (
          <Who
            isEditing
            selectedPlanEntryOwnerId={field.value}
            setPlanEntryOwner={field.onChange}
            networkId={carespaceFilter}
            userType={UserType.ORG_USERS_IN_NETWORK}
          />
        )}
      />
    );
  }
}
