import {
  createColumnHelper,
  type ColumnFiltersState,
} from "@tanstack/react-table";
import { format } from "date-fns";
import group from "lodash/groupBy";
import startCase from "lodash/startCase";
import { useMemo, useState } from "react";

import { CareCentralRoute, SharedRoute, useAppNavigate } from "lib/routing";
import { useUserStore } from "state/user";

import { Avatar } from "shared/ui/avatar";
import { Badge } from "shared/ui/badge";
import { PieChart } from "shared/ui/pie-chart";
import { DataTable } from "shared/ui/table";
import { Text } from "shared/ui/text";

import { TASK_STATUS_COLORS } from "../constants";
import { formatStatus, formatWho } from "../display-formatters";
import type { Task } from "../types";

const columnHelper = createColumnHelper<Task>();

export interface TaskGroupTableProps {
  tasks: Task[];
  groupBy?: keyof Task;
}

export default function TaskGroupTable({
  tasks,
  groupBy = "type",
}: TaskGroupTableProps) {
  const navigate = useAppNavigate();
  const authUser = useUserStore((state) => state.user);
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Care Strategies",
        enableColumnFilter: false,
      }),
      columnHelper.accessor("status", {
        header: "Status",
        cell: (ctx) => {
          const value = ctx.getValue();

          return (
            <Badge color={TASK_STATUS_COLORS[value]}>{startCase(value)}</Badge>
          );
        },
        meta: {
          columnFiltering: {
            filterLabel: "Status",
            formatOptionLabel: (value) => startCase(value),
          },
        },
      }),
      columnHelper.accessor("scheduled_date_time", {
        header: "Date",
        cell: (ctx) => {
          const value = ctx.getValue();

          return value ? format(value, "MM/dd/yyyy") : "N/A";
        },
        meta: {
          columnFiltering: {
            filterLabel: "Date",
            filterType: "date",
          },
        },
      }),
      columnHelper.accessor(
        (task) => `${task.user?.first_name} ${task.user?.last_name}`,
        {
          id: "who",
          header: "Who",
          cell: (ctx) => (
            <div className="flex flex-row items-center gap-2">
              <Avatar
                firstName={ctx.row.original.user?.first_name || ""}
                lastName={ctx.row.original.user?.last_name || ""}
                profileImage={ctx.row.original.user?.profile_image || ""}
              />
              <Text>{formatWho(ctx.row.original, authUser?.id)}</Text>
            </div>
          ),
          meta: {
            columnFiltering: {
              filterLabel: "Who",
            },
          },
        }
      ),
    ],
    [authUser?.id]
  );

  return (
    <DataTable
      columns={columns}
      data={tasks}
      onRowClick={(row) =>
        navigate({
          path: row.is_service_ticket
            ? SharedRoute.SERVICE_REQUEST_VIEW
            : CareCentralRoute.VIEW_TASK,
          params: {
            id: row.id,
          },
        })
      }
      columnFiltering={{
        columnFilters,
        setColumnFilters,
        renderContent: (filteredRows) => {
          const groups = group(filteredRows, (row) =>
            formatStatus(row.original)
          );

          return (
            <PieChart
              groups={Object.entries(groups).map(([label, tasks]) => ({
                label,
                count: tasks.length,
                color: tasks[0].original.is_overdue
                  ? "red"
                  : TASK_STATUS_COLORS[tasks[0].original.status],
              }))}
            />
          );
        },
      }}
      grouping={{
        groupBy,
      }}
    />
  );
}
