export enum PAFStatus {
  DRAFT = "DRAFT",
  READY = "READY",
}

export enum AssessmentType {
  INITIAL_ASSESSMENT = "Initial Assessment",
  REASSESSMENT = "Re-Assessment",
}

export enum InitialPatient {
  EXISTING_PATIENT = "Existing Patient",
  NEW_PATIENT = "New Patient",
}

export enum PTReferralSource {
  HEALTHCARE_PROVIDER = "Healthcare Provider",
  COMMUNITY_BASED_ORGANIZATION = "Community Based Organization",
  SELF_REFERRAL = "Self-Referral",
}

export enum ReassessmentReason {
  ANNUAL_REASSESSMENT = "Annual Re-Assessment",
  REASSESSMENT_DUE_TO_CHANGE_IN_SEVERITY = "Re-Assessment Due To Change In Severity Of Patient's Dementia",
  REASSESSMENT_DUE_TO_CHANGE_IN_CAREGIVER_STATUS = "Re-Assessment Due To Change In Caregiver Status",
}

export enum CaregiverStatus {
  NEW_PRIMARY_CAREGIVER = "New Primary Caregiver",
  LOSS_OF_CAREGIVER = "Loss Of Caregiver",
  PATIENT_CHANGE_IN_RESIDENCE = "Patient Change In Residence",
  OTHER = "Other",
}

export enum ResidenceType {
  PRIVATE_RESIDENCE = "Private Residence",
  ASSISTED_LIVING_FACILITY = "Assisted Living Facility",
  MEMORY_CARE_PROGRAM = "Memory Care Program",
}

export enum PhoneType {
  HOME = "Home",
  BUSINESS = "Business",
  MOBILE = "Mobile",
}

export enum DementiaStageAssessmentTool {
  FAST = "FAST",
  CDR = "CDR",
}

export enum HasPrimaryCareGiver {
  YES_MULTIPLE = "Yes (Multiple)",
  YES_ONE = "Yes (One)",
  NO = "No",
  UNDETERMINED = "Undetermined",
}

export enum Sex {
  MALE = "Male",
  FEMALE = "Female",
  UNKNOWN = "Unknown",
}

export enum Race {
  AMERICAN_INDIAN_OR_ALASKA_NATIVE = "American Indian Or Alaska Native",
  ASIAN = "Asian",
  BLACK_OR_AFRICAN_AMERICAN = "Black Or African American",
  NATIVE_HAWAIIAN_OR_OTHER_PACIFIC_ISLANDER = "Native Hawaiian Or Other Pacific Islander",
  WHITE = "White",
  ASKED_BUT_UNKNOWN = "Asked But Unknown",
  OTHER = "Other",
  UNKNOWN = "Unknown",
}

export enum Relationship {
  SPOUSE = "Spouse",
  DOMESTIC_PARTNER = "Domestic Partner",
  DAUGHTER = "Daughter",
  SON = "Son",
  SIBLING = "Sibling",
  OTHER_FAMILY_MEMBER = "Other Family Member",
  FRIEND = "Friend",
  OTHER_NON_FAMILY_MEMBER = "Other Non-Family Member",
}

export enum ClinicianAttestation {
  YES_DIAGNOSIS = "Yes, The Patient Meets The National Institute On Aging-Alzheimer's Association Diagnostic Guidelines For Dementia And/Or The DSM-5 Diagnostic Guidelines For Major Neurocognitive Disorder",
  YES_REPORT = "Yes, I Received A Written Report Of A Documented Dementia Diagnosis",
  NO = "No, I Cannot Attest To Either Statement",
}

export enum YesNo {
  YES = "Yes",
  NO = "No",
}
