import { create } from "zustand";
import { syncCarespace } from "state/taskFilter/taskFilter";

export enum ServiceFilterTypes {
  ORG = "Org",
  CARESPACE = "Carespace",
  PROVIDER = "Provider",
  SERVICE_TYPE = "Service Type",
  HIDE_INACTIVE = "Hide Inactive",
}

export type ServiceFilterState = {
  org: string | undefined;
  carespace: string | undefined;
  provider: string | undefined;
  serviceType: string | undefined;
  hideInactive: boolean;
  setOrg: (org: string | undefined) => void;
  setCarespace: (carespace: string | undefined) => void;
  setProvider: (provider: string | undefined) => void;
  setServiceType: (serviceType: string | undefined) => void;
  setHideInactive: (hideInactive: boolean | undefined) => void;
};

export const useLocalServiceFilterStore = create<ServiceFilterState>((set) => {
  return {
    org: undefined,
    carespace: undefined,
    provider: undefined,
    serviceType: undefined,
    hideInactive: false,
    setOrg: (org: string | undefined) => set(() => ({ org })),
    setCarespace: (carespace: string | undefined) => set(() => ({ carespace })),
    setProvider: (provider: string | undefined) => set(() => ({ provider })),
    setServiceType: (serviceType: string | undefined) =>
      set(() => ({ serviceType })),
    setHideInactive: (hideInactive: boolean | undefined) =>
      set(() => ({ hideInactive })),
  };
});

// This is a wrapper around the local service filter store that also syncs the carespace to the task filter store
export const useServiceFilterStore = () => {
  const localFilter = useLocalServiceFilterStore();

  const setCarespace = (carespace: string | undefined) => {
    syncCarespace(carespace);
  };

  return { ...localFilter, setCarespace };
};
