import VerticalDotsIcon from "assets/nav/vertical_dots_icon.png";
import { useUserAdlos } from "backend/resources/userAdlo";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { MobileMenuNavItems, NavItem } from "components/NavBar";
import { BottomSheet } from "components/Sheet";
import { LoadingSpinner } from "components/LoadingSpinner";
import { useProductAccess } from "hooks/product/product";
import { useRole } from "hooks/role/useRole";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSideNavStore } from "state/navItems";
import { NAV_ITEMS_CONFIG } from "state/navItems/navItemModels";

export default function BottomNavBar() {
  const navigate = useNavigate();
  const configureNavContext = useSideNavStore(
    (state) => state.configureNavContext
  );
  const items = useSideNavStore((state) => state.items);
  const extendedItems = useSideNavStore((state) => state.extendedItems);

  const { role, isLoading, isSuperSuperUser, hasAdminAccess } = useRole();
  const { data: productAccess } = useProductAccess();
  const [isBottomSheetOpen, setIsBottomSheetOpen] = useState(false);

  useEffect(() => {
    // Only configure nav context when we have all required data
    if (isLoading || !role) return;

    configureNavContext({
      role,
      isMobile: true,
      product: productAccess,
      isSuperSuperUser: isSuperSuperUser,
      hasAdminAccess: hasAdminAccess,
    });
  }, [
    role,
    productAccess,
    isSuperSuperUser,
    hasAdminAccess,
    isLoading,
    configureNavContext,
  ]);

  // Show loading state while data is being fetched
  if (isLoading) {
    return (
      <div className="w-full flex justify-center absolute bottom-0">
        <div className="w-[100%] px-6 items-center h-8 py-8 bg-gray-50 border z-10 flex justify-center">
          <LoadingSpinner className="w-6 h-6" />
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex justify-center absolute bottom-0">
      <div className="w-[100%] px-6 items-center h-8 py-8 bg-gray-50 border z-10 flex justify-around overflow-clip">
        {items.map((item, index) => (
          <button
            key={`button-${item.name}-${index}`}
            onClick={(event) => {
              event.preventDefault();
              navigate(NAV_ITEMS_CONFIG[item.name].route);
            }}
            className={`flex flex-grow ${
              index < items.length - 1 ? "" : ""
            } border-gray-300 justify-center`}>
            <NavItem
              key={item.name}
              name={""}
              icon={item.icon}
              pathname={NAV_ITEMS_CONFIG[item.name].route}
            />
          </button>
        ))}
        {extendedItems.length > 0 ? (
          <button
            onClick={(event) => {
              event.preventDefault();
              setIsBottomSheetOpen(true);
            }}
            className={`flex flex-grow border-gray-300 justify-center`}>
            <img
              src={VerticalDotsIcon}
              className="w-5 h-5"
            />
          </button>
        ) : null}
      </div>
      <BottomSheet
        isOpen={isBottomSheetOpen}
        onClose={() => setIsBottomSheetOpen(false)}>
        <MobileMenuNavItems setIsOpen={setIsBottomSheetOpen} />
      </BottomSheet>
    </div>
  );
}
