import type { GoalTemplate } from "backend/resources/goalTemplate/goalTemplate";
import {
  useDeleteGoalTemplateMutation,
  useGoalTemplateQuery,
} from "backend/resources/goalTemplate/goalTemplate";
import { type ShareableRecommendationType } from "backend/resources/shareableRecommendation/shareableRecommendation";
import {
  useDeleteUserCreatedRecommendationMutation,
  useUserCreatedRecommendationsQuery,
} from "backend/resources/userCreatedRecommendations/userCreatedRecommendations";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { CloseButton } from "components/CloseButton/CloseButton";
import GoalTemplateCard from "components/GoalPage/GoalTemplate/GoalTemplateCard";
import { ShareableRecommendation } from "components/GuidancePage/Recommendations/ShareableRecommendationPage/ShareableRecommendation/ShareableRecommendation";
import { PageContainer } from "components/PageContainer";
import { CareCentralRoute, useAppNavigate } from "lib/routing";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import ActionButtons from "shared/ui/action-buttons";
import { ResponsiveModal } from "shared/ui/responsive-modal";

export enum Section {
  GOALS = "Goals",
  RECOMMENDATIONS = "Recommendations",
}

export default function TemplatesPage() {
  const navigate = useAppNavigate();
  const editTemplatesRef = useRef<null | EditTemplatesRef>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState<Section>(Section.GOALS);

  const { goalTemplates } = useGoalTemplateQuery();
  const { data: recommendationTemplates } =
    useUserCreatedRecommendationsQuery();

  return (
    <div className="gap-2 flex flex-col pb-10 w-full">
      <p className="font-medium">Goals</p>
      <div className="flex gap-5">
        {/* Action Buttons */}
        <ActionButtons title={"Template Actions"}>
          <>
            <ButtonWithIcon
              onClick={() => {
                setIsEditing(true);
                setTitle(Section.GOALS);
              }}
              className="text-left"
              size="small"
              text="Remove Goals"
              icon={IconOption.TRASH}
              disabled={goalTemplates?.length === 0}
            />
            <ButtonWithIcon
              onClick={() => {
                navigate({
                  path: CareCentralRoute.GOAL_TEMPLATE_EDIT,
                });
              }}
              className="text-left"
              size="small"
              text="Add New Goal"
              icon={IconOption.PLUS}
            />
          </>
        </ActionButtons>
      </div>
      <div className="mt-8">
        {/* Goals tab */}
        {goalTemplates && goalTemplates?.length > 0 ? (
          <div className="flex flex-wrap gap-4 justify-around md:justify-start">
            {goalTemplates?.map((goalTemplate) => (
              <GoalTemplateCard
                goalTemplate={goalTemplate}
                key={goalTemplate.id}
              />
            ))}
          </div>
        ) : (
          <p className="mt-4">
            There are no goal templates. Click add to create one, or go to an
            existing coaching plan to copy one from there
          </p>
        )}
      </div>
      <br />
      <p className="font-medium">Recommendations</p>
      <div className="flex gap-5">
        {/* Action Buttons */}
        <ActionButtons title={"Template Actions"}>
          <>
            <ButtonWithIcon
              onClick={() => {
                setIsEditing(true);
                setTitle(Section.RECOMMENDATIONS);
              }}
              className="text-left"
              size="small"
              text="Remove Recommendations"
              icon={IconOption.TRASH}
              disabled={recommendationTemplates?.length === 0}
            />
            <ButtonWithIcon
              onClick={() => {
                navigate({
                  path: CareCentralRoute.SHAREABLE_RECOMMENDATION_ADD,
                });
              }}
              className="text-left"
              size="small"
              text="Add New Recommendation"
              icon={IconOption.PLUS}
            />
          </>
        </ActionButtons>
      </div>
      <div className="mt-8">
        {/* Recs Tab */}
        {recommendationTemplates && recommendationTemplates?.length > 0 ? (
          <div className="flex flex-wrap gap-4 justify-around md:justify-start">
            {recommendationTemplates?.map((recommendationTemplate) => (
              <ShareableRecommendation
                recommendation={recommendationTemplate}
                key={recommendationTemplate.id}
              />
            ))}
          </div>
        ) : (
          <p className="mt-4">
            There are no recommendation templates. Click add to create one.
          </p>
        )}
      </div>
      <ResponsiveModal
        isOpen={isEditing}
        onClose={() => setIsEditing(false)}
        title={`Remove ${title}`}
        footerButtons={
          <>
            <ButtonWithIcon
              onClick={() => setIsEditing(false)}
              text={"Cancel"}
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              onClick={() => {
                editTemplatesRef?.current?.saveTemplates();
              }}
              text={"Save"}
              icon={IconOption.CHECKMARK}
            />
          </>
        }>
        <EditTemplatesComponent
          close={() => setIsEditing(false)}
          isOpen={isEditing}
          currentTab={title}
          ref={editTemplatesRef}
        />
      </ResponsiveModal>
    </div>
  );
}

interface EditTemplatesRef {
  saveTemplates: () => void;
}
interface Props {
  close: any;
  isOpen: boolean;
  currentTab: Section;
}
const EditTemplatesComponent = forwardRef((props: Props, ref: any) => {
  const deleteGoalTemplate = useDeleteGoalTemplateMutation().mutateAsync;
  const deleteRecommendation =
    useDeleteUserCreatedRecommendationMutation().mutateAsync;
  const { goalTemplates } = useGoalTemplateQuery();
  const { data: recommendationTemplates } =
    useUserCreatedRecommendationsQuery();
  const [goalsToDelete, setGoalsToDelete] = useState<GoalTemplate[]>([]);
  const [recsToDelete, setRecsToDelete] = useState<
    ShareableRecommendationType[]
  >([]);

  useImperativeHandle(ref, () => ({
    saveTemplates,
  }));

  const saveTemplates = () => {
    if (goalsToDelete.length > 0)
      for (const goal of goalsToDelete) {
        deleteGoalTemplate(goal.id);
      }
    if (recsToDelete.length > 0)
      for (const rec of recsToDelete) {
        deleteRecommendation(rec.id);
      }
    props.close();
  };

  return (
    <PageContainer>
      <div className="h-[50vh]">
        {props.currentTab === Section.GOALS ? (
          <div className="flex flex-wrap gap-4 justify-around md:justify-start">
            {goalTemplates
              ?.filter((goal) => !goalsToDelete.includes(goal))
              .map((goalTemplate) => (
                <div
                  className="relative w-min"
                  key={goalTemplate.id}>
                  <button
                    type="button"
                    className="absolute top-2 right-1 hover:scale-[1.15] transition-transform z-[2]"
                    onClick={(event) => {
                      event.preventDefault();
                      setGoalsToDelete([...goalsToDelete, goalTemplate]);
                    }}>
                    <CloseButton />
                  </button>
                  <GoalTemplateCard goalTemplate={goalTemplate} />
                </div>
              ))}
          </div>
        ) : null}
        {props.currentTab === Section.RECOMMENDATIONS ? (
          <div className="flex flex-wrap gap-4 justify-around md:justify-start">
            {recommendationTemplates
              ?.filter((rec) => !recsToDelete.includes(rec))
              .map((recommendationTemplate) => (
                <div
                  className="relative w-min"
                  key={recommendationTemplate.id}>
                  <button
                    type="button"
                    className="absolute top-2 right-1 hover:scale-[1.15] transition-transform z-[2]"
                    onClick={(event) => {
                      event.preventDefault();
                      setRecsToDelete([
                        ...recsToDelete,
                        recommendationTemplate,
                      ]);
                    }}>
                    <CloseButton />
                  </button>
                  <ShareableRecommendation
                    recommendation={recommendationTemplate}
                  />
                </div>
              ))}
          </div>
        ) : null}
      </div>
    </PageContainer>
  );
});
