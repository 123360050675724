import {
  createColumnHelper,
  type ColumnFiltersState,
} from "@tanstack/react-table";
import { format } from "date-fns";
import capitalize from "lodash/capitalize";
import { useMemo, useState } from "react";

import { downloadPafFile } from "backend/functions/generatePafFile/generatePafFile";
import { useOrgs } from "backend/resources/orgRole";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { buildPAFFileName } from "components/CarespacePage/Tabs/CarespaceMainTab/carespaceMainTabUtil";
import { AssignCareTeamModal } from "components/HomePage/CareCentralHome/Carespaces/AssignCareTeamModal";
import { CareCentralRoute, useAppNavigate } from "lib/routing";

import { LoadingSpinner } from "components/LoadingSpinner";

import { CarespaceCreationWizard } from "shared/forms/CarespaceCreationWizard/CarespaceCreationWizard";
import { useCarespaceCreationWizard } from "shared/forms/CarespaceCreationWizard/useCarespaceCreationWizard";
import { Badge } from "shared/ui/badge";
import { DataTable } from "shared/ui/table";
import { Text } from "shared/ui/text";
import { Title } from "shared/ui/title";

import { Dyad, GuideStatus, Sex } from "../constants";
import { useFetchMany } from "../queries/hooks";
import type { Carespace } from "../types";

const columnHelper = createColumnHelper<Carespace>();

export default function CarespaceList() {
  const { data: carespaces = [], isLoading, isError } = useFetchMany();
  const reset = useCarespaceCreationWizard((state) => state.reset);
  const navigate = useAppNavigate();

  const [isCreatePopoverOpen, setIsCreatePopoverOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState({});
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [isAssignModalOpen, setIsAssignModalOpen] = useState(false);
  const { isSuperSuperUser, allOrgIdentities } = useOrgs();
  const orgRole = allOrgIdentities?.find(
    (role) =>
      role.organization_id ===
      columnFilters.find((filter) => filter.id === "organization_id")?.value
  );

  const orgs = useMemo(
    () => carespaces.map((carespace) => carespace.organization),
    [carespaces]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: "Carespace",
        meta: {
          columnFiltering: {
            filterLabel: "Carespace",
          },
        },
      }),
      columnHelper.accessor("birthday", {
        header: "DOB",
        cell: (ctx) => {
          const value = ctx.getValue();

          if (!value) {
            return "N/A";
          }

          return format(value, "MM/dd/yyyy");
        },
        meta: {
          columnFiltering: {
            filterLabel: "DOB",
            filterType: "date",
          },
        },
      }),
      columnHelper.accessor("sex", {
        header: "Sex",
        cell: (ctx) => {
          const value = ctx.getValue();

          return (
            <Badge
              color={
                value === Sex.MALE
                  ? "blue"
                  : value === Sex.FEMALE
                  ? "red"
                  : "gray"
              }>
              {capitalize(value)}
            </Badge>
          );
        },
        meta: {
          columnFiltering: {
            filterLabel: "Sex",
            formatOptionLabel: (value) => capitalize(value),
          },
        },
      }),
      columnHelper.accessor("dyad", {
        header: "Dyad",
        cell: (ctx) => {
          const value = ctx.getValue();

          return (
            <Badge
              color={
                value === Dyad.HIGH
                  ? "red"
                  : value === Dyad.LOW
                  ? "green"
                  : value === Dyad.MODERATE
                  ? "orange"
                  : "gray"
              }>
              {value}
            </Badge>
          );
        },
        meta: {
          columnFiltering: {
            filterLabel: "Dyad",
          },
        },
      }),
      columnHelper.accessor("organization.id", {
        id: "organization_id",
        header: "Org",
        cell: (ctx) => (
          <Badge color="purple">{ctx.row.original.organization?.name}</Badge>
        ),
        meta: {
          columnFiltering: {
            filterLabel: "Org",
            formatOptionLabel: (orgId) =>
              orgs.find((org) => org?.id === orgId)?.name || "",
          },
        },
      }),
      columnHelper.accessor("guide_status", {
        header: "Status",
        cell: (ctx) => {
          const value = ctx.getValue();

          return (
            <Badge
              color={
                value === GuideStatus.ALIGNED
                  ? "green"
                  : value === GuideStatus.SUBMITTED
                  ? "purple"
                  : value === GuideStatus.READY
                  ? "blue"
                  : value === GuideStatus.DRAFT
                  ? "orange"
                  : "gray"
              }>
              {value === GuideStatus.NA ? value : capitalize(value)}
            </Badge>
          );
        },
        meta: {
          columnFiltering: {
            filterLabel: "Status",
            formatOptionLabel: (value) =>
              value === GuideStatus.NA ? value : capitalize(value),
          },
        },
      }),
      columnHelper.accessor((row) => row.care_team.doctor?.name || "N/A", {
        header: "Doctor",
        meta: {
          columnFiltering: {
            filterLabel: "Doctor",
          },
        },
      }),
      columnHelper.accessor(
        (row) => row.care_team["primary caregiver"]?.name || "Pending",
        {
          header: "PCG",
          meta: {
            columnFiltering: {
              filterLabel: "PCG",
            },
          },
        }
      ),
      columnHelper.accessor(
        (row) => row.care_team["professional caregiver"]?.name || "TBD",
        {
          header: "CN",
          meta: {
            columnFiltering: {
              filterLabel: "CN",
            },
          },
        }
      ),
      columnHelper.accessor(
        (row) => row.primary_care_physician?.name || "TBD",
        {
          header: "PCP",
          meta: {
            columnFiltering: {
              filterLabel: "PCP",
            },
          },
        }
      ),
    ],
    [orgs]
  );

  if (isLoading) {
    return (
      <div className="w-full h-full flex items-center justify-center">
        <LoadingSpinner className="w-6 h-6" />
      </div>
    );
  }

  if (isError) {
    return (
      <div className="p-6">
        <Text>Sorry, something went wrong. Please try again.</Text>
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 h-full p-6">
      <Title>Carespaces</Title>
      <div className="flex items-center">
        {(orgRole?.is_superuser || isSuperSuperUser) && (
          <>
            <ButtonWithIcon
              text="Add Carespace"
              icon={IconOption.PLUS}
              disabled={!orgRole}
              onClick={() => {
                reset();
                setIsCreatePopoverOpen(true);
              }}
            />
            <div className="ml-4">
              <ButtonWithIcon
                text="Change Care Team"
                icon={IconOption.EDIT}
                disabled={Object.keys(selectedRows).length === 0}
                onClick={() => setIsAssignModalOpen(true)}
              />
            </div>
            <ButtonWithIcon
              disabled={Object.keys(selectedRows).length === 0}
              onClick={() => {
                const fileName = buildPAFFileName(null);
                downloadPafFile(Object.keys(selectedRows), fileName);
              }}
              className="ml-4"
              text="Download PAFs"
              icon={IconOption.DOWNLOAD}
            />
          </>
        )}
      </div>

      <DataTable
        columns={columns}
        data={carespaces}
        onRowClick={(row) =>
          navigate({
            path: CareCentralRoute.CARESPACE,
            params: {
              carespaceId: row.network.id,
            },
          })
        }
        rowSelection={{
          selectedRows,
          setSelectedRows,
          getRowId: (row) => row.network.id,
        }}
        columnFiltering={{
          columnFilters,
          setColumnFilters,
        }}
      />

      <AssignCareTeamModal
        isOpen={isAssignModalOpen}
        onClose={() => setIsAssignModalOpen(false)}
        selectedCarespaces={Object.keys(selectedRows)}
      />

      {isCreatePopoverOpen && (
        <CarespaceCreationWizard
          onClose={() => {
            reset();
            setIsCreatePopoverOpen(false);
          }}
        />
      )}
    </div>
  );
}
