import { TaskStatus, TaskWithGuideInfo } from "backend/resources/planEntry";
import { getTaskTypeForTasksByCarespaceTable } from "components/Tables/TasksByCarespaceTable/TaskTableTypes";
import {
  FilterConfig,
  TaskFilterTypes,
} from "components/TaskNavigatorPage/TaskFilters";
import {
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  isThisMonth,
  isThisQuarter,
  isThisWeek,
  isThisYear,
  isToday,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
} from "date-fns";
import { useMemo } from "react";
import {
  TaskDueDateRange,
  useTaskFilterStore,
} from "state/taskFilter/taskFilter";
import { isMatch } from "utils";
export function isTaskOverdue(dueTime: string | null, status: TaskStatus) {
  return (
    !!dueTime &&
    status !== TaskStatus.Done &&
    status !== TaskStatus.NotApplicable &&
    new Date(dueTime).getTime() < startOfDay(new Date()).getTime()
  );
}

export enum GuideEventTypes {
  Calls = "Call",
}

export enum GuideCategories {
  ComprehensiveAssessment = "Comprehensive Assessment",
  CarePlan = "Care Plan",
  // Access247 = "24/7 Access",
  OngoingMonitoringAndSupport = "Ongoing Monitoring and Support",
  CareCoordinationTransitionalCareMgmt = "Care Coordination",
  ReferralAndCoordinationOfServicesAndSupports = "Referral and Coordination",
  MedicationManagementReconciliation = "Medication Management and Reconciliation",
  CaregiverEducationAndSupport = "Caregiver Education and Support",
  Respite = "Respite",
  Onboarding = "Onboarding",
}

export const GuideCategoryColorStyle: Record<string, string> = (() => {
  const colors = [
    "#468C90",
    "#7BAACC",
    "#7D84BE",
    "#5DD4EE",
    "#6D175A",
    "#AC7F4C",
    "#AEDFB6",
    "#8CB4D6",
    "#9F67C1",
  ];

  return Object.values(GuideCategories).reduce((acc, title, index) => {
    acc[title] = colors[index % colors.length];
    return acc;
  }, {} as Record<string, string>);
})();

export function isDueInRange(
  due: string | null,
  range: TaskDueDateRange | undefined
): boolean {
  if (!range || !due) return true;
  const dueDate = new Date(due);
  switch (range) {
    case TaskDueDateRange.All:
      return true;
    case TaskDueDateRange.Today:
      return isToday(dueDate);
    case TaskDueDateRange.ThisWeek:
      return isThisWeek(dueDate);
    case TaskDueDateRange.ThisMonth:
      return isThisMonth(dueDate);
    case TaskDueDateRange.ThisQuarter:
      return isThisQuarter(dueDate);
    case TaskDueDateRange.ThisYear:
      return isThisYear(dueDate);
    default:
      return false;
  }
}

export function getFirstAndLastDayInDueDateRange(
  dueDate: TaskDueDateRange | undefined
): [Date, Date] {
  if (!dueDate) return [new Date(), new Date()];
  const now = new Date();
  switch (dueDate) {
    case TaskDueDateRange.Today:
      return [startOfDay(Date.now()), endOfDay(Date.now())];
    case TaskDueDateRange.ThisWeek:
      return [startOfWeek(Date.now()), endOfWeek(Date.now())];
    case TaskDueDateRange.ThisMonth:
      return [startOfMonth(Date.now()), endOfMonth(Date.now())];
    case TaskDueDateRange.ThisQuarter:
      return [startOfQuarter(Date.now()), endOfQuarter(Date.now())];
    case TaskDueDateRange.ThisYear:
      return [startOfYear(Date.now()), endOfYear(Date.now())];
    default:
      return [now, now];
  }
}

export const useFilteredData = ({
  data,
  config,
}: {
  data?: TaskWithGuideInfo[];
  config?: FilterConfig;
}) => {
  const filters = useTaskFilterStore();

  return useMemo(() => {
    if (!config) return data;
    return data?.filter((row) => {
      if (config.overrideFilter) {
        return config.overrideFilter(row);
      }

      const isNetworkMatch = config[TaskFilterTypes.CARESPACE]
        ? isMatch(filters.carespace, row.network_id)
        : true;
      const isCategoryMatch = config[TaskFilterTypes.CATEGORY]
        ? isMatch(
            filters.category,
            (row as TaskWithGuideInfo)?.guide_task?.guide_category?.title
          )
        : true;
      const isTaskTypeMatch = config[TaskFilterTypes.TASK_TYPE]
        ? isMatch(
            filters.taskType,
            getTaskTypeForTasksByCarespaceTable(row as TaskWithGuideInfo)
          )
        : true;
      const isWhoMatch = config[TaskFilterTypes.WHO]
        ? isMatch(filters.who, row.user_id)
        : true;
      const isDueInRangeMatch = config[TaskFilterTypes.DUE_DATE]
        ? isDueInRange(row.scheduled_date_time, filters.dueDate)
        : true;
      const isStatusMatch = config[TaskFilterTypes.STATUS]
        ? isMatch(filters.status, row.status)
        : true;
      const isOverdueMatch = config[TaskFilterTypes.STATUS]
        ? filters.status === "Overdue" &&
          isTaskOverdue(row.scheduled_date_time, row.status as TaskStatus)
        : true;
      const isHideDoneMatch = config[TaskFilterTypes.HIDE_DONE]
        ? !(
            filters.hideDone &&
            row.status === TaskStatus.Done &&
            filters.status == "All"
          )
        : true;
      const isGuideTaskMatch = config[TaskFilterTypes.GUIDE_TASK]
        ? isMatch(filters.guideTask, (row as TaskWithGuideInfo)?.guide_task_id)
        : true;
      const isCustomFilterMatch = config.customAdditionalFilter
        ? config.customAdditionalFilter(row)
        : true;

      return (
        isNetworkMatch &&
        isCategoryMatch &&
        isTaskTypeMatch &&
        isWhoMatch &&
        isDueInRangeMatch &&
        (isStatusMatch || isOverdueMatch) &&
        isHideDoneMatch &&
        isCustomFilterMatch &&
        isGuideTaskMatch
      );
    });
  }, [data, config]);
};

export function generateTitleHeader(
  prefix: string,
  tasks: TaskWithGuideInfo[]
) {
  const totalTasks = tasks.length;
  const completedTasks = tasks.filter(
    (task) => task.status === TaskStatus.Done
  ).length;
  const completionPercentage =
    totalTasks > 0 ? Math.round((completedTasks / totalTasks) * 100) : 0;
  return `${prefix} (${completionPercentage}% complete)`;
}
