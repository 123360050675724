import { useMemo } from "react";

import { DiscussionMessage } from "backend/resources/chatGptMessage";
import { useDiscussionFilterStore } from "state/discussionsFilter/discussionsFilter";
import { isMatch } from "utils";
import {
  DiscussionFilterTypes,
  DiscussionsFilterConfig,
} from "components/DiscussionComponent/components/DiscussionsFilter";

export const useDiscussionsFilteredData = ({
  data,
  config,
}: {
  data?: DiscussionMessage[];
  config: DiscussionsFilterConfig;
}) => {
  const filters = useDiscussionFilterStore();

  return useMemo(() => {
    return data?.filter((row) => {
      const isDiscussionTypeMatch = config[DiscussionFilterTypes.TYPE]
        ? isMatch(filters.type, row.type)
        : true;

      const isDiscussionOrganizationMatch = config[DiscussionFilterTypes.ORGANIZATION]
        ? isMatch(filters.org, row.organization_id)
        : true;


      const isDiscussionCarespaceMatch = config[DiscussionFilterTypes.CARESPACE]
        ? isMatch(filters.carespace, row.network_id)
        : true;
      return isDiscussionTypeMatch && isDiscussionCarespaceMatch && isDiscussionOrganizationMatch;
    });
  }, [data, config, filters.org, filters.carespace, filters.type]);
};
