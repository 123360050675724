import ModalInfo from "assets/modal-info.svg?react";
import TemplateButtonClickedIcon from "assets/template-button-clicked.svg?react";
import TemplateButtonUnclickedIcon from "assets/template-button-unclicked.svg?react";
import WarningIcon from "assets/warning.svg?react";
import {
  useUpdateGoalMutation,
  type GoalWithStatus,
} from "backend/resources/goal/goal";
import { useUserRecommendationsForGoal } from "backend/resources/goal/goalUserRecommendationStatus";
import {
  useDeleteGoalTemplateMutation,
  useSaveGoalTemplateMutation,
} from "backend/resources/goalTemplate/goalTemplate";
import { useUserAdloById } from "backend/resources/userAdlo";
import type { VariantProps } from "class-variance-authority";
import { AdloInitials } from "components/AdloComponent/AdloInitials";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import type { cardStyles } from "components/Card/Card";
import { Card } from "components/Card/Card";
import { DefaultCheckbox } from "components/Checkbox";
import FooterButtons from "components/FooterButtons/FooterButtons";
import { default as GoalRating } from "components/GoalPage/Rating";
import { TimeFrame } from "components/GoalPage/utils";
import ResponsivePopup from "components/ResponsivePopup/ResponsivePopup";
import { CraniometrixProduct, useProductAccess } from "hooks/product/product";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import { useState } from "react";
import { useActiveOrganizationId } from "state/organization/organization";
import { useUserStore } from "state/user";

interface Props extends VariantProps<typeof cardStyles> {
  goal: GoalWithStatus;
}

export default function GoalCard({ goal, ...variantProps }: Props) {
  const navigate = useAppNavigate();
  const { data: productAccess } = useProductAccess();
  const { data: userRecommendationsInTemplate } = useUserRecommendationsForGoal(
    goal.id
  );
  const updateGoal = useUpdateGoalMutation().mutateAsync;
  const deleteGoalTemplate = useDeleteGoalTemplateMutation().mutateAsync;
  const saveGoalTemplate = useSaveGoalTemplateMutation(undefined).mutateAsync;
  const authUser = useUserStore((state) => state.user);

  const activeOrganizationId = useActiveOrganizationId();
  const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);
  const [showDeletionPopup, setShowDeletionPopup] = useState(false);
  const [showDeletionConfirmationPopup, setShowDeletionConfirmationPopup] =
    useState(false);
  const { data: userAdlo } = useUserAdloById(goal.adlo_id);
  // Handler

  async function deleteTemplateAssociatedWithGoal() {
    const { progress, rating, status, ...goalWithoutStatus } = goal;

    if (goalWithoutStatus.goal_template_id) {
      await updateGoal({
        ...goalWithoutStatus,
        goal_template_id: null,
      });

      await deleteGoalTemplate(goalWithoutStatus.goal_template_id);
    }
  }
  return (
    <>
      <ResponsivePopup
        isOpen={showDeletionConfirmationPopup}
        onClose={() => setShowDeletionConfirmationPopup(false)}>
        <div className="w-full flex flex-col items-center gap-4 pb-6">
          <ModalInfo className="w-8" />

          <label className="mb-4">Goal removed from your Templates</label>
          <ButtonWithIcon
            onClick={() => setShowDeletionConfirmationPopup(false)}
            text={"OK"}
            size="small"
            icon={IconOption.CHECKMARK}
          />
        </div>
      </ResponsivePopup>
      <ResponsivePopup
        isOpen={showConfirmationPopup}
        onClose={() => setShowConfirmationPopup(false)}
        footerButtons={
          <ButtonWithIcon
            onClick={() => setShowConfirmationPopup(false)}
            text={"OK"}
            size="small"
            icon={IconOption.CHECKMARK}
          />
        }>
        <div className="w-full flex flex-col items-center gap-4 text-center">
          <label className="text-lg">Goal added to your Templates</label>
        </div>
      </ResponsivePopup>
      <ResponsivePopup
        isOpen={showDeletionPopup}
        onClose={() => setShowDeletionPopup(false)}>
        <div className="w-full flex flex-col items-center gap-2 px-4 pb-20 md:pb-2 text-center">
          <WarningIcon className="w-8" />
          <label className="mt-5">
            This will delete the goal from your Templates, including any edits
            you made to it. Are you sure?
          </label>
          <FooterButtons>
            <ButtonWithIcon
              size={"small"}
              onClick={() => setShowDeletionPopup(false)}
              text={"Cancel"}
              icon={IconOption.CANCEL}
            />
            <ButtonWithIcon
              size={"small"}
              onClick={async () => {
                await deleteTemplateAssociatedWithGoal();
                setShowDeletionPopup(false);
                setShowDeletionConfirmationPopup(true);
              }}
              text={"Yes, Delete it"}
              icon={IconOption.CHECKMARK}
            />
          </FooterButtons>
        </div>
      </ResponsivePopup>
      <button
        onClick={(event) => {
          event.stopPropagation();
          navigate({
            path: CarePilotRoute.GOAL_ID,
            params: {
              id: goal.id,
            },
          });
        }}>
        <Card
          {...variantProps}
          color="lightBlue">
          {goal.progress === 100 ? (
            <div className="absolute z-[1] -top-2 -right-2 rounded-full overflow-clip">
              <DefaultCheckbox checked={true} />
            </div>
          ) : null}
          <div
            className={`select-text flex flex-col gap-1 h-full ${
              variantProps.size !== "thumbnail"
                ? "justify-around"
                : "justify-center"
            } px-2`}>
            <div className="w-full flex items-center break-word overflow-wrap px-1">
              <label
                className={`text-lg ${
                  variantProps.size !== "thumbnail"
                    ? "line-clamp-4"
                    : "line-clamp-3"
                } w-full break-word overflow-wrap`}>
                {goal.title}
              </label>
            </div>
            <div>
              {variantProps.size !== "thumbnail" ? (
                <p className="text-sm font-extralight line-clamp-[2]">
                  {goal.description.replace('"', "").replace('"', "")}
                </p>
              ) : null}
            </div>
          </div>

          {variantProps.size !== "thumbnail" ? (
            <div className="flex justify-between gap-5">
              <div className="w-full">
                <div className="px-2 flex w-full items-center justify-between">
                  <label className="text-xs py-2 pb-1">
                    Time frame: {TimeFrame.getLabelFromValue(goal?.timeline)}
                  </label>
                  <label className="text-xs py-2 pb-1">
                    Recs: {userRecommendationsInTemplate?.length ?? 0}
                  </label>
                </div>
                <div className="px-2 flex w-full items-center pb-3 justify-between -mt-1">
                  <label className="text-xs py-2 pb-1">
                    Progress: {`${goal.progress}%`}
                  </label>
                  <label className="text-xs py-2 pb-1 flex gap-2 items-center">
                    Rating: {<GoalRating rating={goal.rating} />}
                  </label>
                </div>
              </div>
              {productAccess === CraniometrixProduct.CARE_CENTRAL ? (
                <button
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={async (event) => {
                    event.stopPropagation();
                    const { progress, rating, status, ...goalWithoutStatus } =
                      goal;

                    if (goal.goal_template_id) {
                      setShowDeletionPopup(true);
                    } else {
                      const shareableRecommendationsIds =
                        userRecommendationsInTemplate?.map(
                          (userRec) => userRec.recommendation.id
                        );
                      if (
                        activeOrganizationId &&
                        authUser &&
                        shareableRecommendationsIds
                      ) {
                        const newGoalTemplate = await saveGoalTemplate({
                          goalTemplate: {
                            title: goal.title,
                            description: goal.description,
                            timeline: goal.timeline,
                            organization_id: activeOrganizationId,
                            user_id: authUser?.id,
                          },
                          recommendations: userRecommendationsInTemplate ?? [],
                        });

                        const res = await updateGoal({
                          ...goalWithoutStatus,
                          goal_template_id: newGoalTemplate?.id,
                        });
                      }
                      setShowConfirmationPopup(true);
                    }
                  }}>
                  {goal.goal_template_id ? (
                    <TemplateButtonClickedIcon />
                  ) : (
                    <TemplateButtonUnclickedIcon />
                  )}
                </button>
              ) : null}
            </div>
          ) : (
            userAdlo && (
              <div className="flex w-full justify-between items-center border-t-[1px] py-2 border-gray-400 h-min pb-0">
                <AdloInitials
                  userAdlo={userAdlo}
                  size={"small"}
                />
                <GoalRating
                  rating={goal?.rating ?? null}
                  size={"large"}
                />
              </div>
            )
          )}
        </Card>
      </button>
    </>
  );
}
