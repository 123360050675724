import LocationOnIcon from "@mui/icons-material/LocationOn";
import type { AutocompleteChangeReason } from "@mui/material";
import {
  Autocomplete,
  Box,
  Grid,
  TextField,
  Typography,
  debounce,
} from "@mui/material";
import parse from "autosuggest-highlight/parse";
import type { VariantProps } from "class-variance-authority";
import { cva } from "class-variance-authority";
import { useWindowSize } from "hooks/useWindowSize";
import * as React from "react";

const autocompleteService = { current: null as any };

interface MainTextMatchedSubstrings {
  offset: number;
  length: number;
}

interface StructuredFormatting {
  main_text: string;
  secondary_text: string;
  main_text_matched_substrings?: readonly MainTextMatchedSubstrings[];
}

export interface PlaceType {
  description: string;
  structured_formatting: StructuredFormatting;
}

declare global {
  interface Window {
    google: any;
  }
}

interface GoogleMapsProps {
  initialValue: PlaceType | null;
  onValueChange: (
    value: PlaceType | null,
    reason: AutocompleteChangeReason
  ) => void;
  disabled?: boolean;
}

const fontStyles = cva([], {
  variants: {
    font: {
      small: ["10px"],
      medium: ["12px"],
    },
  },
  defaultVariants: {
    font: "medium",
  },
});

const heightStyles = cva([], {
  variants: {
    height: {
      small: ["34px"],
      medium: ["2.5rem"],
    },
  },
  defaultVariants: {
    height: "medium",
  },
});

const paddingTopStyles = cva([], {
  variants: {
    paddingTop: {
      small: ["0"],
      medium: ["4px"],
    },
  },
});

export function GoogleAutocomplete({
  initialValue,
  onValueChange,
  disabled,
  ...variantProps
}: GoogleMapsProps &
  VariantProps<typeof fontStyles> &
  VariantProps<typeof heightStyles> &
  VariantProps<typeof paddingTopStyles>) {
  const fontClasses = fontStyles(variantProps);
  const heightClasses = heightStyles(variantProps);
  const paddingTopClasses = paddingTopStyles(variantProps);
  const { isMobile } = useWindowSize();
  const [value, setValue] = React.useState<PlaceType | null>(initialValue);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState<readonly PlaceType[]>([]);
  const loaded = React.useRef(false);

  React.useEffect(() => {
    const checkIfGoogleMapsIsLoaded = () => {
      if (window.google && window.google.maps) {
        // Google Maps is loaded, you can use the API here.
        // Initialize your maps, or perform other logic depending on Google Maps.
      } else {
        // If not loaded, check again in 100ms
        setTimeout(checkIfGoogleMapsIsLoaded, 100);
      }
    };
    // Start the check
    checkIfGoogleMapsIsLoaded();
  }, []);

  const fetch = React.useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly PlaceType[]) => void
        ) => {
          autocompleteService.current.getPlacePredictions(request, callback);
        },
        200
      ),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService.current && (window as any).google) {
      autocompleteService.current = new (
        window as any
      ).google.maps.places.AutocompleteService();
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return;
    }

    fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
      if (active) {
        let newOptions: readonly PlaceType[] = [];

        if (value) {
          newOptions = [value];
        }

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);
  return (
    <Autocomplete
      id="google-map-demo"
      sx={{
        width: isMobile ? "100%" : 250,
        height: heightClasses,
        "& .MuiOutlinedInput-root": {
          paddingTop: 0,
          paddingBottom: 0,
          // targeting the root of the outlined input
          "& fieldset": {
            // targeting the fieldset
            borderColor: "rgb(229 229 229",
            borderRadius: "0.375rem",
            marginTop: "12px",
          },
        },
      }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option.description
      }
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      noOptionsText="No matching locations"
      onChange={(event, newValue, reason) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        onValueChange(newValue, reason);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            style: {
              paddingTop: paddingTopClasses,
              paddingLeft: "16px",
              fontFamily: "poppins",
              fontSize: fontClasses,
              lineHeight: "1.25rem",
              color: "#A1A1AA",
              fontWeight: 300,
              // add more styles as needed
            },
          }}
          fullWidth
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
        />
      )}
      disabled={disabled}
      renderOption={(props, option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings || [];
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: MainTextMatchedSubstrings) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <Box
            component="li"
            sx={{ flexGrow: 1, maxWidth: 300, overflowX: "hidden", p: 0 }}
            {...props}>
            <Grid
              container
              alignItems="center">
              <Grid item>
                <LocationOnIcon sx={{ color: "text.secondary", mr: 1 }} />
              </Grid>
              <Grid
                item
                xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}>
                    {part.text}
                  </span>
                ))}
                <Typography
                  variant="body2"
                  color="text.secondary">
                  {option.structured_formatting.secondary_text}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        );
      }}
    />
  );
}
