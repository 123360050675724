import { flexRender } from "@tanstack/react-table";
import * as d3 from "d3";
import { parse } from "date-fns";
import { useEffect, useRef } from "react";
import {
  AssessmentQuestionAnalytics,
  useAssessmentAnalytics,
} from "backend/functions";
import { DyadTier } from "shared/forms/CarespaceCreationWizard/CarespaceInformationForm";
import { useAnalyticsPageFilterStore } from "state/analyticsPageFilter/analyticsPageFilter";
import DonutChartWithLabels from "components/DonutChart/DonutChartWithLabels";
import { MinimizableButton } from "components/MinimizableButton/MinimizableButton";
import {
  InfiniteScrollingTable,
  TableDataRowType,
} from "components/Tables/InfiniteScrollingTable";
import AnalyticsPageFilters from "components/AnalyticsPage/components/AnalyticsPageFilters";

const A_CHAR_CODE = 65;
const PASTEL_COLORS = [
  "#FFB3BA",
  "#FFDFBA",
  "#BAFFC9",
  "#BAE1FF",
  "#D3B3FF",
  "#FFB3E6",
  "#FFB3B3",
  "#FFB3D9",
  "#FFB3FF",
  "#B3FFBA",
  "#B3FFDF",
  "#B3FFFF",
  "#B3E1FF",
  "#B3BAFF",
];

export default function AnalyticsPage() {
  // Queries
  const { data } = useAssessmentAnalytics();

  // Constants
  // TODO move to other file
  const labels = Object.fromEntries(
    data?.possible_answers?.map((answer, index) => [
      answer,
      String.fromCharCode(A_CHAR_CODE + index),
    ]) || []
  );
  const colorMapping =
    data?.possible_answers?.reduce(
      (acc: { [key: string]: string }, answer, index) => {
        acc[answer] = PASTEL_COLORS[index % PASTEL_COLORS.length];
        return acc;
      },
      {} as { [key: string]: string }
    ) ?? {};

  const sectionData = Object.keys(data?.total_breakdown || {}).map(
    (answer) => ({
      label: answer,
      value: data?.total_breakdown[answer].total_answers,
    })
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between">
        <DonutChartWithLabels
          data={sectionData}
          title={`Answer Breakdown (${data?.total_patients ?? 0} Patients)`}
          colorMapping={colorMapping}
          dataToSection={(d, section) => {
            d[section.label] = {
              section: section.label,
              value: section.value ?? 0,
            };
            return d;
          }}
          labels={labels}
        />
        <AnalyticsPageFilters />
      </div>
      <div>
        <div className="flex flex-col gap-5 ">
          <ValueKeys
            possibleAnswers={data?.possible_answers ?? []}
            colorMapping={colorMapping}
            labels={labels}
          />
          <TrendsChart
            data={data?.month_by_month_breakdown}
            possibleAnswers={data?.possible_answers ?? []}
            colorMapping={colorMapping}
            labels={labels}
          />
        </div>
        {/* Data tables */}
        <div className="flex flex-col gap-3 MT-8">
          {Object.keys(data?.month_by_month_breakdown || {}).map((month) => (
            <MinimizableButton
              key={month}
              title={month}>
              <AnswerBreakdownTable
                data={data?.month_by_month_breakdown[month] ?? {}}
                labels={labels}
                colorMapping={colorMapping}
              />
            </MinimizableButton>
          ))}
        </div>
      </div>
    </div>
  );
}

enum AnswerBreakdownTableFields {
  VALUE = "Value",
  TOTAL_PATIENTS = "Total Patients",
  DYAD = "DYAD (Low/Mod/High/N/A)",
  AVERAGE_AGE = "Average Age",
  SEX = "Sex (M/F)",
  CAREGIVER = "Caregiver (Y/N)",
}

type BaseAnswerBreakdownTableType = {
  [AnswerBreakdownTableFields.VALUE]: string;
  [AnswerBreakdownTableFields.TOTAL_PATIENTS]: number;
  [AnswerBreakdownTableFields.DYAD]: string;
  [AnswerBreakdownTableFields.AVERAGE_AGE]: number;
  [AnswerBreakdownTableFields.SEX]: string;
  [AnswerBreakdownTableFields.CAREGIVER]: string;
};
type AnswerBreakdownTableType = BaseAnswerBreakdownTableType &
  TableDataRowType<BaseAnswerBreakdownTableType>;

function AnswerBreakdownTable({
  data,
  labels,
  colorMapping,
}: {
  data: { [key: string]: AssessmentQuestionAnalytics };
  labels: { [key: string]: string };
  colorMapping: { [key: string]: string };
}) {
  const { dyad } = useAnalyticsPageFilterStore();

  return (
    <div>
      <InfiniteScrollingTable
        data={
          Object.keys(data).map((answer) => ({
            [AnswerBreakdownTableFields.VALUE]: answer,
            [AnswerBreakdownTableFields.TOTAL_PATIENTS]:
              data[answer].total_answers,
            [AnswerBreakdownTableFields.DYAD]:
              dyad === "All"
                ? Object.values(data[answer].dyad_breakdown).join("/")
                : data[answer].dyad_breakdown[dyad as DyadTier],
            [AnswerBreakdownTableFields.AVERAGE_AGE]: data[answer].average_age,
            [AnswerBreakdownTableFields.SEX]: Object.values(
              data[answer].sex_breakdown
            ).join("/"),
            [AnswerBreakdownTableFields.CAREGIVER]: Object.values(
              data[answer].caregiver_breakdown
            ).join("/"),
          })) as AnswerBreakdownTableType[]
        }
        columnFields={Object.values(AnswerBreakdownTableFields)}
        handleClick={() => {}}
        overriddenHeaderNames={{
          [AnswerBreakdownTableFields.DYAD]:
            dyad === "All" ? AnswerBreakdownTableFields.DYAD : `Dyad: ${dyad}`,
        }}
        CellContent={({ cell, row }) => {
          switch (cell.column.id) {
            case AnswerBreakdownTableFields.VALUE:
              return (
                <div
                  className="px-2 py-1 font-bold rounded-full text-white"
                  style={{ backgroundColor: colorMapping[row.original.Value] }}>
                  <p>{labels[row.original.Value]}</p>
                </div>
              );
            default:
              return (
                <div className="w-[160px]">
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </div>
              );
          }
        }}
      />
    </div>
  );
}

function TrendsChart({
  data,
  possibleAnswers,
  colorMapping,
  labels,
}: {
  data:
    | { [month: string]: { [answer: string]: AssessmentQuestionAnalytics } }
    | undefined;
  possibleAnswers: string[];
  colorMapping: { [key: string]: string };
  labels: { [key: string]: string };
}) {
  const ref = useRef<SVGSVGElement>(null);
  useEffect(() => {
    if (!data || !ref.current) return;
    const svg = d3.select(ref.current);
    const margin = { top: 20, right: 30, bottom: 30, left: 40 };
    const width = 800 - margin.left - margin.right;
    const height = 300 - margin.top - margin.bottom;

    svg.selectAll("*").remove(); // Clear previous content

    const g = svg
      .append("g")
      .attr("transform", `translate(${margin.left},${margin.top})`);

    const x = d3
      .scaleTime()
      .domain(
        d3.extent(Object.keys(data), (d) => parse(d, "MMMM", new Date())) as [
          Date,
          Date
        ]
      )
      .range([0, width]);

    const y = d3
      .scaleLinear()
      .domain([
        0,
        (d3.max(
          Object.values(data).flatMap((d) =>
            Object.values(d).map((answer) => answer.total_answers)
          )
        ) as number) + 1,
      ])
      .nice()
      .range([height, 0]);

    g.append("g")
      .attr("transform", `translate(0,${height})`)
      .call(
        d3.axisBottom(x).tickFormat((d, i) => d3.timeFormat("%B")(d as Date))
      ); // Add tick marks

    // dotted lines
    g.append("g")
      .call(
        d3
          .axisLeft(y)
          .tickSize(-width)
          .tickFormat(() => "")
      )
      .selectAll("line")
      .attr("stroke", "#ccc")
      .attr("stroke-dasharray", "2,2");

    g.append("g").call(d3.axisLeft(y));

    const line = d3
      .line<{ date: Date; value: number }>()
      .x((d) => x(d.date))
      .y((d) => y(d.value));

    for (const possibleAnswer of possibleAnswers) {
      const lineData = Object.keys(data).map((month) => ({
        date: parse(month, "MMMM", new Date()),
        value: data[month][possibleAnswer]?.total_answers ?? 0,
      }));

      g.append("path")
        .datum(lineData)
        .attr("fill", "none")
        .attr("stroke", colorMapping[possibleAnswer])
        .attr("stroke-width", 1.5)
        .attr("d", line);
      // Add circles for each data point
      g.selectAll(`circle-${labels[possibleAnswer]}`)
        .data(lineData)
        .enter()
        .append("circle")
        .attr("cx", (d) => x(d.date))
        .attr("cy", (d) => y(d.value))
        .attr("r", 5)
        .attr("fill", colorMapping[possibleAnswer]);
    }
  }, [data]);

  return (
    <svg
      ref={ref}
      width="800"
      height="300"></svg>
  );
}

function ValueKeys({
  possibleAnswers,
  colorMapping,
  labels,
}: {
  possibleAnswers: string[];
  colorMapping: { [key: string]: string };
  labels: { [key: string]: string };
}) {
  return (
    <MinimizableButton title="Value Key">
      <div className="flex flex-col gap-1 pl-6">
        {possibleAnswers.map((answer, index) => (
          <span
            className="text-xs flex gap-4 items-center"
            key={index}>
            <div
              className="px-4 font-bold rounded-full text-white"
              style={{ backgroundColor: colorMapping[answer] }}>
              <span>{labels[answer]}</span>
            </div>
            <p>{answer}</p>
          </span>
        ))}
      </div>
    </MinimizableButton>
  );
}
