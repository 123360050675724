import { DefaultCheckbox } from "components/Checkbox";
import { useAssessmentStore } from "state/assessment";

export enum YesOrNoOptions {
  YES = "Yes",
  NO = "No",
  UNSURE = "Unsure",
  NA = "N/A",
  OPEN = "Open",
}

interface YesOrNoProps {
  currentAnswer: string | undefined;
  updateAnswer: (obj: { answer?: string; context?: string }) => void;
  additionalOptions?: YesOrNoOptions[];
  disabled: boolean;
}

export function YesOrNo({
  currentAnswer,
  updateAnswer,
  additionalOptions,
  disabled,
}: YesOrNoProps) {
  // Stores
  const activeUserAssessmentId = useAssessmentStore(
    (state) => state.activeUserAssessmentId
  );

  // Constants
  const filteredAdditionalOptions = (additionalOptions ?? []).filter(
    (option) => option !== YesOrNoOptions.OPEN
  );

  // Handlers
  const handleOptionChange = (selectedOption: string) => {
    if (activeUserAssessmentId) {
      updateAnswer({ answer: selectedOption });
    }
  };

  // -------*-------*--------------*--------------*--------------*--------------*-------
  return (
    <>
      <YesOrNoGridWrapper filteredAdditionalOptions={filteredAdditionalOptions}>
        {[
          YesOrNoOptions.YES,
          YesOrNoOptions.NO,
          ...filteredAdditionalOptions,
        ].map((option) => (
          <div className="flex items-center justify-center">
            <DefaultCheckbox
              disabled={disabled}
              checked={currentAnswer === option}
              onChange={() => handleOptionChange(option)}
            />
          </div>
        ))}
      </YesOrNoGridWrapper>
    </>
  );
}

export function YesOrNoGridWrapper({
  children,
  filteredAdditionalOptions,
}: {
  children: React.ReactNode;
  filteredAdditionalOptions: YesOrNoOptions[];
}) {
  return (
    <div className="flex justify-end items-center">
      <div
        style={{
          gridTemplateColumns: `repeat(${
            2 + (filteredAdditionalOptions?.length ?? 0)
          }, 1fr)`,
        }}
        className="grid items-center gap-4  text-sm font-bold text-center ">
        {[
          YesOrNoOptions.YES,
          YesOrNoOptions.NO,
          ...(filteredAdditionalOptions ?? []),
        ].map((option) => (
          <p>{option}</p>
        ))}
        {children}
      </div>
    </div>
  );
}
