import type { TaskWithGuideInfo } from "backend/resources/planEntry";
import {
  TaskStatus,
  TaskStatusColor,
  TaskStatusLabel,
} from "backend/resources/planEntry";
import type { HighChartDataPoint } from "components/HighChart";
import { HighPieChart } from "components/HighChart";

interface TaskHighPieChartProps {
  data: TaskWithGuideInfo[];
  title?: string;
  isLoading?: boolean;
}
const OVERDUE = " (Overdue)";

export function TaskHighPieChart({
  data,
  title = undefined,
  isLoading = false,
}: TaskHighPieChartProps) {
  function generateHighChartDonutChartData(
    tasks: TaskWithGuideInfo[]
  ): HighChartDataPoint[] {
    const statusDict = {
      ...Object.values(TaskStatus).reduce((acc, status) => {
        acc[TaskStatusLabel[status]] = {
          name: TaskStatusLabel[status],
          y: 0,
          color: TaskStatusColor[status],
        };
        return acc;
      }, {} as Record<string, HighChartDataPoint>),
    };

    tasks.forEach((task) => {
      const status = task.status as TaskStatus;
      const isTaskOverdue =
        task.scheduled_date_time &&
        new Date(task.scheduled_date_time) < new Date() &&
        status !== TaskStatus.Done;
      const label = `${TaskStatusLabel[status]}${isTaskOverdue ? OVERDUE : ""}`;
      if (!statusDict[label]) {
        statusDict[label] = {
          name: label,
          y: 0,
          color: isTaskOverdue
            ? TaskStatusColor.Overdue
            : TaskStatusColor[status],
        };
      }
      statusDict[label].y += 1;
    });

    // convert dict to array and sort by name
    return Object.values(statusDict).sort((a, b) =>
      b.name.localeCompare(a.name)
    );
  }

  return (
    <div className="flex flex-col gap-2 w-[400px]">
      <HighPieChart
        data={generateHighChartDonutChartData(data ?? [])}
        title={title}
        isLoading={isLoading}
        showTooltip={true}
        showDataLabels={false}
        convertToDonut={true}
        showLegend={true}
      />
    </div>
  );
}
