import AddToFolder from "assets/add-to-folder.svg?react";
import MyPlanIconSvg from "assets/my-plan-icon.svg?react";
import Notes from "assets/notes.svg?react";
import Tag from "assets/orange_tag.svg?react";
import AlfredIcon from "components/Alfred/Alfred";
import { CollapseButton } from "components/ChatGptSideBar/ChatGptSideBar";
import { MessagesThread } from "components/ChatGptSideBar/MessagesThread";
import { useWindowSize } from "hooks/useWindowSize";

export function SideBarMyLibraryPage() {
  const { isMobile } = useWindowSize();

  return (
    <div className="flex flex-col max-h-full h-full w-full">
      <div className="py-3 px-2">{!isMobile && <CollapseButton />}</div>
      <MessagesThread
        threadId={"myLibraryPage"}
        pageType="myLibraryPage">
        <InitialContent />
      </MessagesThread>
    </div>
  );
}
// Welcome to your library! This is where you can organize your bookmarks into folders to make it easier to find them later. Add notes to your content to add more details to help you describe it in your terms. Add bookmarked content to a folder so you can organize it. Note: deleting a folder doesn’t delete its contained bookmarks. Tag content with tags to help you remember what it’s about, e.g., cats, funny, stress relief. Add to your plan as a todo in your calendar.
function InitialContent() {
  return (
    <div className="flex min-w-0 gap-3 pb-6">
      {/* welcome message */}
      <AlfredIcon className="flex-shrink-0 w-8 h-8 mt-1" />
      <div className="flex flex-col gap-3 bg-gray-50  text-sm px-4 py-4 rounded-md">
        <p>
          Welcome to your library! This is where you can organize your bookmarks
          into folders to make it easier to find them later.
        </p>

        <p>
          Add notes to your content to add more details to help you describe it
          in your terms.
        </p>

        <div className="flex justify-center">
          <Notes className="w-8 h-8" />
        </div>

        <p>
          Add bookmarked content to a folder so you can organize it.{" "}
          <span>
            Note: deleting a folder doesn’t delete its contained bookmarks.
          </span>
        </p>

        <div className="flex justify-center">
          <AddToFolder className="w-8 h-8" />
        </div>

        <p>
          Tag content with tags to help you remember what it’s about, e.g.,
          cats, funny, stress relief.
        </p>

        <div className="flex justify-center">
          <Tag className="w-8 h-8" />
        </div>
        <p>Add to your plan as a task in your calendar</p>

        <div className="flex justify-center">
          <MyPlanIconSvg className="w-8 h-8" />
        </div>
      </div>
    </div>
  );
}
