import { cva, type VariantProps } from "class-variance-authority";
import * as React from "react";

import { cn } from "shared/utils/cn";

const badgeVariants = cva(
  "inline-flex items-center whitespace-nowrap rounded-full text-white px-2.5 py-0.5 text-xs transition-colors dark:border-neutral-800 dark:focus:ring-neutral-300",
  {
    variants: {
      variant: {
        default:
          "dark:bg-neutral-50 dark:text-neutral-900 dark:hover:bg-neutral-50/80",
        outline: "border dark:text-neutral-50",
      },
      color: {
        gray: "",
        blue: "",
        red: "",
        green: "",
        orange: "",
        purple: "",
      },
    },
    compoundVariants: [
      {
        variant: "default",
        color: "gray",
        class: "bg-primary-gray",
      },
      {
        variant: "outline",
        color: "gray",
        class: "text-primary-gray border-primartext-primary-gray",
      },
      {
        variant: "default",
        color: "blue",
        class: "bg-primary-blue",
      },
      {
        variant: "outline",
        color: "blue",
        class: "text-primary-blue border-primary-blue",
      },
      {
        variant: "default",
        color: "red",
        class: "bg-primary-red",
      },
      {
        variant: "outline",
        color: "red",
        class: "text-primary-red border-primary-red",
      },
      {
        variant: "default",
        color: "green",
        class: "bg-primary-green",
      },
      {
        variant: "outline",
        color: "green",
        class: "text-primary-green border-primary-green",
      },
      {
        variant: "default",
        color: "orange",
        class: "bg-primary-orange",
      },
      {
        variant: "outline",
        color: "orange",
        class: "text-primary-orange border-primary-orange",
      },
      {
        variant: "default",
        color: "purple",
        class: "bg-primary-purple",
      },
      {
        variant: "outline",
        color: "purple",
        class: "text-primary-purple border-primary-purple",
      },
    ],
    defaultVariants: {
      variant: "default",
      color: "gray",
    },
  }
);

export interface BadgeProps
  extends Omit<React.HTMLAttributes<HTMLDivElement>, "color">,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, color, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, color }), className)}
      {...props}
    />
  );
}

export { Badge, badgeVariants };
