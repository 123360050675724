import MoreInfoIcon from "assets/modal-info.svg?react";
import { NotificationType, sendNotification } from "backend/functions";
import { useUserUpdateDiscussion } from "backend/resources/chatGptConversation";
import {
  useChatGptMessages,
  useMutateChatGptMessages,
} from "backend/resources/chatGptMessage";
import { usePlanEntryData } from "backend/resources/planEntry";
import { useServiceEngagementById } from "backend/resources/services/serviceEngagement";
import { useUserQuery } from "backend/resources/user";
import { useRecommendation } from "backend/resources/userRecommendation";
import { formatDistanceToNow } from "date-fns";
import { useWindowSize } from "hooks/useWindowSize";
import { Arrow } from "icons/Arrow";
import { useEffect, useRef, useState } from "react";
import type { SheetRef } from "react-modal-sheet";
import Sheet from "react-modal-sheet";

import { useActiveNetwork } from "backend/resources/network/network";
import { useIntervention } from "backend/resources/userIntervention";
import AlfredIcon from "components/Alfred/Alfred";
import { ChatGptSideBarInput } from "components/ChatGptSideBar/Input";
import {
  SideBarHomePage,
  SideBarMyLibraryPage,
  SideBarNewAssessmentPage,
  SideBarRecommendationsPage,
} from "components/ChatGptSideBar/Pages";
import { PrivateDiscussion } from "components/ChatGptSideBar/Pages/PrivateDiscussion";
import { ServiceDiscussion } from "components/ChatGptSideBar/Pages/ServiceDiscussion";
import { ServiceExternalDiscussion } from "components/ChatGptSideBar/Pages/ServiceExternalDiscussion";
import { ServiceRequestDiscussion } from "components/ChatGptSideBar/Pages/ServiceRequestDiscussion";
import { ServiceRequestExternalDiscussion } from "components/ChatGptSideBar/Pages/ServiceRequestExternalDiscussion";
import { SideBarAdminPage } from "components/ChatGptSideBar/Pages/SideBarAdminPage";
import { SideBarDiscussionsPage } from "components/ChatGptSideBar/Pages/SideBarDiscussionsPage";
import { SideBarEducationPage } from "components/ChatGptSideBar/Pages/SideBarEducationPage";
import { SideBarInterventionPage } from "components/ChatGptSideBar/Pages/SideBarInterventionPage";
import { SideBarLocalSearchPage } from "components/ChatGptSideBar/Pages/SideBarLocalSearchPage";
import { SideBarMyCarePage } from "components/ChatGptSideBar/Pages/SideBarMyCarePage";
import { SideBarServicesPage } from "components/ChatGptSideBar/Pages/SideBarServicesPage";
import { SideBarTodosPage } from "components/ChatGptSideBar/Pages/SideBarTodosPage";
import { SideBarUserUpdatesPage } from "components/ChatGptSideBar/Pages/SideBarUserUpdatesPage";
import { CarespaceDiscussion } from "components/ChatGptSideBar/Pages/UserCarespaceDiscussion";
import { UserUpdateDiscussion } from "components/ChatGptSideBar/Pages/UserUpdateDiscussion";
import ReactMarkdown from "react-markdown";
import { EmailForm } from "shared/forms/ServiceResourceEmailForm";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { ChatMessageType, SideBarPageType, useGptStore } from "state/gpt";
import { useUserStore } from "state/user";
import { useRole } from "hooks/role/useRole";
import { NetworkRoleType } from "backend/resources/userRole/types";

const BACKEND_URL = import.meta.env.VITE_BACKEND_URL;

export enum DiscussionType {
  UserUpdate,
  Carespace,
  ServiceRequest,
  ServiceRequestExternal,
  Service,
  ServiceExternal,
  Private,
}

type Props = {
  isMobile?: boolean;
  mobileHeight?: string;
  hideBorder?: boolean;
};

export function Alfred() {
  const isGptChatOpen = useGptStore((state) => state.isOpen);
  const authUser = useUserStore((state) => state.user);
  const { role } = useRole();
  const { isMobile } = useWindowSize();

  const allowedRoles = new Set([
    NetworkRoleType.ADMIN,
    NetworkRoleType.CARE_NAVIGATOR,
    NetworkRoleType.DOCTOR,
  ]);
  const shouldShowAlfred = () => role && allowedRoles.has(role);

  if (shouldShowAlfred()) {
    return (
      <>
        {authUser &&
          (isGptChatOpen ? (
            isMobile ? (
              <MobileChatGptSideBar />
            ) : (
              <ChatGptSideBar />
            )
          ) : (
            !isMobile && <MinimizedChatGptSideBar />
          ))}
      </>
    );
  } else {
    return null;
  }
}

export function ChatGptSideBar({ isMobile, mobileHeight, hideBorder }: Props) {
  /**
   * States
   */

  const [currentConversationId, setCurrentConversationId] = useState<string>();
  const [inputPlaceholderText, setInputPlaceholderText] = useState<string>();

  /**
   * Stores
   */

  const authUser = useUserStore((state) => state.user);
  const sidebarPageType = useGptStore((state) => state.type);
  const userAssessmentChatGptRecommendationId = useGptStore(
    (state) => state.userAssessmentChatGptRecommendationId
  );
  const userAssessmentChatGptInterventionId = useGptStore(
    (state) => state.userAssessmentChatGptInterventionId
  );

  const { recommendation } = useRecommendation(
    userAssessmentChatGptRecommendationId || ""
  );
  const { intervention } = useIntervention(
    userAssessmentChatGptInterventionId || ""
  );
  const conversationId = useGptStore((state) => state.conversationId);
  const streamingMessage = useGptStore((state) => state.streamingMessage);
  const setStreamingMessage = useGptStore((state) => state.setStreamingMessage);
  const setPendingMessage = useGptStore((state) => state.setPendingMessage);
  const lastMessageTime = useGptStore((state) => state.lastMessageTime);
  const setLastMessageTime = useGptStore((state) => state.setLastMessageTime);

  const setShouldDisableInput = useGptStore(
    (state) => state.setShouldDisableInput
  );
  const setUserMessage = useGptStore((state) => state.setUserMessage);
  const setMessageError = useGptStore((state) => state.setMessageError);
  const sendToGptRequest = useGptStore((state) => state.sendToGptRequest);
  const setSendToGptRequest = useGptStore((state) => state.setSendToGptRequest);

  /**
   * Hooks
   */

  /**
   * Refs
   */

  const eventSourceRef = useRef<EventSource | null>(null);
  const prevMessageTimeRef = useRef(lastMessageTime);
  const errorTimeoutRef = useRef<NodeJS.Timeout>();

  /**
   * Mutations
   */

  const chatGptMessagesMutation = useMutateChatGptMessages();

  function onMessage(event: any) {
    const data = JSON.parse(event.data);
    const { streamingMessage, conversationId } = useGptStore.getState();
    if (data.content) {
      setPendingMessage(false);
      setStreamingMessage(data.content.replace("\n", "<br /><br />"));
      setShouldDisableInput(true);
      setInputPlaceholderText("Please wait for Alfred to finish...");
      setLastMessageTime(Date.now());
    } else if (["stop", "length"].includes(data.finish_reason)) {
      if (streamingMessage && conversationId) {
        persistMessage();
      }
      closeEventSource();
    }
  }

  function onMessageError(event: any) {
    setMessageError("Something went wrong. Please try again.");
    setStreamingMessage(null);
  }

  async function persistMessage() {
    // grab current threadId based on context
    const threadId = getCurrentThreadId();
    const { streamingMessage } = useGptStore.getState();
    // check that
    if (streamingMessage && currentConversationId && threadId) {
      const persistedMessage = await chatGptMessagesMutation.mutateAsync({
        message: {
          chat_gpt_conversation_id: currentConversationId,
          content: streamingMessage,
          role: "assistant",
          send_to_chat_gpt: true,
          user_id: authUser?.id,
        },
        threadId,
      });
      if (persistedMessage && persistedMessage.length > 0) {
        setStreamingMessage(null);
      }
    } else {
      setStreamingMessage(null); // trigger state update
    }
  }

  async function persistUserMessage(userMessage: string) {
    // grab current threadId based on context
    const threadId = getCurrentThreadId();
    // check all values required to persist messages are truthy
    if (currentConversationId && userMessage && threadId) {
      const persistedMessage = await chatGptMessagesMutation.mutateAsync({
        message: {
          chat_gpt_conversation_id: currentConversationId,
          content: userMessage,
          role: "user",
          send_to_chat_gpt: true,
          user_id: authUser?.id,
        },
        threadId,
      });

      // if user message was persisted, send to GPT and handle required UI changes
      if (persistedMessage && persistedMessage.length > 0) {
        setShouldDisableInput(true);
        setInputPlaceholderText("Please wait for Alfred to finish...");
        setPendingMessage(true);
        setUserMessage("");
        await timeout(1000); // feign 1 second of thinking
        // if the message was sent from a recommendatino thread (rec or intervention),
        // include the recommendation title and description as context in the message

        if (sidebarPageType === "recommendationConversationPage") {
          sendToGpt(
            currentConversationId,
            ChatMessageType.USER_MESSAGE,
            `
          """recommendation context
          recommendation title: ${recommendation?.title}
          recommendation details: ${recommendation?.details}
          """
          ${userMessage}
          `
          );
        } else if (sidebarPageType === "interventionConversationPage") {
          sendToGpt(
            currentConversationId,
            ChatMessageType.USER_MESSAGE,
            `
          """intervention context
          intervention title: ${intervention?.title}
          intervention details: ${intervention?.details}
          """
          ${userMessage}
          `
          );
        } else {
          sendToGpt(
            currentConversationId,
            ChatMessageType.USER_MESSAGE,
            userMessage
          );
        }
      }
    }
  }

  function getCurrentThreadId() {
    switch (sidebarPageType) {
      case "recommendationConversationPage": {
        return userAssessmentChatGptRecommendationId || null;
      }
      case "interventionConversationPage": {
        return intervention?.id || null;
      }
      default: {
        return sidebarPageType;
      }
    }
  }

  function sendToGpt(
    conversationId: string,
    message_type: ChatMessageType,
    text?: string
  ) {
    if (authUser?.id && text && conversationId) {
      const params = new URLSearchParams({
        text,
        user_id: authUser.id,
        chat_gpt_conversation_id: conversationId,
        message_type,
      } as any);
      const evtSource = new EventSource(
        `${BACKEND_URL}/chat_gpt_conversation?${params}`
      );
      eventSourceRef.current = evtSource;
      if (evtSource != null) {
        // @ts-ignore
        eventSourceRef.current.addEventListener("new_message", onMessage);
        eventSourceRef.current.addEventListener("error", onMessageError);
      }
    }
  }

  function closeEventSource() {
    if (eventSourceRef?.current) {
      eventSourceRef.current.removeEventListener("new_message", onMessage);
      eventSourceRef.current.close();

      clearTimeout(errorTimeoutRef.current);
      setShouldDisableInput(false);
      setInputPlaceholderText("");
    }
    setPendingMessage(false);
  }

  /**
   * Effects
   */

  // setup
  useEffect(() => {
    // setup
    clearTimeout(errorTimeoutRef.current); // clear any lingering timeouts on mount
    setShouldDisableInput(false); // ensure input is enabled on mount
    setInputPlaceholderText("");
    setStreamingMessage(null);
    // cleanup
    return () => {
      closeEventSource();
    };
  }, []);

  // set up covnersation ids
  useEffect(() => {
    if (conversationId !== currentConversationId) {
      setCurrentConversationId(conversationId);
    }
  }, [conversationId, currentConversationId]);

  // effect for error handling
  useEffect(() => {
    // update timestamp
    prevMessageTimeRef.current = lastMessageTime;

    // start error timer
    const timer = setTimeout(() => {
      const currentTime = Date.now();
      const prevMessageTime = prevMessageTimeRef.current;
      if (!prevMessageTime) return;
      const elapsedTime = currentTime - prevMessageTime;
      if (elapsedTime >= 8000 && streamingMessage && conversationId) {
        // More than 8 seconds have elapsed since the last message
        // Stop disabling the input and trigger an error
        setMessageError("Timeout.");
        closeEventSource();
        persistMessage();
      }
    }, 8000);

    errorTimeoutRef.current = timer;

    // cleanup
    return () => {
      clearTimeout(errorTimeoutRef.current); // Clear the timer when the component unmounts
    };
  }, [lastMessageTime]);

  // hand Alfred request
  useEffect(() => {
    async function asyncWork() {
      if (sendToGptRequest) {
        setShouldDisableInput(true);
        setInputPlaceholderText("Please wait for Alfred to finish...");
        setPendingMessage(true);
        setUserMessage("");
        await timeout(1000); // feign 1 second of thinking
        sendToGpt(
          sendToGptRequest.conversationId,
          sendToGptRequest.message_type,
          sendToGptRequest.text
        );
        setSendToGptRequest(null);
      }
    }
    asyncWork();
  }, [sendToGptRequest]);

  // switching convestations
  useEffect(() => {
    async function changeConversations() {
      if (conversationId !== currentConversationId) {
        closeEventSource();
        await persistMessage();
        setCurrentConversationId(conversationId);
      }
    }

    changeConversations();
  }, [conversationId, currentConversationId]);

  return (
    <div
      style={{
        height: isMobile ? mobileHeight : "100%",
        width: isMobile ? "100%" : "380px",
      }}
      className={`w-[380px]" flex flex-col max-h-full transition-all  ${
        hideBorder ? "" : "border-l border-neutral-200 drop-shadow-sm"
      } bg-white shrink-0`}>
      <div className="flex-grow overflow-y-auto">
        {renderSideBarPageType(sidebarPageType)}
      </div>
      <ChatGptSideBarInput
        closeEventSource={closeEventSource}
        persistMessage={persistMessage}
        persistUserMessage={persistUserMessage}
        isDiscussion={false}
        inputPlaceholderText={inputPlaceholderText}
      />
    </div>
  );
}

const useConversationId = (
  discussionType: DiscussionType,
  threadId: string | undefined
) => {
  const { data: userUpdateDiscussion } = useUserUpdateDiscussion(threadId);
  const { data: network } = useActiveNetwork();
  const { data: planEntryDiscussion } = usePlanEntryData(threadId);
  const { data: serviceEngagementDiscussion } =
    useServiceEngagementById(threadId);
  switch (discussionType) {
    case DiscussionType.UserUpdate:
      return userUpdateDiscussion?.conversation_id;
    case DiscussionType.Carespace:
      return network?.conversation_id;
    case DiscussionType.ServiceRequest:
      return planEntryDiscussion?.conversation_id;
    case DiscussionType.ServiceRequestExternal:
      return planEntryDiscussion?.external_conversation_id;
    case DiscussionType.Service:
      return serviceEngagementDiscussion?.conversation_id;
    case DiscussionType.ServiceExternal:
      return serviceEngagementDiscussion?.external_conversation_id;
    case DiscussionType.Private:
      return threadId;
    default:
  }
};

export function CollapsedUserDiscussion({
  discussionType,
  threadId,
  title,
}: Props & {
  discussionType: DiscussionType;
  threadId: string | undefined;
  title: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarPageTypeMapping: Record<DiscussionType, SideBarPageType> = {
    [DiscussionType.UserUpdate]: "userUpdate",
    [DiscussionType.Carespace]: "carespace",
    [DiscussionType.ServiceRequest]: "serviceRequest",
    [DiscussionType.ServiceRequestExternal]: "serviceRequestExternal",
    [DiscussionType.Service]: "service",
    [DiscussionType.ServiceExternal]: "serviceExternal",
    [DiscussionType.Private]: "private",
  };

  const pageType = sidebarPageTypeMapping[discussionType];
  const { isLoadingMessages, messages } = useChatGptMessages(
    threadId,
    pageType
  );
  const latestMessage = messages?.[messages.length - 1];
  const { user: messageAuthorUser } = useUserQuery(latestMessage?.user_id);
  const conversationId = useConversationId(discussionType, threadId);

  return (
    <>
      <ResponsiveModal
        isOpen={isOpen}
        title={title}
        closeText="Close"
        onClose={() => setIsOpen(false)}
        fixedHeight="h-[90vh]">
        <div className="flex flex-col justify-end h-[60vh]">
          <UserDiscussion
            discussionType={discussionType}
            threadId={threadId}
          />
        </div>
      </ResponsiveModal>
      <div
        className="flex flex-col gap-2 cursor-pointer"
        onClick={() => setIsOpen(true)}>
        <p className="text-lg">{title}</p>
        <div className="grid grid-cols-[5fr,2fr,2fr]">
          <p>Latest Message</p>
          <p>From</p>
          <p>Sent</p>

          {latestMessage && !isLoadingMessages ? (
            <>
              <p
                style={{
                  fontWeight: latestMessage?.read_message?.length
                    ? "normal"
                    : "bold",
                }}>
                <ReactMarkdown
                  components={{
                    a: ({ node, children, ...props }) => (
                      <a
                        {...props}
                        style={{ textDecoration: "underline" }}
                        target="_blank">
                        {children}
                      </a>
                    ),
                  }}>
                  {latestMessage?.content}
                </ReactMarkdown>
              </p>
              <p
                style={{
                  fontWeight: latestMessage?.read_message?.length
                    ? "normal"
                    : "bold",
                }}>
                {messageAuthorUser?.first_name} {messageAuthorUser?.last_name}
              </p>
              <p
                style={{
                  fontWeight: latestMessage?.read_message?.length
                    ? "normal"
                    : "bold",
                }}>
                {latestMessage?.created_at
                  ? formatDistanceToNow(new Date(latestMessage.created_at))
                  : ""}
              </p>
            </>
          ) : (
            <p className="text-sm">
              No messages yet. Click to start discussion.
            </p>
          )}
        </div>
      </div>
    </>
  );
}

const TITLE_EXTERNAL_DISCUSSION =
  "External Discussion (includes external provider)";

export function ProviderEmailForm({ resourceId }: { resourceId: string }) {
  return (
    <div className="bg-[#D2ECFF] p-5 rounded-md">
      <p className="text-lg mb-2 ">{TITLE_EXTERNAL_DISCUSSION}</p>
      <div className="flex gap-5 items-center ">
        <MoreInfoIcon />
        <p className=" text-sm">
          In order to send a message to this provider, their email address must
          be added to their profile. After you add it, all messages here will be
          sent to that provider via email.
        </p>
      </div>
      <EmailForm service_resource_id={resourceId} />
    </div>
  );
}

export function ExternalDiscussion({
  email,
  discussionType,
  threadId,
  resourceId,
}: {
  email: string | undefined | null;
  discussionType: DiscussionType;
  threadId: string | undefined;
  resourceId: string;
}) {
  return !email ? (
    <ProviderEmailForm resourceId={resourceId} />
  ) : (
    <CollapsedUserDiscussion
      discussionType={discussionType}
      threadId={threadId}
      title={TITLE_EXTERNAL_DISCUSSION}
    />
  );
}

export function UserDiscussion({
  discussionType,
  isMobile,
  hideBorder,
  mobileHeight,
  threadId,
}: Props & { discussionType: DiscussionType; threadId: string | undefined }) {
  /**
   * States
   */

  /**
   * Stores
   */

  const authUser = useUserStore((state) => state.user);
  const setUserMessage = useGptStore((state) => state.setUserMessage);
  const currentConversationId = useConversationId(discussionType, threadId);
  /**
   * Mutations
   */

  const chatGptMessagesMutation = useMutateChatGptMessages();

  /**
   * Handlers
   */

  async function persistUserMessage(userMessage: string) {
    // grab current threadId based on context
    // check all values required to persist messages are truthy
    if (currentConversationId && userMessage && threadId) {
      const persistedMessage = await chatGptMessagesMutation.mutateAsync({
        message: {
          chat_gpt_conversation_id: currentConversationId,
          content: userMessage,
          role: "user",
          send_to_chat_gpt: true,
          user_id: authUser?.id,
        },
        threadId,
      });
      setUserMessage("");

      // Send notifs
      const lastMessage = persistedMessage[persistedMessage.length - 1];
      if (discussionType === DiscussionType.UserUpdate) {
        sendNotification(
          lastMessage.id,
          NotificationType.MESSAGE_POSTED_IN_HUB
        );
      } else if (discussionType === DiscussionType.Carespace) {
        sendNotification(
          lastMessage.id,
          NotificationType.MESSAGE_POSTED_IN_CARESPACE
        );
      } else if (discussionType === DiscussionType.ServiceExternal) {
        sendNotification(lastMessage.id, NotificationType.EXTERNAL_SERVICE);
      } else if (discussionType === DiscussionType.ServiceRequestExternal) {
        sendNotification(
          lastMessage.id,
          NotificationType.EXTERNAL_SERVICE_REQUEST
        );
      } else if (discussionType === DiscussionType.Private) {
        sendNotification(
          lastMessage.id,
          NotificationType.EXTERNAL_DIRECT_MESSAGE
        );
      }
    }
  }

  /**
   * Effects
   */

  return (
    <div
      style={{
        height: isMobile ? mobileHeight : "90%",
        width: isMobile ? "100%" : "",
      }}
      className={`"w-full h-full flex flex-col max-h-full transition-all
      ${hideBorder ? "" : "border-l border-neutral-200 drop-shadow-sm"}
       bg-white shrink-0`}>
      <div className="flex-grow overflow-y-auto">
        {renderUserDiscussion(discussionType)}
      </div>
      <ChatGptSideBarInput
        closeEventSource={() => {}}
        persistMessage={() => {}}
        persistUserMessage={persistUserMessage}
        isDiscussion={true}
        inputPlaceholderText={"Type Here"}
      />
    </div>
  );
}

function renderUserDiscussion(discussionType: DiscussionType) {
  const discussionTypeComponents = {
    [DiscussionType.UserUpdate]: <UserUpdateDiscussion />,
    [DiscussionType.Carespace]: <CarespaceDiscussion />,
    [DiscussionType.ServiceRequest]: <ServiceRequestDiscussion />,
    [DiscussionType.Service]: <ServiceDiscussion />,
    [DiscussionType.ServiceExternal]: <ServiceExternalDiscussion />,
    [DiscussionType.ServiceRequestExternal]: (
      <ServiceRequestExternalDiscussion />
    ),
    [DiscussionType.Private]: <PrivateDiscussion />,
  };
  return discussionTypeComponents[discussionType];
}

function renderSideBarPageType(sidebarPageType: SideBarPageType | undefined) {
  if (!sidebarPageType) return null;
  const pageTypeComponents = {
    recommendationConversationPage: <SideBarRecommendationsPage />,
    interventionConversationPage: <SideBarInterventionPage />,
    homePage: <SideBarHomePage />,
    myLibraryPage: <SideBarMyLibraryPage />,
    todosPage: <SideBarTodosPage />,
    newAssessmentPage: <SideBarNewAssessmentPage />,
    userUpdatePage: <SideBarUserUpdatesPage />,
    localSearchPage: <SideBarLocalSearchPage />,
    educationPage: <SideBarEducationPage />,
    discussionsPage: <SideBarDiscussionsPage />,
    myCarePage: <SideBarMyCarePage />,
    adminPage: <SideBarAdminPage />,
    userUpdate: <UserUpdateDiscussion />,
    carespace: <CarespaceDiscussion />,
    serviceRequest: <ServiceRequestDiscussion />,
    service: <></>,
    mobilePage: <MobileChatGptSideBar />,
    servicesPage: <SideBarServicesPage />,
    serviceExternal: <ServiceExternalDiscussion />,
    serviceRequestExternal: <ServiceRequestExternalDiscussion />,
    private: <></>,
  };
  return pageTypeComponents[sidebarPageType];
}

export function MobileChatGptSideBar() {
  const ref = useRef<SheetRef>();
  const isOpen = useGptStore((state) => state.isOpen);
  const setIsOpen = useGptStore((state) => state.setIsOpen);
  const [snapPoint, setSnapPoint] = useState(0);
  const snapPoints = [0.9, 0.7, 0.4];

  const heightPxForSnapPoint = (snapPoint: number) => {
    // calculate height accounting for 40px <Sheet.Header/> component
    const height = `${snapPoints[snapPoint] * window.innerHeight - 40}px`;
    return height;
  };

  function handleClose() {
    setIsOpen(false);
  }

  return (
    <Sheet
      ref={ref}
      isOpen={isOpen}
      onClose={handleClose}
      initialSnap={0}
      snapPoints={snapPoints}
      onSnap={(snapPoint) => {
        setSnapPoint(snapPoint);
      }}
      detent="full-height">
      <Sheet.Container>
        <Sheet.Header />
        <Sheet.Content disableDrag={true}>
          <ChatGptSideBar
            isMobile={true}
            mobileHeight={heightPxForSnapPoint(snapPoint)}
          />
        </Sheet.Content>
      </Sheet.Container>
      {/* ⚠️ Note: as the element is a motion component you need to use onTap instead of onClick if you want to add a click handler to it. */}
      <Sheet.Backdrop onTap={handleClose} />
    </Sheet>
  );
}

export function MinimizedChatGptSideBar() {
  return (
    <div className="flex flex-col h-full border-l border-neutral-200 w-16 relative items-center gap-5 p-3">
      <CollapseButton />
      <AlfredIcon className="w-8 shrink-0" />
    </div>
  );
}

export function CollapseButton() {
  const isOpen = useGptStore((state) => state.isOpen);
  const setIsOpen = useGptStore((state) => state.setIsOpen);

  return (
    <button
      className={`hover:bg-gray-100 flex items-center justify-center top-9 w-6 h-6  rounded-full border border-neutral-200 bg-white shrink-0`}
      onClick={() => {
        setIsOpen(!isOpen);
      }}>
      <Arrow
        className={`w-2 h-2 ${
          isOpen ? "rotate-90" : "-rotate-90"
        } transition-all fill-brand-orange`}
      />
    </button>
  );
}

function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
