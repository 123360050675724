import { useCreateGoogleMeetingMutation } from "backend/functions";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";

interface VideoCallButtonProps {
  googleMeetingCode: string | null | undefined;
  setGoogleMeetingCode: (code: string) => void;
  hideAddButton?: boolean;
}

export default function VideoCallButton({
  googleMeetingCode,
  setGoogleMeetingCode,
  hideAddButton,
}: VideoCallButtonProps) {
  const createGoogleMeeting = useCreateGoogleMeetingMutation().mutateAsync;

  return googleMeetingCode ? (
    <ButtonWithIcon
      type="button"
      onClick={() =>
        window.open(`https://meet.google.com/${googleMeetingCode}`, "_blank")
      }
      text={"Join Video Call"}
      className="text-sm"
      size="small"
      icon={IconOption.VIDEO}
    />
  ) : hideAddButton ? null : (
    <ButtonWithIcon
      onClick={async () => {
        const response = await createGoogleMeeting();
        setGoogleMeetingCode(response?.data.meeting_code);
      }}
      type="button"
      size="small"
      className="text-sm"
      text={"Add Video Call"}
      icon={IconOption.PLUS}
    />
  );
}
