import { Cell, Row, flexRender } from "@tanstack/react-table";
import { CareCentralRoute, useAppNavigate } from "lib/routing";
import { DyadTier } from "shared/forms/CarespaceCreationWizard/CarespaceInformationForm";
import {
  InfiniteScrollingTable,
  TableDataRowType,
} from "components/Tables/InfiniteScrollingTable";
import { CarespaceDataWithBillingPageInfo } from "components/BillingPage/types";

enum PatientListColumn {
  PatientName = "Patient Name",
  DyadTier = "Dyad Tier",
  HasCaregiver = "Has Caregiver",
  IsEligible = "Is Eligible",
  id = "id",
}
type BasePatientListType = {
  [PatientListColumn.PatientName]: string;
  [PatientListColumn.DyadTier]: DyadTier;
  [PatientListColumn.HasCaregiver]: string;
  [PatientListColumn.IsEligible]: string;
  [PatientListColumn.id]: string;
};
type PatientListType = BasePatientListType &
  TableDataRowType<BasePatientListType>;

export default function PatientList({
  data,
}: {
  data: CarespaceDataWithBillingPageInfo[];
}) {
  const navigate = useAppNavigate();
  const dyadTierOrder = {
    [DyadTier.Low]: 1,
    [DyadTier.Moderate]: 2,
    [DyadTier.High]: 3,
    [DyadTier.NA]: 4,
  };
  const processData = (data: CarespaceDataWithBillingPageInfo[]) => {
    return data
      .map((d) => ({
        [PatientListColumn.PatientName]: d.name,
        [PatientListColumn.DyadTier]: d.dyad_tier,
        [PatientListColumn.HasCaregiver]: d.has_caregiver ? "Yes" : "No",
        [PatientListColumn.IsEligible]: d.is_eligible ? "Yes" : "No",
        [PatientListColumn.id]: d.id,
      }))
      .sort((a, b) => {
        const dyadComparison =
          dyadTierOrder[a[PatientListColumn.DyadTier]] -
          dyadTierOrder[b[PatientListColumn.DyadTier]];
        if (dyadComparison !== 0) {
          return dyadComparison;
        }
        const caregiverComparison = a[
          PatientListColumn.HasCaregiver
        ].localeCompare(b[PatientListColumn.HasCaregiver]);
        return caregiverComparison;
      }) as PatientListType[];
  };
  return (
    <div className="flex flex-col gap-2 w-[1000px]">
      <p className="text-xl font-bold">Patient Breakdown</p>
      <InfiniteScrollingTable
        data={processData(data)}
        hiddenColumns={[PatientListColumn.id]}
        columnFields={Object.values(PatientListColumn)}
        handleClick={(
          cell: Cell<PatientListType, unknown>,
          row: Row<PatientListType>
        ) => {
          navigate({
            path: CareCentralRoute.CARESPACE,
            params: {
              carespaceId: row.original[PatientListColumn.id] as string,
            },
          });
        }}
        CellContent={CellContent}
      />
    </div>
  );
}

function CellContent({
  cell,
  row,
}: {
  cell: Cell<PatientListType, unknown>;
  row: Row<PatientListType>;
}) {
  switch (cell.column.id) {
    default:
      return (
        <p className={`truncate text-left max-w-[200px] w-[200px]`}>
          {flexRender(cell.column.columnDef.cell, cell.getContext())}
        </p>
      );
  }
}
