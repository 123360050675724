import type { User } from "backend/resources/user";
import type { UserAdlo } from "backend/resources/userAdlo";
import type { VariantProps } from "class-variance-authority";
import { AdloInitials } from "components/AdloComponent/AdloInitials";
import type { cardStyles } from "components/Card/Card";
import type { LegalSexOptionsKeys } from "components/CarePilotOnboarding/components/BasicInformationModal";
import { legalSexOptions } from "components/CarePilotOnboarding/components/BasicInformationModal";
import { LoadingSpinner } from "components/LoadingSpinner";
import { differenceInYears } from "date-fns";

export default function AdloComponent({
  userAdlo,
  isLoading,
  withCaregiver,
  doctor,
  ...variantProps
}: {
  userAdlo: UserAdlo | undefined;
  isLoading?: boolean | null;
  withCaregiver?: User | null;
  doctor?: string | null;
} & VariantProps<typeof cardStyles>) {
  return (
    <div
      className={`flex flex-col h-full w-full max-w-full gap-2 overflow-clip line-clamp-1`}>
      {/* patient */}
      <div
        className={`flex flex-row gap-2 w-full max-w-full overflow-clip ${
          variantProps.size === "thumbnail" ? "flex-col items-center" : ""
        }`}>
        {/* avatar circle */}
        <div className="flex items-center">
          <AdloInitials
            userAdlo={userAdlo}
            size={variantProps.size === "thumbnail" ? "medium" : "default"}
          />
        </div>
        {/* patient details */}
        <div
          className={`flex flex-col w-full max-w-full overflow-clip justify-center pt-2 ${
            variantProps.size === "thumbnail" ? "text-center pt-0" : ""
          }`}>
          {/* name */}
          <p
            className={`text-lg truncate ${
              variantProps.size === "thumbnail" ? "text-base" : ""
            }`}>
            {userAdlo?.first_name} {userAdlo?.last_name}
          </p>
          {/*Birthday*/}
          <div
            className={`flex ${
              variantProps.size !== "thumbnail"
                ? "flex-col justify-start text-sm"
                : "justify-between w-full gap-2 text-xs "
            }`}>
            {userAdlo?.birthday && (
              <p
                className={`w-full ${
                  variantProps.size === "thumbnail" ? "w-full" : ""
                }`}>
                {variantProps.size === "thumbnail" ? "" : "DOB: "}
                {new Date(userAdlo?.birthday).toLocaleDateString("en-US", {
                  timeZone: "UTC",
                })}
                {variantProps.size === "thumbnail"
                  ? ""
                  : ` (${differenceInYears(new Date(), userAdlo?.birthday)})`}
              </p>
            )}
            {userAdlo?.sex ? (
              <p
                className={`w-full ${
                  variantProps.size === "thumbnail" ? "w-full" : ""
                }`}>
                {" "}
                {variantProps.size === "thumbnail" ? "" : "Sex: "}
                {legalSexOptions[userAdlo?.sex as LegalSexOptionsKeys].label[0]}
              </p>
            ) : null}
            {userAdlo?.dyad && (
              <p
                className={`w-full ${
                  variantProps.size === "thumbnail" ? "w-full" : ""
                }`}>
                {variantProps.size === "thumbnail" ? "" : "Dyad: "}
                {userAdlo?.dyad}
              </p>
            )}
          </div>
        </div>
      </div>
      {/* bottom */}
      {variantProps.size === "default" ? (
        // TODO: add bookmarks
        <div className="w-full h-8">
          {/* caregiver */}
          <div className="flex gap-2 text-sm w-full pt-2">
            {isLoading ? (
              <div className="flex justify-center">
                <LoadingSpinner className="w-6 h-6" />
              </div>
            ) : withCaregiver ? (
              <div className="flex flex-col gap-y-2 text-sm">
                <div className="flex items-center gap-1">
                  <span>Primary Caregiver:</span>
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {withCaregiver?.first_name} {withCaregiver?.last_name}
                  </span>
                </div>
                <div className="flex items-center gap-1">
                  <span>Doctor:</span>
                  <span className="overflow-hidden text-ellipsis whitespace-nowrap">
                    {doctor}
                  </span>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export function getInitials(
  userAdlo:
    | { first_name: string | null; last_name: string | null }
    | undefined
    | null
): string {
  if (!userAdlo) {
    return "";
  }

  const { first_name, last_name } = userAdlo;

  if (!first_name || !last_name) {
    return "";
  }

  return `${first_name[0].toUpperCase()}${last_name[0].toUpperCase()}`;
}
