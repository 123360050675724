import { useQuery } from "@tanstack/react-query";
import { fetchNews } from "backend/functions";
import { QUERY_KEYS } from "backend/query-keys";
import type { NewsMetadata } from "backend/resources/userGuidanceResource";
import Carousel from "components/Carousel/Carousel";
import NewsArticle from "components/HomePage/CaregiverHome/News/NewsArticle";
import { useWindowSize } from "hooks/useWindowSize";
import Skeleton from "react-loading-skeleton";

export function News() {
  const { isMobile } = useWindowSize();

  const {
    isLoading: isLoadingNews,
    data: news,
    error: newsError,
  } = useQuery({
    queryKey: [`${QUERY_KEYS.news}s`],
    queryFn: () => fetchNews(),
    refetchOnWindowFocus: false,
  });
  return (
    <section>
      <p className="text-[22px] font-light mb-4">Latest News</p>
      {isLoadingNews ? (
        <Skeleton className="w-full" />
      ) : (
        !newsError &&
        news &&
        news.length > 0 && (
          <div className="w-full">
            <Carousel
              items={news.map((article) => (
                <NewsArticle
                  guidance_resource_id={article.id}
                  metadata={article.guidance_resource?.metadata as NewsMetadata}
                />
              ))}
            />
          </div>
        )
      )}
    </section>
  );
}
