import { cva, type VariantProps } from "class-variance-authority";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

import { Title } from "./title";

const groupVariants = cva("[&_.highcharts-point]:fill-inherit", {
  variants: {
    color: {
      blue: "fill-primary-blue",
      red: "fill-primary-red",
      green: "fill-primary-green",
      orange: "fill-primary-orange",
      gray: "fill-primary-gray",
      purple: "fill-primary-purple",
    },
  },
  defaultVariants: {
    color: "gray",
  },
});

interface Group extends VariantProps<typeof groupVariants> {
  label: string;
  count: number;
}

export interface PieChartProps {
  groups: Group[];
  title?: string;
}

export const PieChart = ({ groups, title }: PieChartProps) => {
  const options: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "pie",
      height: "40%",
      alignTicks: false,
    },
    title: {
      text: "",
    },
    plotOptions: {
      pie: {
        shadow: false,
        innerSize: "50%",
        showInLegend: true,
      },
    },
    legend: {
      align: "right",
      verticalAlign: "middle",
      layout: "vertical",
      labelFormat: "{name}: {y}",
      width: "50%",
      itemStyle: {
        fontSize: "14px",
      },
    },
    tooltip: {
      enabled: true,
      pointFormat: "{series.name}: <b>{point.y}</b> ({point.percentage:.1f}%)",
    },
    series: [
      {
        type: "pie",
        data:
          groups.length > 0
            ? groups.map((group) => ({
                id: group.label,
                name: group.label,
                y: group.count,
                className: groupVariants({ color: group.color }),
              }))
            : [
                {
                  name: "No data",
                  y: 100,
                  className: groupVariants({ color: "gray" }),
                },
              ],
        dataLabels: {
          enabled: false,
        },
      } as Highcharts.SeriesPieOptions,
    ],
  };

  return (
    <div className="flex flex-col gap-2">
      {title && <Title order={2}>{title}</Title>}
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};
