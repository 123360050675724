import { useServiceDiscussion } from "backend/resources/chatGptConversation";
import { useConversationDocumentsByConversationId } from "backend/resources/conversation_document/conversation_document";
import { useNetwork } from "backend/resources/network/network";
import { useOrg, useOrgs } from "backend/resources/orgRole";
import { ServiceStatus, useUpdatePlanEntry } from "backend/resources/planEntry";
import {
  useAddConversationToServiceEngagement,
  useServiceEngagementById,
  useUpdateServiceEngagement,
} from "backend/resources/services/serviceEngagement";
import {
  useGuideTaskByServiceEngagementId,
  useSaveGuideTasksInServiceEngagementMutation,
} from "backend/resources/services/serviceEngagementToGuideTask";
import { usePlanEntriesInServiceEngagement } from "backend/resources/services/serviceEngagementToPlanEntry";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import {
  CollapsedUserDiscussion,
  DiscussionType,
  ExternalDiscussion,
} from "components/ChatGptSideBar";
import { GuideTaskSelectorPopup } from "components/GuideTaskSelectorPopup/GuideTaskSelectorPopup";
import { LoadingSpinner } from "components/LoadingSpinner";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { HeaderNamePill } from "components/Pill";
import { Select } from "components/Select";
import DocumentsUploadSection from "components/ServicePage/components/DocumentsSection";
import RespiteServicesActivitySection from "components/ServicePage/components/RespiteServicesActivitySection";
import { ServiceDetailItem } from "components/ServicePage/components/ServiceDetailItem";
import { EditServicePageButton } from "components/ServicePage/components/ServicePageEditButton";
import { TaskTable } from "components/Tables/TaskTable/TaskTable";
import { TaskTableFields } from "components/Tables/TaskTable/TaskTableTypes";
import Tabs from "components/Tabs/Tabs";
import { TextArea } from "components/TextArea";
import {
  CareCentralRoute,
  SharedRoute,
  useAppNavigate,
  useNavigateBack,
} from "lib/routing";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTaskFilterStore } from "state/taskFilter/taskFilter";

const TITLE_INTERNAL_DISCUSSION =
  "Internal Discussion  (care team and caregiver ONLY)";

export enum ServicePageTabs {
  INTERNAL = "Internal",
  EXTERNAL = "External",
}

export const GUIDE_IDENTIFIER_FOR_RESPITE_SERVICE = "9.1";

export default function ServicePage() {
  // navigation hooks
  const navigateBack = useNavigateBack();
  const navigate = useAppNavigate();
  const { id: serviceEngagementId } = useParams<{ id: string }>();

  const [currentTab, setCurrentTab] = useState<ServicePageTabs>(
    ServicePageTabs.INTERNAL
  );
  const { setCarespace: setNetwork, setGuideTask } = useTaskFilterStore();

  // queries

  const {
    data: serviceEngagementData,
    isLoading: isLoadingServiceEngagementData,
  } = useServiceEngagementById(serviceEngagementId);
  const { data: documentsInService } = useConversationDocumentsByConversationId(
    currentTab === ServicePageTabs.INTERNAL
      ? serviceEngagementData?.conversation_id
      : serviceEngagementData?.external_conversation_id
  );

  const { data: guideTasksInService } = useGuideTaskByServiceEngagementId({
    service_engagement_id: serviceEngagementId,
    network_id: serviceEngagementData?.network_id,
  });

  const { data: todosInService } =
    usePlanEntriesInServiceEngagement(serviceEngagementId);

  const { data: networkData, isLoading: isLoadingNetwork } = useNetwork(
    serviceEngagementData?.network_id
  );
  const { data: orgData, isLoading: isLoadingOrgData } = useOrg(
    networkData?.organization_id
  );

  const { hasCareCentralAccess } = useOrgs();
  const {
    data: serviceDiscussionData,
    isLoading: isLoadingServiceDiscussionData,
  } = useServiceDiscussion(
    serviceEngagementId,
    currentTab === ServicePageTabs.EXTERNAL
  );

  // state
  const [note, setNote] = useState<string | undefined | null>(
    serviceEngagementData?.notes
  );
  const [guideTaskPopupState, setGuideTaskPopupState] = useState<{
    isOpen: boolean;
    showOnlySelected: boolean;
  }>({
    isOpen: false,
    showOnlySelected: false,
  });

  // mutations
  const updateServiceEngagement = useUpdateServiceEngagement().mutateAsync;
  const saveServiceEngagementToGuideTask =
    useSaveGuideTasksInServiceEngagementMutation().mutateAsync;
  const addConversationToServiceEngagement =
    useAddConversationToServiceEngagement().mutateAsync;
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;

  useEffect(() => {
    if (!isLoadingServiceDiscussionData && !serviceDiscussionData) {
      addConversationToServiceEngagement({
        serviceEngagementId,
        isExternal: currentTab === ServicePageTabs.EXTERNAL,
        service_resource_id: serviceEngagementData?.service_resource_id,
        networkId: serviceEngagementData?.network_id,
      });
    }
  }, [
    serviceDiscussionData,
    isLoadingServiceDiscussionData,
    serviceEngagementData?.network_id,
    serviceEngagementData?.service_resource_id,
    serviceEngagementId,
  ]);

  // Sync local note state with service engagement data on load or update service engagement data when note changes
  // Skip update if service engagement data is not available or note is not yet set (undefined)
  useEffect(() => {
    if (serviceEngagementData) {
      // undefined means it hasn't been set yet, while null means it has no note
      if (note === undefined) {
        setNote(serviceEngagementData.notes ?? undefined);
      } else {
        updateServiceEngagement({
          id: serviceEngagementData?.id,
          notes: note ?? null,
        });
      }
    }
  }, [note, serviceEngagementData]);

  // consts
  const serviceStatusOptions = [
    { label: ServiceStatus.Active, value: ServiceStatus.Active },
    { label: ServiceStatus.Inactive, value: ServiceStatus.Inactive },
  ];
  const isRespiteService = guideTasksInService?.find(
    (task) => task?.GUIDE_identifier === GUIDE_IDENTIFIER_FOR_RESPITE_SERVICE
  );

  // -----------------------// -----------------------// -----------------------// -----------------------//

  if (
    isLoadingServiceEngagementData ||
    isLoadingNetwork ||
    isLoadingOrgData ||
    !serviceEngagementData ||
    !serviceEngagementId
  ) {
    return <LoadingSpinner />;
  }

  return (
    <PageContainer>
      {/* GUIDE Task selector */}
      <GuideTaskSelectorPopup
        networkId={serviceEngagementData.network_id}
        preSelectedTaskIds={
          guideTasksInService
            ?.filter((service) => !!service)
            ?.map((service) => service?.id ?? "") ?? []
        }
        isOpen={guideTaskPopupState.isOpen}
        onClose={() =>
          setGuideTaskPopupState({ ...guideTaskPopupState, isOpen: false })
        }
        handleSubmit={async (canonicalGuideTasks) => {
          await saveServiceEngagementToGuideTask({
            newGuideTaskIds: canonicalGuideTasks.map((task) => task.id),
            serviceEngagementId,
            networkId: serviceEngagementData.network_id,
          });
        }}
        showOnlySelected={guideTaskPopupState.showOnlySelected}
      />

      <div className="flex flex-col gap-2 max-w-3xl">
        <div className="flex flex-col gap-1">
          <ButtonWithIcon
            onClick={navigateBack}
            text={"Back"}
            icon={IconOption.BACK_ARROW}
            size="small"
            type="button"
          />
          <div className="flex gap-4 w-full mt-2">
            <HeaderNamePill
              text={orgData?.name}
              color="#5D63BA"
            />
            <HeaderNamePill
              text={networkData?.name}
              color="#176590"
            />
          </div>
          <div className="flex gap-5">
            <PageMainHeader text={serviceEngagementData?.name} />
            {hasCareCentralAccess && <EditServicePageButton />}
          </div>
          <Tabs
            tabs={Object.values(ServicePageTabs)}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
        </div>

        {currentTab === ServicePageTabs.INTERNAL && (
          <>
            <div>
              <p>Details</p>
              <TextArea
                disabled
                className="w-full"
                value={serviceEngagementData.details ?? undefined}
              />
            </div>
            <div className="flex flex-col gap-10">
              {/* Detail items */}
              <div className="grid grid-cols-2  gap-x-12  items-center gap-y-2 ">
                {/* Provider details */}
                <ServiceDetailItem
                  sectionLabel="Provider"
                  secondItem={
                    <ButtonWithIcon
                      className="line-clamp-1 w-[200px]"
                      onClick={() => {
                        navigate({
                          path: SharedRoute.SERVICE_PROVIDER_PAGE,
                          params: {
                            id: serviceEngagementData.service_resource_id,
                          },
                        });
                      }}
                      text={serviceEngagementData.service_resource?.name ?? ""}
                      icon={IconOption.FORWARD}
                      size="small"
                    />
                  }
                />

                {/* GUIDE task details */}
                {hasCareCentralAccess && (
                  <ServiceDetailItem
                    sectionLabel={
                      <ButtonWithIcon
                        onClick={() =>
                          setGuideTaskPopupState({
                            ...guideTaskPopupState,
                            isOpen: true,
                            showOnlySelected: false,
                          })
                        }
                        text={"GUIDE Task(s)"}
                        icon={IconOption.PLUS}
                        className="flex-row-reverse flex justify-end"
                        size="small"
                      />
                    }
                    secondItem={
                      guideTasksInService && guideTasksInService?.length > 0 ? (
                        <>
                          <div className="flex items-center">
                            <button
                              onClick={() => {
                                setNetwork(serviceEngagementData?.network_id);
                                setGuideTask(guideTasksInService[0]?.id);
                                navigate({
                                  path: SharedRoute.HOME,
                                });
                              }}
                              className="text-sm truncate">
                              {guideTasksInService[0]?.title}
                            </button>
                            {guideTasksInService.length > 1 && (
                              <p
                                onClick={() =>
                                  setGuideTaskPopupState({
                                    ...guideTaskPopupState,
                                    isOpen: true,
                                    showOnlySelected: true,
                                  })
                                }
                                className="text-sm underline cursor-pointer text-brand-orange ml-2">
                                (See all)
                              </p>
                            )}
                          </div>
                        </>
                      ) : (
                        <p className="text-sm text-zinc-400">
                          No guide task attached
                        </p>
                      )
                    }
                  />
                )}

                {/* Status details */}
                <ServiceDetailItem
                  sectionLabel="Status"
                  secondItem={
                    <Select
                      disabled={!hasCareCentralAccess}
                      options={serviceStatusOptions}
                      currentOption={serviceStatusOptions.find(
                        (option) =>
                          option.value ===
                          (serviceEngagementData?.is_active
                            ? ServiceStatus.Active
                            : ServiceStatus.Inactive)
                      )}
                      onChange={async (value) => {
                        if (serviceEngagementData) {
                          const { service_resource, ...rest } =
                            serviceEngagementData;
                          const now = new Date().toISOString();
                          await updateServiceEngagement({
                            ...rest,
                            is_active: value === ServiceStatus.Active,
                            created_at:
                              value === ServiceStatus.Active
                                ? now
                                : rest.created_at,
                            became_inactive_at:
                              value === ServiceStatus.Inactive ? now : null,
                          });
                        }
                      }}
                    />
                  }
                />

                {/* Date Active details */}
                <ServiceDetailItem
                  sectionLabel="Date Active"
                  secondItem={new Date(
                    serviceEngagementData?.created_at
                  ).toLocaleDateString()}
                />
                {/* Date Inactive details */}
                {serviceEngagementData?.became_inactive_at && (
                  <ServiceDetailItem
                    sectionLabel="Date Inactive"
                    secondItem={new Date(
                      serviceEngagementData?.became_inactive_at
                    ).toLocaleDateString()}
                  />
                )}
              </div>

              <CollapsedUserDiscussion
                discussionType={DiscussionType.Service}
                threadId={serviceEngagementId}
                title={TITLE_INTERNAL_DISCUSSION}
              />

              {/* Todos */}
              <div className="flex flex-col h-full">
                <div className="flex gap-3">
                  <p>Subtasks</p>
                  <ButtonWithIcon
                    icon={IconOption.PLUS}
                    text="Create New"
                    size="small"
                    onClick={() =>
                      navigate({
                        path: CareCentralRoute.NEW_TASK,
                        queryParams: {
                          service_engagement_id: serviceEngagementId ?? null,
                        },
                      })
                    }
                  />
                </div>
                {todosInService && todosInService.length > 0 ? (
                  <TaskTable
                    data={todosInService ?? []}
                    updateTask={updatePlanEntry}
                    hiddenColumns={[
                      TaskTableFields.TaskType,
                      TaskTableFields.Category,
                      TaskTableFields.ServiceStatus,
                      TaskTableFields.CreatedAt,
                    ]}
                  />
                ) : (
                  <p className="text-sm text-zinc-400">No subtasks added yet</p>
                )}
              </div>
            </div>
          </>
        )}
        <div className="flex flex-col gap-10 pt-10">
          {currentTab === ServicePageTabs.EXTERNAL && (
            <ExternalDiscussion
              email={serviceEngagementData.service_resource?.email}
              discussionType={DiscussionType.ServiceExternal}
              threadId={serviceEngagementId}
              resourceId={serviceEngagementData.service_resource_id}
            />
          )}

          {/* Documents */}
          <DocumentsUploadSection
            documents={
              documentsInService?.map((doc) => ({ ...doc, is_new: false })) ??
              []
            }
            conversationId={
              currentTab === ServicePageTabs.INTERNAL
                ? serviceEngagementData.conversation_id
                : serviceEngagementData.external_conversation_id
            }
            networkId={serviceEngagementData.network_id}
            isExternal={currentTab === ServicePageTabs.EXTERNAL}
          />

          {/* Notes */}
          {hasCareCentralAccess && (
            <div className="flex flex-col gap-2">
              <p className="text-xl">Notes</p>
              <TextArea
                className="h-[10rem]"
                onChange={(e) => setNote(e.currentTarget.value)}
                value={note ?? undefined}
                placeholder="Type your note..."
                data-hj-allow
              />
            </div>
          )}

          {isRespiteService && (
            <RespiteServicesActivitySection
              serviceEngagement={serviceEngagementData}
            />
          )}
        </div>

        <div></div>
      </div>
    </PageContainer>
  );
}
