import { flexRender } from "@tanstack/react-table";
import { Cell, Row } from "@tanstack/table-core";
import { useEffect, useState } from "react";
import { useGuideTasks } from "backend/resources/guide/guideTask";
import { SharedRoute, useAppNavigate } from "lib/routing";
import { ResponsiveModal } from "shared/ui/responsive-modal";
import { useTaskFilterStore } from "state/taskFilter/taskFilter";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { LoadingSpinner } from "components/LoadingSpinner";
import {
  InfiniteScrollingTable,
  TableDataRowType,
} from "components/Tables/InfiniteScrollingTable";
import { GuideCategoryColorStyle } from "components/TaskNavigatorPage/taskTableUtils";

export enum CanonicalGuideTaskFields {
  Task = "Task",
  Category = "Category",
  ID = "id",
  IsSelected = "isSelected",
  RecurringInterval = "RecurringInterval",
}

type BaseCanonicalGuideTaskType = {
  [CanonicalGuideTaskFields.Task]: string;
  [CanonicalGuideTaskFields.Category]: string;
  [CanonicalGuideTaskFields.ID]: string;
  [CanonicalGuideTaskFields.IsSelected]: boolean;
  [CanonicalGuideTaskFields.RecurringInterval]: string | null;
};

export type CanonicalGuideTaskType = BaseCanonicalGuideTaskType &
  TableDataRowType<BaseCanonicalGuideTaskType>;

interface GuideTaskSelectorPopupProps {
  isOpen: boolean;
  onClose: () => void;
  handleSubmit: (canonicalGuideTasks: CanonicalGuideTaskType[]) => void;
  preSelectedTaskIds?: string[];
  showOnlySelected?: boolean;
  networkId: string | undefined;
}

export function GuideTaskSelectorPopup({
  isOpen,
  onClose,
  handleSubmit,
  preSelectedTaskIds,
  showOnlySelected,
  networkId,
}: GuideTaskSelectorPopupProps) {
  // queries
  const navigate = useAppNavigate();
  const { data: guideTasks, isLoading: isGuideTasksLoading } = useGuideTasks();
  const [data, setData] = useState<CanonicalGuideTaskType[]>(() => []);
  const { setCarespace: setNetwork, setGuideTask } = useTaskFilterStore();

  useEffect(() => {
    // Updates data
    if (guideTasks) {
      let mappedData = guideTasks.map((item) => ({
        [CanonicalGuideTaskFields.ID]: item.id,
        [CanonicalGuideTaskFields.Task]: item.title,
        [CanonicalGuideTaskFields.Category]: item?.guide_category?.title ?? "",
        [CanonicalGuideTaskFields.IsSelected]:
          preSelectedTaskIds?.includes(item.id) ?? false,
        [CanonicalGuideTaskFields.RecurringInterval]:
          item.recurring_cadence as string,
      }));
      if (showOnlySelected) {
        mappedData = mappedData.filter(
          (item) => item[CanonicalGuideTaskFields.IsSelected]
        );
      }
      setData(mappedData);
    }
  }, [guideTasks, preSelectedTaskIds]);

  const cellContent = ({
    cell,
    row,
  }: {
    cell: Cell<CanonicalGuideTaskType, unknown>;
    row: Row<CanonicalGuideTaskType>;
  }) => {
    switch (cell.column.columnDef.id) {
      case CanonicalGuideTaskFields.Category:
        return (
          <div
            className="rounded-full flex gap-1 text-white text-center"
            style={{
              background: GuideCategoryColorStyle[row.original.Category],
            }}>
            <p className="rounded-full truncate text-xs py-1 text-center text-white px-4 w-full w-min-[150px] max-w-[150px]">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </p>
          </div>
        );
      case CanonicalGuideTaskFields.Task:
        return (
          <div className="rounded-full max-w-[200px] w-[200px] flex gap-2 text-black">
            <p className="truncate text-left">
              {flexRender(cell.column.columnDef.cell, cell.getContext())}
            </p>
          </div>
        );
      default:
        return (
          <div>{flexRender(cell.column.columnDef.cell, cell.getContext())}</div>
        );
    }
  };

  return (
    <ResponsiveModal
      isOpen={isOpen}
      onClose={onClose}
      title={preSelectedTaskIds ? "Selected Task(s)" : "Select Task(s)"}
      footerButtons={
        <>
          <ButtonWithIcon
            onClick={onClose}
            icon={IconOption.CANCEL}
            text="Cancel"
          />
          <ButtonWithIcon
            onClick={() => {
              handleSubmit(
                data.filter((row) => row[CanonicalGuideTaskFields.IsSelected])
              );
              onClose();
            }}
            icon={IconOption.CHECKMARK}
            text="Done"
          />
        </>
      }>
      {!isGuideTasksLoading ? (
        <InfiniteScrollingTable
          data={data}
          columnFields={Object.values(CanonicalGuideTaskFields)}
          handleClick={function (
            cell: Cell<CanonicalGuideTaskType, unknown>,
            row: Row<CanonicalGuideTaskType>
          ): void {
            if (preSelectedTaskIds) {
              setGuideTask(row.original[CanonicalGuideTaskFields.ID]);
              setNetwork(networkId);
              navigate({
                path: SharedRoute.HOME,
              });
            }
          }}
          hiddenColumns={[
            CanonicalGuideTaskFields.ID,
            CanonicalGuideTaskFields.IsSelected,
            CanonicalGuideTaskFields.RecurringInterval,
          ]}
          CellContent={cellContent}
          shouldPreventClicks={!preSelectedTaskIds}
          // If showOnlySelected is true, we don't want to show the checkbox
          checkboxOptions={
            showOnlySelected
              ? undefined
              : {
                  onCheck: (
                    row: Row<CanonicalGuideTaskType>,
                    isChecked: boolean
                  ) => {
                    setData((prevData) =>
                      prevData.map((item) => {
                        if (
                          item[CanonicalGuideTaskFields.ID] ===
                          row.original[CanonicalGuideTaskFields.ID]
                        ) {
                          return {
                            ...item,
                            [CanonicalGuideTaskFields.IsSelected]: isChecked,
                          };
                        }
                        return item;
                      })
                    );
                  },
                  isChecked: (row: Row<CanonicalGuideTaskType>) =>
                    row.original[CanonicalGuideTaskFields.IsSelected],
                  hideStrikeThrough: true,
                }
          }
        />
      ) : (
        <LoadingSpinner />
      )}
    </ResponsiveModal>
  );
}
