import { ReactNode, useState } from "react";
import BottomActionBar from "components/BottomActionBar/BottomActionBar";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { BottomSheet } from "components/Sheet";
import { useWindowSize } from "hooks/useWindowSize";

export default function ActionButtons({
  children,
  title,
}: {
  children: ReactNode;
  title?: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useWindowSize();
  if (!isMobile) {
    return <>{children}</>;
  } else if (!isOpen) {
    return (
      <BottomActionBar
        centerSlot={
          <ButtonWithIcon
            onClick={() => {
              setIsOpen(true);
            }}
            text={""}
            icon={IconOption.MORE}
          />
        }
      />
    );
  } else {
    return (
      <BottomSheet
        isOpen={true}
        onClose={() => setIsOpen(false)}>
        <div
          className="flex flex-col gap-4 items-start pl-10 pb-10"
          onClick={() => setIsOpen(false)}>
          <h1 className="text-lg">{title}</h1>
          {children}
        </div>
      </BottomSheet>
    );
  }
}
