import {
  ServiceStatus,
  TaskStatus,
  TaskWithGuideInfo,
} from "backend/resources/planEntry";

export enum TaskTableRowType {
  ONBOARDING = "Onboarding",
  GENERAL = "General",
  SERVICE_REQUEST = "SR",
  CARE_PLAN = "Care Plan",
}

export const TaskTypeColor = {
  [TaskTableRowType.ONBOARDING]: "#75B0B4",
  [TaskTableRowType.SERVICE_REQUEST]: "#8690EE",
  [TaskTableRowType.GENERAL]: "#45C4EC",
  [TaskTableRowType.CARE_PLAN]: "#45C4EC",
};

export function getTaskType(task: TaskWithGuideInfo) {
  if (!!task.is_service_ticket) {
    return TaskTableRowType.SERVICE_REQUEST;
  } else if (!!task.guide_task_id) {
    return TaskTableRowType.ONBOARDING;
  } else {
    return TaskTableRowType.GENERAL;
  }
}

export enum TaskTableFields {
  TaskName = "Task Name",
  Account = "Account",
  Carespace = "Carespace",
  TaskType = "Type",
  CategoryFullTitle = "Category Title",
  Category = "Category",
  Status = "Status",
  Due = "Due",
  RecurringInterval = "recurring_interval",
  Who = "Who",
  NetworkId = "network_id",
  Id = "id",
  ServiceStatus = "Service Status",
  GUIDEIdentifier = "GUIDE_identifier",
  CreatedAt = "Created",
  RawData = "Raw Data",
  HasDocumentAttached = "Has Document Attached",
}

export type TaskTableType = {
  [TaskTableFields.TaskName]: string;
  [TaskTableFields.Account]: string;
  [TaskTableFields.Carespace]: string;
  [TaskTableFields.CategoryFullTitle]: string;
  [TaskTableFields.Category]: string;
  [TaskTableFields.Status]: TaskStatus;
  [TaskTableFields.Due]: string;
  [TaskTableFields.Who]: string | null;
  [TaskTableFields.NetworkId]: string;
  [TaskTableFields.RecurringInterval]: string | null;
  [TaskTableFields.Id]: string;
  [TaskTableFields.ServiceStatus]: ServiceStatus;
  [TaskTableFields.GUIDEIdentifier]: string;
  [TaskTableFields.CreatedAt]: string;
  [TaskTableFields.TaskType]: string;
  [TaskTableFields.HasDocumentAttached]: boolean;
  ["subRows"]?: TaskTableType[];
  [TaskTableFields.RawData]: TaskWithGuideInfo;
};
