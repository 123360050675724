import {
  TaskStatus,
  usePlanEntries,
  useUpdatePlanEntry,
} from "backend/resources/planEntry";
import { ButtonWithIcon, IconOption } from "components/ButtonWithIcon";
import { TaskTable } from "components/Tables/TaskTable/TaskTable";
import { TaskTableFields } from "components/Tables/TaskTable/TaskTableTypes";
import dayjs from "dayjs";
import { CarePilotRoute, useAppNavigate } from "lib/routing";
import { useGptStore } from "state/gpt";

export default function UpcomingActivities() {
  const setSidebarType = useGptStore((state) => state.setType);
  const navigate = useAppNavigate();
  // Query
  const { planEntries } = usePlanEntries({ includeAll: true });
  // Mutation
  const updatePlanEntry = useUpdatePlanEntry().mutateAsync;

  // Upcoming means it's not done and due later than now.
  const upcomingPlanEntries =
    planEntries
      ?.filter((entry) => entry.status !== TaskStatus.Done)
      .sort(
        (a, b) =>
          dayjs(a.scheduled_date_time).unix() -
          dayjs(b.scheduled_date_time).unix()
      ) ?? [];

  // -----------*-----------*-----------*-----------*-----------*-----------*-----------*-----------
  return (
    <div>
      <ButtonWithIcon
        onClick={() =>
          navigate({
            path: CarePilotRoute.PLAN,
          })
        }
        text={"Tasks"}
        icon={IconOption.ARROW}
        size="small"
        className="text-[22px] flex-row-reverse"
      />
      {upcomingPlanEntries?.length === 0 ? (
        <div className="pb-6 pt-2">
          <label className="font-poppins text-sm">
            You don’t have any upcoming tasks. Click on
            <a
              onClick={async () => {
                setSidebarType("todosPage");
              }}
              className="text-brand-orange"
              href={CarePilotRoute.PLAN}>
              {" "}
              Tasks{" "}
            </a>
            to get started!
          </label>
        </div>
      ) : (
        <div>
          <div className="flex flex-col ">
            <TaskTable
              data={upcomingPlanEntries.slice(0, 3)}
              updateTask={updatePlanEntry}
              hiddenColumns={[
                TaskTableFields.CreatedAt,
                TaskTableFields.ServiceStatus,
                TaskTableFields.Carespace,
                TaskTableFields.Category,
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
}
