import type { UserAssessmentChatGptConversation } from "backend/resources/chatGptConversation";
import { useConversation } from "backend/resources/chatGptConversation";
import { useConversationDocumentsByConversationId } from "backend/resources/conversation_document/conversation_document";
import { useNetwork } from "backend/resources/network/network";
import type { ServiceResource } from "backend/resources/services/serviceResource";
import type { User } from "backend/resources/user";
import BackButton from "components/BackButton/BackButton";
import {
  DiscussionType,
  ProviderEmailForm,
  UserDiscussion,
} from "components/ChatGptSideBar";
import { DiscussionTypeToColor } from "components/DiscussionComponent/components/DiscussionsFilter";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import { HeaderNamePill } from "components/Pill";
import type { DocumentSchemaType } from "components/ServicePage/components/DocumentsSection";
import DocumentsUploadSection from "components/ServicePage/components/DocumentsSection";
import { useParams } from "react-router-dom";
import { useUserStore } from "state/user";

export default function ConversationPage() {
  const { conversation_id } = useParams();
  const { data: conversation } = useConversation(conversation_id);
  const authUser = useUserStore((state) => state.user);

  // Queries
  const { data: network } = useNetwork(conversation?.network_id ?? undefined);
  const { data: documents, isLoading: isDocumentsLoading } =
    useConversationDocumentsByConversationId(conversation_id);

  const isExternal = !!conversation?.external_participant_id;

  function getTitle(
    conversation:
      | undefined
      | null
      | (UserAssessmentChatGptConversation & {
          service_resource: ServiceResource | null;
          conversation_participant: {
            user: User | null;
          }[];
        })
  ) {
    if (!conversation) return "";

    if (conversation.type === "General") {
      return "General Discussion";
    } else if (conversation.type === "Private") {
      if (
        conversation.service_resource &&
        conversation.external_participant_id
      ) {
        return `External Discussion with ${conversation.service_resource.name}`;
      } else {
        const participants = conversation.conversation_participant
          .filter((participant) => participant.user?.id !== authUser?.id)
          .map(
            (participant) =>
              `${participant.user?.first_name} ${participant.user?.last_name}`
          )
          .join(", ");
        return `Discussion with ${participants}`;
      }
    }
    return "";
  }

  if (!network) return null;
  return (
    <PageContainer>
      <BackButton className="mb-2" />
      <div className="flex gap-4 items-center">
        <HeaderNamePill text={network?.name} />
        {conversation?.type && (
          <HeaderNamePill
            text={`${conversation?.type}${isExternal ? "-EXT" : ""}`}
            color={DiscussionTypeToColor[conversation?.type]}
          />
        )}
      </div>
      <PageMainHeader text={getTitle(conversation)} />
      {conversation?.service_resource &&
      !conversation.service_resource.email ? (
        <div className="flex gap-5 pt-10 max-w-[800px]">
          <ProviderEmailForm resourceId={conversation.service_resource.id} />
        </div>
      ) : (
        <div className="h-full pb-24 w-full pt-2">
          <UserDiscussion
            discussionType={DiscussionType.Private}
            hideBorder
            threadId={conversation_id}
          />
          <DocumentsUploadSection
            documents={
              documents?.map((doc: DocumentSchemaType) => ({
                ...doc,
                is_new: false,
              })) ?? []
            }
            conversationId={conversation_id}
            networkId={network.id}
            isExternal={isExternal}
          />
        </div>
      )}
    </PageContainer>
  );
}
