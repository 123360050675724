import { useOrgs } from "backend/resources/orgRole";
import type { OrgRoleType } from "backend/resources/userRole/types";
import { OrgRoleTypeToLabel } from "backend/resources/userRole/types";
import { useActiveOrganizationId } from "state/organization/organization";
import { PageContainer } from "components/PageContainer";
import { PageMainHeader } from "components/PageMainHeader";
import {
  ListType,
  RoleTableFields,
  UserRoleTable,
} from "components/Tables/UserRoleTable";

export default function DirectoryPage() {
  return (
    <PageContainer>
      <PageMainHeader text={"Directory"} />
      <InternalDirectorySubPage />
    </PageContainer>
  );
}

function InternalDirectorySubPage() {
  const { allOrgIdentities } = useOrgs();
  const activeOrgId = useActiveOrganizationId();

  const activeOrgRoles = allOrgIdentities?.filter(
    (organizationRole) => organizationRole.organization_id === activeOrgId
  );

  return (
    <div>
      <UserRoleTable
        hiddenColumns={[RoleTableFields.WorkSpaceName]}
        data={
          activeOrgRoles?.map((activeOrgRole) => ({
            [RoleTableFields.MemberName]:
              `${activeOrgRole.user.first_name} ${activeOrgRole.user.last_name}` ??
              "",
            [RoleTableFields.Role]:
              OrgRoleTypeToLabel[activeOrgRole.role as OrgRoleType] ?? "",
            [RoleTableFields.Id]: activeOrgRole.organization?.id ?? "",
            [RoleTableFields.UserId]: activeOrgRole.user.id ?? "",
            [RoleTableFields.WorkSpaceName]:
              activeOrgRole.organization?.name ?? "",
            [RoleTableFields.roleObject]: activeOrgRole,
          })) ?? []
        }
        listType={ListType.Users}
      />
    </div>
  );
}
