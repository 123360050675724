import { useMutation, useQuery } from "@tanstack/react-query";
import { queryClient } from "app";
import { QUERY_KEYS } from "backend/query-keys";
import type { User } from "backend/resources/user";
import { NetworkRoleType } from "backend/resources/userRole/types";
import { supabase } from "clients/supabaseClient";
import { OrgMember } from "shared/forms/CarespaceCreationWizard/OrgMemberAutocomplete";
import { useActiveNetworkId } from "state/network/network";
import { useUserStore } from "state/user";
import type { Database } from "../../../../types/supabase";

const TABLE = "user_role";
export type UserRole = Database["public"]["Tables"]["user_role"]["Row"];
export type UserRoleInsert =
  Database["public"]["Tables"]["user_role"]["Insert"];
export type UserRoleUpdate =
  Database["public"]["Tables"]["user_role"]["Update"];

export function useUserIsFamilyMember() {
  const {
    userRole,
    isUserRoleLoading: isLoadingUserIsFamilyMember,
    userRoleError,
  } = useActiveUserRole();
  const userIsFamilyMember =
    userRole && userRole.role === NetworkRoleType.FAMILY_MEMBER;

  return { isLoadingUserIsFamilyMember, userIsFamilyMember, userRoleError };
}

export const useActiveUserRole = () => {
  const authUser = useUserStore((state) => state.user);
  const activeNetworkId = useActiveNetworkId();
  const {
    isLoading: isUserRoleLoading,
    error: userRoleError,
    data: userRoleData,
  } = useQuery({
    queryKey: [QUERY_KEYS.userRole, { userId: authUser?.id, activeNetworkId }],
    queryFn: () => fetchUserRole(authUser?.id, activeNetworkId),
    refetchOnWindowFocus: false,
  });
  return { userRole: userRoleData, isUserRoleLoading, userRoleError };
};

export const useUserRoleFromId = (userRoleId?: string) => {
  const activeNetworkId = useActiveNetworkId();
  return useQuery({
    queryKey: [QUERY_KEYS.userRole, { userRoleId, activeNetworkId }],
    queryFn: () => fetchUserRole(userRoleId, activeNetworkId),
    refetchOnWindowFocus: false,
  });
};

export const useUserRolesInNetwork = (networkId?: string) => {
  const activeNetworkId = useActiveNetworkId();
  const selectedNetworkId = networkId ?? activeNetworkId;
  const result = useQuery({
    queryKey: [QUERY_KEYS.userRole, { activeNetworkId: selectedNetworkId }],
    queryFn: () => fetchUserRoles(selectedNetworkId),
  });

  return {
    ...result,
    primaryCaregivers: result.data?.filter(
      (userRole) => userRole.role === NetworkRoleType.PRIMARY_CAREGIVER
    ),
  };
};

export const useUserFromUserRoleId = (user_role_id?: string | null) => {
  const activeNetworkId = useActiveNetworkId();
  return useQuery({
    queryKey: [QUERY_KEYS.userRole, { user_role_id, activeNetworkId }],
    queryFn: () => fetchUserFromUserRoleId(user_role_id, activeNetworkId),
    refetchOnWindowFocus: false,
  });
};

export async function updateUserRole(userRole: UserRoleUpdate) {
  if (!userRole.id) return;

  const { data, error } = await supabase
    .from(TABLE)
    .update({ ...userRole, id: undefined })
    .eq("id", userRole.id)
    .select("*")
    .limit(1)
    .order("id", { ascending: true }) // noop
    .maybeSingle();
  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useUpdateUserRole = () => {
  return useMutation({
    mutationFn: async ({
      roleId,
      newRole,
      isDeactivated,
    }: {
      roleId: string;
      newRole?: NetworkRoleType;
      isDeactivated?: boolean;
    }) => {
      const response = await updateUserRole({
        id: roleId,
        role: newRole,
        is_deactivated: isDeactivated,
      });
      // Need to update the user role and the invitations since that's
      // How we keep track of team members in admin

      return response;
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.invitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.invitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.userRole],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.orgAndCarespaceIdentities],
      });
    },
  });
};

async function insertUserRole(userRoleInsert: UserRoleInsert) {
  const { data, error } = await supabase
    .from(TABLE)
    .insert(userRoleInsert)
    .select();

  if (error) {
    throw new Error(error.message);
  }

  return data;
}

export const useInsertProviderToNetwork = () => {
  const insertUserRole = useInsertUserRole().mutateAsync;

  return useMutation({
    mutationFn: async ({
      carespaceId,
      selectedOrgMember,
    }: {
      carespaceId: string;
      selectedOrgMember: OrgMember;
    }) => {
      const roleRes = await insertUserRole({
        userRoleInsert: {
          is_deactivated: false,
          network_id: carespaceId,
          role: selectedOrgMember.org_role,
          user_id: selectedOrgMember.user_id,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.networksInOrganization],
      });
    },
  });
};

export const useInsertUserRole = () => {
  return useMutation({
    mutationFn: async ({
      userRoleInsert,
    }: {
      userRoleInsert: UserRoleInsert;
    }) => {
      const response = await insertUserRole(userRoleInsert);
      return response;
    },
    onSuccess: (data, variables, context) => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.invitation],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.carespaceMembers],
      });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.userRole],
      });
    },
  });
};

// fetch and queries
export function useNetworkIds() {
  const authUser = useUserStore((state) => state.user);

  const { isLoading, error, data } = useQuery({
    queryKey: [QUERY_KEYS.networkIds, { userId: authUser?.id }],
    queryFn: () => fetchNetworkIds(authUser?.id),
    enabled: !!authUser,
  });
  return {
    networkIds: data,
    networkIdsError: error,
    networkIdsIsLoading: isLoading,
  };
}

export async function fetchUserRole(
  user_id: string | undefined,
  network_id: string | undefined
) {
  if (!user_id || !network_id) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select()
    .eq("user_id", user_id)
    .eq("network_id", network_id)
    .limit(1)
    .maybeSingle();

  if (error) {
    return null;
  }

  return data;
}

export async function fetchUserRoles(network_id: string | undefined) {
  if (!network_id) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select("*, user(*)")
    .eq("network_id", network_id)
    .order("created_at", { ascending: true })
    .returns<(UserRole & { user: User })[]>();

  if (error) {
    return null;
  }

  return data;
}

export async function fetchUserFromUserRoleId(
  user_role_id: string | undefined | null,
  network_id: string | undefined
) {
  if (!user_role_id || !network_id) {
    return null;
  }

  const { data, error } = await supabase
    .from(TABLE)
    .select("*, user(*)")
    .eq("id", user_role_id)
    .eq("network_id", network_id)
    .returns<(UserRole & { user: User })[]>()
    .limit(1)
    .maybeSingle();

  return data;
}

export async function fetchNetworkIds(user_id?: string) {
  if (!user_id) {
    return undefined;
  }

  const { data, error } = await supabase.from(TABLE).select("network_id");

  if (error) {
    throw new Error(error.message);
  }

  return data.map((role) => role.network_id || undefined);
}

export type UserWithRole = { user: User; role: NetworkRoleType };
export function useUsersInNetwork(networkId?: string) {
  return useQuery({
    queryKey: [QUERY_KEYS.usersInNetwork, { networkId: networkId }],
    queryFn: async () => {
      let query = supabase
        .from(TABLE)
        .select("user(*),role")
        .neq("role", "family member");

      if (networkId && networkId !== "All") {
        query = query.eq("network_id", networkId);
      }

      const { data, error } = await query;
      if (error) {
        throw new Error(error.message);
      }

      return data as UserWithRole[];
    },
    refetchOnWindowFocus: false,
  });
}
